/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  H3,
  InputDurationMaskWithSuffix,
  InputFormGroup,
  Label,
  MaskedInputWithSuffix
} from '@bonitour/components'

import { scalarFormaterFunction } from '../AlgorithmSetup.schema'
import { noGrow } from '../AlgorithmSetup.style'
import { Toggle } from '../Toggle'
import { InputGroup, Title } from '../CommonComponents'

const LunchPeriodInput = ({ id, value, onChange, onBlur, error }) => {
  return (
    <InputFormGroup horizontal errorMessage={error} customCss={[noGrow]}>
      {/* @ts-ignore */}
      <InputDurationMaskWithSuffix
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
      >
        Hrs
      </InputDurationMaskWithSuffix>
    </InputFormGroup>
  )
}

export const LunchFormSection = ({
  form,
  errors,
  onInputChange,
  onInputBlur
}) => {
  return (
    <>
      <Title>
        <H3>Almoço</H3>
      </Title>
      <Toggle
        checked={form.hasLunch}
        id='has-lunch'
        label='Possui almoço'
        onChange={onInputChange('hasLunch')}
      >
        <InputGroup>
          <Label>Período de almoço de</Label>
          <LunchPeriodInput
            id='lunchPeriodStart'
            value={form.lunchPeriodStart}
            onBlur={onInputBlur('lunchPeriodStart')}
            onChange={onInputChange('lunchPeriodStart')}
            error={errors.lunchPeriodStart}
          />
          <Label>até as</Label>
          <LunchPeriodInput
            id='lunchPeriodEnd'
            value={form.lunchPeriodEnd}
            onBlur={onInputBlur('lunchPeriodEnd')}
            onChange={onInputChange('lunchPeriodEnd')}
            error={errors.lunchPeriodEnd}
          />
          <Label>com duração de</Label>
          <InputFormGroup
            horizontal
            errorMessage={errors.lunchDuration}
            customCss={[noGrow]}
          >
            {/* @ts-ignore */}
            <MaskedInputWithSuffix
              id='lunchDuration'
              value={form.lunchDuration}
              onChange={onInputChange('lunchDuration')}
              onBlur={onInputBlur('lunchDuration')}
              error={errors.lunchDuration}
              formatterFunction={scalarFormaterFunction}
            >
              min
            </MaskedInputWithSuffix>
          </InputFormGroup>
          <Label>para almoçar.</Label>
        </InputGroup>
      </Toggle>
    </>
  )
}
