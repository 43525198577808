export const CustomRegionParser = {
  list({ custom_regions = [] }) {
    return custom_regions.map(
      ({
        id,
        name,
        user_visible,
        agent_visible,
        position = '',
        description = '',
        searchable_by = '',
        service_count = 0
      }) => ({
        id,
        name,
        position,
        description,
        searchableBy: searchable_by,
        userVisible: user_visible,
        agentVisible: agent_visible,
        serviceCount: service_count
      })
    )
  }
}
