import { convertMarkdownToHypertext } from './markdownToHyper'
import { deserialize } from './deserializer'
import { deserializingCleaner } from './cleaners'
import { unwrapLineBreaks } from './unwrapLineBreaks'

export const makeMarkdownEditable = async markdown => {
  const hyperTextValue = await convertMarkdownToHypertext(markdown)
  const htmlDoc = new DOMParser().parseFromString(hyperTextValue, 'text/html')

  const deserialized = deserialize(htmlDoc.body)

  const cleaned = deserializingCleaner({ children: deserialized })
  const unwraped = unwrapLineBreaks({ children: cleaned.children })

  return unwraped
}
