import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react'

import { getCustomRegions } from 'Domains/Dashboard/CustomRegionsExperiences/io/customRegions.io'

// @ts-ignore
const CustomRegionsContext = createContext()

const CustomRegionsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [customRegions, setCustomRegions] = useState([])
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    setIsLoading(true)
    getCustomRegions({ countServices: true, name: searchFilter })
      .then(regions => {
        setIsLoading(false)
        if (regions.length) {
          setCustomRegions([
            ...customRegions,
            ...regions.filter(
              newRegion =>
                !customRegions.some(oldRegion => oldRegion.id === newRegion.id)
            )
          ])
        }
      })
      .catch(err => {
        console.error(err)
        setCustomRegions([...customRegions])
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter])

  const handleSearchRegions = useCallback(name => {
    setIsLoading(true)
    setCustomRegions([])
    setSearchFilter(name)
  }, [])

  return (
    <CustomRegionsContext.Provider
      value={{
        customRegions,
        isLoading,
        handleSearchRegions,
        setIsLoading,
        setCustomRegions,
        setSearchFilter,
        searchFilter
      }}
    >
      {children}
    </CustomRegionsContext.Provider>
  )
}

/**
 * @typedef RegionType
 * @prop { string } id
 * @prop { string } name
 * @prop { boolean } agentVisible
 * @prop { boolean } userVisible
 * @prop { number } services
 * @prop { string[] } position
 * @prop { number } serviceCount
 */

/**
 * @return { {
 *  customRegions: RegionType[],
 *  isLoading:boolean,
 *  handleSearchRegions: (string) => void,
 *  nextPage: () => void,
 *  setIsLoading: (boolean) => void,
 *  setCustomRegions: (string) => RegionType[],
 *  searchFilter: string
 *  setSearchFilter: (string) => void
 * } }
 */
function useCustomRegions() {
  const context = useContext(CustomRegionsContext)

  if (!context) {
    throw new Error(
      'useCustomRegions must be used within an CustomRegionsProvider'
    )
  }

  return context
}

export { CustomRegionsProvider, useCustomRegions }
