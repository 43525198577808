/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useHistory } from 'react-router-dom'
import { CustomRegionForm } from './CustomRegionForm'
import { useForm } from '@bonitour/app-functions'
import { customRegionSchema } from './CustomRegion.schema'
import { useCallback, useMemo, useEffect, useState } from 'react'
import { getCustomRegion, updateCustomRegion } from '../../io/customRegions.io'
import { useParams } from 'react-router-dom'
import { HeaderPage, useToast } from '@bonitour/components'
import { removeNullAndEmptyFields } from 'utils/utils'
import { headerRow } from '../../Experiences.style'

export const EditCustomRegionPage = () => {
  // @ts-ignore
  const { regionId } = useParams()
  const [isLoading, setIsloading] = useState(false)
  const [customRegion, setCustomRegion] = useState({
    id: '',
    name: '',
    agentVisible: false,
    userVisible: false,
    description: ''
  })

  const { add: addToast } = useToast()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(customRegion, customRegionSchema)

  const hasChanges = useMemo(
    () => Object.keys(form).some(key => form[key] !== customRegion[key]),
    [customRegion, form]
  )

  useEffect(() => {
    setIsloading(true)
    getCustomRegion(regionId)
      .then(region =>
        setCustomRegion({
          id: region.id,
          name: region.name,
          agentVisible: region.agent_visible,
          userVisible: region.user_visible,
          description: region.description || ''
        })
      )
      .catch(e => {
        console.error(e)
        addToast('Houve um erro ao tentar carregar a região')
      })
      .finally(() => setIsloading(false))
  }, [addToast, regionId])

  const { push, goBack } = useHistory()

  const goToExperienceList = useCallback(
    (edit = false) =>
      () => {
        if (!customRegion.name) {
          return goBack()
        }
        push(
          `/app/regions/${regionId}/${encodeURIComponent(customRegion.name)}${
            edit ? '/experiences/to-bind' : ''
          }`
        )
      },
    [customRegion.name, goBack, push, regionId]
  )

  const onUpdateRegion = useCallback(() => {
    updateCustomRegion(
      regionId,
      removeNullAndEmptyFields({
        name: form.name,
        agent_visible: form.agentVisible,
        user_visible: form.userVisible,
        description: form.description
      })
    )
      .then(() => {
        setCustomRegion({
          ...form,
          name: form.name
        })
        addToast('Região atualizada com sucesso', 'success')
        goToExperienceList(false)()
      })
      .catch(() => {
        addToast('Houve um erro ao tentar atualizar a região', 'error')
      })
  }, [addToast, form, goToExperienceList, regionId])

  return (
    <>
      <div css={headerRow}>
        <HeaderPage
          title='Editar região'
          subtitle={customRegion.name}
          onBack={goToExperienceList(false)}
        />
      </div>
      <CustomRegionForm
        isLoading={isLoading}
        hasChanges={hasChanges}
        form={form}
        errors={errors}
        onSubmit={onSubmit(onUpdateRegion)}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        isEditing={true}
        onEditExperiences={goToExperienceList(true)}
      />
    </>
  )
}
