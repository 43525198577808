/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  CloseIcon,
  Row,
  CheckboxInputGroup,
  InfoIcon,
  Card
} from '@bonitour/components'
import { RegionCard } from './RegionCard'
import {
  modalBackdrop,
  modalCard,
  modalCloseButton,
  modalContainer,
  modalContent,
  regionCardContentBox,
  regionsColumn,
  sampleRegionsGrid,
  rowCheckBox,
  mouseOnly,
  touchOnly
} from './RegionsHint.styles'
import { useCallback, useState } from 'react'

export const RegionsHint = ({ onClose, setNotShowAgain }) => {
  const [checked, setChecked] = useState(false)
  const toggle = useCallback(() => {
    setNotShowAgain(!checked)
    setChecked(!checked)
  }, [checked, setNotShowAgain])
  return (
    <div css={modalContainer}>
      <div css={modalContent}>
        <button type='button' css={modalBackdrop} onClick={onClose} />
        <div css={modalCard}>
          <button type='button' css={modalCloseButton} onClick={onClose}>
            <CloseIcon />
          </button>

          <h2>Defina a prioridade das regiões que você vende!</h2>
          <p>
            Você pode alterar a ordem das regiões cadastradas no seu Xpert. A
            mesma organização será apresentada aos viajantes em seu site.
          </p>
          <Card customCss={[touchOnly]}>
            <InfoIcon />A reordenação é possível somente em dispositivos com
            mouse.
          </Card>
          <div css={[sampleRegionsGrid, mouseOnly]}>
            <div css={regionsColumn}>
              <div css={regionCardContentBox} className='first-animated-card'>
                <RegionCard
                  numberOfActivities={7}
                  regionId='test1'
                  title='Bonito'
                  isUserVisible={true}
                  isAgentVisible={true}
                />
              </div>
            </div>

            <div css={regionsColumn}>
              <div css={regionCardContentBox} className='second-animated-card'>
                <RegionCard
                  numberOfActivities={4}
                  regionId='test2'
                  title='Noronha'
                  isUserVisible={true}
                  isAgentVisible={true}
                />
              </div>
            </div>

            <div css={regionsColumn}>
              <div css={regionCardContentBox}>
                <RegionCard
                  numberOfActivities={5}
                  regionId='test3'
                  title='Rio de Janeiro'
                  isUserVisible={true}
                  isAgentVisible={true}
                />
              </div>
            </div>
          </div>
          <Row customCss={[rowCheckBox]}>
            <CheckboxInputGroup
              checked={checked}
              onChange={toggle}
              id='dont-show-hint-again'
            >
              Não exibir novamente
            </CheckboxInputGroup>
          </Row>
        </div>
      </div>
    </div>
  )
}
