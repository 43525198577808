import { useCallback, useEffect, useRef } from 'react'

export function InfiniteScroll({
  loading,
  nextPage,
  scrollTarget = window,
  offset = 300
}) {
  const ref = useRef(null)
  const handleScroll = useCallback(
    options => {
      const target = options?.windowAsTarget ? window : scrollTarget ?? window
      const scrollAreaHeight = target?.innerHeight ?? target?.offsetHeight
      const scrollOffset = target?.pageYOffset ?? target?.scrollTop
      const scrollContentHeight =
        target === window ? document.body.offsetHeight : target?.scrollHeight
      if (
        !loading &&
        scrollAreaHeight + scrollOffset + offset >= scrollContentHeight
      ) {
        clearTimeout(ref.current)
        ref.current = setTimeout(nextPage, 300)
      }
    },
    [scrollTarget, loading, offset, nextPage]
  )
  const handleResize = useCallback(
    () => handleScroll({ windowAsTarget: true }),
    [handleScroll]
  )

  useEffect(() => {
    handleScroll()
    window.addEventListener('resize', handleResize)
    scrollTarget.addEventListener('scroll', handleScroll)
    return () => {
      clearTimeout(ref.current)
      scrollTarget.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, handleScroll, scrollTarget])

  return null
}
