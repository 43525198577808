/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useMemo } from 'react'
import {
  wrapper,
  header,
  logoWrapper,
  logoImg,
  contentArea,
  leftArea,
  leftHeading,
  leftTitle,
  textArea,
  text,
  rightArea,
  rightHeading,
  rightTitle,
  scrollArea,
  card,
  rightButton,
  browserBarStyle,
  circleStyle,
  containerCircles,
  faviconBrowserBar,
  domainBrowserBar,
  login,
  loginText,
  loginWrapper,
  searchBar,
  searchBarText,
  searchBarButton,
  searchBarCalendar
} from './Preview.style'

function Preview({ favicon, title, logo, primaryColor, secondaryColor }) {
  const textElements = useMemo(() => {
    const texts = []
    for (let i = 0; i < 9; i++) {
      texts.push(<div css={text(primaryColor)} key={i} />)
    }
    return texts
  }, [primaryColor])

  return (
    <div css={wrapper}>
      <div css={browserBarStyle}>
        <div>
          <div css={containerCircles}>
            <div css={circleStyle} />
            <div css={circleStyle} />
            <div css={circleStyle} />
          </div>
        </div>
        <img css={faviconBrowserBar} src={favicon} alt={`${title} Favicon`} />
        <span css={domainBrowserBar}>{title || 'Exemplo Viagens'}</span>
      </div>
      <div css={header} style={{ backgroundColor: primaryColor }}>
        <div css={logoWrapper}>
          <img css={logoImg} src={logo} alt={`${title} Logo`} />
        </div>
        <div css={loginWrapper}>
          <div css={login} />
          <div css={loginText} />
        </div>
      </div>
      <div css={searchBar} style={{ backgroundColor: primaryColor }}>
        <div css={searchBarText} />
        <div css={searchBarCalendar}>
          <div css={searchBarText} />
          <div css={searchBarText} />
        </div>
        <div css={searchBarText} />
        <div
          css={searchBarButton}
          style={{ backgroundColor: secondaryColor }}
        />
      </div>
      <div css={contentArea}>
        <div css={leftArea}>
          <div css={leftHeading}>
            <div css={leftTitle} />
          </div>
          <div css={textArea}>{textElements}</div>
        </div>
        <div css={rightArea}>
          <div css={rightHeading}>
            <div css={rightTitle} />
          </div>
          <div css={scrollArea}>
            <div css={card} />
            <div css={card} />
          </div>
          <div css={rightButton} style={{ backgroundColor: secondaryColor }} />
          <div css={rightButton} style={{ backgroundColor: primaryColor }} />
        </div>
      </div>
    </div>
  )
}

export default Preview
