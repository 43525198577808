import axios from 'axios'
import { extractData, xpertAxiosInstance } from 'external/axios'

const xpertInstance = xpertAxiosInstance()

export function getAgentItineraries() {
  return xpertInstance
    .get('itineraries/quotations/status_count/backoffice')
    .then(extractData)
}

export function encodeItineraryId({ id, domain }) {
  const { REACT_APP_SERVERLESS_API_ENDPOINT } = process.env

  return axios
    .post(`${REACT_APP_SERVERLESS_API_ENDPOINT}/encode-itinerary-clone`, {
      id,
      domain
    })
    .then(extractData)
}
