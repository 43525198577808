// @ts-check
import { boolean, string } from 'yup'

const naturalNumber = () =>
  string()
    .test(
      'test-number',
      'Numero deve ser inteiro maior que zero',
      numberLike => {
        if (numberLike === undefined) {
          return false
        }
        const clean = numberLike.replace(/\D/, '')
        const asNumber = +clean
        const asInt = asNumber | 0
        return asInt > 0 && asNumber === asInt && numberLike !== ''
      }
    )
    .required('Campo obrigatório')
const hour = () =>
  naturalNumber().matches(/(?:2[0-4]|[01][0-9]):[0-5]\d/, {
    message: 'Deve estar em formato de hora.',
    excludeEmptyString: true
  })
const conditionalNumber = (dep, type = naturalNumber) =>
  string().when(dep, {
    is: true,
    then: type().required('Campo obrigatório'),
    otherwise: string()
  })

export const scalarFormaterFunction = (scalar = '') =>
  String(scalar).replace(/\D+/g, '').substring(0, 3)

export const setupSchema = {
  // considerTimeToDowntown: boolean(),
  hasLunch: boolean(),
  useIntelligence: boolean(),
  lunchDuration: conditionalNumber('hasLunch'),
  lunchPeriodEnd: conditionalNumber('hasLunch', hour),
  lunchPeriodStart: conditionalNumber('hasLunch', hour),
  maximumDistanceEnabled: boolean(),
  maximumDistanceValue: conditionalNumber('maximumDistanceEnabled'),
  maximumTimeEnabled: boolean(),
  maximumTimeValue: conditionalNumber('maximumTimeEnabled', hour),
  minimumTimeBetweenActivitiesEnabled: boolean(),
  minimumTimeBetweenActivitiesValue: conditionalNumber(
    'minimumTimeBetweenActivitiesEnabled'
  ),
  minimumTimeEnabled: boolean(),
  minimumTimeValue: conditionalNumber('minimumTimeEnabled', hour),
  minDaysRequired: naturalNumber()
}
