import { colors } from '@bonitour/components'
import styled from '@emotion/styled'

export const NewActivityButton = styled.button`
  max-width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  color: ${colors.primaryVariant};
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: ${colors.primary};

    .icon {
      background-color: ${colors.primary};
    }
  }

  .icon {
    font-size: 16px;
    color: ${colors.white};

    background-color: ${colors.primaryVariant};
    width: 26px;
    height: 26px;
    border-radius: var(--round-element-radius, 50%);
  }
`
