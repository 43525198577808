export const BREAK_POINTS = {
  afterLaptop: 1280,
  beforeLaptop: 1279,

  afterTabletLandscape: 1024,
  beforeTabletLandscape: 1023,

  afterPhoneLandscape: 768,
  beforePhoneLandscape: 767,

  beforeTabletUp: 590,

  afterPhoneUp: 480,
  beforePhoneUp: 479,

  afterSmallPhone: 420,
  afterTinyPhone: 360,

  searchBarAjust: 1220
}

export const afterTabletLandscape = (style = '') => `
    @media screen and (min-width: ${BREAK_POINTS.afterTabletLandscape}px){
      ${style}
    }
  `

export const beforeTabletLandscape = (style = '') => `
    @media screen and (max-width: ${BREAK_POINTS.beforeTabletLandscape}px){
      ${style}
    }
  `

export const beforeTabletUp = (style = '') => `
    @media screen and (max-width: ${BREAK_POINTS.beforeTabletUp}px){
      ${style}
    }
  `
