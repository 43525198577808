/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import { Editable, withReact, Slate } from 'slate-react'

import { createEditor } from 'slate'

import { withHistory } from 'slate-history'

import { textEditorContainer } from './Components.styles'
import { EditorToolbar } from './Components/EditorToolbar'
import { EDITOR_TOOLS } from './consts/EditorConsts'

const errorUnsetHandler = e => console.error('Please define handleSetValue')

export const TextEditor = ({
  currentValue = [],
  tools = [
    EDITOR_TOOLS.bold,
    EDITOR_TOOLS.italic,
    EDITOR_TOOLS.list,
    EDITOR_TOOLS.h2,
    EDITOR_TOOLS.h3
  ],
  handleSetValue = errorUnsetHandler
}) => {
  // @ts-ignore
  const [editor] = useState(() => withHistory(withReact(createEditor())), [])

  return (
    <div css={textEditorContainer}>
      <Slate editor={editor} value={currentValue} onChange={handleSetValue}>
        <EditorToolbar tools={tools} />

        <Editable
          renderElement={Element}
          renderLeaf={Leaf}
          spellCheck
          autoFocus
          style={{ minHeight: '220px' }}
        />
      </Slate>
    </div>
  )
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case EDITOR_TOOLS.list:
      return <ul {...attributes}>{children}</ul>
    case EDITOR_TOOLS.orderedList:
      return <ol {...attributes}>{children}</ol>
    case EDITOR_TOOLS.h1:
      return <h1 {...attributes}>{children}</h1>
    case EDITOR_TOOLS.h2:
      return <h2 {...attributes}>{children}</h2>
    case EDITOR_TOOLS.h3:
      return <h3 {...attributes}>{children}</h3>
    case EDITOR_TOOLS.h4:
      return <h4 {...attributes}>{children}</h4>
    case EDITOR_TOOLS.h5:
      return <h5 {...attributes}>{children}</h5>
    case EDITOR_TOOLS.h6:
      return <h6 {...attributes}>{children}</h6>
    case EDITOR_TOOLS.listItem:
      return <li {...attributes}>{children}</li>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>
  if (leaf.italic) children = <em>{children}</em>
  if (leaf.underline) children = <u>{children}</u>
  if (leaf.strikethrough) children = <del>{children}</del>

  return <span {...attributes}>{children}</span>
}
