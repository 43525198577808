/** @jsxRuntime classic */
/** @jsx jsx */
import { LoadingAnimation } from '@bonitour/components'
import { css, jsx } from '@emotion/react'

export function LoadingContainer({ loading, children }) {
  return (
    <div>
      {children}
      {loading && (
        <div css={loadingContainerStyles}>
          <LoadingAnimation />
        </div>
      )}
    </div>
  )
}

const loadingContainerStyles = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: grid;
  place-items: center;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 30;
`
