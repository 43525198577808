import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const toggleContainerCss = css`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const toggleLabelCss = css`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray4};
  white-space: nowrap;
`
