import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  iconStyle as iconStyleBase,
  filterDrawerContainer as filterDrawerContainerBase
} from 'Domains/Dashboard/Quotations/QuotationsPage.style'

export const iconStyle = ({ hasFilters, isOpen }) => css`
  ${iconStyleBase({ hasFilters, isOpen })}

  color: ${isOpen ? colors.white : colors.gray3};
  transform: scale(0.75);
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
`

export const filterDrawerContainer = css`
  ${filterDrawerContainerBase}
  font-size: 14px;
  font-weight: bold;
  color: ${colors.gray3};
  min-width: 12.5rem;
`

export const PopoverWrapper = styled.div`
  .component-popover {
    margin-right: -0.625rem;
  }
  .component-popover-arrow {
    right: 1.125rem;
  }
`

export const multiSelectStyle = css`
  width: 100%;
  border-radius: 0.625rem;
  border: 1px solid ${colors.gray7};
  padding: 0.5rem;
  margin-top: -0.5rem;
`
