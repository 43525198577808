export function formatBrazilianPhoneNumber(phoneNumber) {
  // Remove any non-digit characters from the input
  const digitsOnly = phoneNumber.replace(/\D/g, '')

  // Check if the input contains enough digits for a valid Brazilian number
  if (digitsOnly.length < 10 || digitsOnly.length > 13) {
    return phoneNumber
  }

  // Extract the area code and telephone number parts
  let areaCode = ''
  let telephoneNumber = ''
  let countryCode = ''

  if (digitsOnly.length === 10 || digitsOnly.length === 11) {
    areaCode = digitsOnly.substring(0, 2)
    telephoneNumber = digitsOnly.substring(2)
  } else if (digitsOnly.length === 12 || digitsOnly.length === 13) {
    countryCode = digitsOnly.substring(0, 2)
    areaCode = digitsOnly.substring(2, 4)
    telephoneNumber = digitsOnly.substring(4)
  }

  // Format the phone number with parentheses for the area code
  return `${
    countryCode ? `+${countryCode}` : ''
  } (${areaCode}) ${telephoneNumber.substring(
    0,
    4
  )}-${telephoneNumber.substring(4)}`
}
