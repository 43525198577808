import { colors } from '@bonitour/components'
import { css, keyframes } from '@emotion/react'
import { BREAK_POINTS } from 'assets/styles/queries'
import { inset } from 'assets/styles/styles'

const modalCardDisplayAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const infoModalCard = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  width: 100%;
  max-width: 332px;
  max-height: calc(100% - 2rem);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  background: ${colors.white};

  z-index: 4;
  opacity: 1;
  transform: translateY(0);
  animation: ${modalCardDisplayAnimation} 400ms ease-out;

  .close-modal-button {
    --close-modal-button-size: 30px;
    height: var(--close-modal-button-size);
    width: var(--close-modal-button-size);
    min-width: var(--close-modal-button-size);
    min-height: var(--close-modal-button-size);
    border-radius: var(--round-element-radius, 100vw);
    top: calc(var(--close-modal-button-size) / 2);
    right: calc(var(--close-modal-button-size) / 2);

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    background: ${colors.primary};
    color: ${colors.white};
  }

  @media (max-width: ${BREAK_POINTS.afterTinyPhone}px) {
    padding: 15px;
  }
`

export const languageSelectorContent = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  b {
    color: ${colors.gray2};
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
  }
`

const modalBgDisplayAnimation = keyframes`
  from {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.0);
  }
  to {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
  }
`

export const modalBg = css`
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
  animation: ${modalBgDisplayAnimation} 200ms ease-out;
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 100%;
  ${inset('0')}
  z-index: 3;
`

export const modalContainer = css`
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
`

export const modalTitle = css`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.gray1};
  margin-bottom: 40px;
`

export const modalButtonsBar = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 40px;

  button {
    width: 120px;
    min-height: 40px;
    border-radius: var(--round-element-radius, 100vw);
    border: 0;

    font-weight: 700;
    font-size: 14px;
    background: transparent;

    cursor: pointer;
  }

  button + button {
    margin-left: 20px;
  }

  .cancel_btn {
    background: ${colors.white};
    border: 1px solid ${colors.gray5};
    color: ${colors.gray4};
  }

  .add_btn {
    background: ${colors.primary};
    border: 0;
    color: ${colors.white};
  }
`
