import { Card, H3 } from '@bonitour/components'
import React from 'react'

export const ContentCard = ({ marginBottom = undefined, children, title }) => {
  return (
    <>
      <Card>
        <H3 style={{ marginBottom }}>{title}</H3>
        {children}
      </Card>
    </>
  )
}
