/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  H3,
  InputDurationMaskWithSuffix,
  InputFormGroup,
  Label
} from '@bonitour/components'
import { InputGroup, Title } from '../CommonComponents'
import { noGrow } from '../AlgorithmSetup.style'
import { Toggle } from '../Toggle'

const LimitTime = ({
  label,
  inputLabel,
  id,
  value,
  checked,
  onBlur,
  onEnabledChange,
  onValueChange,
  error
}) => {
  return (
    <Toggle checked={checked} id={id} label={label} onChange={onEnabledChange}>
      <InputGroup>
        <Label>{inputLabel}</Label>
        <InputFormGroup horizontal errorMessage={error} customCss={[noGrow]}>
          {/* @ts-ignore */}
          <InputDurationMaskWithSuffix
            id={`${id}-value`}
            value={value}
            onChange={ev => {
              onValueChange(ev)
            }}
            onBlur={onBlur}
            error={error}
          >
            Hrs
          </InputDurationMaskWithSuffix>
        </InputFormGroup>
      </InputGroup>
    </Toggle>
  )
}

export const ActivityTimeFormSection = ({
  form,
  errors,
  onInputChange,
  onInputBlur
}) => {
  return (
    <>
      <Title tooltipText='Marque essa opção para não alocar automaticamente atividades em determinado horário, como por exemplo, fora do horário comercial.'>
        <H3>Horário para atividade</H3>
      </Title>

      <LimitTime
        label='Horário mínimo'
        inputLabel='Alocar somente atividades com início após'
        id='minimum-time'
        value={form.minimumTimeValue}
        checked={form.minimumTimeEnabled}
        onBlur={onInputBlur('minimumTimeValue')}
        onEnabledChange={onInputChange('minimumTimeEnabled')}
        onValueChange={onInputChange('minimumTimeValue')}
        error={errors.minimumTimeValue}
      />
      <LimitTime
        label='Horário máximo'
        inputLabel='Não locar atividades com início após'
        id='maximum-time'
        value={form.maximumTimeValue}
        checked={form.maximumTimeEnabled}
        onBlur={onInputBlur('maximumTimeValue')}
        onEnabledChange={onInputChange('maximumTimeEnabled')}
        onValueChange={onInputChange('maximumTimeValue')}
        error={errors.maximumTimeValue}
      />
    </>
  )
}
