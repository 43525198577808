/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  H4,
  InputFormGroup,
  Label,
  MaskedInputWithSuffix
} from '@bonitour/components'

import { InfoTooltip } from '../InfoTooltip'
import { scalarFormaterFunction } from './AlgorithmSetup.schema'
import {
  inputGroupStyle,
  noGrow,
  titleStyles,
  toolTipStyles
} from './AlgorithmSetup.style'
import { Toggle } from './Toggle'

export const InputGroup = props => (
  <H4 customCss={[inputGroupStyle]} {...props} />
)

export const Title = ({ tooltipText = '', css = [], children }) => {
  return (
    <div css={[titleStyles, ...(Array.isArray(css) ? css : [css])]}>
      {children}
      {tooltipText && (
        <InfoTooltip aditionalCss={[toolTipStyles]}>{tooltipText}</InfoTooltip>
      )}
    </div>
  )
}

export const LimitIntegerScalar = ({
  label,
  inputLabelPreffix,
  inputLabelSuffix,
  id,
  unit,
  value,
  checked,
  onBlur,
  onEnabledChange,
  onValueChange,
  error
}) => {
  return (
    <Toggle checked={checked} id={id} label={label} onChange={onEnabledChange}>
      <InputGroup>
        <Label>{inputLabelPreffix}</Label>
        <InputFormGroup horizontal errorMessage={error} customCss={[noGrow]}>
          {/* @ts-ignore */}
          <MaskedInputWithSuffix
            id={`${id}-value`}
            value={value}
            onChange={ev => {
              onValueChange(ev)
            }}
            onBlur={onBlur}
            error={error}
            formatterFunction={scalarFormaterFunction}
          >
            {unit}
          </MaskedInputWithSuffix>
        </InputFormGroup>
        <Label>{inputLabelSuffix}</Label>
      </InputGroup>
    </Toggle>
  )
}
