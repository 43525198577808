import * as t from 'io-ts'
import { getXpert, patchXpert, postXpert } from 'external/axios'

const optional = type => t.union([type, t.null, t.undefined])

const customTextsType = t.array(
  t.strict({
    type: t.string,
    content: t.strict({
      'pt-br': optional(t.string),
      'es-es': optional(t.string),
      'en-us': optional(t.string)
    })
  })
)

const customTextType = t.strict({
  type: t.string,
  content: t.strict({
    'pt-br': optional(t.string),
    'es-es': optional(t.string),
    'en-us': optional(t.string)
  })
})

export function getCustomText() {
  return getXpert('v2/custom_text', {}, customTextsType)
}

export function patchCustomText(data) {
  return patchXpert('v2/custom_text', data, t.string)
}

export function postCustomText(data) {
  return postXpert('v2/custom_text', data, customTextType)
}
