/** @jsxRuntime classic */
/** @jsx jsx */
import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .end {
    align-self: end;
  }
`

export const h6 = css`
  text-align: left;
  font: normal normal bold 11px/14px Mulish;
  letter-spacing: 0px;
  color: ${colors.gray6};
  opacity: 1;
`
