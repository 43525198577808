// @ts-check

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {
  Button,
  Card,
  H3,
  Input,
  InputFormGroup,
  Textarea,
  Upload,
  GhostButton,
  ConfirmDialog,
  KeyWords,
  useToast
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import slugify from 'slugify'
import { InfoTooltip } from '../InfoTooltip'
import * as yup from 'yup'
import { idealWeightStyle } from './Experiences.style'
import { getConfigLang, resetData } from './ExperienceConfigManager'
import {
  button,
  cardStyles,
  h6,
  heading,
  inputGroupWithLimitStyles,
  resetButton,
  tooltipStyles,
  w400,
  w575
} from './ExperienceConfiguration.style'
import { UploadImage } from './Components/UploadImage'
import { VisibleImages } from './Components/VisibleImages'

const TEXTAREA_MAX_LENGTH = 3000

/** @type { Partial<typeof schemaShape> } */
const initialErrors = {}

const schemaShape = {
  slug: yup.string().required('Esse campo é obrigatório.')
}
const schema = yup.object().shape(schemaShape)

export const ExperienceConfigurations = ({
  currentTab,
  form,
  onChange,
  updateData,
  uploadImageData,
  deleteImageData,
  loading,
  rulesAndObservationFields,
  images
}) => {
  const [errors, setErrors] = useState(initialErrors)
  const [showErrors, setShowErrors] = useState(false)
  const [confirmationIsVisible, setConfirmationVisible] = useState(false)
  const [newKeyWord, setNewKeyWord] = useState('')

  const { add: addToast } = useToast()

  const data = useMemo(
    () => getConfigLang(form, currentTab),
    [currentTab, form]
  )

  useEffect(() => {
    if (showErrors) {
      validateForm(data).then(setErrors)
    } else {
      setErrors({})
    }
  }, [data, setErrors, showErrors])

  const handleSlugChange = useCallback(
    /**
     * @param { string } slug
     */
    slug =>
      onChange('slug')(`${slugify(slug)}${slug.endsWith(' ') ? '-' : ''}`),
    [onChange]
  )

  const handleBlur = useCallback(() => {
    setShowErrors(true)
  }, [])

  const handleCancel = useCallback(() => setConfirmationVisible(false), [])

  const handleReset = useCallback(() => {
    const resetForm = resetData(form, currentTab)
    onChange('form')(resetForm)
    setConfirmationVisible(false)
    updateData(resetForm, true)
  }, [currentTab, form, onChange, updateData])

  const hasImage = Boolean(data?.image?.file)

  const replaceSpace = metaDescription =>
    metaDescription?.replace(/(\n)|(\s+)/g, ' ')

  const cardStyle = useMemo(() => cardStyles(hasImage), [hasImage])

  return (
    <>
      <Card css={cardStyle}>
        <H3>Identificação</H3>
        <InputFormGroup label={logoLabel}>
          <div css={w400}>
            {!loading && (
              <Upload
                files={data?.image?.file ? [data?.image?.file] : []}
                onChange={onChange('image')}
                onError={e => addToast(e)}
                limitMbSize={2.5}
                emptyMessage='Arraste a imagem ou clique para adicionar'
                accept='image/png, image/jpeg'
                onBlur={handleBlur}
                disabled={currentTab !== 'pt-br'}
              />
            )}
            <span css={idealWeightStyle}>Peso: até 2.5MB</span>
          </div>
          <InputFormGroup label={imageAltLabel} css={inputGroupWithLimitStyles}>
            <div css={w400}>
              {input(
                data?.image?.description,
                onChange('image.description'),
                { css: w400, onBlur: handleBlur },
                !hasImage
              )}
            </div>
          </InputFormGroup>
        </InputFormGroup>
        <InputFormGroup
          label={descriptionLabel}
          css={inputGroupWithLimitStyles}
        >
          <Textarea
            value={data?.description || ''}
            onChange={onChange('description')}
            maxLength={TEXTAREA_MAX_LENGTH}
            onBlur={handleBlur}
          />
          <span className='end'>
            <H6>
              {data?.description?.length || 0}/{TEXTAREA_MAX_LENGTH}
            </H6>
          </span>
        </InputFormGroup>
        {rulesAndObservationFields && (
          <>
            <InputFormGroup
              label={generalRulesDescriptionLabel}
              css={inputGroupWithLimitStyles}
            >
              <Textarea
                value={data?.general_rules || ''}
                onChange={onChange('general_rules')}
                maxLength={TEXTAREA_MAX_LENGTH}
                onBlur={handleBlur}
              />
              <span className='end'>
                <H6>
                  {data?.general_rules?.length || 0}/{TEXTAREA_MAX_LENGTH}
                </H6>
              </span>
            </InputFormGroup>
            <InputFormGroup
              label={observationLabel}
              css={inputGroupWithLimitStyles}
            >
              <Textarea
                value={data?.observation || ''}
                onChange={onChange('observation')}
                maxLength={TEXTAREA_MAX_LENGTH}
                onBlur={handleBlur}
              />
              <span className='end'>
                <H6>
                  {data?.observation?.length || 0}/{TEXTAREA_MAX_LENGTH}
                </H6>
              </span>
            </InputFormGroup>
          </>
        )}
        {currentTab === 'pt-br' && (
          <InputFormGroup label={slugLabel} errorMessage={errors.slug}>
            <div css={w575}>
              {input(data?.slug, handleSlugChange, {
                css: w575,
                onBlur: handleBlur
              })}
            </div>
          </InputFormGroup>
        )}
        <InputFormGroup
          label={metaDescriptionLabel}
          css={inputGroupWithLimitStyles}
        >
          <Textarea
            value={replaceSpace(data?.meta_tags?.description) || ''}
            onChange={onChange('meta_tags.description')}
            maxLength={170}
            onBlur={handleBlur}
          />
          <span className='end'>
            <H6>{data?.meta_tags?.description?.length || 0}/170</H6>
          </span>
        </InputFormGroup>
        {currentTab === 'pt-br' && (
          <>
            <InputFormGroup
              label={videoLinkLabel}
              css={inputGroupWithLimitStyles}
            >
              <div css={w575}>
                {input(data?.video_url, onChange('video_url'), {
                  css: w575,
                  onBlur: handleBlur
                })}
              </div>
            </InputFormGroup>
            <InputFormGroup
              label={pageTitleLabel}
              css={inputGroupWithLimitStyles}
            >
              <div css={w575}>
                {input(data?.meta_tags?.title, onChange('meta_tags.title'), {
                  css: w575,
                  onBlur: handleBlur
                })}
              </div>
            </InputFormGroup>
            <KeyWords
              label={keyWordsLabel}
              keyWords={data?.key_words || []}
              setKeyWords={onChange('key_words')}
              setNewKeyWord={setNewKeyWord}
              newKeyWord={newKeyWord}
            />
          </>
        )}

        {currentTab === 'pt-br' && images && (
          <UploadImage
            labels={uploadImageLabel}
            onAdd={uploadImageData}
            limitMbSize={2.5}
            accept='image/png, image/jpeg'
            onError={e => addToast(e)}
            disabled={Object.keys(form?.images || {})?.length >= 9}
          />
        )}

        <VisibleImages
          imageList={form?.images}
          deleteImageData={deleteImageData}
          lang={currentTab}
          setAltText={onChange('images')}
          onBlur={handleBlur}
          disabled={currentTab === 'pt-br'}
        />

        <GhostButton
          className='resetButton'
          onClick={() => setConfirmationVisible(true)}
          disabled={Boolean(Object.keys(errors).length)}
          css={resetButton}
        >
          Resetar informações
        </GhostButton>

        <div css={heading}>
          <Button
            customCss={[button]}
            onClick={updateData}
            disabled={Boolean(Object.keys(errors).length)}
          >
            Salvar
          </Button>
        </div>
      </Card>
      <ConfirmDialog
        title='Resetar informações?'
        message='Tem certeza que deseja resetar todas as informações cadastradas? Após continuar, não há como rever essa ação.'
        isVisible={confirmationIsVisible}
        successCallback={handleReset}
        cancelCallback={handleCancel}
      />
    </>
  )
}
/**
 * @param {string} value
 * @param { function (string) : void } setValue
 * @param { any } props
 * @param { boolean } hasImage
 */
const input = (value, setValue, props = {}, hasImage = false) => (
  <Input {...{ ...props, value, onChange: setValue, disabled: hasImage }} />
)

const H6 = ({ children, ...other }) => (
  <h6 css={h6} {...other}>
    {children}
  </h6>
)
/**
 * @param {{}} labelText
 */
const label = (labelText, tooltipText = '') =>
  tooltipText ? (
    <div css={tooltipStyles}>
      {labelText} <InfoTooltip>{tooltipText}</InfoTooltip>
    </div>
  ) : (
    <>{labelText}</>
  )
const logoLabel = label('Imagem destaque')
const descriptionLabel = label('Descrição')
const slugLabel = label(
  'Slug',
  'Texto que aparece logo após o seu domínio como parte do link permanente que leva até o conteúdo'
)
const metaDescriptionLabel = label(
  'Descrição para SEO/Compartilhamento',
  'Pequeno texto que aparece logo abaixo do título e do link de sua página no Google'
)

const generalRulesDescriptionLabel = label('Normas')

const observationLabel = label('Observações')

const videoLinkLabel = label('Link do vídeo (Youtube)')
const pageTitleLabel = label(
  'Título da página',
  'Título para ser apresentado no html da página para SEO'
)
const keyWordsLabel = label('Palavras destaques')
const imageAltLabel = label('Texto alternativo da imagem')

const uploadImageLabel = {
  titlePage: 'Fotos da Experiência',
  titleContent: 'Arraste a  imagem ou',
  subTitleContent: 'clique para adicionar'
}

function validateForm(formData) {
  return schema
    .validate(formData, { abortEarly: false })
    .then(() => [])
    .catch(({ inner = [] }) =>
      inner.reduce(
        (errors, { path = '', message = '' }) => ({
          ...errors,
          [path]: message
        }),
        {}
      )
    )
}
