import React from 'react'
import { AngleRightIcon, H3 } from '@bonitour/components'
import { Link } from 'react-router-dom'
import { CardHeaderContainer, title } from './CardHeader.style'

/**
 * @typedef CardHeaderProps
 * @prop { string } [linkUrl]
 * @prop {string} [titleCard]
 */

/** @type { React.FC<CardHeaderProps> } */
export const CardHeader = ({ titleCard, linkUrl }) => {
  return (
    <>
      <CardHeaderContainer>
        <H3 customCss={[title]}>{titleCard}</H3>
        <Link to={linkUrl}>
          <div className='dropdown'>
            <span>Ver todos</span>
            <AngleRightIcon className='icon' />
          </div>
        </Link>
      </CardHeaderContainer>
    </>
  )
}
