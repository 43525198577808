import {
  deleteImageExperience,
  getExperience,
  patchExperience,
  patchImageExperience,
  postImageExperience
} from './Experiences.io'
import { toLowerCase } from 'fp-ts/lib/string'
import slugify from 'slugify'

export async function getConfig(idActivity) {
  return await getExperience(idActivity)
}

export function patchConfig(data) {
  const clone = JSON.parse(JSON.stringify(data))
  const hasImage = !!clone.image?.file
  const hasNewImage = typeof clone.image?.file === 'object'

  delete clone.image
  delete clone.images

  return patchExperience({
    ...clone,
    ...(data.key_words ? { key_words: JSON.stringify(data.key_words) } : {}),
    'image[description]': data?.image?.file ? data.image.description : '',
    ...(hasNewImage
      ? { 'image[file]': data.image.file.file }
      : hasImage
      ? {}
      : { 'image[file]': '' }),
    delete_all_images: clone.delete_all_images || false
  })
}

export function postImage(data) {
  return postImageExperience(data)
}

export function deleteImage(data) {
  return deleteImageExperience(data)
}

export function patchImagemAlt(data) {
  return patchImageExperience(data)
}

/**
 * @typedef ImageInput
 * @prop { string } [file]
 * @prop { Record<string, string> } [description]
 */
/**
 * @typedef ImageOutput
 * @prop { Record<string, string> } [file]
 * @prop { string } [description]
 */

/**
 * @typedef ImagesInput
 * @prop { string } [image_url]
 * @prop { Record<string, string> } [description]
 */
/**
 * @typedef ImagesOutput
 * @prop { string } [image_url]
 * @prop { string } [description]
 */

/**
 * @typedef MetaTagsInput
 * @prop { Record<string, string> } [description]
 * @prop { Record<string, string> | string} [title]
 */
/**
 * @typedef MetaTagsOutput
 * @prop { string } [description]
 * @prop { string} [title]
 */

/**
 * @typedef FormInput
 * @prop { ImageInput } [image]
 * @prop { ImagesInput } [images]
 * @prop {Record<string, string>} [title]
 * @prop {Record<string, string>} [description]
 * @prop {string} [slug]
 * @prop {Record<string, string>} [general_rules]
 * @prop {Record<string, string>} [observation]
 * @prop {MetaTagsInput} [meta_tags]
 * @prop {boolean} [visible]
 * @prop {string[]} [key_words]
 * @prop {string} [video_url]
 */

/**
 * @typedef FormCompany
 * @prop {string} [name]
 * @prop {string} [id]
 */

/**
 * @typedef FormOutput
 * @prop { ImageOutput } [image]
 * @prop { ImagesOutput } [images]
 * @prop {string} [title]
 * @prop {string} [description]
 * @prop {string} [slug]
 * @prop {string} [general_rules]
 * @prop {string} [observation]
 * @prop {MetaTagsOutput} [meta_tags]
 * @prop {boolean} [visible]
 * @prop {string[]} [key_words]
 * @prop {string} [video_url]
 * @prop {FormCompany} [company]
 */

/**
 * @param {FormInput} [data]
 * @param {string} [lang]
 * @returns {FormOutput}
 */

export function getConfigLang(data, lang) {
  return {
    ...Object.keys(data).reduce(
      (acc, fieldName) => ({
        ...acc,
        [fieldName]:
          typeof data[fieldName] === 'string'
            ? data[fieldName]
            : data[fieldName]?.[lang] || ''
      }),
      {}
    ),
    visible: data?.visible,
    image: {
      file: data?.image?.file
        ? typeof data?.image?.file === 'string'
          ? { src: data?.image?.file, name: '' }
          : data?.image?.file
        : null,
      description: data?.image?.description?.[lang] || ''
    },
    key_words: data?.key_words,
    meta_tags: {
      title: data?.meta_tags?.title?.[lang],
      description: data?.meta_tags?.description?.[lang]
    },
    images: {
      image_url: data?.images?.image_url
        ? typeof data?.images?.image_url === 'string'
          ? data?.images?.image_url
          : data?.images?.image_url
        : null,
      description: data?.images?.description?.[lang] || ''
    }
  }
}

export function setImageDescription(data, imageIdToEdit, lang, newValue) {
  const newImages = Object.values(data.images)
    .map(image => {
      return image.id === imageIdToEdit
        ? {
            ...image,
            description: {
              ...(image?.description || {}),
              [lang]: newValue
            }
          }
        : image
    })
    .reduce(
      (acc, image) => ({
        ...acc,
        [image.id]: image
      }),
      {}
    )
  return setConfigField(data, 'images', lang, newImages)
}

export function setConfigField(data, fieldName, lang, newValue) {
  const clone = JSON.parse(JSON.stringify(data))
  if (data?.image?.file?.file) {
    clone.image.file.file = data.image.file.file
  }
  switch (fieldName) {
    case 'image':
      clone.image = clone.image || {}
      clone.image.file = newValue?.length ? newValue[0] : ''
      break
    case 'image.description':
      clone.image = clone.image || {}
      clone.image.description = {
        ...(clone.image.description || {}),
        [lang]: newValue
      }
      break
    case 'images':
      clone.images = newValue || {}
      break
    case 'meta_tags.description':
      clone.meta_tags = clone.meta_tags || {}
      clone.meta_tags.description = {
        ...(clone.meta_tags.description || {}),
        [lang]: newValue
      }
      break

    case 'meta_tags.title':
      clone.meta_tags = clone.meta_tags || {}
      clone.meta_tags.title = {
        ...(clone.meta_tags.title || {}),
        [lang]: newValue
      }
      break

    case 'slug':
    case 'key_words':
    case 'video_url':
      clone[fieldName] =
        typeof newValue === 'function'
          ? newValue(clone?.[fieldName] || [])
          : newValue

      break

    default:
      clone[fieldName] = {
        ...clone[fieldName],
        [lang]: newValue
      }
  }
  return clone
}

export function resetData(data, lang) {
  const clone = JSON.parse(JSON.stringify(data))
  if (data?.image?.file?.file) {
    clone.image.file.file = data.image.file.file
  }

  return {
    ...Object.keys(clone).reduce(
      (acc, currentKey) =>
        [
          'company',
          'id',
          'visible',
          'meta_tags',
          'slug',
          'title',
          'image',
          ...(lang !== 'pt-br' ? ['slug', 'video_url', 'key_words'] : [])
        ].includes(currentKey)
          ? acc
          : setConfigField(acc, currentKey, lang, ''),
      clone
    ),
    ...(lang === 'pt-br'
      ? {
          slug: slugify(
            toLowerCase(`${data.title['pt-br']}-${data.company.name}`)
          ),
          image: {
            file: '',
            description: ''
          },
          delete_all_images: true
        }
      : {
          image: {
            file: clone?.image?.file,
            description: {
              ...(clone?.image?.description || {}),
              [lang]: ''
            }
          },
          images: Object.keys(clone?.images).reduce(
            (acc, imageId) => ({
              ...acc,
              [imageId]: {
                ...(clone?.images[imageId] || {}),
                description: {
                  ...(clone?.images[imageId]?.description || {}),
                  [lang]: ''
                }
              }
            }),
            {}
          )
        }),
    meta_tags: Object.keys(clone.meta_tags || {}).reduce(
      (acc, currentKey) => ({
        ...acc,
        [currentKey]: {
          ...clone.meta_tags[currentKey],
          [lang]: ''
        }
      }),
      {}
    )
  }
}

export function formHasLang(form, lang) {
  return Object.keys(form).some(key => {
    return (
      form[key] &&
      typeof form[key] === 'object' &&
      (lang in form[key] ||
        Object.keys(form[key]).some(childKey => {
          return (
            form[key][childKey] &&
            typeof form[key][childKey] === 'object' &&
            lang in form[key][childKey]
          )
        }))
    )
  })
}

export function savedLangs(form) {
  return [
    { label: 'Português', value: 'pt-br' },
    ...(formHasLang(form, 'en-us')
      ? [{ label: 'Inglês', value: 'en-us' }]
      : []),
    ...(formHasLang(form, 'es-es')
      ? [{ label: 'Espanhol', value: 'es-es' }]
      : [])
  ]
}

export function unsavedLangs(avaibleLangs) {
  return [
    ...(!avaibleLangs.some(tab => tab.value === 'es-es')
      ? [
          {
            value: 'es-es',
            label: 'Espanhol'
          }
        ]
      : []),
    ...(!avaibleLangs.some(tab => tab.value === 'en-us')
      ? [
          {
            value: 'en-us',
            label: 'Inglês'
          }
        ]
      : [])
  ]
}
