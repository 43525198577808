/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import { SearchBar } from '../../Components/SearchBar'
import { InputFormGroup, Select } from '@bonitour/components'
import {
  FiltersContainer,
  FilterFormContainer,
  searchBarStyle
} from './CustomRegionExperiencesFilter.style'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import { FilterExperiencesSchema } from './CustomRegionExperiencesFilter.schema'
import { useAddressLocation } from 'utilityHooks/useAddressLocation'
import { Filter } from 'components/Filters'

const FilterForm = ({ form, errors, onInputChange }) => {
  const { states, cities, updateAvailableCities } = useAddressLocation({
    defaultCountryId: form.countryId
  })

  useEffect(() => {
    if (states?.length && updateAvailableCities) {
      if (form?.stateId) {
        updateAvailableCities(form?.countryId, form.stateId)
      }
    }
  }, [form?.countryId, form.stateId, states?.length, updateAvailableCities])

  const typeOptions = useMemo(
    () => [
      { value: 'Experiences::Activity', label: 'Atividade' },
      { value: 'Experiences::Transport', label: 'Transporte' }
    ],
    []
  )

  const onStateChange = useCallback(
    state => {
      onInputChange('stateId')(state)
    },
    [onInputChange]
  )

  const onCityChange = useCallback(
    cityId => {
      onInputChange('cityId')(cityId)
    },
    [onInputChange]
  )

  const onTypeChange = useCallback(
    type => {
      onInputChange('serviceType')(type)
    },
    [onInputChange]
  )

  return (
    <FilterFormContainer>
      <InputFormGroup className='input_container' label='Tipo do serviço'>
        <Select
          className='select'
          placeholder='Selecione um tipo'
          onChange={onTypeChange}
          value={form.serviceType}
          options={typeOptions}
        />
      </InputFormGroup>
      <InputFormGroup
        className='input_container'
        errorMessage={errors?.stateId}
        label='Estado da experiência'
      >
        <Select
          className='select'
          placeholder='Selecione um estado'
          onChange={onStateChange}
          value={form.stateId}
          options={states}
        />
      </InputFormGroup>
      <InputFormGroup
        className='input_container'
        errorMessage={errors?.cityId}
        label='Cidade da experiência'
      >
        <Select
          className='select'
          placeholder='Selecione uma cidade'
          onChange={onCityChange}
          value={form.cityId}
          options={cities}
        />
      </InputFormGroup>
    </FilterFormContainer>
  )
}

export const CustomRegionExperiencesFilter = ({ setFilters: applyFilters }) => {
  const baseForm = useMemo(
    () => ({
      countryId: '56cab607-42ea-4b51-8e33-5a3a1be408b8', // BRASIL_ID - TODO: change to accept all contries
      stateId: '',
      cityId: '',
      name: ''
    }),
    []
  )
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange }
  } = useForm(baseForm, FilterExperiencesSchema)

  const hasFilters = useMemo(() => {
    return Boolean(form.stateId || form.cityId || form.serviceType)
  }, [form])

  const onResetFilters = useCallback(() => {
    onInputChange('stateId')('')
    onInputChange('cityId')('')
    onInputChange('serviceType')('')
    applyFilters({
      title: form.title
    })
  }, [applyFilters, form.title, onInputChange])

  const onSubmitFilters = useCallback(() => {
    onSubmit(applyFilters)(form)
  }, [applyFilters, form, onSubmit])

  useEffect(() => {
    applyFilters(form)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.title])

  return (
    <FiltersContainer>
      <SearchBar
        currentValue={form.title}
        onDebounce={onInputChange('title')}
        customCss={[searchBarStyle]}
        className='search-bar'
        placeholder='Buscar Experiências'
      />
      <Filter
        onResetFilters={onResetFilters}
        onSubmit={onSubmitFilters}
        hasFilterApplied={hasFilters}
      >
        <FilterForm onInputChange={onInputChange} form={form} errors={errors} />
      </Filter>
    </FiltersContainer>
  )
}
