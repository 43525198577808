import { useCallback, useRef } from 'react'

const getDistanceAndDuration = (mouseDownEvent, mouseUpEvent) => {
  const distance = Math.sqrt(
    Math.pow(mouseUpEvent.clientX - mouseDownEvent.clientX, 2) +
      Math.pow(mouseUpEvent.clientY - mouseDownEvent.clientY, 2)
  )
  const duration = mouseUpEvent.timeStamp - mouseDownEvent.timeStamp
  return { distance, duration }
}

// Hook for draggables, where you want to perform one action on click
// and another action on drag.
// Click not triggered on elements and children with class 'disable-fast-click'
export const useActionOnFastClick = callback => {
  const lastMouseEvent = useRef(null)
  const onMouseEvent = useCallback(
    event => {
      if (!event.target.closest('.disable-fast-click')) {
        switch (event.type) {
          case 'mousedown':
          case 'touchstart':
            lastMouseEvent.current = event
            break
          case 'mouseup':
          case 'touchend':
            if (lastMouseEvent.current) {
              const { distance, duration } = getDistanceAndDuration(
                lastMouseEvent.current,
                event
              )
              // Take into account the duration and distance
              // to determine if it was a fast click
              if (distance * 10 + duration < 300) {
                callback()
              }
            }
        }
      }
    },
    [callback]
  )
  return { onMouseEvent }
}
