/* eslint-disable camelcase */
import { useToast } from '@bonitour/components'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

function parseLocationList({ data: localityList = [] }) {
  return localityList
    .map(({ id, name, abbreviation, state_code }) => ({
      value: id,
      label: name,
      initials: abbreviation || state_code
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

const localitiesAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_LOCALITIES_URL
})

const listCities = (countryId, stateId) => {
  const stateListResource = `/v1/countries/${countryId}/states/${stateId}/cities`
  return localitiesAxiosInstance.get(stateListResource).then(parseLocationList)
}
const listCountries = () => {
  const countryListResource = '/v1/countries'
  return localitiesAxiosInstance
    .get(countryListResource)
    .then(parseLocationList)
}
const listStates = countryId => {
  const stateListResource = `/v1/countries/${countryId}/states`
  return localitiesAxiosInstance.get(stateListResource).then(parseLocationList)
}

export const useAddressLocation = ({
  defaultCountryId = '',
  defaultStateId = ''
}) => {
  const { add: addToast } = useToast()
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const updateAvailableStates = useCallback(
    selectedCountry => {
      listStates(selectedCountry)
        .then(states => {
          setStates(states)
          setCities([])
        })
        .catch(() => {
          setCities([])
          setStates([])
          addToast({
            text: 'Ocorreu um erro na busca pelo país',
            type: 'danger'
          })
        })
    },
    [addToast]
  )

  const updateAvailableCities = useCallback(
    (selectedCountry, selectedState) => {
      listCities(selectedCountry, selectedState)
        .then(setCities)
        .catch(() => {
          setCities([])
          addToast({
            text: 'Ocorreu um erro na busca pelo estado',
            type: 'danger'
          })
        })
    },
    [addToast]
  )

  useEffect(() => {
    if (!defaultCountryId) listCountries().then(setCountries)
  }, [defaultCountryId])

  useEffect(() => {
    if (defaultCountryId) updateAvailableStates(defaultCountryId)
    if (defaultCountryId && defaultStateId) {
      updateAvailableStates(defaultCountryId)
      updateAvailableCities(defaultCountryId, defaultStateId)
    }
  }, [
    defaultCountryId,
    defaultStateId,
    updateAvailableStates,
    updateAvailableCities
  ])

  return {
    countries,
    states,
    cities,
    updateAvailableStates,
    updateAvailableCities
  }
}
