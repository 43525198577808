import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const minutesToText = min => {
  if (!min) return '0min'

  const duration = dayjs.duration(min, 'minutes')
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()

  if (!hours && !minutes) return '0min'

  return hours
    ? `${hours}h` + (minutes ? `${minutes}min` : '')
    : `${minutes}min`
}
