import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const resetPosition = css`
  align-items: unset;
  justify-content: unset;
`

export const arrowIconStyle = isOpen => css`
  cursor: pointer;
  font-size: 10px;
  padding: 8px;
  transition: transform 200ms ease;
  transform: rotate(${isOpen ? '90' : '0'}deg);
`

export const statusStyle = statusColor => css`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    margin: 5px;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: var(--round-element-radius, 100vw);
    background-color: ${statusColor};
  }
`

export const detailsStyle = css`
  padding: 30px;
  max-width: 100%;
  border: 1px solid ${colors.gray7};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .detailed-price {
    position: relative;
  }

  .people-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: relative;
  }

  .info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid currentColor;
    border-radius: 10px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    margin-left: 6px;
  }

  .info {
    &:hover {
      .info-tooltip {
        display: grid;
      }
    }
  }

  .info-tooltip {
    display: none;
    border: 1px solid ${colors.gray11};
    background-color: ${colors.white};
    box-shadow: 0 2px 5px ${colors.gray8};
    border-radius: 10px;

    grid-template-columns: repeat(auto-fill, minmax(min(140px, 100%), 1fr));
    gap: 10px;
    width: 165px;
    padding: 20px;
    position: absolute;
    left: 50px;
    top: -15px;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      right: 90%;
      top: 18px;
      width: 15px;
      height: 15px;
      background: white;
      z-index: 1;
      transform: translateX(-50%) rotate(225deg);
      box-shadow: 1px -1px 2px ${colors.gray8};
    }

    .info-content {
      width: 100%;
      display: flex;
      justify-content: left;
      gap: 10px;

      p {
        &:nth-child(2) {
          color: ${colors.gray2};
          font-weight: 700;
        }
      }
    }
  }

  h3 {
    align-self: flex-start;
  }

  table {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    width: 100%;
    background-color: ${colors.white};

    tr > th {
      font-weight: bold;
      color: ${colors.gray4};
      padding-bottom: 1em;
    }

    td {
      padding-bottom: 10px;
    }

    tr:nth-of-type(2n) td {
      background-color: ${colors.white};
    }
  }
`

export const Icon = css`
  font-size: 20px;
`

export const checkBoxContainer = css`
  display: flex;
  align-items: center;

  .nameStyle {
    cursor: pointer;
  }
`

export const smallFont = css`
  td {
    font-size: 12px;
  }
`
