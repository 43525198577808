import { jsx } from 'slate-hyperscript'
import { EDITOR_TOOLS } from '../consts/EditorConsts'

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  }

  let children = Array.from(el.childNodes).map(deserialize)

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  const conditionalParagraph = (paragraphEl, children) => {
    if (paragraphEl.parentNode?.nodeName === 'LI') {
      return jsx('fragment', {}, children)
    } else {
      return jsx('element', { type: EDITOR_TOOLS.paragraph }, children)
    }
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return jsx('text', '')
    case 'H1':
      return jsx('element', { type: EDITOR_TOOLS.h1 }, children)
    case 'H2':
      return jsx('element', { type: EDITOR_TOOLS.h2 }, children)
    case 'H3':
      return jsx('element', { type: EDITOR_TOOLS.h3 }, children)
    case 'H4':
      return jsx('element', { type: EDITOR_TOOLS.h4 }, children)
    case 'H5':
      return jsx('element', { type: EDITOR_TOOLS.h5 }, children)
    case 'H6':
      return jsx('element', { type: EDITOR_TOOLS.h6 }, children)
    case 'P':
      return conditionalParagraph(el, children)
    case 'UL':
      return jsx('element', { type: EDITOR_TOOLS.list }, children)
    case 'OL':
      return jsx('element', { type: EDITOR_TOOLS.orderedList }, children)
    case 'LI':
      return jsx('element', { type: EDITOR_TOOLS.listItem }, children)
    case 'STRONG':
      return jsx('text', { bold: true }, children)
    case 'EM':
      return jsx('text', { italic: true }, children)
    case 'U':
      return jsx('text', { underline: true }, children)
    case 'S':
      return jsx('text', { strikethrough: true }, children)

    default:
      return el.textContent
  }
}
