import { extractData, xpertAxiosInstance } from 'external/axios'
import { removeNullAndEmptyFields } from 'utils/utils'

const xpertInstance = xpertAxiosInstance()

export const listRegionCustomServices = params => {
  return xpertInstance
    .get('v2/activities/backoffice', {
      params: removeNullAndEmptyFields({
        title: params.title,
        service_types: params.serviceType
          ? [params.serviceType]
          : ['Experiences::Activity', 'Experiences::Transport'],
        city_id: params.cityId,
        state_id: params.stateId,
        page: params.page,
        per_page: params.perPage,
        custom_region_id: params.customRegionId
      })
    })
    .then(extractData)
}
