/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Row, GhostButton } from '@bonitour/components'
import { jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { unlinkIcon } from 'Domains/Dashboard/Documents/NoConfig'

import { useCallback } from 'react'
import { useHistory } from 'react-router'
import {
  alertContainerCss,
  purchasingPolicyRow,
  rowButtons,
  saveButton
} from '../Appearance.style'
import { ContentCard } from './ContentCard'

const NoUrlAlert = () => {
  return (
    <div css={alertContainerCss}>
      {unlinkIcon}
      <p>
        Você ainda não definiu a url do seu E-commerce Xpert
        <br />
        Para configurar suas políticas
      </p>
    </div>
  )
}

export function PoliciesConfig({ config }) {
  const { push } = useHistory()
  const goBack = useCallback(() => push('/'), [push])

  return (
    <>
      <ContentCard title='Configurações das Políticas'>
        {config?.url ? (
          <>
            <Row>
              <div css={purchasingPolicyRow}>
                <label htmlFor='purchasing-policy-text'>
                  Política de compra
                </label>
                <p id='purchasing-policy-text'>
                  Para visualizar ou cadastrar sua política de compra, &nbsp;
                  <Link to='/app/documents/purchasing-policy'>
                    clique aqui.
                  </Link>
                </p>
              </div>

              <div css={purchasingPolicyRow}>
                <label htmlFor='purchasing-policy-text'>
                  Política de cotação
                </label>
                <p id='purchasing-policy-text'>
                  Para visualizar ou cadastrar sua política de cotação, &nbsp;
                  <Link to='/app/documents/quotation-policy'>clique aqui.</Link>
                </p>
              </div>
            </Row>
          </>
        ) : (
          <NoUrlAlert />
        )}
      </ContentCard>
      <Row customCss={[rowButtons]}>
        <GhostButton css={saveButton} onClick={goBack}>
          Voltar
        </GhostButton>
      </Row>
    </>
  )
}
