/* eslint-disable camelcase */
import * as t from 'io-ts'
import { patchXpert } from 'external/axios'

/**
 * @type {(data: {
 * itineraryId: string
 * name: string
 * email: string
 * uri: string
 * }) => Promise<[boolean, any]>}
 */
export async function shareByEmail({ itineraryId, name, email, uri }) {
  /** @type {any} */
  const result = await patchXpert(
    `/itineraries/${itineraryId}/share_by_email`,
    {
      name,
      email,
      uri
    },
    t.unknown
  )
    .then(() => [true, false])
    .catch(err => {
      console.error({ errData: err.response?.data })
      if (
        err.response?.data?.error?.errors_msg?.some(e =>
          e.includes('minimum_advance')
        )
      ) {
        return [false, 'minium_advance']
      }
      console.error(err?.response?.data)
      return [false, true]
    })

  return result
}
