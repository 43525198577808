import { getXpert } from 'external/axios'
import * as t from 'io-ts'

const optional = type => t.union([type, t.null, t.undefined])

const regionType = t.strict({
  name: optional(t.string)
})

const serviceType = optional(
  t.strict({
    title: optional(t.record(t.string, t.string)),
    company: t.strict({
      name: optional(t.string)
    })
  })
)
const tourType = t.array(
  t.type({
    date: optional(t.string),
    price: optional(t.string),
    time: optional(t.string),
    adults: optional(t.number),
    children_ages: optional(t.array(t.number)),
    seniors: optional(t.number),
    activity: serviceType,
    transport: serviceType
  })
)

const AbandonedCartType = t.type({
  created_at: optional(t.string),
  travel_date: t.strict({
    start_date: optional(t.string),
    end_date: optional(t.string)
  }),
  reservation: t.strict({
    name: optional(t.string),
    email: optional(t.string)
  }),
  status: t.string,
  region: optional(regionType),
  custom_regions: optional(t.array(regionType)),
  tours: optional(t.dictionary(t.string, optional(tourType))),
  transports: optional(t.dictionary(t.string, optional(tourType)))
})

const abandonedCartsType = t.strict({
  abandoned_carts: t.array(AbandonedCartType),
  meta: t.strict({
    total_entries: t.number,
    current_page: t.number,
    total_pages: t.number
  })
})

export async function getAbandonedsCarts(pagination) {
  return await getXpert(
    '/itineraries/abandoned_carts',
    pagination,
    abandonedCartsType
  ).then(res => res)
}
