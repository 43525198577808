import { colors } from '@bonitour/components'
import styled from '@emotion/styled'

export const PopUpContainer = styled.div`
  width: 100%;
  height: 100vh;

  position: relative;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;

  button {
    border: none;
    cursor: pointer;
  }

  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  * {
    box-sizing: border-box;
  }
`

export const PopUpBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.overlayRGBA};

  z-index: 0;

  ${props => (props.bluredOverlay ? 'backdrop-filter: blur(2px);' : '')}
`

export const PopUpCard = styled.div`
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  justify-self: center;
  min-width: 250px;
  min-height: 150px;
  background: ${colors.white};
  padding: ${props =>
    props.isPadless
      ? '0'
      : `${props.hasClosingIcon ? '2.5rem' : '1.5rem'} 1rem 1rem 1rem`};

  position: relative;
  z-index: 1;

  border-radius: 0.5rem;

  box-shadow: rgb(0 0 0 / 10%) 0px 5px 5px -3px,
    rgb(0 0 0 / 7%) 0px 8px 10px 1px, rgb(0 0 0 / 6%) 0px 3px 14px 2px;

  ${({ cardCustomStyles }) => cardCustomStyles}
`

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  --close-modal-button-size: 2rem;

  width: var(--close-modal-button-size);
  height: var(--close-modal-button-size);
  min-width: var(--close-modal-button-size);
  min-height: var(--close-modal-button-size);

  top: 16px;
  right: 16px;
  border-radius: var(--round-element-radius, 100vw);
  padding: 2px;

  color: ${colors.white};
  background: var(--primary-color);

  position: absolute;
`
