import { orbCore } from './Orb/Service'
import {
  PermissionsMacrosParser,
  PermissionsMapParser
} from 'core/Parser/Permissions'

const { rolesDomain } = orbCore

export const PermissionsService = {
  getPermissionsMap() {
    return rolesDomain.getPermissionsMap().then(PermissionsMapParser.get)
  },
  getMacroPermissions() {
    return rolesDomain.getMacroPermissions().then(PermissionsMacrosParser.get)
  }
}
