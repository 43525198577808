import { css } from '@emotion/react'

const { colors } = require('@bonitour/components')
const { default: styled } = require('@emotion/styled')

export const SuggestionWrapper = styled.li`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray7};
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .left-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  h4 {
    margin: 0;
  }
  p {
    color: ${colors.gray3};
    font-size: 14px;
  }

  button {
    background-color: transparent;
    border: none;
    color: ${colors.gray2};
    cursor: pointer;

    &.options {
      width: 35px;
      height: 35px;
      border-radius: 35px;
      margin: -15px 0;
      font-size: 20px;
      color: ${colors.gray4};

      &:hover {
        background-color: #f2f2ff;
        color: ${colors.primary};
      }
    }
  }
`

export const suggestionOptionsPopover = css`
  margin-right: -10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;

  .component-popover-arrow {
    right: 20px;
  }
`
