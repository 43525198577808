import React, { useCallback, useState } from 'react'
import { Button } from 'components/Button'
import { validateYupSchema } from 'Shared/utils/validateYupSchema'
import { Input, InputFormGroup } from '@bonitour/components'
import { whatsappSchema } from './schemas/whatsappSchema'
import { inputContainerWithIcon, ShareButtonsContainer } from './styles'

/**
 * @typedef {{
 * shareLink: string
 * regionName: string
 * onNext: () => void
 * onPrevious: () => void
 * i18n: {
 *   backButton: string
 *   shareButton: string
 *   whatsappPlaceholder: string
 *   whatsappInputAria: string
 *   titleSendItineraryMobile: string
 *   shareWhatsapp: string
 * }
 * }} ShareWhatsappProps
 */

/** @type { React.FC<ShareWhatsappProps> } */
export const ShareWhatsapp = ({
  shareLink,
  regionName,
  onNext,
  onPrevious,
  i18n
}) => {
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState('')

  const handleShare = useCallback(async () => {
    const phoneNumber = phone.replace(/\D+/g, '')

    const [isValid, err] = await validateYupSchema(whatsappSchema, {
      phone: phoneNumber
    })

    if (isValid) {
      setPhoneError('')

      const phoneNumberWithDDI =
        phoneNumber.length <= 11 ? `55${phoneNumber}` : phoneNumber

      const message = `Da uma olhada nesse roteiro com atividades incríveis para ${regionName}%0a${shareLink}`

      window.open(
        `https://wa.me/${phoneNumberWithDDI}?text=${message}`,
        '_blank',
        'noopener,noreferrer'
      )

      onNext()
    } else {
      if (err?.phone) setPhoneError(err?.phone)
    }
  }, [onNext, phone, regionName, shareLink])

  return (
    <>
      <b className='titleShareAgent'>{i18n.shareWhatsapp}</b>

      <InputFormGroup
        errorMessage={phoneError}
        customCss={[inputContainerWithIcon]}
      >
        <aside className='input__icon'>
          <img
            src='/icons/whatsappIcon.svg'
            className='whatsapp_icon'
            alt={i18n.whatsappPlaceholder}
          />
        </aside>
        <Input
          // @ts-ignore
          name='phone'
          value={phone}
          // @ts-ignore
          onChange={setPhone}
          // @ts-ignore
          ariaLabel={i18n.whatsappInputAria}
          placeholder={i18n.whatsappPlaceholder}
          required
        />
      </InputFormGroup>
      <ShareButtonsContainer>
        <Button onClick={onPrevious} outlined>
          {i18n.backButton}
        </Button>
        <Button onClick={handleShare}>{i18n.shareButton}</Button>
      </ShareButtonsContainer>
    </>
  )
}
