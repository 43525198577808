import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Card,
  HeaderPage,
  LoadingAnimation,
  PaginationContainer,
  Row,
  TableBody,
  TableContainer
} from '@bonitour/components'
import { AbandonedCartsHeader } from './AbandonedCartsHeader'
import { useHistory } from 'react-router'
import { tableContainer, secondaryRow } from './AbandonedCartsPage.style'
import { AbandonedCartsCount } from '../AbandonedCart/AbandonedCartCount'
import { getAbandonedsCarts } from './io/AbandonedCarts.io'
import { AbandonedCartsList } from './AbandonedCartsList'
import { AbandonedCartsFilter } from './AbandonedCartsFilter'
import { useRequirePermission } from 'Shared/contexts/Permissions'
import { useCustomRegions } from 'Shared/contexts/CustomRegions'

/**
 * @typedef DataProps
 * @prop { Record<string, string>[] } [abandoned_carts]
 */

/**
 * @typedef AbandonedCartsCardProps
 * @prop { boolean} [loading]
 * @prop { DataProps } [totalAbandonedCarts]
 * @prop { (e) => void } [onChange]
 * @prop { string } [order]
 */

/** @type { React.FC<AbandonedCartsCardProps> } */
const AbandonedCartsCard = ({
  loading,
  totalAbandonedCarts,
  onChange,
  order
}) => {
  return (
    <Card customCss={[tableContainer]}>
      <TableContainer>
        <AbandonedCartsHeader onChange={onChange} order={order} />
        <TableBody loading={loading}>
          {totalAbandonedCarts.abandoned_carts.map(abandonedCart => (
            <AbandonedCartsList
              key={abandonedCart.id}
              abandonedCart={abandonedCart}
            />
          ))}
        </TableBody>
      </TableContainer>
    </Card>
  )
}

export const AbandonedsCartsPage = () => {
  const [totalAbandonedCarts, setTotalAbandonedCarts] = useState(null)

  const { customRegions: regions, isLoading, nextPage } = useCustomRegions()

  const [pagination, setPagination] = useState({
    current_page: 0,
    total_entries: 0,
    total_pages: 0,
    currentPage: 1,
    perPage: 10
  })

  const [isAbandonedCartsLoading, setIsAbandonedCartsLoading] = useState(true)

  const [filters, setFilters] = useState({
    travel_initial_date: null,
    travel_final_date: null,
    created_initial_date: null,
    created_final_date: null,
    region_ids: null,
    order_by: '-created_at'
  })

  const { push } = useHistory()

  const changePagination = useCallback((currentPage, perPage) => {
    setPagination(v => {
      return {
        ...v,
        currentPage,
        perPage
      }
    })
  }, [])

  const goToDashboard = useCallback(() => {
    push('/app')
  }, [push])

  useEffect(() => {
    setIsAbandonedCartsLoading(true)
    getAbandonedsCarts({
      ...(pagination.currentPage && {
        page: pagination.currentPage
      }),
      ...(pagination.perPage && {
        per_page: pagination.perPage
      }),
      ...(filters.travel_initial_date instanceof Date && {
        travel_initial_date: filters.travel_initial_date
          .toISOString()
          .substring(0, 10)
      }),
      ...(filters.travel_final_date instanceof Date && {
        travel_final_date: filters.travel_final_date
          .toISOString()
          .substring(0, 10)
      }),
      ...(filters.created_initial_date instanceof Date && {
        created_initial_date: filters.created_initial_date
          .toISOString()
          .substring(0, 10)
      }),
      ...(filters.created_final_date instanceof Date && {
        created_final_date: filters.created_final_date
          .toISOString()
          .substring(0, 10)
      }),
      ...(filters.region_ids && {
        region_ids: filters.region_ids
      }),
      ...(filters.order_by && {
        order_by: filters.order_by
      })
    }).then(res => {
      setTotalAbandonedCarts(res)
      setPagination(v => {
        return {
          ...v,
          ...res.meta
        }
      })
      setIsAbandonedCartsLoading(false)
    })
  }, [pagination.currentPage, pagination.perPage, filters])

  useEffect(() => {
    if (pagination.currentPage > pagination.total_pages) {
      changePagination(Math.max(pagination.total_pages, 1), pagination.perPage)
    }
  }, [
    changePagination,
    pagination.currentPage,
    pagination.perPage,
    pagination.total_pages
  ])

  const filterOrder = useCallback(orderBy => {
    setFilters(filter => ({
      ...filter,
      order_by: orderBy
    }))
  }, [])

  const hasFilters = useMemo(() => {
    return Boolean(
      Object.values(filters).filter(
        filter =>
          filter &&
          filter !== '-created_at' &&
          (filter?.length || filter?.toLocaleString()?.length)
      ).length
    )
  }, [filters])

  useRequirePermission({
    permission: 'xpert_agent'
  })

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Carrinhos abandonados' />
      <Row customCss={[secondaryRow]}>
        <AbandonedCartsCount
          isPageAbandonedCarts
          totalAbandonedCarts={pagination.total_entries}
        />
        <div className='header'>
          <div className='filters'>
            <AbandonedCartsFilter
              onFilter={setFilters}
              regions={regions}
              isLoading={isLoading}
              nextPage={nextPage}
              hasFilters={hasFilters}
            />
          </div>
        </div>
      </Row>
      <div style={{ minHeight: '550px' }}>
        <PaginationContainer
          title={
            isAbandonedCartsLoading ? (
              <LoadingAnimation />
            ) : (
              'Nenhum roteiro encontrado.'
            )
          }
          subtitle={
            isAbandonedCartsLoading
              ? null
              : 'Você não possui nenhum roteiro abandonado'
          }
          onPagination={changePagination}
          pageLimitDefault={pagination.perPage}
          total={pagination.total_entries}
          currentPage={pagination.currentPage}
          pagesQuantity={pagination.total_pages}
          isOpenDrawer
        >
          <AbandonedCartsCard
            loading={isAbandonedCartsLoading}
            totalAbandonedCarts={totalAbandonedCarts}
            onChange={value => filterOrder(value)}
            order={filters?.order_by}
          />
        </PaginationContainer>
      </div>
    </>
  )
}
