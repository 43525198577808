import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect
} from 'react'
import * as CompanyStore from 'core/Store/Company'
import { CompanyService } from 'core/Service/Company'

export const CompanyContext = createContext({})

export const useCompany = () => useContext(CompanyContext)

export const CompanyProvider = props => {
  const [company] = useState(() => CompanyStore.getCompany() || {})
  const [userCompanies, setUserCompanies] = useState([])

  useEffect(() => {
    if (company.id) {
      CompanyService.listUserCompanies(true).then(setUserCompanies)
    }
  }, [company])

  const isBonitour = useMemo(() => {
    const forceBonitour =
      localStorage.getItem('@Binamik:FORCE_BONITOUR') === 'true'

    const isProd =
      (process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV) ===
      'production'

    const shouldForceBonitour = forceBonitour && !isProd

    return (
      shouldForceBonitour ||
      company?.id === '330e4a9b-94cc-450f-bb06-fd64aad0b135'
    )
  }, [company?.id])

  const providerData = useMemo(
    () => ({
      // @ts-ignore
      get id() {
        return company?.id
      },
      company,
      userCompanies,
      isBonitour
    }),
    [company, isBonitour, userCompanies]
  )

  return <CompanyContext.Provider value={providerData} {...props} />
}
