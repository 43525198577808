/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */

import {
  EllipsisIcon,
  H4,
  Manager,
  Popover,
  Reference,
  useToast
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { browseItinerarySuggestions } from '../Experiences.io'
import {
  suggestionOptionsPopover,
  SuggestionWrapper
} from './ItinerarySuggestions.style'

const SuggestionOptionsButton = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen])
  return (
    <>
      <Manager>
        <Reference>
          {({ ref }) => (
            <button ref={ref} onClick={toggle} className='options'>
              <EllipsisIcon />
            </button>
          )}
        </Reference>
        {isOpen && (
          <Popover position='bottom-end' customCss={[suggestionOptionsPopover]}>
            <button>Ver informações</button>
            <button>Desativar sugestão</button>
          </Popover>
        )}
      </Manager>
    </>
  )
}

const ItinerarySuggestion = ({
  suggestion: {
    name,
    id,
    services_list: { length: servicesQty }
  }
}) => (
  <SuggestionWrapper>
    <div className='left-section'>
      <H4>{name}</H4>
      <p>
        {servicesQty} experiência{servicesQty > 1 ? 's' : ''}
      </p>
    </div>
    <SuggestionOptionsButton id={id} />
  </SuggestionWrapper>
)

export const ItinerarySuggestionsList = ({ regionId, filters = {} }) => {
  const [suggestionsLists, setSuggestionsLists] = useState([])

  const { add: addToast } = useToast()

  const loadPage = useCallback(
    (page = 1) => {
      browseItinerarySuggestions(regionId, page, filters)
        .then(({ suggestions, meta }) => {
          setSuggestionsLists(previousState => [
            ...previousState,
            ...suggestions
          ])
          if (meta.current_page < meta.total_pages) {
            loadPage(page + 1)
          }
        })
        .catch(() => {
          addToast('Erro ao obter lista de sugestões de roteiros')
        })
      return () => {
        setSuggestionsLists([])
      }
    },
    [addToast, regionId, filters]
  )

  useEffect(loadPage, [loadPage])

  return (
    <>
      <ul>
        {suggestionsLists.map(suggestion => (
          <ItinerarySuggestion key={suggestion.id} suggestion={suggestion} />
        ))}
      </ul>
    </>
  )
}
