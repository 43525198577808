import { getImageUrl } from 'core/Service/Orb/Service'

export const CompanyParser = {
  listUserCompanies({ companies = [] }) {
    return companies.map(company => ({
      id: company.id,
      name: company.name,
      image: getImageUrl(company.image)
    }))
  },
  getCardBrands({ gateway_card_brands = [] }) {
    return gateway_card_brands
  }
}
