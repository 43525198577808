/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  H3,
  InputFormGroup,
  Label,
  MaskedInputWithSuffix
} from '@bonitour/components'
import { InputGroup, Title } from '../CommonComponents'
import { noGrow } from '../AlgorithmSetup.style'
import { scalarFormaterFunction } from '../AlgorithmSetup.schema'

export const ItineraryPeriodFormSection = ({
  form,
  errors,
  onInputChange,
  onInputBlur
}) => {
  return (
    <>
      <Title tooltipText='Define a quantidade mínima de dias que um itinerário deverá ter no e-commerce'>
        <H3>Duração mínima do itinerário</H3>
      </Title>
      <InputGroup>
        <Label>Período do itinerário deverá possuir no mínimo</Label>
        <InputFormGroup
          horizontal
          errorMessage={errors.minDaysRequired}
          customCss={[noGrow]}
        >
          {/* @ts-ignore */}
          <MaskedInputWithSuffix
            id='minDaysRequired'
            value={form.minDaysRequired}
            onChange={onInputChange('minDaysRequired')}
            onBlur={onInputBlur('minDaysRequired')}
            error={errors.minDaysRequired}
            formatterFunction={scalarFormaterFunction}
          >
            dia(s)
          </MaskedInputWithSuffix>
        </InputFormGroup>
        <Label>.</Label>
      </InputGroup>
    </>
  )
}
