import { cookieStorage } from '@bonitour/app-functions'
const { REACT_APP_COOKIE_DOMAIN, REACT_APP_COOKIE_PREFIX } = process.env

const AppStore =
  REACT_APP_COOKIE_DOMAIN && REACT_APP_COOKIE_DOMAIN !== 'localhost'
    ? cookieStorage(
        { domain: REACT_APP_COOKIE_DOMAIN, path: '/', secure: true },
        REACT_APP_COOKIE_PREFIX
      )
    : cookieStorage({ path: '/' }, REACT_APP_COOKIE_PREFIX)

AppStore.clean = () =>
  AppStore.remove('auth-key') &&
  AppStore.remove('user') &&
  AppStore.remove('company')

export { AppStore }
