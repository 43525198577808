import { css } from '@emotion/react'
import { colors } from '@bonitour/components'
import { classContains } from 'Shared/utils/utils.styles'

export const heading = css`
  display: block;
  h1 > p {
    font-size: 12px;
    color: ${colors.gray4};
    font-weight: 300;

    > span {
      display: flex;
      align-items: baseline;
      gap: 4px;
    }
  }

  h2 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 20px;
    color: ${colors.gray2};
  }

  h3 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 18px;
    color: ${colors.gray3};
  }
`

export const modalConfirm = css`
  span {
    color: ${colors.gray3};
  }
  ${classContains('flexCenteredSpaceBetweenRow')} {
    width: 100%;
    justify-content: flex-start;
  }
`

export const buttonOnClose = css`
  &${classContains('closeIcon')} {
    position: relative;
    top: -10px;
    right: -10px;
  }
`
