/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { H3 } from '@bonitour/components'
import { LimitIntegerScalar, Title } from '../CommonComponents'

export const SafetyTimeFormSection = ({
  form,
  errors,
  onInputChange,
  onInputBlur
}) => {
  return (
    <>
      <Title tooltipText='Montar roteiro com tempo de segurança entre atividades'>
        <H3>Tempo de segurança</H3>
      </Title>
      <LimitIntegerScalar
        label='Tempo entre atividades'
        inputLabelPreffix='Na hora de alocar atividades, levar em conta o tempo de'
        inputLabelSuffix='entre o fim de uma atividade e o começo de outra.'
        id='minimum-time-between-activities'
        unit='min'
        value={form.minimumTimeBetweenActivitiesValue}
        checked={form.minimumTimeBetweenActivitiesEnabled}
        onBlur={onInputBlur('minimumTimeBetweenActivitiesValue')}
        onEnabledChange={onInputChange('minimumTimeBetweenActivitiesEnabled')}
        onValueChange={onInputChange('minimumTimeBetweenActivitiesValue')}
        error={errors.minimumTimeBetweenActivitiesValue}
      />
    </>
  )
}
