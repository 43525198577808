export const getYupValidationErrors = err => {
  /** @type {Record<string, any>} */
  const validationErrors = {}

  if (err?.inner?.length) {
    err.inner.forEach(error => {
      validationErrors[error.path] = error.message
    })
  } else if (err?.path && err?.message) {
    validationErrors[err.path] = err.message
  } else if (!err?.path && err?.type === 'typeError') {
    validationErrors.body = err.errors[0]
  }

  return validationErrors
}

/** @type {(schema: any, objToCheck: any) => Promise<[boolean, Record<string, any>]>} */
export const validateYupSchema = async (schema, objToCheck) => {
  try {
    await schema.validate(objToCheck)
    return [true, {}]
  } catch (err) {
    const validationErrors = getYupValidationErrors(err)
    return [false, validationErrors]
  }
}
