/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { jsx } from '@emotion/react'
import { useToast } from '@bonitour/components'

import { getSiteConfig } from './Appearance.io'
import { useCompany } from 'Shared/contexts/Company'

import { SiteConfigContainer } from './components/SiteConfigContainer'
import { SiteConfigForm } from './components/SiteConfigForm'
import { AnalyticsConfigForm } from './components/AnalyticsConfigForm'
import { WidgetConfigurator } from './components/WidgetConfigurator'
import { TranslationsEnabler } from './components/TranslationsEnabler'
import { useFeatureFlags } from 'Shared/contexts/FeatureFlags'
import { useRequirePermission } from 'Shared/contexts/Permissions'
import { PoliciesConfig } from './components/PoliciesConfig'

const Appearance = () => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const [config, setConfig] = useState(null)
  const [error, setError] = useState(null)
  const { id: companyId } = useCompany()
  useRequirePermission({ permission: 'xpert_backoffice' })

  const [currentTab, setCurrentTab] = useState('site')

  const { translationsEnablerPage } = useFeatureFlags()

  const availableTabs = useMemo(() => {
    return [
      { label: 'Site', value: 'site' },
      { label: 'Integrações', value: 'integrations' },
      { label: 'Widget', value: 'widget' },
      ...(translationsEnablerPage
        ? [{ label: 'Traduções', value: 'translations' }]
        : []),
      { label: 'Políticas', value: 'policies' }
    ]
  }, [translationsEnablerPage])

  useEffect(() => {
    if (companyId) {
      getSiteConfig(companyId)
        .then(setConfig)
        .catch(e => {
          const apiError = e?.response?.data?.error
          if (
            apiError?.errors_msg?.includes('auth::api_key::unauthorized') &&
            apiError?.origin === 'XPERT::DATA'
          ) {
            // empty configuration
            setConfig({})
          } else {
            setError(e)
          }
        })
    }
  }, [companyId])

  if (error) {
    addToast('Erro ao obter configurações')
    push('/')
  }

  const OpenedTabContent = useMemo(() => {
    switch (currentTab) {
      case 'widget':
        return <WidgetConfigurator config={config} />
      case 'site':
        return (
          <SiteConfigForm
            config={config}
            setConfig={setConfig}
            companyId={companyId}
          />
        )
      case 'translations':
        return <TranslationsEnabler config={config} setConfig={setConfig} />
      case 'policies':
        return <PoliciesConfig config={config} />
      default:
        return (
          <AnalyticsConfigForm
            companyId={companyId}
            setConfig={setConfig}
            config={config}
          />
        )
    }
  }, [companyId, config, currentTab])

  return (
    <SiteConfigContainer
      tabs={availableTabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      isLoading={!config}
    >
      {OpenedTabContent}
    </SiteConfigContainer>
  )
}

export default Appearance
