/* eslint-disable camelcase */

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useToast,
  UnderlineNavContainer,
  UnderlineNavItem,
  HeaderPage,
  CogIcon
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { useHistory } from 'react-router-dom'

import {
  getServiceTypePriorities,
  putServiceTypePriorities
} from './Experiences.io'
import { TabsContainer, TabsOptions, headerRow } from './Experiences.style'
import { LoadingContainer } from '../../../components/LoadingContainer/LoadingContainer'
import { ServiceTypeOrder } from './Components/ServiceTypeOrder'
import { ExperiencesList } from './Components/ExperiencesList'
import { ItinerarySuggestionsList } from './Components/ItinerarySuggestions'
import { useFeatureFlags } from 'Shared/contexts/FeatureFlags'
import { ExperiencesFilter } from './Components/ExperiencesFilter'
import { useRequirePermission } from 'Shared/contexts/Permissions'
import { identity } from 'io-ts'
import { getCustomServicesByCustomRegion } from './io/customRegions.io'

export const ACTIVITY_TYPE = 'Experiences::Activity'
export const TRANSPORT_TYPE = 'Experiences::Transport'

const defaultPriorities = [ACTIVITY_TYPE, TRANSPORT_TYPE]

/**
 * @param {any} props
 */
export function Experiences(props) {
  const { push } = useHistory()

  const [allActivities, setAllActivities] = useState(undefined)
  const [allTransports, setAllTransports] = useState(undefined)

  const [loading, setLoading] = useState(false)
  const initialLoading = useMemo(
    () => allActivities === undefined || allTransports === undefined,
    [allActivities, allTransports]
  )

  const regionId = useMemo(() => {
    return props?.match?.params?.regionId
  }, [props])

  const regionName = useMemo(() => {
    return decodeURIComponent(props?.match?.params?.regionName)
  }, [props])

  const [currentTab, setCurrentTab] = useState(undefined)

  const [prioritiesByRegion, setPrioritiesByRegion] = useState(undefined)

  const { add: addToast } = useToast()

  const { itinerarySuggestions } = useFeatureFlags()

  useEffect(() => {
    getServiceTypePriorities()
      .then(setPrioritiesByRegion)
      .catch(e => {
        if (e?.response?.status === 404) {
          setPrioritiesByRegion([])
        } else {
          addToast('Erro ao obter priorização de tipo de serviço', 'danger')
        }
      })
  }, [addToast])

  const servicePriorities = useMemo(() => {
    const priorities = prioritiesByRegion?.[regionId] || []
    defaultPriorities.forEach(type => {
      if (!priorities.includes(type)) {
        priorities.push(type)
      }
    })
    return priorities
  }, [prioritiesByRegion, regionId])

  const isNotEmptyType = useCallback(
    type =>
      (type === ACTIVITY_TYPE && Boolean(allActivities?.length)) ||
      (type === TRANSPORT_TYPE && Boolean(allTransports?.length)),
    [allActivities, allTransports]
  )

  const hasAnyService = useMemo(
    () => allActivities?.length || allTransports?.length,
    [allActivities?.length, allTransports?.length]
  )

  const retrieveInitialTabs = useCallback(
    () =>
      hasAnyService
        ? servicePriorities.filter(isNotEmptyType)
        : [ACTIVITY_TYPE],
    [hasAnyService, isNotEmptyType, servicePriorities]
  )

  const availableTabs = useMemo(
    () => (initialLoading ? [] : retrieveInitialTabs()),
    [initialLoading, retrieveInitialTabs]
  )

  useEffect(() => {
    if (!currentTab && !initialLoading) {
      const newCurrentTab = availableTabs.length
        ? availableTabs[0]
        : ACTIVITY_TYPE

      setCurrentTab(newCurrentTab)
    }
  }, [availableTabs, currentTab, initialLoading])

  const allExperiencesOnCurrentTab = useMemo(
    () =>
      currentTab === ACTIVITY_TYPE
        ? allActivities
        : currentTab === TRANSPORT_TYPE
        ? allTransports
        : [],
    [currentTab, allActivities, allTransports]
  )

  const setAllExperiencesOnTab = useCallback(
    tab =>
      tab === ACTIVITY_TYPE
        ? setAllActivities
        : tab === TRANSPORT_TYPE
        ? setAllTransports
        : identity,
    []
  )

  const setAllExperiencesOnCurrentTab = useMemo(
    () => setAllExperiencesOnTab(currentTab),
    [currentTab, setAllExperiencesOnTab]
  )

  const reloadExperiences = useCallback(
    type => {
      if (!type) {
        reloadExperiences(ACTIVITY_TYPE)
        reloadExperiences(TRANSPORT_TYPE)
        return
      }
      setAllExperiencesOnTab(type)([])

      let canceled = false
      setLoading(true)

      const processData = ({ services, meta }) => {
        if (!canceled) {
          setAllExperiencesOnTab(type)(experiences =>
            (experiences || []).concat(services)
          )
          if (meta.current_page < meta.total_pages) {
            return getCustomServicesByCustomRegion({
              customRegionId: regionId,
              page: meta.current_page + 1,
              type
            })
              .then(processData)
              .catch(e => {
                console.error(e)
                addToast('Erro ao obter configurações')
              })
          }
        }
      }

      getCustomServicesByCustomRegion({
        customRegionId: regionId,
        page: 1,
        type
      })
        .then(data => {
          setAllExperiencesOnTab(type)(experiences =>
            experiences === undefined ? undefined : []
          )
          processData(data)
        })
        .then(() => setLoading(false))
        .catch(e => {
          const apiError = e?.response?.data?.error
          if (
            apiError?.errors_msg?.includes('auth::api_key::unauthorized') &&
            apiError?.origin === 'XPERT::DATA'
          ) {
            addToast('É necessário primeiro configurar o "Meu Site"', 'warning')
          } else {
            console.error(e)
            addToast(
              `Erro ao obter ${
                type === ACTIVITY_TYPE ? 'atividades' : 'transportes'
              }`
            )
          }
          push('/')
        })

      return () => {
        canceled = true
        setLoading(false)
      }
    },
    [addToast, push, regionId, setAllExperiencesOnTab]
  )

  useEffect(reloadExperiences, [reloadExperiences])

  const reloadExperiencesOnCurrentTab = useCallback(
    () => reloadExperiences(currentTab),
    [currentTab, reloadExperiences]
  )

  /**
   * @param {{
   *   updateAllRegions: boolean,
   *   priorityList: string[],
   * }} params
   * @returns {void}
   */
  const updatePriorities = useCallback(
    ({ updateAllRegions, priorityList }) => {
      setLoading(true)
      putServiceTypePriorities({ updateAllRegions, priorityList, regionId })
        .then(() => {
          setPrioritiesByRegion(priorities => ({
            ...priorities,
            [regionId]: priorityList
          }))
          addToast('Prioridades atualizadas com sucesso', 'success')
        })
        .finally(() => setLoading(false))
    },
    [addToast, regionId]
  )

  const [suggestionsFilters, setSuggestionsFilters] = useState({
    // TODO change to array after API allows it
    active: true
  })
  const hasSuggestionsFilters = !suggestionsFilters.active

  useRequirePermission({
    permission: 'xpert_backoffice'
  })

  const goToRegionsList = useCallback(() => {
    push('/app/regions')
  }, [push])

  const onEditClick = useCallback(() => {
    push(`/app/regions/${regionId}/edit`)
  }, [push, regionId])

  return (
    <LoadingContainer loading={loading || initialLoading}>
      <div css={headerRow}>
        <HeaderPage
          title={`Experiências`}
          subtitle={regionName}
          onBack={goToRegionsList}
        />
        <CogIcon onClick={onEditClick} className='cog-icon' />
      </div>
      <TabsContainer>
        <UnderlineNavContainer
          selectedNav={currentTab}
          onChange={setCurrentTab}
        >
          {availableTabs?.map(tab => (
            <UnderlineNavItem navId={tab} key={tab}>
              {tab === ACTIVITY_TYPE ? 'Atividades' : 'Transportes'}
            </UnderlineNavItem>
          ))}
          {itinerarySuggestions ? (
            <UnderlineNavItem navId='suggestion' key='suggestion'>
              Sugestões de Roteiros
            </UnderlineNavItem>
          ) : (
            <></>
          )}
        </UnderlineNavContainer>
        <TabsOptions>
          <ServiceTypeOrder
            regionName={regionName}
            savedPriorities={servicePriorities}
            onSave={updatePriorities}
          />
          {currentTab === 'suggestion' ? (
            <ExperiencesFilter
              onFilter={setSuggestionsFilters}
              hasFilters={hasSuggestionsFilters}
              filters={suggestionsFilters}
            />
          ) : null}
        </TabsOptions>
      </TabsContainer>

      {currentTab === 'suggestion' ? (
        <ItinerarySuggestionsList
          regionId={regionId}
          filters={suggestionsFilters}
        />
      ) : (
        <ExperiencesList
          experiences={allExperiencesOnCurrentTab}
          setExperiences={setAllExperiencesOnCurrentTab}
          reloadExperiences={reloadExperiencesOnCurrentTab}
          type={currentTab}
          region={{
            id: regionId,
            name: regionName
          }}
          setLoading={setLoading}
        />
      )}
    </LoadingContainer>
  )
}
