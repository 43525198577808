import styled from '@emotion/styled'
import { BREAK_POINTS } from 'assets/styles/queries'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;

  .actions__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .searchBar__element {
      align-self: flex-end;
      border-radius: 10px;
      margin-bottom: 0;
    }
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    flex-direction: column;

    .actions__container {
      flex-direction: column;
      gap: 10px;
    }
  }
`
