/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Image, Input } from '@bonitour/components'
import { jsx } from '@emotion/react'
import { VisibleContainer } from './VisibleImages.style'

/**
 * @typedef Image
 * @prop { string } [image_url]
 * @prop { string } [id]
 * @prop { string } [description]
 */

/**
 * @typedef ImageId
 * @prop { string } [id]
 */

/**
 * @typedef VisibleImagesProps
 * @prop { [] | Image } [imageList]
 * @prop { () => void } [onBlur]
 * @prop {function (Image): void} [deleteImageData]
 * @prop {(string, ImageId) => void } [setAltText]
 * @prop { string } [lang]
 * @prop { boolean } [disabled]
 */

/**
 * @param {VisibleImagesProps} [data]
 */
export const VisibleImages = ({
  imageList = [],
  deleteImageData,
  onBlur,
  setAltText,
  lang,
  disabled
}) => {
  const deleteFile = file => {
    deleteImageData(file)
  }
  return (
    <VisibleContainer>
      {Object.values(imageList).map(image => (
        <>
          <div className='visibleContent'>
            <div className='imageContainer'>
              {disabled && <button onClick={() => deleteFile(image)}>X</button>}
              <Image src={image?.image_url} />
            </div>
            <h2>Texto alternativo</h2>
            <Input
              className='inputTextAlt'
              type='string'
              value={image?.description?.[lang] || ''}
              onChange={value => setAltText(value, image?.id)}
              onBlur={onBlur}
            />
          </div>
        </>
      ))}
    </VisibleContainer>
  )
}
