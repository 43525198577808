/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import { SelectableTable } from 'components/Table/SelectableTable'
import { useCallback, useMemo } from 'react'
import { colors } from '@bonitour/components'

const NoRegionLabel = () => (
  <span style={{ color: colors.secondary }}>Nenhuma região</span>
)

export const ExperiencesTableSelector = ({
  data = [],
  sortedColumn = null,
  customCss = [],
  columnsWidths = [],
  isLoading = false,
  alreadyLinkedIds = [],
  onSubmit,
  isSubmitting
}) => {
  const labels = useMemo(
    () => [
      'Nome da experiência',
      'Nome do fornecedor',
      'Região turística',
      'Categoria'
    ],
    []
  )

  const parseType = useCallback(type => {
    const types = {
      'Experiences::Activity': 'Atividade',
      'Experiences::Transport': 'Transporte'
    }
    return types[type]
  }, [])

  const parsedData = useMemo(
    () =>
      data.map((item, idx) => ({
        ...item,
        serviceType: parseType(item.serviceType),
        regionsName: item.regionsName || <NoRegionLabel key={idx} />
      })),
    [data, parseType]
  )

  return (
    <SelectableTable
      labels={labels}
      data={parsedData}
      sortedColumn={sortedColumn}
      customCss={customCss}
      columnsWidths={columnsWidths}
      isLoading={isLoading}
      emptyMessage='Nenhuma experiência encontrada'
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      alreadyLinkedIds={alreadyLinkedIds}
    />
  )
}

ExperiencesTableSelector.propTypes = {
  data: PropTypes.any.isRequired,
  columnsWidths: PropTypes.arrayOf(PropTypes.string),
  customCss: PropTypes.arrayOf(PropTypes.string),
  sortedColumn: PropTypes.string
}
