/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { CloseIcon, Select } from '@bonitour/components'

import { Portal } from '../../Portal/Portal'

import {
  infoModalCard,
  languageSelectorContent,
  modalBg,
  modalButtonsBar,
  modalContainer,
  modalTitle
} from './NewLanguageModal.styles'

import { useState } from 'react'

export const NewLanguageModal = ({ onClose, onConfirm, languageOptions }) => {
  const [language, setLanguage] = useState('')

  return (
    <Portal selector='__MODALS_PORTAL__'>
      <div css={modalContainer}>
        <button type='button' onClick={onClose} css={modalBg} />
        <div css={infoModalCard}>
          <button
            type='button'
            onClick={onClose}
            className='close-modal-button'
          >
            <CloseIcon />
          </button>
          <div css={modalTitle}>
            <b>Adicionar tradução</b>
          </div>

          <div css={languageSelectorContent}>
            <b>Escolha o idioma</b>
            <Select
              options={languageOptions}
              value={language}
              placeholder='Selecione o idioma'
              // @ts-ignore
              onChange={setLanguage}
            />

            <div css={modalButtonsBar}>
              <button className='cancel_btn' onClick={onClose}>
                Cancelar
              </button>

              <button className='add_btn' onClick={() => onConfirm(language)}>
                Adicionar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}
