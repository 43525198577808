/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { GenericDocumentPage } from './GenericDocumentPage'
import { useCallback } from 'react'
import {
  getCustomText,
  patchCustomText,
  postCustomText
} from '../CustomText/CustomText.io'
import { EDITOR_TOOLS } from '../TextEditor/consts/EditorConsts'

const QUOTATION_POLICY = 'quotation_policy'

export const QuotationPolicy = () => {
  const handleGetDoc = useCallback(async () => {
    const res = await getCustomText()
    const content = res?.find(item => item.type === QUOTATION_POLICY)?.content
    return content || ''
  }, [])

  const handlePostDoc = useCallback(
    async ({ content }) =>
      await postCustomText({
        type: QUOTATION_POLICY,
        content
      }),
    []
  )

  const handlePatchDoc = useCallback(
    async ({ content }) =>
      await patchCustomText({
        type: QUOTATION_POLICY,
        content
      }),
    []
  )

  return (
    <GenericDocumentPage
      getDoc={handleGetDoc}
      postDoc={handlePostDoc}
      patchDoc={handlePatchDoc}
      successMessage='Política de cotação salva com sucesso!'
      successRemovalMessage='Política de cotação removida com sucesso!'
      impossibleToSaveEmptyMessage='Não é possível salvar uma política de cotação vazia!'
      failedToSaveMessage='Não foi possível salvar as alterações, tente novamente mais tarde'
      documentTitle='Política de cotação'
      tools={[
        EDITOR_TOOLS.bold,
        EDITOR_TOOLS.italic,
        EDITOR_TOOLS.strikethrough,
        EDITOR_TOOLS.list,
        EDITOR_TOOLS.orderedList,
        EDITOR_TOOLS.h2,
        EDITOR_TOOLS.h3,
        EDITOR_TOOLS.h4,
        EDITOR_TOOLS.h5,
        EDITOR_TOOLS.h6
      ]}
    />
  )
}
