import { colors } from '@bonitour/components'
import styled from '@emotion/styled'
import { BREAK_POINTS } from 'assets/styles/queries'

import { depth } from 'styles'

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;
`

export const OpenFiltersButton = styled.button`
  --filter--btn-size: 2.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  position: relative;
  width: var(--filter--btn-size);
  height: var(--filter--btn-size);
  min-width: var(--filter--btn-size);
  min-height: var(--filter--btn-size);
  border-radius: var(--round-element-radius, 100vw);

  background: transparent;
  font-size: 1.25rem;
  color: ${colors.gray3};

  transition: background 125ms ease, color 125ms ease;

  &:not(.is_open):hover {
    background: ${colors.white};
  }

  &.is_open {
    --filter-alert-color: ${colors.white};
    background: ${colors.primary};
    color: ${colors.white};
  }

  &.has_filter::before {
    --filter-alert-size: calc(var(--filter--btn-size) * 0.25);

    content: '';
    position: absolute;
    top: 0.45rem;
    right: 0.4rem;
    width: var(--filter-alert-size);
    height: var(--filter-alert-size);
    min-width: var(--filter-alert-size);
    min-height: var(--filter-alert-size);

    background: var(--filter-alert-color, ${colors.orange1});
    border-radius: var(--round-element-radius, 100vw);
  }
`

export const FilterPopUp = styled.div`
  ${depth({ depthValue: 7 })}

  position: absolute;

  display: flex;
  flex-direction: column;

  width: 15rem;
  min-height: 5rem;
  top: -1.25rem;
  right: calc(100% + 1.25rem);
  z-index: 10;

  background: ${colors.white};
  color: ${colors.gray3};

  padding: 1rem 1.25rem;
  border-radius: 0.675rem;

  .container__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    gap: 10px;

    .filter__icon {
      font-size: 1.125rem; // 18px
    }

    .popup__title {
      margin-bottom: 1.25rem;

      font-size: 1.125rem; // 18px
      font-weight: 700;
    }
  }

  .buttons_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 10px;

    .reset_button {
      border: none;

      &:hover {
        background-color: ${colors.gray11};
      }
    }

    button {
      border-radius: 10px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    background: ${colors.white};
    border-radius: 0.25rem 0px 0.5rem 0px;
    width: 1rem;
    height: 1rem;
    z-index: 8;
    box-shadow: -2px -2px 4px -2px rgba(0, 0, 0, 0.12),
      0 -3px 3px -3px rgba(0, 0, 0, 0.08);

    right: -0.5rem;
    top: 1.85rem;
    transform: rotate(135deg);
  }

  @media (max-width: ${BREAK_POINTS.phone}) {
    width: 75vw;
    right: calc(100% + 0.25rem);
  }

  @media (max-width: ${BREAK_POINTS.smallPhone}) {
    width: 80vw;
    right: calc(100% - 0.5rem);
  }
`

export const FiltersFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > div + div {
    margin-top: 1.25rem;
  }

  .filter_buttons__bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    margin-top: 2rem;

    button {
      padding: 0.75rem 2.25rem;
    }

    button + button {
      margin-left: 1rem;
    }

    .button__outline {
      border-color: ${colors.gray4};
      color: ${colors.gray4};
      padding: 0.75rem 1.5rem;
    }
  }
`
