/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { formatMoney } from '@bonitour/common-functions'
import { extraServicesSection } from './QuotationExtraServicesListStyles'
import { peopleRowStyle } from './QuotationsListItem.style'

export const QuotationExtraServiceList = ({ extraServices = [] }) => {
  return (
    <div css={extraServicesSection}>
      <h4 className='extra_services__title'>Serviços Extra</h4>

      <table>
        <tr>
          <th>Fornecedor Externo</th>
          <th>Nome da Experiência</th>
          <th>Data</th>
          <th>Hora</th>
          <th>Viajantes</th>
          <th>Valor</th>
        </tr>
        {extraServices.map(service => (
          <tr key={service.id}>
            <td>{service.seller || '--'}</td>
            <td>{service?.name || '--'}</td>
            <td>{service.slot.format('DD/MM/YYYY')}</td>
            <td>{service?.slot.format('HH:mm')}</td>
            <td>
              <span css={peopleRowStyle}>{service.pax}</span>
            </td>
            <td>{formatMoney(service.price)}</td>
          </tr>
        ))}
        <br />
      </table>
    </div>
  )
}
