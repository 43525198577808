/** @type {(title:Record<string, string> | string, lang?: string) => string} */
export const parseTitleProp = (title, lang = 'pt-br') => {
  if (typeof title === 'string' || title instanceof String) return String(title)
  return title ? title[lang] || title['pt-br'] || '' : ''
}

/** @type {(str: string) => string} */
export const normalizeString = str => {
  const accentsMap = {
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ'
  }

  return Object.keys(accentsMap).reduce((acc, curr) => {
    return acc.replace(new RegExp(accentsMap[curr], 'g'), curr)
  }, str.toLowerCase())
}

const typeLabels = {
  pt: {
    adult: 'Adultos',
    child: 'Crianças',
    senior: 'Sêniors',
    free: 'Não Pagantes',
    unique: 'Tarifa Única'
  }
}

const ageRangeLabels = {
  pt: {
    years: 'anos',
    to: 'a'
  }
}

export const priceDetailsParser = ({ priceDetails = [] }) => {
  const parsedDetails = priceDetails.reduce((acc, item) => {
    if (item.ageRange) {
      const ageRange = item.ageRange
        .replace(/\D*([0-9]+)\D+([0-9]+).*/, '$1-$2')
        .split('-')

      const updatedItem = {
        ...item,
        minAge: Math.min(...ageRange),
        maxAge: Math.max(...ageRange)
      }

      return [...acc, updatedItem]
    }

    if (item.name.toLowerCase() === 'no_price') {
      return [...acc, parseSinglePrice(item, 'noPrice', 'error')]
    }

    switch (normalizeString(item.name)) {
      case 'tarifa unica':
        return [...acc, parseSinglePrice(item, typeLabels.pt.unique, 'unique')]

      case 'adulto':
        return [...acc, parseSinglePrice(item, typeLabels.pt.adult, 'adult')]

      case 'senior':
        return [...acc, parseSinglePrice(item, typeLabels.pt.senior, 'senior')]

      case 'crianca':
        return [...acc, parseSinglePrice(item, typeLabels.pt.child, 'child')]

      case 'nao pagante':
        return [...acc, parseSinglePrice(item, typeLabels.pt.free, 'free')]

      default:
        return acc
    }
  }, [])

  const hasNoPriceOrHasPriceError = parsedDetails?.some(detail => {
    return detail.typeId === 'error' || detail.totalPrice === 'priceError'
  })

  const sortedParsedDetails = parsedDetails.sort((a, b) => {
    if (a.unitPrice < b.unitPrice) return 1
    if (a.unitPrice > b.unitPrice) return -1
    return 0
  })

  return hasNoPriceOrHasPriceError ? [] : sortedParsedDetails
}

const getTotalPrice = (unitPrice, count) => {
  try {
    const numericPrice = Number(unitPrice)
    if (isNaN(numericPrice)) throw new Error('Invalid unit price')

    const totalPrice = count * numericPrice
    return String(totalPrice)
  } catch (error) {
    return 'priceError'
  }
}

const parseSinglePrice = (item, typeLabel, typeId) => {
  return {
    typeId,
    type: typeLabel,
    count: item.count,
    unitPrice: item.unit_price,
    totalPrice: getTotalPrice(item.unit_price, item.count),
    minAge: item.description?.min_age || 0,
    maxAge: item.description?.max_age || 120,
    ageRange: `${item.description?.min_age || 0} ${ageRangeLabels.pt.to} ${
      item.description?.max_age || 120
    } ${ageRangeLabels.pt.years}`
  }
}

export const parseDate = date => {
  const parsedDate = new Date(date)
  parsedDate.setMinutes(
    parsedDate.getMinutes() + parsedDate.getTimezoneOffset()
  )
  return parsedDate.toLocaleDateString('pt')
}

const calculatePrice = (unitPrice, count) => {
  try {
    const numericPrice = Number(unitPrice)
    return count * numericPrice
  } catch (error) {
    return 0
  }
}

export const priceCalculate = ({
  priceDetails = [],
  adults = 0,
  seniors = 0,
  children = []
}) => {
  const parsedPrices = priceDetailsParser({ priceDetails }) || []

  const passengerAges = children.concat(
    Array(adults).fill(35),
    Array(seniors).fill(65)
  )

  const totalPrice = parsedPrices.reduce(
    (acc, current) => {
      const currentPassengersAge = passengerAges.filter(
        age =>
          age >= current.minAge &&
          age <= current.maxAge &&
          !acc.passengerAges.includes(age)
      )
      const partial = calculatePrice(
        current.unitPrice,
        currentPassengersAge.length
      )

      return {
        total: acc.total + partial,
        discriminated: [
          ...acc.discriminated,
          {
            type: current.type,
            unitPrice: current.unitPrice,
            totalPrice: partial,
            ageRange: current.ageRange,
            count: currentPassengersAge.length
          }
        ],
        passengerAges: acc.passengerAges.concat(currentPassengersAge)
      }
    },
    { total: 0, discriminated: [], passengerAges: [] }
  )

  return totalPrice
}

export const safeHtmlString = str =>
  String(str || '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/\n/g, '<br/>')

export const htmlToString = html => {
  const element = document.createElement('html')
  element.innerHTML = String(html || '')
  return element.innerText
}

export const generateUUID = () => {
  let uuid = ''
  let i
  let random
  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-'
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16)
  }
  return uuid
}

export function removeNullAndEmptyFields(obj) {
  Object.keys(obj).forEach(key => {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === '' ||
      obj[key]?.length === 0
    ) {
      delete obj[key]
    } else if (Array.isArray(obj[key])) {
      // Check if the array contains only undefined values
      const isUndefinedArray = obj[key].every(value => value === undefined)
      if (isUndefinedArray) {
        delete obj[key]
      }
    }
  })

  return obj
}
