import { string } from 'yup'

export const FilterExperiencesSchema = {
  title: string(),
  serviceType: string().nullable(),
  stateId: string(),
  cityId: string().test('has-state', 'A cidade é obrigatória', function () {
    const isStateSelected = this.parent?.stateId?.length > 0 || false
    const isCitySelected = this.parent?.cityId?.length > 0 || false

    if (isStateSelected && !isCitySelected) {
      return false
    } else {
      return true
    }
  })
}
