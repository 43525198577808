import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import { classContains } from 'Shared/utils/utils.styles'

export const cardStyles = hasImage => css`
  display: flex;
  gap: 20px;
  flex-direction: column;

  ${hasImage
    ? ''
    : `
    ${classContains('dropzoneDisabled')} {
      background-color: ${colors.gray11};
      position: relative;

      &::after{
        content: 'Sem imagem cadastrada';
        position: absolute;
        inset: 0;
        text-align: center;
        top: 50%;
        display: block;
        transform: translateY(-50%);
        line-height: 60px;
      }
    }
  `}
`

export const inputGroupWithLimitStyles = css`
  display: flex;
  gap: 5px;
  .end {
    align-self: end;
  }

  .keyword {
    top: 1193px;
    left: 90px;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #00000029;
    color: ${colors.gray3};
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font: normal normal normal 14px/18px Mulish;
  }

  .closeIcon {
    padding: 5px;
    cursor: pointer;
  }

  .padder {
    border: none;
  }
`

export const tooltipStyles = css`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`

export const h6 = css`
  text-align: left;
  font: normal normal bold 11px/14px Mulish;
  letter-spacing: 0px;
  color: ${colors.gray6};
  opacity: 1;
`

export const heading = css`
  margin: 30px 0;
`

export const button = css`
  padding: 10px 30px;
`

export const resetButton = css`
  margin: 30px 0;
  align-self: start;
  border: 1px solid ${colors.gray5};
`

export const width = px => css`
  width: ${px}px;
`

export const w400 = width(400)
export const w575 = width(575)
