import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const extraServicesSection = css`
  margin-top: 0.5em;

  .extra_services__title {
    margin-bottom: 1em;
    font-size: 15px;
    font-weight: 700;
    color: ${colors.gray6};
    width: 100%;
    display: block;
    padding-top: 0.5em;
    border-top: 1px solid ${colors.gray8};
  }
`
