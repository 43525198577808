import { colors, flex, flexHorizontallyCentered } from '@bonitour/components'
import { css } from '@emotion/react'

export const headingRow = css`
  justify-content: space-between;
  align-items: baseline;
`
export const saveButton = css`
  margin-left: 20px;
  width: 102px;
`

export const resetFile = css`
  font-size: 12px;
  margin-top: 10px;
  padding: 3px 10px;
  border: 1px solid ${colors.primary};
  border-radius: var(--small-round-element-radius, 20px);
`

export const rowButtons = css`
  width: 300px;
`

export const disabled = css`
  color: ${colors.gray4};
  border-color: ${colors.gray4};
`

export const uploadRowStyle = css`
  justify-content: space-between;
`

export const idealWeightStyle = css`
  color: ${colors.gray5};
  font: normal normal 600 10px/18px Mulish;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const idealSizeStyle = css`
  color: ${colors.gray5};
  font: normal normal 600 10px/18px Mulish;
  display: flex;
  flex-direction: row;
`

export const previewWrapper = css`
  width: 100%;
  @media (min-width: 900px) {
    width: 66.66%;
    padding-left: 10px;
    box-sizing: border-box;
  }
`
export const formStyle = css`
  width: 100%;
  @media (min-width: 900px) {
    width: 33.33%;
    padding-right: 10px;
    box-sizing: border-box;
  }
`

export const formLabel = css`
  label {
    font-size: 16px;
    color: ${colors.gray2};
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
  }

  @media (min-width: 900px) {
    width: 60%;
  }
`

export const helpInfo = css`
  font-size: 14px;
  color: ${colors.gray2};
  font-weight: normal;
  margin-top: 10px;
`

export const helpInfoExternalLink = css`
  margin-top: 5px;
  margin-bottom: 20px;

  span {
    text-decoration: underline;
    margin-right: 10px;
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: ${colors.primary};
  }
`

export const label = css`
  width: 100%;
  margin-left: 0;
  display: flex;
  align-items: baseline;
  gap: 8px;
  max-width: 320px;
  [class*='arrow-right'] {
    bottom: 8px;
  }
`

export const footerRowCss = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  margin-bottom: 30px;
`

export const divInput = css`
  width: 100%;
  max-width: 500px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 10px;
  align-items: center;
  color: ${colors.gray3};

  .inputSocialNetworks {
    width: 100%;
  }

  .icon {
    width: 36px;
    height: 36px;
  }
`

export const colorPicker = css`
  width: 100%;
`

export const cardStyle = css`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0px 35px;
  width: 470px;
  height: 206px;
  box-sizing: border-box;
  overflow-y: auto;
`

export const circleContainerStyle = css`
  height: 30px;
  width: 30px;
  position: relative;
`

export const titleStyle = css`
  margin: 0;
`

export const headerStyle = css`
  ${flex}
  ${flexHorizontallyCentered}
  justify-content: space-between;
  align-items: baseline;
`

export const buttonGroupStyle = css`
  ${flex}
  ${flexHorizontallyCentered}
  gap: 20px;
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
`

export const marginBottom20 = css`
  margin-bottom: 20px;
`

export const marginBottom30 = css`
  margin-bottom: 30px;
`

export const coverParent = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const overlayStyle = css`
  ${coverParent}
  background-color: #0000004D;
  display: grid;
  place-items: center;
`

export const confirmationTextStyle = css`
  color: ${colors.gray3};
  margin-bottom: 5%;
`

export const infoTooltipStyle = css`
  margin-left: 5px;

  &:hover {
    .tooltip-on-hover {
      white-space: nowrap;

      a {
        color: inherit;
      }
    }
  }
`

export const purchasingPolicyRow = css`
  width: 100%;
  margin-top: 30px;

  label {
    font-size: 18px;
    font-weight: 700;
    color: ${colors.gray3};
  }

  p {
    margin-top: 10px;
    color: ${colors.gray3};
    font-size: 14px;
  }

  a {
    color: ${colors.primary};
    font-weight: 700;
  }

  & + & {
    margin-top: 40px;
  }
`

export const titleHeight = css`
  line-height: 1.4;
`

export const alertContainerCss = css`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 64px;

  svg {
    opacity: 7%;
    width: 140px;
    height: 140px;
  }

  p {
    margin-top: 32px;
    color: ${colors.gray3};
    line-height: 20px;
  }
`
