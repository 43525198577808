/** @jsxRuntime classic */
/** @jsx jsx */
import { useCallback, useMemo, useState } from 'react'
import { Card, H3, CogIcon, useToast } from '@bonitour/components'
import { css, jsx } from '@emotion/react'
import { useHistory } from 'react-router-dom'
import { InlineToggle } from 'components/InlineToggle/InlineToggle'
import { Link } from 'react-router-dom'
import { normalizeString } from 'utils/utils'
import {
  InlineToggleContainer,
  cardStyles,
  itinialsCircleStyle,
  marginless
} from './RegionCard.style'
import { updateCustomRegion } from '../io/customRegions.io'
import { usePermission } from 'Shared/contexts/Permissions'
import { useActionOnFastClick } from 'utils/utilityHooks/useActionOnFastClick'
import { useScrollOnDrag } from 'utils/utilityHooks/useScrollOnDrag'

/** @type {(initials: string) => string} */
const getCircleColor = initials => {
  const colorPallete = [
    '#29E0C5',
    '#1FD6BB',
    '#1CC4AB',
    '#19B39C',
    '#00ABB8',
    '#0096A2',
    '#007DA3',
    '#006D8F',
    '#4D4DFF',
    '#0957C3',
    '#4747FF',
    '#005670',
    '#084EB2',
    '#3333FF',
    '#07459C',
    '#063475',
    '#042759'
  ]
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

  const [firstLetter = 'a', secondLetter = 'a'] = initials
    .toLocaleLowerCase()
    .split('')

  const firstLetterValue = alphabet.indexOf(firstLetter) + 1
  const secondLetterValue = alphabet.indexOf(secondLetter) + 1

  const colorValue =
    ((firstLetterValue * secondLetterValue) % colorPallete.length) - 1

  return colorPallete[colorValue] || colorPallete[0]
}

/** @type {(title: string) => string} */
const createInitials = title => {
  const wordsArray = title.split(' ')

  const validWords = wordsArray.filter(word => {
    return word.length > 3 || /^[A-Z]/.test(word)
  })

  if (validWords.length <= 1) {
    return normalizeString(title.substr(0, 2)).toUpperCase()
  }

  return normalizeString(
    `${validWords[0].substr(0, 1)}${validWords[1].substr(0, 1)}`
  ).toUpperCase()
}

/**
 * @param {{
 *  title: string,
 *  size?: number
 * }} props
 */
export const RegionCircle = ({ title, size = 80 }) => {
  const regionCardData = useMemo(() => {
    const initials = createInitials(title)
    return {
      initials,
      bgColor: getCircleColor(initials)
    }
  }, [title])

  const sizeCss = useMemo(
    () => css`
      width: ${size}px;
      min-width: ${size}px;
      height: ${size}px;
      min-height: ${size}px;
      font-size: ${size / 2.5}px;
    `,
    [size]
  )

  return (
    <div
      css={[itinialsCircleStyle, sizeCss]}
      className='region-circle'
      style={{ backgroundColor: regionCardData.bgColor }}
    >
      {regionCardData.initials}
    </div>
  )
}

/**
 * @param {{
 *  regionId: string,
 *  title: string,
 *  isAgentVisible: boolean,
 *  isUserVisible: boolean,
 *  numberOfActivities: number,
 *  isDragging: boolean
 * }} props
 */
export const RegionCard = ({
  title,
  regionId,
  isAgentVisible,
  isUserVisible,
  numberOfActivities = 0,
  isDragging = false
}) => {
  const { push } = useHistory()
  const { add: addToast } = useToast()

  const { allowed: canEdit } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const regionUrl = useMemo(
    () => `/app/regions/${regionId}/${encodeURIComponent(title)}`,
    [regionId, title]
  )

  const onFastClick = useCallback(() => {
    push(regionUrl)
  }, [push, regionUrl])
  const { onMouseEvent } = useActionOnFastClick(onFastClick)

  const [isVisibleForUser, setIsVisibleForUser] = useState(isUserVisible)
  const [isVisibleForAgent, setIsVisibleForAgent] = useState(isAgentVisible)

  const stopPropagation = useCallback(e => e.stopPropagation(), [])

  const onChangeUserVisibility = useCallback(
    isVisible => {
      setIsVisibleForUser(isVisible)

      updateCustomRegion(regionId, {
        user_visible: isVisible
      })
        .then(() => {
          addToast(
            `Visibilidade do turista ${
              isVisible ? 'ativada' : 'desativada'
            } com sucesso`,
            'success'
          )
        })
        .catch(e => {
          console.error(e)
          setIsVisibleForUser(!isVisible)
          addToast(
            'Houve um erro alterar a visibilidade para turistas',
            'error'
          )
        })
    },
    [addToast, regionId]
  )

  const onChangeAgentVisibility = useCallback(
    isVisible => {
      setIsVisibleForAgent(isVisible)
      if (!isVisible) setIsVisibleForUser(false)

      updateCustomRegion(regionId, {
        agent_visible: isVisible,
        ...(!isVisible && { user_visible: false })
      })
        .then(() => {
          addToast(
            `Visibilidade do agente ${
              isVisible ? 'ativada' : 'e turista desativadas'
            } com sucesso`,
            'success'
          )
        })
        .catch(e => {
          console.error(e)
          setIsVisibleForAgent(!isVisible)
          setIsVisibleForUser(!isVisible)
          addToast('Houve um erro alterar a visibilidade para agentes', 'error')
        })
    },
    [addToast, regionId]
  )

  const { onMouseMove } = useScrollOnDrag({ enabled: isDragging })

  return (
    <Card
      css={cardStyles}
      onMouseUp={onMouseEvent}
      onMouseDown={onMouseEvent}
      onTouchStart={onMouseEvent}
      onTouchEnd={onMouseEvent}
      onMouseMove={onMouseMove}
      onTouchMove={onMouseMove}
    >
      <RegionCircle title={title} />
      <H3 css={marginless}>
        <span>{title}</span>
      </H3>
      <span>{numberOfActivities} experiências</span>

      <Link to={regionUrl} onMouseDown={stopPropagation}>
        <CogIcon />
      </Link>

      <InlineToggleContainer className='disable-fast-click'>
        <InlineToggle
          id={`toggle-agent-visibility-${regionId}`}
          disabled={!canEdit}
          onChange={ev => onChangeAgentVisibility(ev.target.checked)}
          isEnabled={isVisibleForAgent}
          label='Visível para agente'
        />
        <InlineToggle
          id={`toggle-user-visibility-${regionId}`}
          disabled={!canEdit || !isVisibleForAgent}
          onChange={ev => onChangeUserVisibility(ev.target.checked)}
          isEnabled={isVisibleForUser}
          label='Visível para turista'
        />
      </InlineToggleContainer>
    </Card>
  )
}
