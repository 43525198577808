/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Card,
  TableContainer,
  TableBody,
  H2,
  Row,
  PaginationContainer,
  LoadingAnimation,
  HeaderPage
} from '@bonitour/components'

import { QuotationsHeader } from './QuotationsHeader'
import { QuotationsListItem } from './QuotationsListItem'

import { getQuotations } from './io/Quotations.io'
/*
TO DO: Waiting for new approach to get updated itineraries reservations status

import { getQuotationsTotalizer } from './io/Quotations.io'
*/
import { useHistory } from 'react-router'
import {
  secondaryRow,
  searchBarContainerStyle,
  tableContainer,
  quotationsPaginationContainer
} from './QuotationsPage.style'
import { QuotationsFilter } from './QuotationsFilter'
import { AgentShareModal } from 'components/AgentTools/ShareModal'
/* 
TO DO: Waiting for new approach to get updated itineraries reservations status

import { QuotationsTotalizer } from './QuotationsTotalizer' 
*/
import { encodeToShortId } from 'Shared/utils/mongoIdEncodingProvider'
import { useRequirePermission } from 'Shared/contexts/Permissions'
import { useQuotationExporter } from 'utilityHooks/useQuotationExporter/useQuotationExporter'
import { SearchBar } from '../CustomRegionsExperiences/Components/SearchBar'

const QuotationsCard = ({ totalQuotations, loading = false }) => {
  const [isResendOpen, setIsResendOpen] = useState(false)
  const [quotationIdToResend, setQuotationIdToResend] = useState(null)

  const { onExport } = useQuotationExporter()

  const handleOnResend = useCallback(quotationId => {
    setQuotationIdToResend(quotationId)
  }, [])

  const quotationDataToResend = useMemo(() => {
    const quotationData = totalQuotations?.quotations?.find(
      quotation => quotation.id === quotationIdToResend
    )

    if (!quotationData) {
      return null
    }
    setIsResendOpen(true)

    return quotationData
  }, [quotationIdToResend, totalQuotations])

  // TODO - Fix it to work with than one region
  const quotationRegion = useMemo(
    () =>
      quotationDataToResend?.custom_regions?.[0] || {
        name: '--'
      },
    [quotationDataToResend]
  )

  const handleOnCloseResendQuotation = useCallback(() => {
    setIsResendOpen(false)
    setQuotationIdToResend(null)
  }, [])

  return (
    <Card customCss={[tableContainer]}>
      <TableContainer>
        <QuotationsHeader />
        {totalQuotations && (
          <TableBody loading={loading}>
            {totalQuotations.quotations.map(quotation => (
              <QuotationsListItem
                key={quotation.id}
                quotation={quotation}
                onResend={handleOnResend}
                onExport={onExport}
              />
            ))}
          </TableBody>
        )}
      </TableContainer>

      <AgentShareModal
        isOpen={isResendOpen && quotationDataToResend}
        handleClose={handleOnCloseResendQuotation}
        host={quotationDataToResend?.domain || ''}
        itineraryId={quotationDataToResend?.id || ''}
        quotation={{
          encodedItineraryId: encodeToShortId(quotationDataToResend?.id || ''),
          name: quotationDataToResend?.quotation?.client_name || '',
          quotedItineraryId: quotationDataToResend?.id || '',
          reservationRef: quotationDataToResend?.quotation?.code || ''
        }}
        regionName={quotationRegion.name}
      />
    </Card>
  )
}

export const QuotationsPage = () => {
  const [quotations, setQuotations] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  /* 
     TO DO: Waiting for new approach to get updated itineraries reservations status

     const [isTotalizerLoading, setIsTotalizerLoading] = useState(true)
     const [quotationsTotalizer, setQuotationsTotalizer] = useState(null)
  */
  const [pagination, setPagination] = useState({
    current_page: 0,
    total_entries: 0,
    total_pages: 0,
    currentPage: 1,
    perPage: 10
  })

  const [filters, setFilters] = useState({
    initial_date: null,
    final_date: null,
    agent_name: null,
    client_name: null,
    client_email: null,
    query_string: null
  })

  const hasFilters = useMemo(() => {
    return Object.values(filters).some(filter => filter)
  }, [filters])

  const { push } = useHistory()

  const setQueryFilter = useCallback(query => {
    setFilters(v => {
      return {
        ...v,
        query_string: query
      }
    })
  }, [])

  const changePagination = useCallback((currentPage, perPage) => {
    setPagination(v => {
      return {
        ...v,
        currentPage,
        perPage
      }
    })
  }, [])

  const filterProps = useMemo(
    () => ({
      ...(filters.initial_date instanceof Date && {
        initial_date: filters.initial_date.toISOString().substring(0, 10)
      }),
      ...(filters.final_date instanceof Date && {
        final_date: filters.final_date.toISOString().substring(0, 10)
      }),
      ...(filters.agent_name && {
        agent_name: filters.agent_name
      }),
      ...(filters.client_name && {
        client_name: filters.client_name
      }),
      ...(filters.client_email && {
        client_email: filters.client_email
      }),
      ...(filters.query_string && {
        q: filters.query_string
      })
    }),
    [filters]
  )

  useEffect(() => {
    setIsLoading(true)
    getQuotations({
      ...(pagination.currentPage && {
        page: pagination.currentPage
      }),
      ...(pagination.perPage && {
        per_page: pagination.perPage
      }),
      ...filterProps
    }).then(res => {
      setIsLoading(false)
      setQuotations(res)
      setPagination(v => {
        return {
          ...v,
          ...res.meta
        }
      })
    })
  }, [pagination.currentPage, pagination.perPage, filterProps])

  useEffect(() => {
    if (pagination.currentPage > pagination.total_pages) {
      changePagination(Math.max(pagination.total_pages, 1), pagination.perPage)
    }
  }, [
    changePagination,
    pagination.currentPage,
    pagination.perPage,
    pagination.total_pages
  ])

  /*
  TO DO: Waiting for new approach to get updated itineraries reservations status

  useEffect(() => {
    setIsTotalizerLoading(true)
    getQuotationsTotalizer(filterProps)
      .then(setQuotationsTotalizer)
      .finally(() => setIsTotalizerLoading(false))
  }, [filterProps]) 
  */

  useRequirePermission({
    permission: 'xpert_agent'
  })

  const goToDashboard = useCallback(() => {
    push('/app')
  }, [push])

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Roteiros enviados' />
      <Row customCss={[secondaryRow]}>
        <H2>Todos os roteiros</H2>
        <Row customCss={[searchBarContainerStyle]}>
          <SearchBar
            onDebounce={setQueryFilter}
            currentValue={filters?.query_string}
            placeholder='Buscar em roteiros'
          />
          <QuotationsFilter onFilter={setFilters} hasFilters={hasFilters} />
          {/* TO DO: ? */}
          {/* <ExportIcon /> */}
        </Row>
      </Row>
      {/*  TO DO: Waiting for new approach to get updated itineraries reservations status */}
      {/* <QuotationsTotalizer
        quotationsTotalizer={quotationsTotalizer}
        isLoading={isTotalizerLoading}
      /> */}
      <PaginationContainer
        // @ts-ignore
        title={isLoading ? <LoadingAnimation /> : 'Nenhum roteiro encontrado.'}
        subtitle={
          isLoading ? null : 'Você não enviou nenhum roteiro até o momento.'
        }
        onPagination={changePagination}
        pageLimitDefault={pagination.perPage}
        total={pagination.total_entries}
        currentPage={pagination.currentPage}
        pagesQuantity={pagination.total_pages}
        isOpenDrawer
        childrenCustomCss={[quotationsPaginationContainer]}
      >
        <QuotationsCard loading={false} totalQuotations={quotations} />
      </PaginationContainer>
    </>
  )
}

/* const ExportIcon = () => <LogoutIcon css={iconStyle} /> */
