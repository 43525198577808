/** @jsx jsx **/
import { jsx } from '@emotion/react'
import { Card, Label, PlusIcon } from '@bonitour/components'
import { cardButton, cardIcon, labelCardButton } from './NewRegionButton.style'

export const NewRegionButton = ({ onClick }) => {
  return (
    <Card onClick={onClick} customCss={[cardButton]}>
      <PlusIcon css={cardIcon} />
      <Label css={labelCardButton}>Nova região</Label>
    </Card>
  )
}
