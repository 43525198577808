/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import {
  ClickIcon,
  colors,
  RoleIndicatorIcon,
  MountainIcon,
  TooltipOnHover,
  MailIcon
} from '@bonitour/components'
import { useEffect, useState } from 'react'
import { getSiteConfig } from './Appearance/Appearance.io'
import { useCompany } from 'Shared/contexts/Company'
import { ItinerariesStatistics } from './AgentComponent/ItinerariesStatistics'
import { AbandonedCartsCount } from './AbandonedCart/AbandonedCartCount'
import { useFeatureFlags } from 'Shared/contexts/FeatureFlags'
import { usePermission } from 'Shared/contexts/Permissions'
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer'
import { BREAK_POINTS } from 'assets/styles/queries'

const card = css`
  width: 120px;
  height: 69px;
  text-decoration: none;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 20px 10px 0;
  color: ${colors.gray4};
  font-weight: 600;
  font-size: 14px;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
`

const icon = css`
  font-size: 22px;
  color: ${colors.gray4};
`

const cardRow = css`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const blockedBackoffice = css`
  position: relative;

  &::after {
    content: 'Usuário sem permissão para as funcionalidades de Backoffice';
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: rgba(247, 247, 247, 0.7);
    color: transparent;
    transition: color 300ms ease-in-out;
    font-weight: 500;
    z-index: 1;
  }
  &:hover::after {
    color: ${colors.gray1};
  }
`

const blockedAgent = css`
  ${blockedBackoffice}
  &::after {
    content: 'Usuário sem permissão para as funcionalidades de agente';
  }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    top: 94px;
    bottom: 20px;
    backdrop-filter: blur(6px);
    z-index: 1;
  }
`

const disabled = css`
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
`

const tooltip = enable => css`
  position: initial;
  &:hover .tooltip-on-hover {
    max-width: 300px;
    transform: translate(-12px, 90px);

    ${!enable ? 'display: none;' : ''}
  }
`

const cards = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 400px));
  gap: 20px;

  @media (max-width: ${BREAK_POINTS.beforePhoneLandscape}px) {
    grid-template-columns: 1fr;
  }
`

function Home() {
  const [companyHasSite, setHasSite] = useState(false)
  const [loading, setLoading] = useState(true)
  const { id: companyId } = useCompany()
  const { abandonedCart } = useFeatureFlags()

  useEffect(() => {
    if (companyId) {
      getSiteConfig(companyId)
        .then(config => {
          setHasSite(Boolean(config?.url))
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }, [companyId])

  const { isLoading: isLoadingAgentPermission, allowed: displayAgentOptions } =
    usePermission({
      permission: 'xpert_agent'
    })

  const {
    isLoading: isLoadingBackofficePermission,
    allowed: displayBackofficeOptions
  } = usePermission({
    permission: 'xpert_backoffice'
  })

  return (
    <LoadingContainer
      loading={isLoadingAgentPermission || isLoadingBackofficePermission}
    >
      <div css={[cardRow, !displayBackofficeOptions && blockedBackoffice]}>
        <Link to='/app/appearance' css={card}>
          <ClickIcon css={icon} />
          <p>Meu Site</p>
        </Link>
        <TooltipOnHover
          text='Para usar as funcionalidades de “Roteirização” e “Experiências” é necessário primeiro configurar o "Meu Site"'
          position='bottom'
          customCss={tooltip(!companyHasSite && !loading)}
        >
          <Link
            to='/app/algorithm-setup'
            css={[card, !companyHasSite && disabled]}
          >
            <RoleIndicatorIcon css={icon} />
            <p>Roteirização</p>
          </Link>
        </TooltipOnHover>
        <TooltipOnHover
          text='Para usar as funcionalidades de “Roteirização” e “Experiências” é necessário primeiro configurar o "Meu Site"'
          position='bottom'
          customCss={tooltip(!companyHasSite && !loading)}
        >
          <Link to='/app/regions' css={[card, !companyHasSite && disabled]}>
            <MountainIcon css={icon} />
            <p>Experiências</p>
          </Link>
        </TooltipOnHover>
        <TooltipOnHover
          text='Para usar as funcionalidades de “E-mails” é necessário primeiro configurar o "Meu Site"'
          position='bottom'
          customCss={tooltip(!companyHasSite && !loading)}
        >
          <Link to='/app/custom-text' css={[card, !companyHasSite && disabled]}>
            <MailIcon css={icon} />
            <p>E-mails</p>
          </Link>
        </TooltipOnHover>
      </div>
      <div css={[cards, !displayAgentOptions && blockedAgent]}>
        <ItinerariesStatistics
          mocked={!displayAgentOptions}
          hideErrors={!companyHasSite}
        />
        {abandonedCart ? (
          <AbandonedCartsCount
            mocked={!displayAgentOptions}
            hideErrors={!companyHasSite}
          />
        ) : null}
      </div>
    </LoadingContainer>
  )
}

export default Home
