import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const textEditorContainer = css`
  border: 1px solid ${colors.gray8};
  padding: 20px;
  border-radius: 10px;
  min-height: 275px;

  color: ${colors.gray2};
  font-family: Mulish, sans-serif;

  > div {
    > *:first-child {
      margin-top: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: 700;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 32px;
  }

  h2 {
    font-size: 22px;
    margin-top: 64px;
    margin-bottom: 32px;
  }

  h3 {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  h4 {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  h5 {
    font-size: 17px;
    margin-top: 28px;
    margin-bottom: 12px;
  }

  h6 {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin-top: 12px;
    line-height: 18px;
  }

  em {
    font-style: italic;
  }

  li {
    display: flex;
    margin-top: 8px;
    line-height: 18px;
  }

  ul li {
    &:before {
      content: '- ';
    }
  }

  ol {
    counter-reset: item;
  }

  ol li {
    &:before {
      content: counter(item) '. ';
      counter-increment: item;
    }
  }
`

export const toolbarStyles = css`
  width: calc(100% + 40px);
  border-bottom: 1px solid ${colors.gray8};
  margin: -20px -20px 0 -20px;
  padding: 10px 20px;
  box-sizing: border-box;

  display: flex;

  margin-bottom: 20px;
`

export const buttonStyles = ({ active = false }) => css`
  height: 35px;
  width: 35px;
  min-width: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: transparent;
  border: none;

  svg {
    color: ${active ? colors.gray1 : colors.gray7};
  }

  & + button {
    margin-left: 4px;
  }

  border: 1px solid ${colors.white};
  border-radius: 2px;
  transition: all 250ms;

  &:hover {
    svg {
      color: ${colors.gray1};
    }

    filter: brightness(0.8);
    border-radius: 10px;
  }
`
