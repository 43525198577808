import { Termservice } from 'core/Service/Terms'
import { useState, useEffect, useCallback } from 'react'

/**
 * @typedef Term
 * @prop { string } [id]
 * @prop { string } [updatedAt]
 */

export const useTerms = type => {
  /** @type {[Term, (Term) => void]} */
  const [term, setTerm] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchTerm = useCallback(type => {
    setLoading(true)

    return Termservice.getByType(type)
      .then(data => {
        setTerm(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const acceptTerm = useCallback(termId => {
    setLoading(true)

    return Termservice.acceptTerm(termId).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    fetchTerm(type)
  }, [fetchTerm, type])

  return {
    term,
    setTerm,
    loading,
    acceptTerm
  }
}
