import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { useTable } from './Table'
import {
  TableCell,
  TableCellRef,
  TableCellWrapper,
  TableRowAnnexWrapper,
  TableRowContainer,
  TableRowWrapper
} from './styles'

const TableRow = ({ children, onClick, className = '', annex = null }) => {
  const { labels } = useTable()
  const rowId = useMemo(
    () => `table-row-${Math.random().toString(16).slice(2)}`,
    []
  )

  return (
    <>
      <TableRowContainer onClick={onClick} layout className={className}>
        <TableRowWrapper id={rowId} layoutId={rowId} className='TableRow'>
          {labels.map((label, idx) => (
            <TableCellWrapper
              className={`TableCellWrapper column-${idx}`}
              key={idx}
            >
              <TableCellRef className={`TableCellRef column-${idx}`}>
                {label}
              </TableCellRef>
              <TableCell className={`TableCell column-${idx}`}>
                {children[idx]}
              </TableCell>
            </TableCellWrapper>
          ))}
        </TableRowWrapper>

        <AnimatePresence>
          {annex ? (
            <TableRowAnnexWrapper
              layoutId={`${rowId}-annex`}
              key='modal'
              initial={{
                opacity: 0
              }}
              animate={{
                opacity: 1
              }}
              exit={{
                opacity: 0,
                height: 0,
                padding: 0,
                margin: 0,
                overflow: 'hidden'
              }}
              transition={{ type: 'tween' }}
              // eslint-disable-next-line
              aria-details={rowId}
              className='TableRowAnnex'
            >
              {annex}
            </TableRowAnnexWrapper>
          ) : null}
        </AnimatePresence>
      </TableRowContainer>
    </>
  )
}
TableRow.displayName = 'TableRow'
TableRow.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  annex: PropTypes.any
}

export { TableRow }
