import { BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { headerRow as baseHeader } from '../../Experiences.style'

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: flex-end;

  .filter-icon {
    font-size: 22px;
  }
`

export const searchBarStyle = css`
  min-width: 322px;
  margin-bottom: 0;

  input {
    margin-left: 0;
  }

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    min-width: 0;
  }
`

export const FilterFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .input_container {
    align-items: flex-start;

    .select {
      border-radius: 10px;
    }
  }
`

export const headerRow = css`
  ${baseHeader}
  justify-content: space-between;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
