/** @jsxRuntime classic */
/** @jsx jsx */
import { Textarea } from '@bonitour/components'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'
import { TextAreaContainer, h6 } from './TextareaWithLength.style'

const H6 = ({ children, ...other }) => (
  <h6 css={h6} {...other}>
    {children}
  </h6>
)

export const TextareaWithLength = ({
  value,
  onChange,
  maxLength,
  error = null,
  ...props
}) => {
  return (
    <TextAreaContainer>
      <Textarea
        value={value}
        onChange={onChange}
        error={error}
        maxLength={maxLength}
        {...props}
      />
      <span className='end'>
        <H6>
          {value?.length || 0}/ {maxLength}
        </H6>
      </span>
    </TextAreaContainer>
  )
}

TextareaWithLength.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  maxLength: PropTypes.number,
  props: PropTypes.any
}
