import { extractData, xpertAxiosInstance } from 'external/axios'

const xpertInstance = xpertAxiosInstance()

export function updateCustomRegionToCustomService({
  customRegionId,
  customServiceId,
  updateParams
}) {
  return xpertInstance
    .patch(
      `/custom_regions/${customRegionId}/custom_services/${customServiceId}/backoffice`,
      updateParams
    )
    .then(extractData)
}
