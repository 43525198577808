import { isValidJson } from './isValidJson'

const LANGUAGE_DICTIONARY = {
  'pt-br': 'Português',
  'en-us': 'Inglês',
  'es-es': 'Espanhol'
}

/**
 * @param {{
 *    content: Record<string,string>;
 *    defaultLanguage?: string;
 *  }} formErrors
 * @return {{
 *  label: string;
 *  value: string;
 * }[]}
 */
export const getAvailableLanguages = ({
  content = {},
  defaultLanguage = 'pt-br'
}) => {
  const languageList = Object.keys(content)

  const languagesWithValidContent = languageList.filter(lang => {
    if (!isValidJson(content[lang])) return false

    const langValue = JSON.parse(content[lang])

    if (!langValue) return false

    return true
  })

  const languageListWithDefault = [
    defaultLanguage,
    ...languagesWithValidContent
  ].filter((item, pos, self) => self.indexOf(item) === pos)

  const safeLanguages = languageListWithDefault.filter(
    lang => !!LANGUAGE_DICTIONARY[lang]
  )

  return safeLanguages.map(language => ({
    label: LANGUAGE_DICTIONARY[language],
    value: language
  }))
}
