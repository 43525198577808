/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useEffect, useState } from 'react'
import BinamikLogo from 'Shared/svgs/logoXpert.svg'
import {
  Header,
  Hamburguer,
  Content,
  Footer,
  Sidebar,
  Logo,
  flexRowCentered,
  colors
} from '@bonitour/components'
import { ProductsSection } from './ProductsSection'
import { User } from './User'
import { AuthorizationProvider } from 'Shared/contexts/Authorization'
import { CompanyProvider, useCompany } from 'Shared/contexts/Company'
import { UserProvider } from 'Shared/contexts/User'
import { FeatureFlagsProvider } from 'Shared/contexts/FeatureFlags'
import { Link } from 'react-router-dom'
import { Menu } from './Menu'
import { HelpSection } from './Help'
import { TermsHandler } from 'Domains/Terms/TermsHandler'
import { CookieConsentHandler } from 'Domains/Cookies/CookieConsentHandler'
import { PermissionsProvider } from 'Shared/contexts/Permissions'
import { CustomRegionsProvider } from 'Shared/contexts/CustomRegions'

const APP_ENVIRONMENT =
  process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV
const { REACT_APP_ACCOUNTS_URL } = process.env

const envStyle = css`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
  text-transform: capitalize;
`

const wrapperStyle = css`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const contentStyle = css`
  padding: 30px 20px;
  max-width: 1308px;
`

const LayoutContainer = ({ children }) => {
  const [isVisible, setVisibility] = useState(false)
  const closeSidebar = () => setVisibility(false)
  const openSidebar = () => setVisibility(true)
  const { id: companyId } = useCompany()

  useEffect(() => {
    if (!companyId) {
      window.location = `${REACT_APP_ACCOUNTS_URL}/app?error=missing-company`
    }
  }, [companyId])

  return (
    <div css={wrapperStyle}>
      <Header>
        <div css={flexRowCentered}>
          <Hamburguer onClick={openSidebar} />
          <Link to='/'>
            <Logo src={BinamikLogo} />
          </Link>

          {APP_ENVIRONMENT !== 'production' && (
            <span css={envStyle}>{APP_ENVIRONMENT}</span>
          )}
        </div>
        <div css={flexRowCentered}>
          <HelpSection />
          <ProductsSection />
          <User />
        </div>
      </Header>
      <Content customCss={[contentStyle]}>{children}</Content>
      <CookieConsentHandler />
      <TermsHandler />
      <Footer />
      <Sidebar isOpen={isVisible} onSidebarClose={closeSidebar}>
        <Menu onNavigation={closeSidebar} />
      </Sidebar>
    </div>
  )
}

export const Layout = props => {
  return (
    <UserProvider>
      <CustomRegionsProvider>
        <CompanyProvider>
          <AuthorizationProvider>
            <PermissionsProvider>
              <FeatureFlagsProvider>
                <LayoutContainer {...props} />
              </FeatureFlagsProvider>
            </PermissionsProvider>
          </AuthorizationProvider>
        </CompanyProvider>
      </CustomRegionsProvider>
    </UserProvider>
  )
}
