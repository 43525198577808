import React, { useCallback, useEffect, useState } from 'react'
import { MailIcon, LinkIcon } from '@bonitour/components'
import { LinkToShare, ShareInfo, ShareModeContainer } from './styles'

/**
 * @typedef {{
 * travelerName: string
 * reservationRef: string
 * shareLink: string
 * onNext: (nextState: 'shareWhatsapp' | 'shareEmail') => void
 * addToast: any
 * i18n: {
 *   itineraryDetails: string
 *   travelersName: string
 *   refCode: string
 *   shareItinerary: string
 *   shareByEmailAria: string
 *   shareByWhatsappAria: string
 *   copyLinkAria: string
 *   linkCopiedAlert: string
 *   titleSendItineraryMobile: string
 *   whatsappLabel: string
 *   emailLabel: string
 * }
 * }} SharingInfoProps
 */

/** @type { React.FC<SharingInfoProps> } */
export const SharingInfo = ({
  travelerName,
  reservationRef,
  shareLink,
  addToast,
  onNext,
  i18n
}) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const handleCopyHtml = useCallback(() => {
    navigator.clipboard.writeText(shareLink)
    if (!isLinkCopied) {
      setIsLinkCopied(true)
      addToast({ type: 'success', text: i18n.linkCopiedAlert })
    }
  }, [addToast, i18n.linkCopiedAlert, isLinkCopied, shareLink])

  useEffect(() => {
    if (!isLinkCopied) return

    const tooltipTimer = setTimeout(() => setIsLinkCopied(false), 3 * 1000)
    return () => {
      clearTimeout(tooltipTimer)
    }
  }, [isLinkCopied])

  return (
    <>
      <b>{i18n.itineraryDetails}</b>

      <ShareInfo>
        <div>
          <label htmlFor='share--traveler-name'>{i18n.travelersName}</label>
          <p id='share--traveler-name'>{travelerName}</p>
        </div>

        <div>
          <label htmlFor='share--ref-code'>{i18n.refCode}</label>
          <p id='share--ref-code'>{reservationRef}</p>
        </div>
      </ShareInfo>

      <b className='titleShareItinerary'>{i18n.shareItinerary}</b>

      <LinkToShare
        type='button'
        onClick={handleCopyHtml}
        aria-label={i18n.copyLinkAria}
      >
        <span>{shareLink}</span>
        <span className='link_icon'>
          <LinkIcon />
        </span>
      </LinkToShare>

      <ShareModeContainer>
        <button
          type='button'
          onClick={() => onNext('shareWhatsapp')}
          aria-label={i18n.shareByWhatsappAria}
        >
          <img
            src='/icons/whatsappIcon.svg'
            className='icon'
            alt={i18n.whatsappLabel}
          />
          <span className='titleButton'>{i18n.whatsappLabel}</span>
        </button>

        <button
          type='button'
          onClick={() => onNext('shareEmail')}
          aria-label={i18n.shareByEmailAria}
        >
          <span className='with_border'>
            <MailIcon />
          </span>
          <span className='titleButton'>{i18n.emailLabel}</span>
        </button>
      </ShareModeContainer>
    </>
  )
}
