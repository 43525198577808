/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { colors, ToastProvider } from '@bonitour/components'
import { jsx, css, Global } from '@emotion/react'
import 'reset-css'
import '@bonitour/font/style.css'
import PortalsContainer from 'components/Portal/PortalsContainer'

const bodyStyle = css`
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700&display=swap');
  body,
  input,
  textarea,
  button {
    font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;

    --primary-color: ${colors.primary};

    /* Rounded Square appearence: */
    --round-element-radius: 10px;
    --small-round-element-radius: 6px;
  }

  body,
  * {
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${colors.gray10};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${colors.indigo2};
      border-radius: 5px;
    }
  }
`

const toastPosition = css`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
`

export const Base = ({ children }) => (
  <ToastProvider cssStyle={toastPosition}>
    <PortalsContainer>
      <Global styles={bodyStyle} />
      {children}
    </PortalsContainer>
  </ToastProvider>
)
