/* eslint-disable camelcase */

/** @jsxRuntime classic */
/** @jsx jsx */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import {
  Backdrop,
  Card,
  CircleImage,
  CogIcon,
  EllipsisIcon,
  H4,
  Manager,
  MountainIcon,
  Popover,
  Portal,
  Reference,
  StarFilledIcon,
  StarIcon,
  TrashIcon,
  useToast
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { identity } from 'fp-ts/lib/function'

import {
  cardMenu,
  cardStyles,
  cardTitle,
  transparentBackdrop,
  experienceImage,
  marginless,
  menuPopover,
  smallText,
  invisibleCloseMenuTrigger,
  filledStar,
  imageWrapper,
  defaultImage
} from '../Experiences.style'
import { Link } from 'react-router-dom'
import { InlineToggle } from 'components/InlineToggle/InlineToggle'
import { InlineToggleContainer } from './ExperienceCard.style'
import { updateCustomRegionToCustomService } from '../io/customRegionsToCustomServices.io'
import { usePermission } from 'Shared/contexts/Permissions'
import { useActionOnFastClick } from 'utils/utilityHooks/useActionOnFastClick'
import { useScrollOnDrag } from 'utils/utilityHooks/useScrollOnDrag'

const accidentalDragDelay = 400

/**
 * @param {{
 * isAgentVisible: boolean;
 * isUserVisible: boolean;
 * title: string;
 * companyName: string;
 * serviceId: string;
 * imageURL: string;
 * navigate: function (string) : void;
 * regionId: string;
 * isMain?: boolean;
 * onChangeMain?: function(string, boolean) : void;
 * [x: string]: any;
 * onMainList?: boolean;
 * }} props
 */
export function Experience({
  isAgentVisible,
  isUserVisible,
  title,
  companyName,
  customServiceId,
  serviceId,
  imageURL,
  navigate,
  regionId,
  regionName = '',
  isMain,
  onChangeMain = identity,
  isDragging = false,
  onDragStart = identity,
  onDragEnd = identity,
  onUnbindService = identity,
  setExperiences = identity,
  onMainList = false,
  ...other
}) {
  const ref = useRef()
  const { add: addToast } = useToast()

  const [menuOpen, setMenuOpen] = useState(false)
  const [isDraggingDelayed, setDraggingDelayed] = useState(false)
  const [isVisibleForUser, setIsVisibleForUser] = useState(isUserVisible)
  const [isVisibleForAgent, setIsVisibleForAgent] = useState(isAgentVisible)

  const { allowed: canEdit } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  useEffect(() => {
    setIsVisibleForUser(isUserVisible)
    setIsVisibleForAgent(isAgentVisible)
  }, [isAgentVisible, isUserVisible])

  const closeMenu = useCallback(() => setMenuOpen(false), [])
  const handleMenu = useCallback(() => {
    const oldValue = menuOpen
    setMenuOpen(!oldValue)
    setTimeout(() => setMenuOpen(!oldValue), accidentalDragDelay)
  }, [menuOpen, setMenuOpen])

  const { onMouseEvent } = useActionOnFastClick(handleMenu)

  const handleDraggingChange = useCallback(() => {
    if (isDragging) {
      onDragStart(serviceId)
      setTimeout(() => {
        closeMenu()
        setDraggingDelayed(true)
      }, accidentalDragDelay)
    } else {
      onDragEnd(serviceId)
      setTimeout(() => setDraggingDelayed(false), accidentalDragDelay)
    }
  }, [closeMenu, isDragging, onDragEnd, onDragStart, serviceId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(handleDraggingChange, [isDragging])

  const handleConfig = useCallback(
    ev => {
      if (isDragging || isDraggingDelayed) return
      const shouldBail = ref?.current?.contains(ev?.target) ?? false
      if (shouldBail) {
        ev.preventDefault()
      }
    },
    [isDragging, isDraggingDelayed]
  )
  const handleMainToggleClick = useCallback(() => {
    setMenuOpen(false)
    onChangeMain(serviceId, !isMain)
  }, [serviceId, isMain, onChangeMain])

  const onRemoveActivity = useCallback(
    () => onUnbindService(),
    [onUnbindService]
  )

  const updateExperienceState = useCallback(
    ({ customServiceId, newParams }) => {
      setExperiences(prev => {
        const newExperiences = prev.map(experience => {
          if (experience.id === customServiceId) {
            return {
              ...experience,
              ...newParams
            }
          }
          return experience
        })
        return newExperiences
      })
    },
    [setExperiences]
  )

  const handleUserVisibilityChange = useCallback(
    visibility => {
      setIsVisibleForUser(visibility)
      updateExperienceState({
        customServiceId,
        newParams: { user_visible: visibility }
      })
      updateCustomRegionToCustomService({
        customRegionId: regionId,
        customServiceId,
        updateParams: {
          user_visible: visibility
        }
      })
        .then(() => {
          addToast(
            `Visibilidade do turista ${
              visibility ? 'ativada' : 'desativada'
            } com sucesso`,
            'success'
          )
        })
        .catch(() => {
          setIsVisibleForUser(!visibility)
          updateExperienceState({
            customServiceId,
            newParams: { user_visible: !visibility }
          })
          addToast('Não foi possível atualizar a visibilidade do usuário')
        })
    },
    [addToast, customServiceId, regionId, updateExperienceState]
  )

  const handleAgentVisibilityChange = useCallback(
    visibility => {
      setIsVisibleForAgent(!isVisibleForAgent)
      if (!visibility) setIsVisibleForUser(false)

      updateExperienceState({
        customServiceId,
        newParams: {
          agent_visible: visibility,
          ...(!visibility && { user_visible: false })
        }
      })

      updateCustomRegionToCustomService({
        customRegionId: regionId,
        customServiceId,
        updateParams: {
          agent_visible: visibility,
          ...(!visibility && { user_visible: false })
        }
      })
        .then(() => {
          addToast(
            `Visibilidade do agente ${
              visibility ? 'ativada' : 'e turista desativadas'
            } com sucesso`,
            'success'
          )
        })
        .catch(() => {
          updateExperienceState({
            customServiceId,
            newParams: {
              agent_visible: !visibility,
              ...(!visibility && { user_visible: true })
            }
          })
          setIsVisibleForAgent(!visibility)
          addToast('Não foi possível atualizar a visibilidade do agente')
        })
    },
    [
      addToast,
      customServiceId,
      isVisibleForAgent,
      regionId,
      updateExperienceState
    ]
  )

  const stopPropagation = useCallback(ev => ev.stopPropagation(), [])

  const { onMouseMove } = useScrollOnDrag({ enabled: isDragging })

  const toastTimeout = useRef(null)
  const handleMouseEvent = useCallback(
    event => {
      onMouseEvent(event)
      onMouseMove(event)
      if (isMain && !onMainList) {
        event.stopPropagation()
        if (['mousedown', 'touchstart'].includes(event.type)) {
          toastTimeout.current = setTimeout(
            () => addToast('Experiência já destacada', 'warning'),
            300
          )
        } else if (['mouseup', 'touchend'].includes(event.type)) {
          clearTimeout(toastTimeout.current)
        }
      }
    },
    [addToast, isMain, onMainList, onMouseEvent, onMouseMove]
  )

  return (
    <Card
      padding={20}
      customCss={[cardStyles]}
      {...other}
      onMouseDown={handleMouseEvent}
      onMouseUp={handleMouseEvent}
      onMouseMove={handleMouseEvent}
      onTouchStart={handleMouseEvent}
      onTouchEnd={handleMouseEvent}
      onTouchMove={handleMouseEvent}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              css={cardMenu}
              ref={ref}
              onClick={handleMenu}
              onMouseDown={stopPropagation}
              className='disable-fast-click'
            >
              <EllipsisIcon />
            </div>
          )}
        </Reference>
        {menuOpen && (
          <Portal>
            <Popover
              onMouseDown={stopPropagation}
              position='bottom-end'
              customCss={[menuPopover]}
            >
              <div onClick={closeMenu} css={invisibleCloseMenuTrigger} />
              <Link
                to={`/app/regions/${regionId}/${encodeURIComponent(
                  regionName
                )}/experiences/${serviceId}`}
                onClick={handleConfig}
                className='menu-option'
              >
                <CogIcon />
                <p>Configurar experiência</p>
              </Link>
              <div onClick={handleMainToggleClick} className='menu-option'>
                <StarIcon className={isMain && 'icon-stroke'} />
                <p>{isMain ? 'Desmarcar' : 'Marcar'} como destaque</p>
              </div>
              <div
                onClick={onRemoveActivity}
                className='menu-option remove-option'
              >
                <TrashIcon />
                <p>Remover experiência</p>
              </div>
            </Popover>
            <Backdrop css={transparentBackdrop} onClick={handleMenu} />
          </Portal>
        )}
      </Manager>
      <div css={imageWrapper}>
        {imageURL ? (
          <CircleImage
            customCss={[experienceImage]}
            src={imageURL ? `${imageURL}` : fallbackImageBase64}
            width={80}
            height={80}
          />
        ) : (
          <MountainIcon css={defaultImage} />
        )}
        {isMain && <StarFilledIcon css={filledStar} />}
      </div>
      <H4 customCss={[marginless, cardTitle]}>
        <span>{title}</span>
      </H4>
      <p css={smallText} className='company'>
        <span>Vendido por: {companyName}</span>
      </p>
      <span ref={ref}>
        <InlineToggleContainer className='disable-fast-click'>
          <InlineToggle
            id={`agent-visibility-for-${customServiceId}`}
            disabled={!canEdit}
            onChange={() => handleAgentVisibilityChange(!isVisibleForAgent)}
            isEnabled={isVisibleForAgent}
            label='Visível para agente'
          />
          <InlineToggle
            id={`user-visibility-for-${customServiceId}`}
            disabled={!canEdit || !isVisibleForAgent}
            onChange={() => handleUserVisibilityChange(!isVisibleForUser)}
            isEnabled={isVisibleForUser}
            label='Visível para turista'
          />
        </InlineToggleContainer>
      </span>
    </Card>
  )
}

const fallbackImageBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO89x8AAsEB3+IGkhwAAAAASUVORK5CYII='
