/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Manager, Reference, Popper } from 'react-popper'
import { useCallback, useState } from 'react'
import {
  AngleDownIcon,
  Button,
  CheckboxInputGroup,
  DatePicker,
  FilterIcon,
  GhostButton,
  H2,
  Label,
  Row,
  useToast,
  LoadingAnimation
} from '@bonitour/components'
import {
  dropdownLoading,
  FilterDrawerContainer,
  iconStyle,
  selectArrowIcon
} from './AbandonedCartsFilter.style'
import { InfiniteScroll } from '../CustomRegionsExperiences/Components/InfiniteScroll'

/**
 * @typedef DataProps
 * @prop { Record<string, string>[] } [abandoned_carts]
 */

/**
 * @typedef AbandonedCartsFilterProps
 * @prop { (e) => void} [onFilter]
 * @prop { {id: string, name: string }[] } [regions]
 * @prop { boolean } [isLoading]
 * @prop { () => void } [nextPage]
 * @prop { boolean } [hasFilters]
 */

/** @type { React.FC<AbandonedCartsFilterProps> } */

export const AbandonedCartsFilter = ({
  onFilter,
  regions,
  isLoading,
  nextPage,
  hasFilters = false
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { add: addToast } = useToast()

  const toggleDropdown = useCallback(
    () => setDropdownOpen(!dropdownOpen),
    [dropdownOpen]
  )

  const [filters, setFilters] = useState({
    travel_initial_date: '',
    travel_final_date: '',
    created_initial_date: '',
    created_final_date: '',
    region_ids: []
  })

  const onResetFilters = useCallback(() => {
    setFilters({
      travel_initial_date: '',
      travel_final_date: '',
      created_initial_date: '',
      created_final_date: '',
      region_ids: []
    })
    onFilter({
      travel_initial_date: '',
      travel_final_date: '',
      created_initial_date: '',
      created_final_date: '',
      region_ids: []
    })
  }, [onFilter])

  const filterTravelDate = useCallback(
    (isInitialDate, date) => {
      if (
        isInitialDate
          ? date > filters.travel_final_date && filters.travel_final_date
          : date < filters.travel_initial_date && filters.travel_initial_date
      ) {
        return addToast(
          `A data ${isInitialDate ? 'inicial' : 'final'} não pode ser ${
            isInitialDate ? 'maior' : 'menor'
          }
        que a data ${isInitialDate ? 'final' : 'inicial'}.`,
          'danger'
        )
      }
      setFilters(currFilters => {
        return {
          ...currFilters,
          [isInitialDate ? 'travel_initial_date' : 'travel_final_date']: date
        }
      })
    },
    [addToast, filters.travel_final_date, filters.travel_initial_date]
  )

  const filterCreatedAtDate = useCallback(
    (isInitialDate, date) => {
      if (
        isInitialDate
          ? date > filters.created_final_date && filters.created_final_date
          : date < filters.created_initial_date && filters.created_initial_date
      ) {
        return addToast(
          `A data ${isInitialDate ? 'inicial' : 'final'} não pode ser ${
            isInitialDate ? 'maior' : 'menor'
          }
        que a data ${isInitialDate ? 'final' : 'inicial'}.`,
          'danger'
        )
      } else if (date > new Date()) {
        return addToast(
          `A data ${
            isInitialDate ? 'inicial' : 'final'
          } não pode ser maior que a data de hoje.`,
          'danger'
        )
      }
      setFilters(currFilters => {
        return {
          ...currFilters,
          [isInitialDate ? 'created_initial_date' : 'created_final_date']: date
        }
      })
    },
    [addToast, filters.created_final_date, filters.created_initial_date]
  )

  const filterRegion = useCallback(
    region => {
      const checked = filters.region_ids.includes(region?.id)
      setFilters(currFilters => {
        return {
          ...currFilters,
          region_ids: checked
            ? filters?.region_ids.filter(regionId => region?.id !== regionId)
            : [...filters?.region_ids, ...[region?.id]]
        }
      })
    },
    [filters]
  )

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <FilterIcon
            onClick={() => setIsOpen(v => !v)}
            css={iconStyle({ hasFilters, isOpen })}
            ref={ref}
          />
        )}
      </Reference>
      <Popper placement='auto'>
        {({ ref, placement }) => (
          <div style={{ position: 'relative', height: '0' }}>
            <div style={{ position: 'relative', top: '45px', left: '45px' }}>
              {isOpen && (
                <>
                  <FilterDrawerContainer ref={ref} data-placement={placement}>
                    <H2>Filtros</H2>
                    <Label>Data de criação do roteiro</Label>
                    <Row>
                      <span className='datas'>
                        <Label>Data inicial</Label>
                        <DatePicker
                          placeholder='__/__/____'
                          allowsEmpty
                          onChange={(v, isInitialDate = true) =>
                            filterCreatedAtDate(isInitialDate, v)
                          }
                          value={filters.created_initial_date}
                        />
                      </span>
                      <span className='datas'>
                        <Label>Data final</Label>
                        <DatePicker
                          placeholder='__/__/____'
                          allowsEmpty
                          onChange={(v, isInitialDate = false) =>
                            filterCreatedAtDate(isInitialDate, v)
                          }
                          value={filters.created_final_date}
                        />
                      </span>
                    </Row>
                    <Label>Data período:</Label>
                    <Row>
                      <span className='datas'>
                        <Label>Data inicial</Label>
                        <DatePicker
                          placeholder='__/__/____'
                          allowsEmpty
                          onChange={(v, isInitialDate = true) =>
                            filterTravelDate(isInitialDate, v)
                          }
                          value={filters.travel_initial_date}
                        />
                      </span>
                      <span className='datas'>
                        <Label>Data final</Label>
                        <DatePicker
                          placeholder='__/__/____'
                          allowsEmpty
                          onChange={(v, isInitialDate = false) =>
                            filterTravelDate(isInitialDate, v)
                          }
                          value={filters.travel_final_date}
                        />
                      </span>
                    </Row>
                    <Label>Região turística</Label>
                    <Row>
                      <div className='contentRegion'>
                        <div className='inputRegion' onClick={toggleDropdown}>
                          Selecione as regiões turísticas
                          <AngleDownIcon css={selectArrowIcon} />
                        </div>
                        {dropdownOpen ? (
                          <div className='regionsOptions'>
                            {regions.map(region => {
                              return (
                                <CheckboxInputGroup
                                  key={region?.id}
                                  onChange={() => {
                                    filterRegion(region)
                                  }}
                                  checked={filters.region_ids.includes(
                                    region?.id
                                  )}
                                  disabled={false}
                                  id={region}
                                >
                                  {region?.name}
                                </CheckboxInputGroup>
                              )
                            })}
                            {!isLoading && !regions.length && (
                              <h6>Nenhuma região encontrada!</h6>
                            )}
                            {isLoading && (
                              <LoadingAnimation css={dropdownLoading} />
                            )}
                            <InfiniteScroll
                              loading={isLoading}
                              nextPage={nextPage}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className='buttonsContainer'>
                      <GhostButton onClick={onResetFilters}>Limpar</GhostButton>
                      <Button
                        type='submit'
                        onClick={() => {
                          setIsOpen(false)
                          onFilter(filters)
                        }}
                      >
                        Filtrar
                      </Button>
                    </Row>
                  </FilterDrawerContainer>
                </>
              )}
            </div>
          </div>
        )}
      </Popper>
    </Manager>
  )
}
