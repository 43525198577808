import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const cardButton = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  align-items: center;
  text-align: center;

  max-width: 100px;

  padding: 20px 30px;
  margin-top: 10px;
  box-shadow: 2px 2px 15px 0px #0000001a;

  cursor: pointer;
`

export const cardIcon = css`
  width: 22px;
  height: 22px;
  padding: 5px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: var(--round-element-radius, 100vw);
`

export const labelCardButton = css`
  width: 100%;
  font-weight: bold;
  color: ${colors.gray3};
  font-size: 14px;

  cursor: pointer;
`
