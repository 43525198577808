/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { CircleImage, MountainIcon, CloseIcon } from '@bonitour/components'

import { jsx } from '@emotion/react'
import { AnimatePresence } from 'framer-motion'

import {
  ServiceCard,
  ServiceCardsContainer,
  defaultImage,
  LabelsContent
} from './ServiceCards.styles'

/**
 * @type {React.FC<{
 *   services: {
 *        id: string
 *        title?: { 'pt-br': string }
 *        image?: { file: string, description?: { 'pt-br': string, 'en-us'?: string, 'es-es'?: string } }
 *        visible?: boolean
 *        main_position?: boolean
 *        company: { id: string, name: string }
 *    }[]
 *   isLoading?: boolean
 *   onRemove?: (experienceId?: string ) => void
 * }>}
 */
export const ServiceCards = ({ services, onRemove }) => {
  return (
    <ServiceCardsContainer layout>
      <AnimatePresence>
        {services.map(service => (
          <ServiceCard
            key={service.id}
            layout
            {...{
              initial: {
                scale: 0,
                maxHeight: 0,
                opacity: 0,
                padding: 0,
                position: 'relative',
                marginBottom: 0
              },
              animate: {
                scale: 1,
                maxHeight: '65px',
                opacity: 1,
                padding: '15px',
                marginBottom: '10px'
              },
              exit: {
                scale: 0,
                maxHeight: 0,
                opacity: 0,
                padding: 0,
                marginBottom: 0
              },
              transition: {
                default: { ease: 'easeInOut' }
              }
            }}
          >
            <button type='button' onClick={() => onRemove(service?.id)}>
              <CloseIcon />
            </button>

            {service?.image ? (
              <CircleImage src={service?.image?.file} width={40} height={40} />
            ) : (
              <MountainIcon css={defaultImage} />
            )}

            <LabelsContent>
              <span>{service?.title['pt-br']}</span>
              <span>{service?.company?.name}</span>
            </LabelsContent>
          </ServiceCard>
        ))}
      </AnimatePresence>
    </ServiceCardsContainer>
  )
}
