/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import React, { useMemo, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import { useForm } from '@bonitour/app-functions'
import {
  ColorPicker,
  Button,
  Card,
  Row,
  H3,
  Label,
  Upload,
  ErrorTooltip,
  GhostPrimaryButton,
  Input,
  MaskedInput,
  useToast,
  InputFormGroup,
  GhostButton,
  ConfirmDialog,
  InputPhoneMask,
  Email,
  ContactForm
} from '@bonitour/components'

import {
  formStyle,
  label,
  colorPicker,
  uploadRowStyle,
  idealWeightStyle,
  idealSizeStyle,
  previewWrapper,
  rowButtons,
  saveButton,
  resetFile,
  disabled,
  footerRowCss,
  divInput,
  titleHeight
} from '../Appearance.style'

import blogIcon from 'Shared/svgs/blogIcon.svg'
import tiktokIcon from 'Shared/svgs/tiktokIcon.svg'
import facebookIcon from 'Shared/svgs/facebookIcon.svg'
import instagramIcon from 'Shared/svgs/instagramIcon.svg'
import linkedinIcon from 'Shared/svgs/linkedinIcon.svg'
import youtubeIcon from 'Shared/svgs/youtubeIcon.svg'
import whatsappIcon from 'Shared/svgs/whatsappIcon.svg'
import XpertLogo from 'Shared/svgs/logoXpertWhite.svg'
import XpertFavicon from 'Shared/svgs/faviconBinamik.png'
import flag from 'Shared/img/brazilian-flag.png'

import { InfoTooltip } from '../../InfoTooltip'
import { patchSiteConfig, postSiteConfig } from '../Appearance.io'

import Preview from './Preview'
import { BranchSchema } from '../forms/schemas'
import { formatSocial, formatTiktok } from '../forms/formatters'
import { ContentCard } from './ContentCard'
import { ExternalLink } from './ExternalLink'
import { usePermission } from 'Shared/contexts/Permissions'

const { REACT_APP_HOST_ORB_URI: ORB_URI } = process.env

export function SiteConfigForm({ config, setConfig, companyId }) {
  const { add: addToast } = useToast()
  const [fileLogo, setLogo] = React.useState([])
  const [fileFavicon, setFavicon] = React.useState([])
  const [resetLogo, setResetLogo] = React.useState(false)
  const [resetFavicon, setResetFavicon] = React.useState(false)
  const [fileLogoError, setLogoError] = React.useState(null)
  const [fileFaviconError, setFaviconError] = React.useState(null)
  const [domainError, setDomainError] = React.useState(null)
  const [confirmationIsVisible, setConfirmationVisible] = React.useState(false)

  const { allowed: canSave } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const savedLogo = useMemo(
    () =>
      !resetLogo && config?.logo
        ? `${config?.logo?.startsWith('http') ? '' : `${ORB_URI}/`}${
            config?.logo
          }`
        : XpertLogo,
    [config, resetLogo]
  )
  const savedFavicon = useMemo(
    () =>
      !resetFavicon && config?.favicons?.['16x16']
        ? `${
            config?.favicons?.['16x16']?.startsWith('http') ? '' : `${ORB_URI}/`
          }${config?.favicons?.['16x16']}`
        : XpertFavicon,
    [config, resetFavicon]
  )

  const initialFormData = useMemo(
    () => ({
      color1: config.colors?.primary || '#8080f0',
      color2: config.colors?.secondary || '#1ED6BB',
      title: config.title || '',
      url: config.url || '',
      facebook: config?.facebook || '',
      instagram: config?.instagram || '',
      linkedin: config?.linkedin || '',
      youtube: config?.youtube || '',
      whatsapp: config.whatsapp || '',
      tiktok: config.tiktok || '',
      blog: config.blog || '',
      phones: config?.phones?.map(number => ({ number })) || [],
      email: config?.email || '',
      homepage: config?.homepage || ''
    }),
    [config]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray }
  } = useForm(initialFormData, BranchSchema)

  const onFormError = useCallback(
    err => !err?.phones && window.scrollTo({ top: 550, behavior: 'smooth' }),
    []
  )

  const allowPreview = useMemo(() => config?.url, [config])

  const unauthorizedToast = useCallback(
    () =>
      addToast('Usuário sem permissão de escrita/atualização do Backoffice'),
    [addToast]
  )

  const save = () => {
    if (!canSave) {
      return unauthorizedToast()
    }
    const phoneNumbers = form.phones
      ?.map(entry => entry?.number)
      .filter(number => number && number !== '(00) 0000-0000')
    const whatsapp = form.whatsapp === '(00) 0000-0000' ? '' : form.whatsapp

    const isPatch = Boolean(config?.url)
    const payload = {
      ...(Boolean(form.url) && {
        url: `https://${form.url.replace(
          /(https)|\s|[^A-z0-9.-]|:|^[-]*|(\/\/)/g,
          ''
        )}`
      }),
      title: form.title.trim(),
      colors: JSON.stringify({
        primary: form.color1,
        secondary: form.color2
      }),
      ...(fileLogo[0]?.file
        ? { logo: fileLogo[0]?.file }
        : resetLogo && { logo: '' }),
      ...(fileFavicon[0]?.file
        ? { favicon: fileFavicon[0]?.file }
        : resetFavicon && { favicon: '' }),
      ...((form.facebook || isPatch) && { facebook: form.facebook }),
      ...((form.instagram || isPatch) && { instagram: form.instagram }),
      ...((form.linkedin || isPatch) && { linkedin: form.linkedin }),
      ...((form.youtube || isPatch) && { youtube: form.youtube }),
      ...((whatsapp || isPatch) && { whatsapp }),
      ...((form.tiktok || isPatch) && { tiktok: form.tiktok }),
      ...((form.blog || isPatch) && { blog: form.blog }),
      ...((form.email || isPatch) && { email: form.email }),
      ...((phoneNumbers?.length || isPatch) && {
        phones: JSON.stringify(phoneNumbers)
      }),
      ...((form.homepage || isPatch) && { homepage: form.homepage })
    }

    setConfirmationVisible(false)
    ;(isPatch ? patchSiteConfig : postSiteConfig)(payload, companyId)
      .then(config => {
        addToast('Configurações salvas com sucesso', 'success')
        setConfig(config)
      })
      .catch(e => {
        const apiError = e?.response?.data?.error
        if (
          apiError?.errors_msg?.some(
            msg =>
              msg.includes('url::taken') || msg.includes('url::already_exists')
          )
        ) {
          setDomainError('Url já cadastrado')
          onFormError()
        } else if (
          apiError?.errors_msg?.some(msg => msg.includes('url::invalid'))
        ) {
          setDomainError('Url inválido')
          onFormError()
        }
        console.error(e)
        addToast('Erro ao salvar configurações')
      })
  }

  const cancel = useCallback(() => setConfirmationVisible(false), [])

  const stopPropagation = callback => e => {
    e.stopPropagation()
    callback(e)
  }

  const logo = () => (fileLogo[0] && fileLogo[0].src) || savedLogo
  const favicon = () => (fileFavicon[0] && fileFavicon[0].src) || savedFavicon

  return (
    <>
      <ContentCard title='Configurações da marca'>
        <Row>
          <div css={formStyle}>
            <Row>
              <InputFormGroup
                css={label}
                label='Cor primária'
                errorMessage={errors.color1}
              >
                <ColorPicker
                  id='color1'
                  value={form.color1}
                  onChange={onInputChange('color1')}
                  onBlur={onInputBlur('color1')}
                  css={colorPicker}
                />
              </InputFormGroup>
            </Row>
            <Row>
              <InputFormGroup
                css={label}
                label='Cor secundária'
                errorMessage={errors.color1}
              >
                <ColorPicker
                  id='color2'
                  value={form.color2}
                  onChange={onInputChange('color2')}
                  onBlur={onInputBlur('color2')}
                  css={colorPicker}
                />
              </InputFormGroup>
            </Row>
            <Row css={uploadRowStyle}>
              {fileLogoError && <ErrorTooltip>{fileLogoError}</ErrorTooltip>}
              <Label css={label}>Logo</Label>
              <Upload
                files={fileLogo}
                onChange={setLogo}
                onError={setLogoError}
                limitMbSize={2.5}
                accept='image/png, image/jpeg'
                emptyMessage={
                  <div>
                    <p>Arraste a imagem ou clique para adicionar</p>
                    {config.logo &&
                      (resetLogo ? (
                        <Button
                          customCss={[resetFile]}
                          onClick={stopPropagation(() => setResetLogo(false))}
                        >
                          Restaurar padrão
                        </Button>
                      ) : (
                        <GhostButton
                          customCss={[resetFile]}
                          onClick={stopPropagation(() => setResetLogo(true))}
                        >
                          Restaurar padrão
                        </GhostButton>
                      ))}
                  </div>
                }
              />
              <span css={idealWeightStyle}>Peso: até 2.5MB</span>
              <span css={idealSizeStyle}>Tamanho ideal: 200px X 85px</span>
            </Row>
            <Row css={uploadRowStyle}>
              {fileFaviconError && (
                <ErrorTooltip>{fileFaviconError}</ErrorTooltip>
              )}
              <Label css={label}>
                Favicon
                <InfoTooltip>
                  Imagem que aparecerá na aba do seu Xpert
                </InfoTooltip>
              </Label>
              <Upload
                files={fileFavicon}
                onChange={setFavicon}
                onError={setFaviconError}
                limitMbSize={2.5}
                accept='image/png, image/jpeg'
                emptyMessage={
                  <div>
                    <p>Arraste a imagem ou clique para adicionar</p>
                    {config.favicons?.['16x16'] &&
                      (resetFavicon ? (
                        <Button
                          customCss={[resetFile]}
                          onClick={stopPropagation(() =>
                            setResetFavicon(false)
                          )}
                        >
                          Restaurar padrão
                        </Button>
                      ) : (
                        <GhostButton
                          customCss={[resetFile]}
                          onClick={stopPropagation(() => setResetFavicon(true))}
                        >
                          Restaurar padrão
                        </GhostButton>
                      ))}
                  </div>
                }
              />
              <span css={idealWeightStyle}>Peso: até 2.5MB</span>
              <span css={idealSizeStyle}>Tamanho ideal: 512px X 512px</span>
            </Row>
            <Row>
              <InputFormGroup
                css={label}
                label='Título da aba'
                errorMessage={errors.title}
              >
                <Input
                  id='title'
                  value={form.title}
                  error={errors.title}
                  onChange={onInputChange('title')}
                  onBlur={onInputBlur('title')}
                  placeholder='Exemplo Viagens'
                />
              </InputFormGroup>
            </Row>
            <Row>
              <InputFormGroup
                errorMessage={domainError || errors.url}
                label={
                  <>
                    <span>URL</span>
                    <ExternalLink
                      href='https://ajuda.binamik.com.br/binamik-xpert/cadastro-subdominio-xpert/'
                      title='Endereço utilizado pelos viajantes para localizar seu Xpert. Saiba mais sobre URL em nossa '
                      linkText='Central de Ajuda'
                      customCss={[titleHeight]}
                    />
                  </>
                }
              >
                <Row>
                  <MaskedInput
                    id='url'
                    formatterFunction={v =>
                      v
                        ?.replace(/(https)|\s|[^A-z0-9.-]|:|^[-]*|(\/\/)/g, '')
                        .toLowerCase()
                    }
                    maxLength={50}
                    style={{ width: '100%' }}
                    value={form.url}
                    error={errors.url}
                    onChange={val => {
                      setDomainError(null)
                      onInputChange('url')(val)
                    }}
                    onBlur={onInputBlur('url')}
                    placeholder='URL'
                  />
                </Row>
              </InputFormGroup>
            </Row>
          </div>
          <div css={previewWrapper}>
            <Preview
              title={form.title}
              favicon={favicon()}
              logo={logo()}
              primaryColor={form.color1}
              secondaryColor={form.color2}
            />
          </div>
        </Row>
        <Card>
          <H3>Configurações do rodapé</H3>
          <InputFormGroup label='Redes sociais'>
            <div css={footerRowCss}>
              <div css={divInput}>
                <img src={facebookIcon} className='icon' alt='Facebook Icon' />
                <span>https://facebook.com/</span>
                <MaskedInput
                  formatterFunction={formatSocial('facebook')}
                  className='inputSocialNetworks'
                  placeholder='seufacebook'
                  value={form.facebook}
                  onChange={onInputChange('facebook')}
                />
              </div>
              <div css={divInput}>
                <img
                  src={instagramIcon}
                  className='icon'
                  alt='Instagram Icon'
                />
                <span>https://instagram.com/</span>
                <MaskedInput
                  formatterFunction={formatSocial('instagram')}
                  className='inputSocialNetworks'
                  placeholder='seuinstagram'
                  value={form.instagram}
                  onChange={onInputChange('instagram')}
                />
              </div>
              <div css={divInput}>
                <img src={linkedinIcon} className='icon' alt='Linkedin Icon' />
                <span>https://linkedin.com/</span>
                <MaskedInput
                  formatterFunction={formatSocial('linkedin')}
                  className='inputSocialNetworks'
                  placeholder='in/usuario ou company/empresa'
                  value={form.linkedin}
                  onChange={onInputChange('linkedin')}
                />
              </div>
              <div css={divInput}>
                <img src={youtubeIcon} className='icon' alt='Youtube Icon' />
                <span>https://youtube.com/</span>
                <MaskedInput
                  formatterFunction={formatSocial('youtube')}
                  className='inputSocialNetworks'
                  placeholder='user/usuario ou channel/codigo'
                  value={form.youtube}
                  onChange={onInputChange('youtube')}
                />
              </div>
              <div css={divInput}>
                <img src={whatsappIcon} className='icon' alt='WhatsApp Icon' />
                <span />
                <InputPhoneMask
                  className='inputSocialNetworks'
                  placeholder='WhatsApp'
                  value={form.whatsapp}
                  onChange={onInputChange('whatsapp')}
                />
              </div>
              <div css={divInput}>
                <img src={tiktokIcon} className='icon' alt='Tiktok Icon' />
                <span>https://tiktok.com/</span>
                <MaskedInput
                  formatterFunction={formatTiktok}
                  className='inputSocialNetworks'
                  placeholder='@seutiktok'
                  value={form.tiktok}
                  onChange={onInputChange('tiktok')}
                />
              </div>
              <div css={divInput}>
                <img src={blogIcon} className='icon' alt='Blog Icon' />
                <span>https://</span>
                <MaskedInput
                  formatterFunction={formatSocial('blog')}
                  className='inputSocialNetworks'
                  placeholder='seublog'
                  value={form.blog}
                  onChange={onInputChange('blog')}
                />
              </div>
            </div>
          </InputFormGroup>
          <InputFormGroup label='Telefones'>
            <ContactForm
              phones={form.phones}
              phonesErrors={errors.phones}
              onBlur={onInputBlur}
              onChange={onInputChange}
              onPhoneAdd={addItemOnArray('phones')}
              onPhoneRemove={removeItemArray('phones')}
              maxPhones={2}
              flag={flag}
            />
          </InputFormGroup>
          <Row>
            <InputFormGroup label='Email para contato'>
              <Email
                placeholder='exemplo@binamik.com.br'
                style={{ width: '40%' }}
                value={form.email}
                onChange={onInputChange('email')}
              />
            </InputFormGroup>
          </Row>

          <Row>
            <InputFormGroup label='Homepage'>
              <MaskedInput
                value={form.homepage}
                onChange={onInputChange('homepage')}
                placeholder='https://seusite.com.br'
                style={{ width: '40%' }}
              />
            </InputFormGroup>
          </Row>
        </Card>
      </ContentCard>
      <Row css={rowButtons}>
        <a
          href={(allowPreview && config?.url) || '#'}
          target='_blank'
          css={css`
            text-decoration: none;
          `}
          rel='noopener noreferrer'
        >
          <GhostPrimaryButton
            disabled={!allowPreview}
            css={[!allowPreview && disabled]}
          >
            Visualizar
          </GhostPrimaryButton>
        </a>
        <Button
          customCss={[saveButton]}
          onClick={onSubmit(
            canSave ? setConfirmationVisible : unauthorizedToast,
            onFormError
          )}
        >
          Salvar
        </Button>
      </Row>
      {config.url !== form.url ? (
        <ConfirmDialog
          title='Confirmar configuração?'
          message='Lembre-se de realizar as alterações necessárias no seu gerenciamento de DNS antes de mudar a url. Ao confirmar o seu Xpert (e-commerce) será alterado para as configurações informadas em até 1 dia.'
          isVisible={confirmationIsVisible}
          successCallback={save}
          cancelCallback={cancel}
        />
      ) : (
        <ConfirmDialog
          title='Confirmar configuração?'
          message='Ao confirmar o seu Xpert (e-commerce) será alterado para as configurações informadas em até 15 minutos.'
          isVisible={confirmationIsVisible}
          successCallback={save}
          cancelCallback={cancel}
        />
      )}
    </>
  )
}
