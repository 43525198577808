import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { classContains } from 'Shared/utils/utils.styles'

export const headingRow = css`
  justify-content: space-between;
  align-items: baseline;
  margin-top: 15px;
`

export const experiencesRow = css`
  gap: 15px;
`

export const experienceImage = css`
  pointer-events: none;
`

export const imageWrapper = css`
  position: relative;
`

export const cardStyles = css`
  width: 225px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 0;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  .company {
    height: 46px;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  cursor: grab;
`

export const cardMenu = css`
  position: absolute;
  top: 20px;
  right: 15px;
  color: ${colors.gray5};
  font-size: 21px;
  padding: 0 5px;
  cursor: pointer;
`

export const cardTitle = css`
  height: 60px;
  font-size: 18px;
  color: ${colors.gray3};
  display: flex;
  align-items: center;
  max-width: 100%;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 2px 0;
    word-wrap: break-word;
  }
`

export const marginless = css`
  margin: 0;
`

export const smallText = css`
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.gray5};
  font-weight: 600;
`

export const regionPopover = css`
  opacity: 1;
  width: 266px;
  padding: 20px;
  box-sizing: border-box;
  margin-right: -8px;
  ${classContains('bottomArrow-Popover')} {
    right: 17px;
  }
  .regions-list {
    max-height: 270px;
    overflow-y: auto;
    padding: 5px 0;
  }
  h6 {
    color: ${colors.gray5};
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 10px;
  }
`

export const hidden = css`
  opacity: 0;
  pointer-events: none;
`

export const menuPopover = css`
  min-width: 210px;
  opacity: 1;
  padding: 10px 20px;
  color: ${colors.gray3};
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  text-align: left;
  margin-right: -15px;
  margin-bottom: 10px;
  transform: translateX(-25px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colors.white};
  z-index: 6;
  transform: translate(0, 0);
  ${classContains('bottomArrow-Popover')} {
    right: 17px;
  }
  ${classContains('-IconWrapper')} {
    font-size: 28px;
    margin-right: 10px;
    position: relative;
    &.icon-stroke::after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 30px;
      background-color: ${colors.gray3};
      border: 1px solid ${colors.white};
      border-width: 1px 0;
      transform: rotate(40deg);
    }
  }
  .menu-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 4px 0;
    text-decoration: none;
    color: inherit;
  }

  .remove-option {
    color: ${colors.red2};
  }
`

export const dropdown = css`
  position: relative;
  display: inline-block;
  color: ${colors.primary};
  padding-right: 25px;
  cursor: pointer;
  &:after {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border: 2px solid ${colors.primary};
    border-top: none;
    border-left: none;
    transform: rotate(45deg) translate(5px, -10px);
  }
`

export const regionSelector = css`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.gray3};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  font-weight: 700;
  padding: 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 3px;
  .region-circle {
    margin: 0 8px 0 0;
  }
  &:hover {
    background-color: ${colors.gray12};
  }
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`

export const transparentBackdrop = css`
  z-index: 5;
  opacity: 0;
  transform: translate(0, 0);
`

export const dropdownLoading = css`
  margin: 10px auto;
`

export const pageCard = css`
  position: relative;
  padding: 30px 20px;
  background-color: ${colors.gray15};
`

export const sectionTitle = css`
  font-size: 18px;
  line-height: 20px;
  color: ${colors.gray3};
`

export const sectionTitleRow = css`
  display: flex;
  align-items: baseline;
  > *:not(:last-child) {
    margin-right: 15px;
  }
  > span {
    display: inline-flex;
    i {
      margin-left: 5px;
      margin-bottom: 20px;
    }
  }
`

export const pointer = css`
  cursor: pointer;
`

export const GAP = 16
export const CARD_HEIGHT = 290

export const experiencesGridStyle = (
  childrenNumber = 0,
  columnsNumber = 5
) => css`
  height: ${Math.ceil(childrenNumber / columnsNumber) * (CARD_HEIGHT + GAP)}px;
  position: relative;
  display: grid;
  gap: 16px 0;
  grid-template-columns: repeat(${columnsNumber}, 1fr);
  grid-template-rows: repeat(
    ${Math.ceil(childrenNumber / columnsNumber)},
    ${CARD_HEIGHT}px
  );
  user-select: none;
  transition: height 0.2s ease-in-out;

  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;

    &${classContains('--cloned-')} {
      opacity: 0.5 !important;
      pointer-events: none;
    }
  }

  .card-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${CARD_HEIGHT + GAP}px;
    box-sizing: border-box;

    padding: ${GAP + 4}px;
  }

  .card-position-indicatior {
    box-sizing: border-box;
    max-width: 220px;
    height: 240px;
    border: 3px dashed ${colors.gray6};
    background-color: ${colors.gray8};
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .plus-icon {
      font-size: 26px;
      background-color: ${colors.gray6};
      color: ${colors.white};
      height: 60px;
      width: 60px;
      border-radius: var(--round-element-radius, 50%);
      margin-top: 39px;
    }
    .hover-text {
      opacity: 0;
      color: ${colors.gray3};
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      padding: 11px;
    }
    &:hover .hover-text {
      opacity: 1;
    }
  }

  ${classContains('-dropzone')} {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    pointer-events: none;
    & > * {
      pointer-events: auto;
    }
  }

  &.collapsed {
    height: 0;
    overflow: hidden;
  }
`

export const invisibleCloseMenuTrigger = css`
  position: absolute;
  top: -52px;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;

  .trash__icon {
    font-size: 20px;
  }
`

export const filledStar = css`
  font-size: 30px;
  line-height: 28px;
  color: ${colors.yellow1};
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: ${colors.gray12};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const defaultImage = css`
  font-size: 40px;
  border-radius: var(--round-element-radius, 50%);
  height: 82px;
  width: 80px;
  color: ${colors.primary};
  background-color: ${colors.gray11};
`

export const idealWeightStyle = css`
  color: ${colors.gray5};
  font: normal normal 600 10px/18px Mulish;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TabsContainer = styled.div`
  position: relative;

  ${classContains('-underlineNavContainer')} {
    padding: 0;
    padding-right: 30px;
    margin-bottom: 20px;
    min-height: 30px;
    border-bottom: 2px solid ${colors.gray7};
    overflow: visible;
  }
  ${classContains('-navItem-')} {
    margin-left: 0;
    margin-bottom: -2px;
    padding: 10px 5px;
    z-index: 1;
  }
`

export const TabsOptions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 450px) {
    top: -1.5rem;
    bottom: 1.5rem;
  }
`

export const headerRow = css`
  display: flex;
  align-items: end;
  gap: 5px;

  h1 {
    width: auto;
    p {
      font-size: 1.25rem;
    }
  }

  .cog-icon {
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    color: ${colors.gray3};
    -webkit-text-stroke-width: 0.4px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${colors.gray1};
    }
  }
`
