import { SelectServices } from '../CustomRegionsExperiences/Components/SelectServices'

const React = require('react')

const values = [
  {
    id: '3281cf70-5087-46eb-aa5d-322e773208c0',
    title: {
      'pt-br': 'Rio da prata Arthur'
    },
    company: {
      id: '9c65e2bd-4d38-4b9b-9246-894d54f26273',
      name: 'Arthur Travel Co.'
    }
  },
  {
    id: '765c2800-d7cc-44bc-b9e7-2be8c12c9029',
    title: {
      'pt-br': 'Teste Aqui'
    },
    company: {
      id: '9c65e2bd-4d38-4b9b-9246-894d54f26273',
      name: 'Arthur Travel Co.'
    }
  },
  {
    id: 'f897aabc-9031-44f7-a41c-9c1b1f19d22b',
    title: {
      'pt-br': 'Teste  Sem Imagem'
    },
    company: {
      id: '9c65e2bd-4d38-4b9b-9246-894d54f26273',
      name: 'Arthur Travel Co.'
    }
  },
  {
    id: '76f9275b-c42e-4194-946a-e4ff10a2d72c',
    title: {
      'pt-br': 'FIltro Travel Co.'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxsWTJRd05qbGlaaTAzWkRVekxUUXpaR1F0WVdReVlpMHlNek0yWm1Wa09HRTFORGdHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--9485e6834d89c02a7b9f225353a97f1f8ddd0e50/4.jpg'
    },
    company: {
      id: '9c65e2bd-4d38-4b9b-9246-894d54f26273',
      name: 'Arthur Travel Co.'
    }
  },
  {
    id: '9f57d295-1a67-49ce-a442-0f4c7e276b36',
    title: {
      'pt-br':
        'Buraco das araras arthur Buraco das araras arthur Buraco das araras arthur Buraco das araras arthur Buraco das araras arthur'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxoTldNek9EY3paUzAyTjJRNUxUUTVZMkl0T0RjMk55MW1aamcxWkRjeFptUmxZVGdHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--0fd1d8165997615d85e05945c7a7fda6fa0520f6/download.jpeg'
    },
    visible: true,
    position: 'yu',
    main_position: true,
    company: {
      id: '9c65e2bd-4d38-4b9b-9246-894d54f26273',
      name: 'Arthur Travel Co.'
    }
  },
  {
    id: 'cc98fbf0-d6cf-4ed2-9fde-b39ddefe72c7',
    title: {
      'pt-br': 'Bagui doido teste'
    },
    visible: false,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: 'd499f955-1dcf-48ed-975b-1334eaf921cf',
    title: {
      'pt-br': 'Testando Sem o Custom Service'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWt5Wkdaa01qZzRaQzB4WkRJeExUUmxaV1F0T1dFMU5pMDNNVFV5Tmpjek1EaG1NalFHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--29b257b45cb98277b3dd09df744c1951c4317c6b/PONTUACAO.png'
    },
    visible: false,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: 'a5341d14-9d3f-4233-bc11-db611a963f7b',
    title: {
      'pt-br': 'Testar Custom Service'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxoTTJRNE1UTXlNaTB5TjJGaUxUUmpOVFl0WVdGaVlpMW1ZVE5tTXpVMk4yWTROemdHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--9d97b5062e5bba209dcef901bccf4c5c768ec27c/Screen%20Shot%202022-01-26%20at%209.28.53%20AM.png'
    },
    visible: false,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: 'c7c3ec6e-2d70-472e-baf4-be66c6012b3d',
    title: {
      'pt-br': 'Transporte Bonito - Campo Grande'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWswT1dRNU5tSTNNQzB3WVdRMkxUUmhaRGN0WVRWbU55MHdZelExTldKak5UVXhPR0lHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--22e4b53258691a9dfc2cd76057a1caaf16e6a9da/Screen%20Shot%202022-03-01%20at%2013.53.18.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: 'eae8a6f6-4fca-4705-9aa1-4ca44e7c9d12',
    title: {
      'pt-br': 'Projeto Jiboia'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWt3TVRNMU56bGpNeTFoT0RWaUxUUmlORGN0WVRsaU15MWxOVFF5TkRKalltTTFPVFlHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--c890c74b8b45ce42ce7d097fdfba2f6815f31018/maxresdefault.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: 'f1ad1900-74db-42e2-b7dc-edb17893676e',
    title: {
      'pt-br': 'Atividade  Encaixe'
    },
    visible: true,
    position: 'yp',
    main_position: true,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: 'bf585dfe-a6d9-46a0-b316-10a573deb72b',
    title: {
      'pt-br': 'Balneário do Sol'
    },
    visible: true,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: '301a9559-c2a9-4a09-9faa-808f379bb4c1',
    title: {
      'pt-br': 'Serra da Bodoquena'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWszWm1Nd01ERTBPUzB4TVdGa0xUUXhOekl0T1dFME9TMW1PR1V3TkRFM1lUY3lOVGdHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--72408685d370860ae33352ea959d838eafd10ed1/photo-1494625927555-6ec4433b1571.jpg'
    },
    visible: true,
    position: 'l',
    main_position: true,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: 'a9eede2d-d036-4a10-b6b0-095693bc5dbb',
    title: {
      'pt-br': 'Lagoa Misteriosa'
    },
    visible: true,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: 'd3141107-4ff0-40e8-b0d9-4a50d1deb42c',
    title: {
      'pt-br': 'Boca da Onca - Trilha Aventura'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWs0WmpRMU1USXhZaTB4TldJd0xUUTFZVEl0T1Roak1pMHdabVZqTlRFMU1tTmtaR0VHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--9a6324e4453a8e588d29bbef2a5a279bdb75c57e/Ceita%20Core.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: 'cb1ca0d4-88a5-443f-8d23-57060e4ba398',
    title: {
      'pt-br': 'Nascente Azul - Flutuação'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWswTVdZd04yRXdZaTFrTURSa0xUUTBZV0V0T0dKa05TMWlOekJrT1RjeE1UQmxaV1VHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--fac2d3fb3eb4fee2fcbf00e25f41653ca2754435/nascente-azul.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: '62469ca5-e8e9-4520-a1b4-981668ca9972',
    title: {
      'pt-br': 'Rio Sucuri'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWszWldNd09UWmtNeTB3TkdGa0xUUTBZamt0T1RNMVl5MDFNRE0zTkRjeU56bGlZelVHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--c262a4b3f93a4ce9a5132069297140fd12d5ef22/logo%20binamik@2x.png'
    },
    visible: false,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: '1daafd75-52e6-492b-9da3-5a4dc3d1d168',
    title: {
      'pt-br': 'Ceita Corê III'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWsyWTJaa01Ua3pOUzAwTjJReExUUTVOall0WVRabVpTMWhOMlE0WmprNU1EQXhPVFlHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--be3278ca8c0ad19179800b5681450ef40e09437e/Ceita%20Core.jpg'
    },
    visible: false,
    main_position: false,
    company: {
      id: '4a460ee1-2931-41c1-aade-ff939e96f981',
      name: 'Lucas Tour'
    }
  },
  {
    id: '6aa639e2-6315-411a-87d4-35f6d8489200',
    title: {
      'pt-br': 'Estancia Mimosa (Transporte)'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWs1TldNNE1UUTJZeTB6WkRjeExUUTJNbVF0T1dSak5pMWlZMk0zTURFM1kyTmtaRE1HT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--5422602fb1d5695125acbb5d1ec8ee656b1f0cf2/download.jpeg'
    },
    visible: false,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: '862c449d-0d82-4d45-849e-eb87b8dcec76',
    title: {
      'pt-br': 'Boca da Onça'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWt5TWpka016QTFOUzFsWWpRMExUUmhPRFV0WVRFNFpDMHhOVEppTTJReE5tUXpZellHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--099b10a91dc5d8c0ee415847ef58c2b8ae907cf1/boca%20da%20on%C3%A7a.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '0d374a74-ddbd-4253-abc5-fe4f8f739a34',
      name: 'leonardo corredeiras'
    }
  },
  {
    id: '3358233e-eee0-42ca-ba9c-ad7bc432b256',
    title: {
      'pt-br': 'Atividade X'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxoTVRCallqUmhNQzAzTldGaExUUXpaR1V0T1Rka01DMW1ZVE14Wm1Wa01UYzVOakVHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--b416e51ea733882aafaf289e05a3af1ce0277aa3/ursal.png'
    },
    visible: false,
    main_position: false,
    company: {
      id: 'fb0da5ee-e18e-4120-b3b2-ff3908685b26',
      name: 'Teste Devs'
    }
  },
  {
    id: 'c6155c12-9e65-4cbb-bf7f-2b2ad75fd237',
    title: {
      'pt-br': 'Atividade Y'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxrTldNeVpXUXdNQzAzTkRZeUxUUTVabU10WVRFeU5DMWtaalF6TUdJNE9ERTFOekFHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--5928c5d8f6af05a8a56804badd25d56693f08a30/josh-hild-uHrPTJ_c6gE-unsplash.jpg',
      description: {
        'pt-br': 'nova imagem'
      }
    },
    visible: true,
    main_position: false,
    company: {
      id: 'fb0da5ee-e18e-4120-b3b2-ff3908685b26',
      name: 'Teste Devs'
    }
  },
  {
    id: '78f11915-a077-4b7c-b05c-4f73a579da31',
    title: {
      'pt-br': 'Parque das Cachoeiras'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWswTW1SaU1EQTFOQzFtTURJNExUUXhOVGN0WW1aa1lpMWxORFU1TW1KaFpXRXhOR0VHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--aa8ef1db36eaa7a2016d1a6bb9cf337b01b72ba1/Parque-das-Cachoeiras-Bonitour-Passeios-em-Bonito-MS-1115_1766.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: 'f397fe3f-17e3-4c23-b2fb-2c77fe675cfd',
    title: {
      'pt-br': 'Atração Poucas Vagas test'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxpTnpRd01UQm1OUzFtTldZeUxUUmlNVEl0T1RCbVpDMW1OREJrTWpkaVkyVmpZVEFHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--a479bf46f7a0fb8a0a58402bd579c9fd7843de9e/error-img.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: '6b686c9b-2f74-41c3-88d6-06f473a849b7',
    title: {
      'pt-br': 'Rio da Prata 4'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxoT1RJMU1EVmxNUzFqTVdReExUUTJNVFV0WVRNeU1pMDVaRFF5TlRBNU5qazVZMlVHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--500edf1048d925e3b31788ce53081eb486e5df07/Rio-Da-prata-Flutuacao-Bonitour-Passeios-em-Bonito-ms-958_69448.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: '460f176e-f5cd-4926-9cba-054b163db4a1',
    title: {
      'pt-br': 'Gruta do Lago Azul'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWt4WkRWaFpqZG1OUzFpTmpFekxUUmtNbUl0WWpWa05DMDNNRFUxWVdRek1qUmhOellHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--6127ef523654325ed80bc931557a51ae6b5595a7/Grupo%20de%20m%C3%A1scara%20158@2x.png',
      description: {
        'pt-br': 'Descri\u00e7\u00e3o',
        'en-us': 'Description'
      }
    },
    visible: false,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: '4846d7a6-9ee6-4932-9cd4-2c7f688c50f0',
    title: {
      'pt-br': 'Boca da Onça'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWs1T0dJd01HSXlaQzB3TlRneExUUXhNRFF0WWpNd1lpMWlaR013WVRZME9ERmlNallHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--6125923af3e95728cf26124941c68a6a8bab9781/Boca-da-Onca-Bonitour-Passeios-em-Bonito-MS-1113_2363.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: '0b1d6406-87ff-437c-ad0a-d4f1ff56b4ff',
    title: {
      'pt-br': 'Rio Sucuri'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWs1WXpBMk16a3haaTA0TVdNM0xUUmpNbU10T1RNNVppMHdPV1kwWm1ObE5tUXdPV1lHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--cf00c3888a34505baffb037cc0306488a5eb141d/IMAGEM@2x.png'
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: '264b3165-d910-46bd-ae1e-fe4aed93a692',
    title: {
      'pt-br': 'Mengo cuiabano'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWsyTm1Ka01qRmpOaTB3TURkaUxUUTJOVFl0WWpRM1lpMWlPVGRpT0dZeFlUSTVaVGtHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--3c6b8b16a8a983a244fed8f18d354ad7960c0210/error-img.jpg'
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  },
  {
    id: 'a8f8a1aa-41d6-4028-880f-54c089f165ce',
    title: {
      'pt-br': 'Buraco das Araras'
    },
    image: {
      file: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWszT0RFNVlXUm1NUzFqWVdNNExUUTBZV1F0WWpka1ppMHpaV1ZoTWpRNVltRTNabU1HT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--6959241102fefc9dba6a9408b4a940ce3c083c6c/Buraco-das-araras-Bonitour-Passeios-em-Bonito-MS-1110_2370.jpg',
      description: {
        'pt-br': 'testeteste'
      }
    },
    visible: true,
    main_position: false,
    company: {
      id: '864bd557-c0dd-40d1-83d8-02b8508edaec',
      name: 'Development Team'
    }
  }
]

export function SelectExperiencesMock() {
  const [selectedValues, setSelectedValue] = React.useState([])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        alignSelf: 'center'
      }}
    >
      <SelectServices
        services={values}
        onChange={setSelectedValue}
        selectedIds={selectedValues}
      />
    </div>
  )
}
