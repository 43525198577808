/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  LogoutIcon,
  UsersIcon,
  CompanyIcon,
  flexColumn,
  flexRowCentered,
  colors,
  AccountIcon
} from '@bonitour/components'
import { useAuthorization } from 'Shared/contexts/Authorization'
import { useCompany } from 'Shared/contexts/Company'
import { useUser } from 'Shared/contexts/User'
import { useMemo } from 'react'

const { REACT_APP_ACCOUNTS_URL } = process.env

const overflowLink = css`
  text-decoration: none;
`

const flexStart = css`
  align-items: flex-start;
`

const itemStyle = css`
  margin-top: 10px;
  font-weight: 800;
  color: ${colors.gray5};
  cursor: pointer;
`

const iconStyle = css`
  margin-right: 10px;
  font-size: 24px;
  min-width: 26px;
  text-align: center;

  .b-font-logout {
    transform: scale(1.15) translateX(1px);
    -webkit-text-stroke: 0.05px;
  }

  .b-font-users {
    transform: scale(1.25) translateY(1px);
    -webkit-text-stroke: 0.15px;
  }

  .b-font-account {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border: 1px solid ${colors.gray5};
      border-radius: 1px;
      position: absolute;
      top: 2px;
      right: 1px;
      transform: rotate(10deg);
      border-top: none;
      border-left: none;
      box-shadow: 3px 3px 0px 0px ${colors.white};
    }
  }
`

const Item = ({ icon: Icon, label, ...other }) => (
  <div css={[flexRowCentered, itemStyle]} {...other}>
    <Icon css={iconStyle} />
    <span>{label}</span>
  </div>
)

const UserLinkRow = ({ to = '', children, icon: Icon = null }) => (
  <a href={to} css={overflowLink}>
    <Item icon={Icon} label={children} />
  </a>
)

export const Shortcuts = () => {
  // @ts-ignore
  const { logout } = useAuthorization()
  // @ts-ignore
  const { userCompanies } = useCompany()
  // @ts-ignore
  const { hasAffiliateProfile } = useUser()
  const allowsChangingProfile = useMemo(
    () => hasAffiliateProfile || userCompanies?.length > 1,
    [hasAffiliateProfile, userCompanies]
  )

  return (
    <div css={[flexColumn, flexStart]}>
      <UserLinkRow
        icon={CompanyIcon}
        to={`${REACT_APP_ACCOUNTS_URL}/app/company/edit`}
      >
        Empresa
      </UserLinkRow>
      <UserLinkRow icon={UsersIcon} to={`${REACT_APP_ACCOUNTS_URL}/app/users`}>
        Usuários
      </UserLinkRow>
      {allowsChangingProfile && (
        <UserLinkRow
          icon={AccountIcon}
          to={`${REACT_APP_ACCOUNTS_URL}/auth/profiles?redirectUrl=${decodeURIComponent(
            window.location.origin
          )}`}
        >
          Alterar perfil
        </UserLinkRow>
      )}
      <Item onClick={logout} icon={LogoutIcon} label='Sair' />
    </div>
  )
}
