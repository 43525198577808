import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const cardContainer = isEnabled => css`
  background-color: ${isEnabled ? colors.white : colors.gray11};
  margin-bottom: 30px;
`

export const titleContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${colors.gray1};
  align-items: center;
  padding-bottom: 20px;
`

export const toggleLabel = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const selectorDaysLabel = css`
  color: ${colors.gray3};
`

export const tagLabel = css`
  background-color: ${colors.gray11};
  color: ${colors.gray3};
  padding: 6px 10px;
  border-radius: var(--round-element-radius, 20px);
  font-weight: 600;
  text-transform: capitalize;

  &.error {
    color: ${colors.red1};
  }

  &.inline {
    padding: 4px 5px;
    margin: 1px;
    display: inline;
    line-height: 1.85em;
  }

  &.add {
    cursor: pointer;
    margin-right: 15px;
    &::after {
      content: '\u002B';
      background-color: ${colors.white};
      border-radius: var(--small-round-element-radius, 20px);
      display: inline-flex;
      height: 18px;
      width: 18px;
      color: ${colors.primary};
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-left: 10px;
    }
  }
`

export const textAreaStyle = css`
  border: 1.5px solid ${colors.gray7};
  background-color: ${colors.white};
  line-height: 1.75em;
  padding: 0.75em 1.2em;
  min-height: 6em;
  max-height: 15em;
  overflow-x: hidden;
  overflow-y: auto;
  color: ${colors.gray2};
  outline: none;
  border-radius: 0 0 10px 10px;
  &.disabled {
    background-color: ${colors.gray14};
    border-radius: 10px;
    cursor: not-allowed;
  }
  .tag {
    ${tagLabel}
  }
  textarea& {
    height: 14em;
  }
`

export const titleStyle = css`
  padding-left: 10px;
  font-size: 20px;
  color: ${colors.gray3};
  font-weight: bold;
`

export const tagArea = css`
  border: 1.5px solid ${colors.gray7};
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  background-color: ${colors.white};
  padding: 1.2em;
  > * {
    box-shadow: 0px 3px 6px #00000029;
  }
`

/* TODO */
/* export const previewLabelStyle = css`
  cursor: pointer;
  &:hover {
    color: ${colors.gray3};
  }
` */
