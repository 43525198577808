import { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'

/**
 * @typedef IPortal
 * @prop {string} [selector]
 */

/** @type {(props: React.PropsWithChildren<IPortal>) => React.ReactPortal} */
export const Portal = ({ selector = '__ROOT_PORTAL__', children }) => {
  /** @type {React.MutableRefObject<Element>} */
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const selectorPrefixed = '#' + selector.replace(/^#/, '')
    ref.current = document.querySelector(selectorPrefixed)

    if (!ref.current) {
      const div = document.createElement('div')
      div.setAttribute('id', selector)
      document.body.appendChild(div)
      ref.current = div
    }

    setMounted(true)
  }, [selector])

  return mounted ? createPortal(children, ref.current) : null
}
