import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import { classContains } from 'Shared/utils/utils.styles'

export const noGrow = css`
  flex-grow: unset;
`

export const headingStyles = css`
  .tooltip-on-hover {
    width: 240px;
  }
`

export const headingRow = css`
  justify-content: space-between;
  align-items: baseline;
  ${headingStyles}
`

export const infoBlock = css`
  background: ${colors.gray13} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  color: ${colors.gray5};
  font-family: Mulish;
  margin-bottom: 35px;
`

export const inputContainer = css`
  input {
    width: 70px;
  }
`
export const inputGroupStyle = css`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  & > * {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  ${inputContainer}
`

export const loadingStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: grid;
  place-items: center;
`
export const cardStyles = css`
  position: relative;
`

export const titleStyles = css`
  display: flex;
  gap: 15px;
  align-items: baseline;
  min-width: 50%;

  h3 {
    margin-bottom: 14px;
  }
`
export const toolTipStyles = css`
  .tooltip-on-hover {
    ${classContains('arrow')} {
      bottom: 10%;
    }
  }
`

export const lockedSection = css`
  filter: grayscale(70%);
  opacity: 0.15;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`

export const baseSetupGroup = css`
  margin-bottom: 20px;
  padding-bottom: 20px;

  border-bottom: 1px solid ${colors.gray10};
`
