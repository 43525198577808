/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Button } from '@bonitour/components'
import { jsx } from '@emotion/react'
import { OrderByColumnContainer } from './OrderByColumn.style'

/**
 * @typedef OrderByColumnProps
 * @prop { (e) => void} [onChange]
 * @prop { string } [order]
 * @prop { React.ReactNode } [children]
 */

/** @type { React.FC<OrderByColumnProps> } */
export const OrderByColumn = ({ order, onChange, children }) => {
  return (
    <div css={OrderByColumnContainer({ order })}>
      <Button
        type='button'
        onClick={() => onChange(order !== '+' ? '+' : '-')}
        value={order}
      >
        {children}
      </Button>
    </div>
  )
}
