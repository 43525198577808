// @ts-nocheck

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {
  CheckIcon,
  Manager,
  PlusIcon,
  Popover,
  Reference,
  LoadingAnimation
} from '@bonitour/components'
import { Button } from 'components/Button'
import { jsx } from '@emotion/react'
import { useState, useCallback, useMemo } from 'react'
import { SearchBar } from './SearchBar'
import {
  customSearchBar,
  servicesDropDown,
  SelectServicesContainer,
  ServicesContent,
  ServiceButton,
  dropdownLoading,
  SelectServicesBg
} from './SelectServices.styles'
import { ServiceCards } from './ServiceCards'

/**
 * @type {React.FC<{
 *   services: {
 *        id: string,
 *        title?: {'pt-br': string},
 *        image?: { file: string, description?: { 'pt-br': string, 'en-us'?: string, 'es-es'?: string } },
 *        visible?: boolean,
 *        main_position?: boolean,
 *        company?: { id: string, name: string }
 *    }[]
 *   isLoading?: boolean
 *   onChange?: (value?: []) => void
 *   selectedIds?: string[]
 * }>}
 */
export const SelectServices = ({
  services,
  selectedIds,
  isLoading,
  onChange
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [search, setSearch] = useState('')
  const toggleModal = useCallback(() => setIsDropDownOpen(value => !value), [])

  const serviceIsListed = useCallback(
    serviceId => selectedIds.includes(serviceId),
    [selectedIds]
  )

  const handleClick = useCallback(
    experienceId => {
      const existInList = serviceIsListed(experienceId)

      const listWithRemovedId = selectedIds.filter(id => id !== experienceId)
      const listWithAddedId = existInList
        ? listWithRemovedId
        : [...listWithRemovedId, experienceId]

      onChange(listWithAddedId)
    },
    [onChange, selectedIds, serviceIsListed]
  )

  const serviceLabel = useCallback(
    ({ title, company }) => `${title['pt-br']} - ${company?.name}`,
    []
  )

  const filteredServices = useMemo(
    () =>
      services.filter(service =>
        (search || '')
          .normalize('NFD')
          .toLocaleLowerCase()
          .trim()
          .split(' ')
          .every(word =>
            serviceLabel({
              title: service.title,
              company: service?.company
            })
              .normalize('NFD')
              .toLocaleLowerCase()
              .includes(word)
          )
      ),
    [services, search, serviceLabel]
  )

  const selectedServices = useMemo(
    () => selectedIds.map(id => services.find(service => service.id === id)),
    [services, selectedIds]
  )

  return (
    <SelectServicesContainer>
      {isDropDownOpen && (
        <SelectServicesBg
          type='button'
          onClick={() => setIsDropDownOpen(false)}
        />
      )}
      <ServiceCards services={selectedServices} onRemove={handleClick} />
      <Manager>
        <Reference>
          {({ ref }) => (
            <button ref={ref} onClick={toggleModal} className='plus-button'>
              <PlusIcon className='plus-icon' />
              <span>Adicionar mais experiências</span>
            </button>
          )}
        </Reference>

        {isDropDownOpen && (
          <>
            <Popover position='right-start' customCss={[servicesDropDown]}>
              <SearchBar
                onDebounce={setSearch}
                currentValue={search}
                placeholder='Adicionar atividades, transportes ou gastronomias'
                small
                customCss={[customSearchBar]}
                disabled={false}
                debounceTime={0}
              />
              {isLoading ? (
                <LoadingAnimation css={dropdownLoading} />
              ) : (
                <ServicesContent>
                  {filteredServices.map(service => (
                    <ServiceButton
                      isActive={!!serviceIsListed(service?.id)}
                      key={service?.id}
                      onClick={() => handleClick(service?.id)}
                    >
                      <CheckIcon />
                      <span>
                        {serviceLabel({
                          title: service?.title,
                          company: service?.company
                        })}
                      </span>
                    </ServiceButton>
                  ))}
                </ServicesContent>
              )}
              <Button
                className='complete_button'
                onClick={() => setIsDropDownOpen(false)}
              >
                Concluir
              </Button>
            </Popover>
          </>
        )}
      </Manager>
    </SelectServicesContainer>
  )
}
