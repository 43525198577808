export const EDITOR_TOOLS = {
  bold: 'bold',
  italic: 'italic',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  list: 'list',
  orderedList: 'ordered_list',
  underline: 'underline',
  strikethrough: 'strikethrough',
  listItem: 'list-item',
  paragraph: 'paragraph'
}
