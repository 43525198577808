/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Card,
  colors,
  Textarea,
  Row,
  Button,
  InputWithSuffix,
  GhostButton,
  useToast
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  cardContainer,
  selectorDaysLabel,
  tagArea,
  tagLabel,
  textAreaStyle,
  titleContainer,
  titleStyle,
  toggleLabel
} from './CardCustomText.style'
import { patchCustomText, postCustomText } from './CustomText.io'
import { identity } from 'fp-ts/lib/function'
import { safeHtmlString } from 'utils/utils'
import { usePermission } from 'Shared/contexts/Permissions'

export const CardCustomText = ({
  title,
  scheduleMessage = {
    firstMessage: '',
    secondMessage: ''
  },
  value = {
    type: '',
    content: {},
    isPatch: false
  },
  isScheduled = true,
  defaultMessage = '',
  daysInterval,
  customTags = [],
  onValueChange = identity,
  lang,
  setHasEdit = identity,
  shouldSave = false
}) => {
  const id = useMemo(() => (Math.random() + 1).toString(36).substring(2), [])
  const [isEditing, setIsEditing] = useState(false)
  const [customText, setCustomText] = useState(value?.content[lang])

  const { allowed: canEdit } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const { add: addToast } = useToast()

  const startEditing = useCallback(() => {
    if (!canEdit) {
      return addToast(
        'Usuário sem permissão de escrita/atualização do Backoffice'
      )
    }
    setIsEditing(true)
  }, [canEdit, addToast])

  const requiredTags = useMemo(() => {
    return customTags.map(item => item.value)
  }, [customTags])

  useEffect(() => {
    setIsEditing(false)
  }, [lang])

  useEffect(() => {
    setHasEdit(isEditing)
  }, [isEditing, setHasEdit])

  useEffect(() => {
    if (isEditing) {
      /** @type HTMLTextAreaElement **/
      // @ts-ignore
      const textareaNode = document.getElementById(`textarea-${id}`)
      const valueLength = value?.content?.length || 1e30
      setTimeout(() =>
        textareaNode?.setSelectionRange(valueLength, valueLength)
      )
    } else {
      value?.content[lang]
        ? setCustomText(value?.content[lang])
        : setCustomText(defaultMessage)
    }
  }, [id, value, isEditing, lang, defaultMessage])

  const hasTags = useCallback(
    value => {
      if (value.length) {
        return (
          requiredTags.filter(tag => value?.includes(tag)).length ===
          requiredTags.length
        )
      }
    },
    [requiredTags]
  )

  const saveCustomText = useCallback(() => {
    const isSameOrEmptyText =
      value.content[lang] === customText || (!value.isPatch && !customText)

    if (customText.length && !hasTags(customText)) {
      return addToast('Cada tag precisa ser usada pelo menos uma vez', 'danger')
    }

    if (isSameOrEmptyText) {
      setIsEditing(false)
      return addToast('Texto customizado salvo com sucesso!', 'success')
    }

    value?.isPatch
      ? patchCustomText({
          type: value.type,
          content: {
            [lang]: customText.trim()
          }
        })
          .then(() => {
            setIsEditing(false)
            !customText && setCustomText(defaultMessage)
            addToast('Texto customizado salvo com sucesso!', 'success')
            onValueChange({
              type: value.type,
              content: {
                [lang]: customText || defaultMessage || null
              },
              isPatch: Boolean(customText)
            })
          })
          .catch(() => {
            addToast('Erro ao salvar texto customizado', 'danger')
            setIsEditing(false)
            setCustomText(value?.content)
          })
      : postCustomText({
          type: value.type,
          content: {
            [lang]: customText.trim()
          }
        })
          .then(() => {
            onValueChange({
              type: value.type,
              content: {
                [lang]: customText
              },
              isPatch: Boolean(customText)
            })
            !customText && setCustomText(defaultMessage)
            setIsEditing(false)
            addToast('Texto customizado salvo com sucesso!', 'success')
          })
          .catch(() => {
            addToast('Erro ao salvar texto customizado', 'danger')
            setIsEditing(false)
            setCustomText(value?.content)
          })
  }, [
    value,
    lang,
    customText,
    hasTags,
    addToast,
    defaultMessage,
    onValueChange
  ])

  useEffect(() => {
    if (shouldSave && isEditing) {
      saveCustomText()
    }
  }, [shouldSave, saveCustomText, isEditing])

  const replaceTags = useCallback(
    text =>
      safeHtmlString(text)
        .replace(/&lt;&lt;/g, '<<')
        .replace(/&gt;&gt;/g, '>>')
        .replace(/<<(([^>]|>[^>])*)>>/g, tagToReplace => {
          const newTag = customTags.filter(
            tag => tag.value === tagToReplace
          )?.[0]?.label
          return `<span class="tag inline ${
            newTag ? '' : 'error'
          }" data-key="$1">${newTag || tagToReplace}</span>`
        })
        .replace(/<</g, '&lt;&lt;')
        .replace(/>>/g, '&gt;&gt;'),
    [customTags]
  )

  const preview = useMemo(
    () => (
      <div
        className='disabled'
        css={textAreaStyle}
        dangerouslySetInnerHTML={{
          __html: replaceTags(customText || value.content)
        }}
      />
    ),
    [customText, replaceTags, value.content]
  )

  const addTag = useCallback(
    key => () => {
      const currentValue = customText || defaultMessage || ''
      /** @type HTMLTextAreaElement **/
      // @ts-ignore
      const textareaNode = document.getElementById(`textarea-${id}`)
      const selectionStart = Math.min(
        textareaNode?.selectionStart,
        textareaNode?.selectionEnd
      )
      const selectionEnd = Math.max(
        textareaNode?.selectionStart,
        textareaNode?.selectionEnd
      )
      setCustomText(
        `${currentValue.substring(
          0,
          selectionStart
        )}${key}${currentValue.substring(selectionEnd)}`
      )
      textareaNode.focus()
      setTimeout(() =>
        textareaNode?.setSelectionRange(
          selectionStart + key.length,
          selectionStart + key.length
        )
      )
    },
    [customText, defaultMessage, id]
  )

  return (
    <Card css={cardContainer(isEditing)}>
      <div css={titleContainer}>
        <div css={toggleLabel}>
          {/* TODO */}
          {/* <Toggle onChange={() => setIsEnabled(v => !v)} /> */}
          <span css={titleStyle}>{title}</span>
        </div>
        {/* TODO */}
        {/* <span {...css={previewLabelStyle}}>Visualizar e-mail</span> */}
      </div>
      {isEditing && (
        <div css={tagArea}>
          {customTags.map(tag => (
            <span
              css={tagLabel}
              key={tag.value}
              data-key={tag.value}
              className='add'
              onClick={addTag(tag.value)}
            >
              {tag.label}
            </span>
          ))}
        </div>
      )}
      {isEditing ? (
        <Textarea
          id={`textarea-${id}`}
          minLength={1}
          disabled={!isEditing}
          value={customText || ''}
          onChange={setCustomText}
          css={textAreaStyle}
          error=''
          className={!isEditing && 'disabled'}
        />
      ) : (
        preview
      )}
      <Row style={{ justifyContent: isScheduled ? 'space-between' : 'end' }}>
        {isScheduled && (
          <div css={selectorDaysLabel}>
            <span style={{ marginRight: '10px' }}>
              {scheduleMessage?.firstMessage}
            </span>
            <InputWithSuffix
              value={daysInterval}
              maxLength={3}
              style={{ maxWidth: '30px' }}
            >
              <b
                style={{
                  fontWeight: 'bold',
                  fontSize: '15px',
                  color: colors.gray3
                }}
              >
                dia/dias
              </b>
            </InputWithSuffix>
            <span style={{ marginLeft: '10px' }}>
              {scheduleMessage?.secondMessage}
            </span>
          </div>
        )}
        {isEditing ? (
          <div style={{ height: '40px' }}>
            <GhostButton
              style={{ height: '100%' }}
              onClick={() => {
                setIsEditing(false)
                setCustomText(value?.content)
              }}
            >
              Cancelar
            </GhostButton>
            <Button
              onClick={() => {
                saveCustomText()
              }}
              style={{
                height: '100%',
                marginLeft: '20px',
                paddingLeft: '30px',
                paddingRight: '30px'
              }}
            >
              Salvar
            </Button>
          </div>
        ) : (
          <Button
            onClick={startEditing}
            style={{ padding: '0 40px', height: '40px' }}
          >
            Editar
          </Button>
        )}
      </Row>
    </Card>
  )
}
