import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const AbandonedContainer = styled.div`
  .card {
    margin-top: 20px;
    padding: 10px;
  }

  .container {
    padding: 10px;
  }

  .loadingStyle {
    margin: 20px auto;
  }

  .content {
    align-items: center;

    display: flex;

    margin-top: 25px;
    margin-bottom: 30px;

    .totalCount {
      display: block;
      margin-left: 20px;

      p {
        margin-bottom: 5px;

        font-size: 14px;
        font-weight: 600;
        color: ${colors.gray4};
      }

      span {
        font-size: 24px;
        font-weight: 700;
        color: ${colors.gray3};
      }
    }
  }
`

export const iconPurchase = css`
  margin-top: 10px;

  font-size: 55px;
  color: ${colors.indigo1};
`
