// @ts-check

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { FadeInOut, H4, ToggleInputGroup } from '@bonitour/components'
import { css, jsx } from '@emotion/react'

/**
 * @typedef ToggleProps
 * @prop { React.ReactNode } children
 * @prop { React.ReactNode } label
 * @prop { string } id
 * @prop { boolean } checked
 * @prop { function (boolean) : void } onChange
 */

const marginless = css`
  margin: 0;
`
const ToggleInputLabel = props => <H4 customCss={[marginless]} {...props} />
/**
 * @param { ToggleProps } props
 */
export function Toggle({ label, children, id, checked, onChange }) {
  return (
    <>
      <ToggleInputGroup
        id={`${id}-enabled`}
        checked={checked}
        onChange={ev => onChange(ev.target.checked)}
      >
        <ToggleInputLabel>{label}</ToggleInputLabel>
      </ToggleInputGroup>
      <FadeInOut in={checked}>{children}</FadeInOut>
    </>
  )
}
