/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import {
  ColorPicker,
  CopyIcon,
  colors,
  InputFormGroup,
  Row,
  GhostButton
} from '@bonitour/components'
import { css, jsx } from '@emotion/react'
import { unlinkIcon } from 'Domains/Dashboard/Documents/NoConfig'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import {
  alertContainerCss,
  colorPicker,
  label,
  rowButtons,
  saveButton
} from '../Appearance.style'
import { ContentCard } from './ContentCard'
import { WidgetPreview } from './WidgetPreview'

const codeContainer = css`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${colors.gray8};
  width: 100%;
  box-sizing: border-box;

  p {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.gray5};
    font-family: monospace;

    span {
      &.brackets-highlight {
        color: #a0aec0;
      }
      &.element-highlight {
        color: #d47da7;
      }
      &.prop-highlight {
        color: #3691a1;
      }
      &.prop-value-highlight {
        color: #44c178;
      }
    }
  }
`

const copyButton = css`
  position: relative;

  background: transparent;
  cursor: pointer;
  border: none;

  margin-top: 20px;

  font-size: 22px;
  font-weight: 700;
  color: ${colors.primary};

  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 8px;
  }

  .copy-tooltip {
    position: absolute;
    right: -8em;

    font-size: 12px;
    color: ${colors.white};

    background: ${colors.gray4};
    padding: 5px;
    display: flex;
    width: 10em;
    border-radius: 8px;

    pointer-events: none;
    opacity: 0;

    transition: all 150ms ease-in-out;

    &::before {
      position: absolute;
      content: '';
      left: -6px;
      top: 13px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;

      border-right: 6px solid ${colors.gray4};
    }
  }

  .active-tooltip {
    opacity: 1;
    right: -11em;
  }
`

const WIDGET_DIV_ID = 'xpert-search-widget'

const NoUrlAlert = () => {
  return (
    <div css={alertContainerCss}>
      {unlinkIcon}
      <p>
        Você ainda não definiu a url do seu E-commerce Xpert
        <br />
        Para configurar seu Widget
      </p>
    </div>
  )
}

export function WidgetConfigurator({ config }) {
  const [widgetColor, setWidgetColor] = useState(
    config.colors?.secondary || '#1ED6BB'
  )
  const [copied, setCopied] = useState(false)

  const widgetCodeColor = useMemo(() => {
    return widgetColor.replace('#', '%23')
  }, [widgetColor])

  const scriptSrc = useMemo(() => {
    return `${
      config?.url || ''
    }/api/widget/xpert-search-widget/${widgetCodeColor}`
  }, [config, widgetCodeColor])

  const textToCopy = useMemo(() => {
    const divText = `<div id="${WIDGET_DIV_ID}"></div>`
    const scriptText = `<script src="${scriptSrc}" defer></script>`

    return `${divText}\n${scriptText}`
  }, [scriptSrc])

  const handleCopyHtml = useCallback(() => {
    navigator.clipboard.writeText(textToCopy)
    if (!copied) {
      setCopied(true)
    }
  }, [copied, textToCopy])

  useEffect(() => {
    if (!copied) return

    const tooltipTimer = setTimeout(() => setCopied(false), 3 * 1000)
    return () => {
      clearTimeout(tooltipTimer)
    }
  }, [copied])

  const { push } = useHistory()
  const goBack = useCallback(() => push('/'), [push])

  return (
    <>
      <ContentCard title='Configurações do Widget de busca'>
        {config?.url ? (
          <>
            <Row>
              <InputFormGroup
                css={label}
                label='Cor do widget'
                style={{ maxWidth: '300px' }}
              >
                <ColorPicker
                  id='widget-color'
                  value={widgetColor}
                  onChange={setWidgetColor}
                  css={colorPicker}
                />
              </InputFormGroup>
              <WidgetPreview color={widgetColor} />
            </Row>
            <div css={codeContainer}>
              <p>
                <span className='brackets-highlight'>{'<'}</span>
                <span className='element-highlight'>div</span>
                <span className='prop-highlight'>&nbsp;id</span>
                <span className='element-highlight'>=</span>
                <span className='prop-value-highlight'>
                  &quot;
                  {WIDGET_DIV_ID}
                  &quot;
                </span>
                <span className='brackets-highlight'>{'></'}</span>
                <span className='element-highlight'>div</span>
                <span className='brackets-highlight'>{'>'}</span>
              </p>

              <p>
                <span className='brackets-highlight'>{'<'}</span>
                <span className='element-highlight'>script</span>
                <span className='prop-highlight'>&nbsp;src</span>
                <span className='element-highlight'>=</span>
                <span className='prop-value-highlight'>
                  &quot;{scriptSrc}&quot;
                </span>
                <span className='prop-highlight'>&nbsp;defer</span>
                <span className='brackets-highlight'>{'></'}</span>
                <span className='element-highlight'>script</span>
                <span className='brackets-highlight'>{'>'}</span>
              </p>
            </div>

            <button type='button' css={copyButton} onClick={handleCopyHtml}>
              <CopyIcon />
              <span>Copiar código html</span>
              <span
                className={`copy-tooltip ${copied ? 'active-tooltip' : ''}`}
              >
                Código&nbsp;copiado com&nbsp;sucesso
              </span>
            </button>
          </>
        ) : (
          <NoUrlAlert />
        )}
      </ContentCard>
      <Row customCss={[rowButtons]}>
        <GhostButton css={saveButton} onClick={goBack}>
          Voltar
        </GhostButton>
      </Row>
    </>
  )
}
