import { ConfirmDialog, HeaderPage, useToast } from '@bonitour/components'
import { LanguageTabs } from 'components/I18n/LanguageTabs/LanguageTabs'
import { NewLanguageModal } from 'components/I18n/LanguageTabs/NewLanguageModal'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFeatureFlags } from 'Shared/contexts/FeatureFlags'
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer'
import { CustomText } from './CustomText'
import { buttonOnClose, modalConfirm } from './CustomTextPage.style'
import { getCustomText } from './CustomText.io'
import { useHistory } from 'react-router-dom'

function unsavedLangs(avaibleLangs) {
  return [
    ...(!avaibleLangs.some(tab => tab.value === 'es-es')
      ? [
          {
            value: 'es-es',
            label: 'Espanhol'
          }
        ]
      : []),
    ...(!avaibleLangs.some(tab => tab.value === 'en-us')
      ? [
          {
            value: 'en-us',
            label: 'Inglês'
          }
        ]
      : [])
  ]
}

export const CustomTextPage = () => {
  const [currentTab, setCurrentTab] = useState('pt-br')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [newLanguageModal, setNewLanguageModal] = useState(false)
  const [availableTabs, setAvailableTabs] = useState([])
  const [openingTab, setOpeningTab] = useState('')
  const [hasEdit, setHasEdit] = useState(false)
  const { add: addToast } = useToast()
  const { experienceConfigTranslation } = useFeatureFlags()
  const [shouldSave, setShouldSave] = useState(false)
  const languageOptions = useMemo(
    () => unsavedLangs(availableTabs),
    [availableTabs]
  )
  const changeTab = useCallback(
    lang => {
      if (lang === currentTab) {
        return
      }

      if (hasEdit) {
        setOpeningTab(lang)
      } else {
        setCurrentTab(lang)
      }
    },
    [currentTab, hasEdit]
  )

  const addLanguage = useCallback(() => {
    if (hasEdit) {
      setOpeningTab('new')
    } else {
      setNewLanguageModal(true)
    }
  }, [hasEdit])

  const getExistsTabs = useCallback(customTexts => {
    const existsLangs = {
      'en-us': 'Inglês',
      'es-es': 'Espanhol'
    }
    const tabs = [{ label: 'Português', value: 'pt-br' }]

    const alreadyAdded = ['pt-br']

    customTexts.forEach(item => {
      Object.keys(item.content).forEach(lang => {
        if (item.content[lang] && !alreadyAdded.includes(lang)) {
          alreadyAdded.push(lang)
          tabs.push({ label: existsLangs[lang], value: lang })
        }
      })
    })
    return tabs
  }, [])

  const loadData = useCallback(() => {
    setLoading(true)
    getCustomText()
      .then(value => {
        const existsTabs = getExistsTabs(value)

        setData(value)
        setHasEdit(false)
        if (!availableTabs?.length) {
          setAvailableTabs([...availableTabs, ...existsTabs])
        }
      })
      .catch(() => {
        addToast('Erro ao carregar textos customizados.', 'danger')
      })
      .finally(() => setLoading(false))
  }, [addToast, availableTabs, getExistsTabs])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onConfirm = useCallback(
    lang => {
      if (lang === '') {
        addToast('Idioma não encontrado')
        return
      }

      setAvailableTabs([
        ...availableTabs,
        { label: lang === 'en-us' ? 'Inglês' : 'Espanhol', value: lang }
      ])
      changeTab(lang)
      setNewLanguageModal(false)
    },
    [addToast, availableTabs, changeTab]
  )

  const cancelTabChange = useCallback(() => {
    loadData()
    if (openingTab === 'new') {
      setNewLanguageModal(true)
    } else {
      setCurrentTab(openingTab)
    }
    setOpeningTab('')
  }, [loadData, openingTab])

  const confirmTabChange = useCallback(() => {
    setShouldSave(true)
    setTimeout(() => {
      setShouldSave(false)
      if (openingTab === 'new') {
        setNewLanguageModal(true)
      } else {
        setCurrentTab(openingTab)
      }
      setOpeningTab('')
    })
  }, [openingTab])

  const { push } = useHistory()

  const goToDashboard = useCallback(() => {
    push('/app')
  }, [push])

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='E-mails' />
      <LoadingContainer loading={loading}>
        <>
          {experienceConfigTranslation && (
            <LanguageTabs
              tabs={availableTabs}
              currentTab={currentTab}
              onNewLanguage={addLanguage}
              onTabSelect={changeTab}
              hideButton={availableTabs.length === 3}
            />
          )}
          <CustomText
            currentTab={currentTab}
            onUpdateData={() => {
              loadData()
            }}
            data={data}
            setHasEdit={setHasEdit}
            shouldSave={shouldSave}
          />
          {newLanguageModal && (
            <>
              <NewLanguageModal
                onClose={() => setNewLanguageModal(false)}
                onConfirm={onConfirm}
                languageOptions={languageOptions}
              />
            </>
          )}
          <ConfirmDialog
            title='Deseja salvar sua tradução'
            message='Você possui alterações em andamento. Deseja salvar antes de sair?'
            isVisible={hasEdit && !!openingTab}
            successCallback={confirmTabChange}
            cancelCallback={cancelTabChange}
            onClose={() => setOpeningTab('')}
            buttonLabelCancel='Continuar sem salvar'
            buttonLabelConfirm='Salvar'
            customCss={[modalConfirm]}
            customButtonCss={[buttonOnClose]}
          />
        </>
      </LoadingContainer>
    </>
  )
}
