/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useCallback, useState } from 'react'

import {
  Button,
  FilterIcon,
  GhostButton,
  Manager,
  Popover,
  Reference,
  Row,
  ToggleInputGroup
} from '@bonitour/components'
import {
  filterDrawerContainer,
  iconStyle,
  PopoverWrapper
} from './ExperiencesFilter.styles'
import { useMemo } from 'react'

export const ExperiencesFilter = ({
  onFilter,
  hasFilters = false,
  filters: initialFilters
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const [filters, setFilters] = useState(initialFilters)

  const onResetFilters = useCallback(() => {
    setFilters({
      active: true
    })
    onFilter({
      active: true
    })
  }, [onFilter])

  const active = useMemo(() => filters.active, [filters])
  const setActive = useCallback(
    active =>
      setFilters(currentFilters => ({
        ...currentFilters,
        active
      })),
    []
  )
  const toggleActive = useCallback(
    () => setActive(!active),
    [active, setActive]
  )

  // TODO change to multi select after API allows it
  /*
    const multiSelectOptions = ['Ativo', 'Inativo']
    const [selected, setActive] = useState([])
    const [areAllSelected, setAreAllSelected] = useState(false)

  
    useEffect(() => {
      if (multiSelectOptions.length === active.length) {
        setAreAllSelected(true)
      } else setAreAllSelected(false)
    }, [active])

    const isChecked = selectedOption => active.includes(selectedOption)

    const onOptionChange = selectedOption => () => {
      if (isChecked(selectedOption)) {
        setActive(active && active.filter(item => item !== selectedOption))
      } else {
        setActive([...active, selectedOption])
      }
    }

    const allScheduleChange = () => {
      areAllSelected ? setActive([]) : setActive([...multiSelectOptions])
    }
  */

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <button
            ref={ref}
            onClick={() => setIsOpen(v => !v)}
            css={iconStyle({ hasFilters, isOpen })}
          >
            <FilterIcon />
          </button>
        )}
      </Reference>

      {isOpen && (
        <PopoverWrapper>
          <Popover position='bottom-end'>
            <>
              <div css={filterDrawerContainer}>
                <Row>Visibilidade</Row>
                <Row>
                  {/* TODO change to multi select after API allows it */}
                  <ToggleInputGroup
                    id='visibility'
                    checked={active}
                    onChange={toggleActive}
                  >
                    {active ? 'Ativo' : 'Inativo'}
                  </ToggleInputGroup>
                  {/* <MultiSelect
                    css={multiSelectStyle}
                    options={multiSelectOptions}
                    // @ts-ignore
                    isChecked={isChecked}
                    onOptionChange={onOptionChange}
                    onAllOptionsChange={allScheduleChange}
                    allSelected={areAllSelected}
                  /> */}
                </Row>

                <Row
                  style={{ justifyContent: 'space-around' }}
                  className='buttonsContainer'
                >
                  <GhostButton onClick={onResetFilters}>Limpar</GhostButton>
                  <Button
                    type='submit'
                    onClick={() => {
                      setIsOpen(false)
                      onFilter(filters)
                    }}
                  >
                    Filtrar
                  </Button>
                </Row>
              </div>
            </>
          </Popover>
          <button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              width: '100vw',
              height: '100vh',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1
            }}
            onClick={() => setIsOpen(v => !v)}
          />
        </PopoverWrapper>
      )}
    </Manager>
  )
}
