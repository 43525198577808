/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { jsx } from '@emotion/react'

import {
  Button,
  Card,
  Row,
  H3,
  useToast,
  LoadingAnimation,
  ConfirmDialog,
  HeaderPage
} from '@bonitour/components'

import {
  customCardStyles,
  rowButtons,
  saveButton,
  customTooltip,
  documentsHeader
} from './PurchasingPolicy.styles'

import { useCompany } from 'Shared/contexts/Company'
import { TextEditor } from '../TextEditor/TextEditor'
import { serializeToMarkdown } from '../TextEditor/utils/serializer'
import { makeMarkdownEditable } from '../TextEditor/utils/makeMarkdownEditable'
import { InfoTooltip } from '../InfoTooltip'
import { NoConfig } from './NoConfig'
import { getSiteConfig } from '../Appearance/Appearance.io'
import { markdownCleaner } from '../TextEditor/utils/cleaners'
import { checkMarkdownEmptiness } from '../TextEditor/utils/checkEmptiness'
import { LanguageTabs } from 'components/I18n/LanguageTabs/LanguageTabs'
import { getAvailableLanguages } from 'Shared/utils/getAvailableLanguages'
import { isValidJson } from 'Shared/utils/isValidJson'
import { NewLanguageModal } from 'components/I18n/LanguageTabs/NewLanguageModal'
import {
  getLanguageData,
  getUnselectedLanguages
} from 'Shared/utils/i18n/customLanguages'
import { useFeatureFlags } from 'Shared/contexts/FeatureFlags'
import { EDITOR_TOOLS } from '../TextEditor/consts/EditorConsts'
import { buttonOnClose, modalConfirm } from '../CustomText/CustomTextPage.style'

const DEFAULT_LANGUAGE = 'pt-br'

const placeHolderValue = [{ type: 'paragraph', children: [{ text: ' ' }] }]

function EditorElements({
  getDoc,
  postDoc,
  patchDoc,
  successMessage,
  successRemovalMessage,
  impossibleToSaveEmptyMessage,
  failedToSaveMessage,
  documentTitle,
  tools = []
}) {
  const [error, setError] = useState(null)
  const [isFirstEdit, setIsFirstEdit] = useState(true)

  const [isSaved, setIsSaved] = useState(true)
  const [showUnsavedWarning, setShowUnsavedWarning] = useState(false)
  const [isAddNewLanguage, setIsAddNewLanguage] = useState(false)

  const [isNewLanguageModalOpen, setIsNewLanguageModalOpen] = useState(false)

  const { editDocumentsTranslation } = useFeatureFlags()

  const [savedValues, setSavedValues] = useState({})
  const [isReloading, setIsReloading] = useState(false)

  const [editingValue, setEditingValue] = useState(placeHolderValue)
  const [preEditValue, setPreEditValue] = useState(placeHolderValue)

  const [selectableLanguages, setSelectableLanguages] = useState([])
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE)
  const [nextLanguage, setNextLanguage] = useState('')

  const { push } = useHistory()
  const { add: addToast } = useToast()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getDoc()

        if (res) {
          const resLanguages = getAvailableLanguages({ content: res })

          setSelectableLanguages(resLanguages)
          setSavedValues(res)
          setIsFirstEdit(false)
          setIsReloading(true)
        }
      } catch (err) {
        const apiError = err?.response?.data?.error
        if (
          apiError?.errors_msg?.includes('custom_page::document::not_found') &&
          apiError?.origin === 'XPERT::DATA'
        ) {
          // empty document
          setSavedValues({ [DEFAULT_LANGUAGE]: '' })
        } else {
          setError(err)
        }
      }
    }

    getData()
  }, [getDoc])

  useEffect(() => {
    const getEditableValue = async () => {
      const langValue = savedValues[currentLanguage] || '""'

      const isValidContent = isValidJson(langValue)

      const isEmptyContent = isValidContent
        ? !JSON.parse(langValue).length
        : false

      const parsedContent =
        isValidContent && !isEmptyContent
          ? await makeMarkdownEditable(JSON.parse(langValue))
          : { children: placeHolderValue }

      setEditingValue(parsedContent.children)
      setPreEditValue(parsedContent.children)
      setIsSaved(true)
    }

    getEditableValue()
  }, [currentLanguage, savedValues])

  useEffect(() => {
    // RELOAD EDITOR
    const reloadingTimer = setTimeout(() => {
      if (isReloading) {
        setIsReloading(false)
      }
    }, 10)

    return () => clearTimeout(reloadingTimer)
  }, [isReloading])

  const handleEdit = useCallback(
    value => {
      setEditingValue(value)

      if (JSON.stringify(value) !== JSON.stringify(preEditValue)) {
        setIsSaved(false)
      }
    },
    [preEditValue]
  )

  const handleLanguageChange = useCallback(
    (language, skipSave = false) => {
      if (language === currentLanguage) return

      if (!skipSave && !isSaved) {
        setShowUnsavedWarning(true)
        setNextLanguage(language)
        return
      }

      setIsReloading(true)
      setNextLanguage('')
      setCurrentLanguage(language)
    },
    [currentLanguage, isSaved]
  )

  const handleSaveDocument = useCallback(async () => {
    try {
      const mdValue = serializeToMarkdown({ children: editingValue })

      const cleanMarkdown = markdownCleaner(mdValue)

      const isMarkdownEmpty = checkMarkdownEmptiness(cleanMarkdown)

      if (isFirstEdit) {
        if (isMarkdownEmpty) {
          addToast(impossibleToSaveEmptyMessage)
          return
        }
        await postDoc({
          content: {
            ...savedValues,
            [currentLanguage]: JSON.stringify(cleanMarkdown)
          }
        })
      } else {
        await patchDoc({
          content: {
            ...savedValues,
            [currentLanguage]: JSON.stringify(
              isMarkdownEmpty ? '' : cleanMarkdown
            )
          }
        })
      }
      if (isMarkdownEmpty) {
        addToast(successRemovalMessage, 'success')
      } else {
        addToast(successMessage, 'success')
      }

      if (isMarkdownEmpty) {
        if (currentLanguage !== DEFAULT_LANGUAGE) {
          setSelectableLanguages(prevSelectable =>
            prevSelectable.filter(lang => lang.value !== currentLanguage)
          )
        }
        setCurrentLanguage(DEFAULT_LANGUAGE)
      }

      setSavedValues(oldValues => ({
        ...oldValues,
        [currentLanguage]: JSON.stringify(isMarkdownEmpty ? '' : cleanMarkdown)
      }))
      setIsSaved(true)
      return true
    } catch (err) {
      console.error(err)

      addToast(failedToSaveMessage)
    }
  }, [
    editingValue,
    isFirstEdit,
    postDoc,
    savedValues,
    currentLanguage,
    addToast,
    impossibleToSaveEmptyMessage,
    patchDoc,
    successRemovalMessage,
    successMessage,
    failedToSaveMessage
  ])

  const handleContinueWithoutSave = useCallback(() => {
    if (isAddNewLanguage) {
      setIsAddNewLanguage(false)
      setIsNewLanguageModalOpen(true)
    } else {
      handleLanguageChange(nextLanguage, true)
    }
    setShowUnsavedWarning(false)
  }, [handleLanguageChange, isAddNewLanguage, nextLanguage])

  const handleSaveAndContinue = useCallback(async () => {
    const wasSaved = await handleSaveDocument()
    if (wasSaved) {
      if (isAddNewLanguage) {
        setIsAddNewLanguage(false)
        setIsNewLanguageModalOpen(true)
      } else {
        handleLanguageChange(nextLanguage, true)
      }
      setShowUnsavedWarning(false)
    }
  }, [handleLanguageChange, handleSaveDocument, isAddNewLanguage, nextLanguage])

  const possibleNewLanguages = useMemo(() => {
    return getUnselectedLanguages(
      selectableLanguages.map(({ value }) => value)
    ).sort((a, b) => a.label.localeCompare(b.label))
  }, [selectableLanguages])

  const onAddNewLanguage = useCallback(
    lang => {
      const selectedLangData = getLanguageData(lang)
      if (!selectedLangData) {
        addToast('Idioma não encontrado')
        return
      }

      setSelectableLanguages(oldLanguages => [
        ...oldLanguages,
        selectedLangData
      ])
      setIsReloading(true)
      setCurrentLanguage(lang)
      setIsSaved(true)
      setIsNewLanguageModalOpen(false)
    },
    [addToast]
  )

  const handleOpenNewLanguageModal = useCallback(() => {
    if (isSaved) {
      setIsNewLanguageModalOpen(true)
    } else {
      setIsAddNewLanguage(true)
      setShowUnsavedWarning(true)
    }
  }, [isSaved])

  if (error) {
    addToast('Erro ao obter configurações')
    push('/')
  }

  const goToConfig = useCallback(() => {
    push('/app/appearance?tab=policies')
  }, [push])

  return (
    <>
      <HeaderPage
        customCss={[documentsHeader]}
        onBack={goToConfig}
        title='Documentação'
        subtitle={documentTitle}
      />
      {editDocumentsTranslation ? (
        <LanguageTabs
          hideButton={!possibleNewLanguages.length}
          tabs={selectableLanguages}
          currentTab={currentLanguage}
          onTabSelect={handleLanguageChange}
          onNewLanguage={handleOpenNewLanguageModal}
        />
      ) : null}
      {/* @ts-ignore */}
      <Card customCss={[customCardStyles]}>
        <H3>
          Sobre a política
          <InfoTooltip aditionalCss={[customTooltip]}>
            Sua política será exibida ao final dos PDFs de cotação.
          </InfoTooltip>
        </H3>
        {!isReloading ? (
          <TextEditor
            currentValue={editingValue}
            handleSetValue={handleEdit}
            tools={tools}
          />
        ) : (
          <div style={{ height: '318px', width: '100%', opacity: 0 }} />
        )}
      </Card>
      <Row customCss={[rowButtons]}>
        <Button customCss={[saveButton]} onClick={handleSaveDocument}>
          Salvar
        </Button>
      </Row>

      {isNewLanguageModalOpen && (
        <NewLanguageModal
          onClose={() => setIsNewLanguageModalOpen(false)}
          onConfirm={onAddNewLanguage}
          languageOptions={possibleNewLanguages}
        />
      )}

      {/* @ts-ignore */}
      <ConfirmDialog
        title='Deseja salvar sua tradução'
        message='Você possui alterações em andamento. Deseja salvar antes de sair?'
        isVisible={showUnsavedWarning}
        successCallback={handleSaveAndContinue}
        cancelCallback={handleContinueWithoutSave}
        onClose={() => {
          setShowUnsavedWarning(false)
          setNextLanguage('')
        }}
        buttonLabelCancel='Continuar sem salvar'
        buttonLabelConfirm='Salvar'
        customCss={[modalConfirm]}
        customButtonCss={[buttonOnClose]}
      />
    </>
  )
}

export const GenericDocumentPage = ({
  getDoc,
  postDoc,
  patchDoc,
  successMessage,
  successRemovalMessage,
  impossibleToSaveEmptyMessage,
  failedToSaveMessage,
  documentTitle,
  tools = [
    EDITOR_TOOLS.bold,
    EDITOR_TOOLS.italic,
    EDITOR_TOOLS.list,
    EDITOR_TOOLS.h2,
    EDITOR_TOOLS.h3
  ]
}) => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const [config, setConfig] = useState(null)
  const [error, setError] = useState(null)
  // @ts-ignore
  const { id: companyId } = useCompany()

  useEffect(() => {
    if (companyId) {
      getSiteConfig(companyId)
        .then(setConfig)
        .catch(e => {
          const apiError = e?.response?.data?.error
          if (
            apiError?.errors_msg?.includes('auth::api_key::unauthorized') &&
            apiError?.origin === 'XPERT::DATA'
          ) {
            // empty configuration
            setConfig({})
          } else {
            setError(e)
          }
        })
    }
  }, [companyId])

  if (error) {
    addToast('Erro ao obter configurações')
    push('/')
  }

  if (!config) {
    return <LoadingAnimation style={{ width: '100%', height: '300px' }} />
  }

  if (config?.colors?.primary && config?.title) {
    return (
      <EditorElements
        getDoc={getDoc}
        postDoc={postDoc}
        patchDoc={patchDoc}
        successMessage={successMessage}
        successRemovalMessage={successRemovalMessage}
        impossibleToSaveEmptyMessage={impossibleToSaveEmptyMessage}
        failedToSaveMessage={failedToSaveMessage}
        documentTitle={documentTitle}
        tools={tools}
      />
    )
  }

  return <NoConfig />
}
