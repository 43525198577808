export const isFileTypeValid = (acceptedTypes, files) => {
  if (acceptedTypes) {
    const acceptedFiles = acceptedTypes.replace(/\s/g, '').split(',')
    const notAcceptedFile = files.find(
      ({ type }) => !acceptedFiles.includes(type)
    )
    if (notAcceptedFile) {
      return false
    }
  }
  return true
}

const hasFiledPassedLimits = (size, limit) => Math.round(size / 1048576) > limit

export const isFileSizeValid = (sizeLimit, files) => {
  if (sizeLimit) {
    const notAcceptedFile = files.find(({ size }) =>
      hasFiledPassedLimits(size, sizeLimit)
    )
    if (notAcceptedFile) {
      return false
    }
  }
  return true
}
