// @ts-nocheck
import { AppStore } from './App'

export const UserStore = {
  clear() {
    return AppStore.clean()
  },
  get authKey() {
    return AppStore.get('auth-key')
  },
  set authKey(authKey) {
    AppStore.set('auth-key', authKey)
  },
  get user() {
    try {
      return JSON.parse(AppStore.get('user')) || {}
    } catch (error) {
      return {}
    }
  },
  set user(info) {
    const infoString = JSON.stringify(info)
    AppStore.set('user', infoString)
  }
}
