import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

export const ServiceCardsContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  max-height: 225px;
  min-height: 75px;
  overflow: auto;
  padding-right: 10px;
`

export const ServiceCard = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  max-width: 190px;
  width: 100%;
  max-height: 65px;
  background: white;
  border-radius: 10px;
  padding: 15px;
  overflow: hidden;

  button {
    position: absolute;
    background: none;
    border: none;
    right: 8px;
    top: 5px;
    font-size: 15px;
    color: ${colors.gray5};
    cursor: pointer;
    padding: 0;
  }
`

export const LabelsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
  max-width: 112px;

  span {
    color: ${colors.gray3};
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span:first-of-type {
    margin-right: 6px;
  }

  span + span {
    color: ${colors.gray4};
    font-size: 12px;
  }
`

export const defaultImage = css`
  font-size: 20px;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  color: ${colors.primary};
  background-color: ${colors.gray11};
`
