/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'

const checkBoxContainer = css`
  display: flex;
  align-items: center;
`

export const QuotationsHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell css={checkBoxContainer} colSpan={1}>
          Nome do agente
        </TableHeaderCell>
        <TableHeaderCell colSpan={1}>Nome do cliente</TableHeaderCell>
        <TableHeaderCell colSpan={1}>Email do cliente</TableHeaderCell>
        <TableHeaderCell colSpan={1}>Criação da cotação</TableHeaderCell>
        <TableHeaderCell colSpan={1}>Status</TableHeaderCell>
        <TableHeaderCell colSpan={1}>Código ID</TableHeaderCell>
        <TableHeaderCell colSpan={1}>Código da reserva</TableHeaderCell>
        <TableHeaderCell colSpan={1}>Ações</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}
