import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback
} from 'react'
import { UserStore } from 'core/Store/User'

/**
 * @typedef UserContext
 * @prop { any } [user]
 * @prop { string } [authKey]
 * @prop { any } [updateAuthKey]
 * @prop { any } [updateUser]
 */

/** @type {import('react').Context<UserContext>} */
export const UserContext = createContext({})

export const useUser = () => useContext(UserContext)

export const UserProvider = props => {
  const [user, setUser] = useState(() => UserStore.user)
  const [authKey, setAuthKey] = useState(() => UserStore.authKey)
  const hasAffiliateProfile = useMemo(
    () => Boolean(user?.affiliates_info?.length),
    [user]
  )

  const updateAuthKey = useCallback(newAuthKey => {
    UserStore.authKey = newAuthKey
    setAuthKey(newAuthKey)
  }, [])

  const updateUser = useCallback(newUser => {
    UserStore.user = newUser
    setUser(newUser)
  }, [])

  const providerData = useMemo(
    () => ({
      user,
      authKey,
      updateAuthKey,
      updateUser,
      hasAffiliateProfile
    }),
    [user, authKey, updateAuthKey, updateUser, hasAffiliateProfile]
  )

  return <UserContext.Provider value={providerData} {...props} />
}
