import React from 'react'

import { MIN_TABLE_MOBILE_BREAKPOINT } from './styles'

const LOOK_AT_ME = '\n^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^\n'

export const buildColumnTemplate = (labelsLength, columnsWidths) =>
  columnsWidths?.map(width => width.replace(/\s/g, ''))?.join(' ') ||
  new Array(labelsLength).fill('1fr').join(' ')

export const validateProps = ({
  labels,
  children,
  columnsWidths,
  mobileBreakpoint = 900,
  sortableColumns = []
}) => {
  if (mobileBreakpoint < MIN_TABLE_MOBILE_BREAKPOINT) {
    console.warn(
      `Table: mobileBreakpoint (${mobileBreakpoint}) is too small. The minimum of ${MIN_TABLE_MOBILE_BREAKPOINT} will be used instead.${LOOK_AT_ME}`
    )
  }

  const columnTemplate = buildColumnTemplate(labels.length, columnsWidths)
  const splitTemplate = columnTemplate.split(' ')
  const columnTemplateLength = columnTemplate.length ? splitTemplate.length : 0
  if (columnTemplateLength !== labels.length) {
    console.warn(
      `Table: Length of columnsWidths (${columnTemplateLength}) and labels (${labels.length}) should be equal.${LOOK_AT_ME}`
    )
  }
  splitTemplate
    .map((value, idx) => ({ value, idx }))
    .filter(width => !CSS.supports('grid-template-columns', width.value))
    .forEach(width => {
      const parsedWidth = width.value.replace(/"/g, '\\"')
      console.warn(
        `Table: Invalid width value "${parsedWidth}" on columnsWidths[${width.idx}].${LOOK_AT_ME}`
      )
    })

  const tableRowChildren = children
    .map((value, idx) => ({ value, idx }))
    .filter(
      child =>
        React.isValidElement(child.value) &&
        child.value?.type?.displayName === 'TableRow'
    )

  tableRowChildren.forEach(child => {
    const childChildrenLength = child.value?.props?.children?.length
    if (childChildrenLength !== labels.length) {
      console.warn(
        `Table: children[${child.idx}] length (${childChildrenLength}) should be equal to labels length (${labels.length}).${LOOK_AT_ME}`
      )
    }
  })

  const nonTableRowChildren = children
    .map((value, idx) => ({ value, idx }))
    .filter(
      child =>
        !tableRowChildren.some(tableRowChild => tableRowChild.idx === child.idx)
    )

  nonTableRowChildren.forEach(child => {
    console.warn(
      `Table: children[${child.idx}] is not a TableRow. See TableRow component.${LOOK_AT_ME}`
    )
  })

  if (sortableColumns) {
    const isEverySortableColumnValid = sortableColumns.every(column => {
      const isValidSortable = labels.includes(column)

      if (!isValidSortable) {
        console.warn(
          `Table: sortableColumns[${column}] is not listed on labels.${LOOK_AT_ME}`
        )
      }

      return isValidSortable
    })

    if (!isEverySortableColumnValid) {
      console.warn(
        `Table: some sortableColumns are not valid. Check labels.${LOOK_AT_ME}`
      )
    }
  }
}

export const changeSortOrder = sortOrder =>
  sortOrder !== 'asc' ? 'asc' : 'desc'
