import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const OrderByColumnContainer = ({ order }) => css`
  position: relative;

  button {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0 0 0 20px;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.gray3};

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 1px;
      width: 5px;
      height: 5px;
      border: 2px solid ${colors.gray3};
      border-top: none;
      border-left: none;
    }

    &:before {
      display: ${order === '-' ? 'none' : 'block'};
      left: 1px;
      top: ${order ? '7px' : '1px'};
      transform: translateX(-50%) rotate(225deg);
    }

    &::after {
      display: ${order === '+' ? 'none' : 'block'};
      margin-top: ${order ? '3px' : '10px'};
      top: 1px;
      transform: translateX(-50%) rotate(405deg);
    }
  }
`
