// @ts-check
import { xpertAxiosInstance } from 'external/axios'
import { parseFromXpert, parseToXpert } from './AlgorithmSetup.parser'

const axiosInstance = xpertAxiosInstance()

export async function getAlgorithmSetupData() {
  const clientData = (await axiosInstance.get('/config/client'))?.data
  return parseFromXpert(clientData)
}

export function patchAlgorithmSetupData(formData) {
  const requestBody = parseToXpert(formData)
  return axiosInstance.patch('/config/client', requestBody)
}
