import escapeHtml from 'escape-html'
import { Text } from 'slate'

// remove javascript linebreaks left from deserializing process
export const deserializingCleaner = node => {
  if (Text.isText(node)) {
    const nodeText = escapeHtml(node.text)
    // remove if is only a line break
    if (nodeText === '\n') {
      return undefined
    }
    // return as empty text if it has a markdown line break
    if (nodeText === '\n\\' || nodeText === '\\') {
      return { text: '' }
    }
    return node
  }

  const nodeChildrenList = node.children.reduce((acc, curr) => {
    const cleanedNode = deserializingCleaner(curr)
    if (cleanedNode) {
      return [...acc, cleanedNode]
    }
    return acc
  }, [])

  return {
    ...node,
    children: nodeChildrenList
  }
}

// remove javascript linebreaks at the end of a Markdown
export const markdownCleaner = markdown => {
  return markdown.replace(/[\n ]+$/gi, '')
}
