import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { BREAK_POINTS } from 'assets/styles/queries'

export const ShareModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 450px;
  max-width: 500px;

  padding: 10px 50px;
  color: ${colors.gray3};

  b {
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${colors.gray6};

    &.titleShareItinerary {
      border-bottom: none;
    }

    &.titleShareAgent {
      border-bottom: none;
    }
  }

  p.error__message {
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
  }

  .titleSendItinerary {
    display: none;
    font-size: 22px;
    padding-bottom: 50px;
    font-weight: 700;
    width: 100%;
    margin-left: 80px;
  }

  .flow_button {
    min-width: 140px;
    margin-top: 20px;
    min-height: 44px;
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    min-width: 300px;
    padding: 10px;

    .flow_button {
      min-width: 0;
      position: fixed;
      right: 20px;
      bottom: 20px;
      border-radius: var(--round-element-radius, 100vw);
      height: 50px;
      width: 50px;

      .button__spinner_wrapper {
        right: 8px;
        position: relative;
      }
    }

    b {
      text-align: left;
      border-bottom: none;

      &.titleShareAgent {
        text-align: start;
      }

      &.titleShareItinerary {
        font-size: 18px;
        padding: 0 10px 20px;
      }
    }

    .titleSendItinerary {
      display: flex;
    }
  }
`

export const shareModalPopUp = css`
  .mobile_only {
    display: none;
    font-size: 20px;
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    min-width: 100vw;
    min-height: 100vh;
    max-height: 100%;

    border-radius: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    padding: 10px;
    background-color: ${colors.gray15};

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 60px;
      top: 0;
      left: 0;
      right: 0;
      background-color: ${colors.white};
      box-shadow: 0 2px 2px ${colors.gray8};
      z-index: -1;
    }

    .closeButton {
      left: 10px;
      background-color: transparent;
      color: ${colors.gray3};
      top: 15px;
      font-size: 20px;
    }

    .mobile_only {
      display: flex;
    }

    .desktop_only {
      display: none;
    }
  }
`

export const ShareInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 40px;
  gap: 2rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  label {
    font-size: 14px;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    grid-template-columns: 1fr;
    width: 100%;
    margin: 10px 0 40px;
    padding-bottom: 20px;
    padding: 0 10px;

    > div {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
`

export const LinkToShare = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 0.25em 0;
  border: none;
  background-color: transparent;
  color: inherit;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .link_icon {
    margin-left: 10px;
    color: var(--primary-color);
    min-width: 2ch;
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    padding: 0 10px;
    display: flex;
    justify-content: flex-start;
  }
`

export const ShareModeContainer = styled.div`
  --share-mode-button-size: 38px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 20px;
  padding-bottom: 8px;

  button {
    width: var(--share-mode-button-size);
    min-width: var(--share-mode-button-size);
    height: var(--share-mode-button-size);
    min-height: var(--share-mode-button-size);
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    color: var(--primary-color);
    font-size: 20px;

    cursor: pointer;

    img {
      width: var(--share-mode-button-size);
      height: var(--share-mode-button-size);
    }

    span {
      &.with_border {
        border: 1px solid currentColor;
        border-radius: var(--round-element-radius, 50%);
        width: var(--share-mode-button-size);
        height: var(--share-mode-button-size);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.titleButton {
        display: none;
        margin-left: 10px;
        font-size: 17px;
        font-weight: 600;
        color: ${colors.gray3};
      }
    }

    &:nth-child(2) {
      margin-left: 20px;
    }
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    --share-mode-button-size: 40px;

    flex-direction: column;
    padding: 32px 10px 0;
    width: 100%;

    button {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      padding: 0;
      justify-content: flex-start;

      span {
        &.titleButton {
          display: flex;
        }
      }

      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }
`

export const ShareButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;

  button {
    min-width: 140px;
    min-height: 44px;
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 50px;

    button {
      min-height: 0;
      min-width: 60px;
      width: 50%;

      &:nth-child(1) {
        font-weight: 700;
        font-size: 19px;
        width: 20%;
        text-decoration: underline;
        background: none;
        padding: 0;
        border: none;
      }
    }
  }
`

export const inputContainerWithIcon = css`
  position: relative;

  aside {
    display: flex;
    justify-content: center;
    align-items: center;

    .whatsapp_icon {
      --w-icon-size: 1em;

      filter: grayscale(100%);
      width: var(--w-icon-size);
      height: var(--w-icon-size);
      min-width: var(--w-icon-size);
      min-height: var(--w-icon-size);
    }
  }

  .input__icon {
    position: absolute;
    top: 50%;
    left: 1em;
    transform: translateY(-50%);
    color: ${colors.gray5};
    font-size: 1.25em;
  }

  input {
    padding-left: 3em;
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    padding-right: 30px;
    input {
      background-color: ${colors.gray12};
    }
  }
`
