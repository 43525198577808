/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { H3 } from '@bonitour/components'
import { LimitIntegerScalar, Title } from '../CommonComponents'

export const DistanceFormSection = ({
  form,
  errors,
  onInputChange,
  onInputBlur
}) => {
  return (
    <>
      <Title tooltipText='Montar roteiros com atividades com distanciamento máximo'>
        <H3>Distância entre atividades</H3>
      </Title>
      <LimitIntegerScalar
        label='Distância máxima'
        inputLabelPreffix='Alocar atividades que tem uma distância de até'
        inputLabelSuffix='entre elas.'
        id='maximum-distance'
        unit='Km'
        value={form.maximumDistanceValue}
        checked={form.maximumDistanceEnabled}
        onBlur={onInputBlur('maximumDistanceValue')}
        onEnabledChange={onInputChange('maximumDistanceEnabled')}
        onValueChange={onInputChange('maximumDistanceValue')}
        error={errors.maximumDistanceValue}
      />
    </>
  )
}
