import { css, keyframes } from '@emotion/react'
import { colors } from '@bonitour/components'

const containerAnimation = keyframes`
  from {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  83% {
    opacity: 1;
  }
  88% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
`

const userInteractionAnimation = keyframes`
  from {
    transform: translateX(0px) translateY(150px);
  }
  8% {
    transform: translateX(0px) translateY(150px);
  }
  35% {
    opacity: 0.20;
    transform: translateX(400px) translateY(0px);
    width: 64px;
    height: 64px;
  }
  36% {
    opacity: 0.55;
    transform: translateX(400px) translateY(5px);
    width: 32px;
    height: 32px;
  }
  40% {
    opacity: 0.55;
    transform: translateX(400px) translateY(5px);
  }
  60% {
    opacity: 0.55;
    transform: translateX(138px) translateY(15px);
    width: 32px;
    height: 32px;
  }
  61% {
    opacity: 0.20;
    transform: translateX(138px);
    width: 64px;
    height: 64px;
  }
  72% {
    opacity: 0;
  }
  to {
    opacity: 0;
    transform: translateX(0px);
    width: 64px;
    height: 64px;
  }
`

const card1Animation = keyframes`
from {
  transform: scale(0.92) translateX(0px) translateY(0px);
}
50% {
  transform: scale(0.92) translateX(0px) translateY(0px);
}
54% {
  transform: scale(0.92) translateX(268px) translateY(0px);
}
to {
  transform: scale(0.92) translateX(268px) translateY(0px);
}
`

const card2Animation = keyframes`
from {
  transform: scale(0.92) translateX(0px) translateY(0px);
  opacity: 1;
}
35% {
  transform: scale(0.92) translateX(0px) translateY(0px);
  opacity: 1;
}
40% {
  transform: scale(1.05) translateX(0px) translateY(20px);
  opacity: 0.95;
}
60% {
  transform: scale(1.05) translateX(-230px) translateY(30px);
  opacity: 0.95;
}
65% {
  opacity: 1;
  transform: scale(0.92) translateX(-268px) translateY(0px);
}
to {
  opacity: 1;
  transform: scale(0.92) translateX(-268px) translateY(0px);
}
`

export const modalContainer = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  z-index: 99999;
`

export const modalContent = css`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;
  box-sizing: border-box;
`

export const modalBackdrop = css`
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
`

export const rowCheckBox = css`
  position: absolute;
  left: 25px;
  bottom: 5px;
  justify-content: flex-start;
  align-items: center;

  h3 {
    margin-left: 10px;
    color: ${colors.gray5};
  }
`

export const modalCard = css`
  z-index: 1;
  display: flex;
  position: relative;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  max-width: 800px;
  max-height: 450px;
  padding: 30px;
  box-sizing: border-box;

  border-radius: 10px;
  background: ${colors.white};

  overflow: hidden;

  @media (hover: hover) {
    height: 100%;
  }

  h2 {
    align-self: flex-start;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 18px;
  }

  p {
    align-self: flex-start;
    font-size: 14px;
  }
`

export const modalCloseButton = css`
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;

  border: none;
  border-radius: var(--round-element-radius, 50%);

  background: ${colors.primary};
  color: ${colors.white};

  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const sampleRegionsGrid = css`
  --cards-animation-duration: 7s;

  width: 100%;
  position: relative;

  margin-top: 30px;
  margin-bottom: 100px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  pointer-events: none;

  animation: ${containerAnimation} var(--cards-animation-duration) ease-in-out
    infinite;

  &::after {
    content: '';
    position: absolute;

    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: ${colors.primary};
    opacity: 0.1;
    z-index: 3;

    transform-origin: center;

    left: -120px;
    top: 32px;

    animation: ${userInteractionAnimation} var(--cards-animation-duration)
      ease-in-out infinite;
  }
`

export const regionsColumn = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 190px;

  &::after {
    content: '';
    z-index: 1;

    width: 78%;
    height: 82%;
    border-radius: 10px;
    border: 3px dashed ${colors.gray6};
  }
`

export const regionCardContentBox = css`
  z-index: 2;
  transform-origin: center;
  transform: scale(0.92);
  position: absolute;

  &.first-animated-card {
    animation: ${card1Animation} var(--cards-animation-duration) ease-in-out
      infinite;
  }

  &.second-animated-card {
    animation: ${card2Animation} var(--cards-animation-duration) ease-in-out
      infinite;
  }
`

export const touchOnly = css`
  @media (hover: hover) {
    display: none;
  }

  background-color: ${colors.yellow3};
  border-color: ${colors.yellow1};
  color: ${colors.orange6};
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  font-size: 0.9rem;
  padding: 10px 20px;
`

export const mouseOnly = css`
  @media (hover: none) {
    display: none;
  }
`
