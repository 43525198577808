// @ts-check
import { getCompany } from 'core/Store/Company'

export function parseFromXpert({
  min_tour_time: minTourTime,
  max_tour_time: maxTourTime,
  max_distance_allowed: maxDistanceAllowed,
  minutes_between_tours: minutesBetweenTours,
  min_days_required: minDaysRequired,
  lunch: { start_time: startTime, end_time: endTime, duration },
  optional_services: optionalServices,
  lunch
}) {
  const { use_intelligent_algorithm } = optionalServices || {}

  return {
    hasLunch: Boolean(lunch),
    lunchDuration: String(duration),
    lunchPeriodEnd: String(endTime),
    lunchPeriodStart: String(startTime),
    maximumDistanceEnabled: Boolean(maxDistanceAllowed),
    maximumDistanceValue: String(maxDistanceAllowed),
    maximumTimeEnabled: Boolean(maxTourTime),
    maximumTimeValue: String(maxTourTime),
    minimumTimeBetweenActivitiesEnabled: Boolean(minutesBetweenTours),
    minimumTimeBetweenActivitiesValue: String(minutesBetweenTours),
    minimumTimeEnabled: Boolean(minTourTime),
    minimumTimeValue: String(minTourTime),
    minDaysRequired: parseInt(minDaysRequired || 1),
    useIntelligence: Boolean(use_intelligent_algorithm ?? true)
  }
}

export function parseToXpert({
  hasLunch,
  lunchDuration,
  lunchPeriodEnd,
  lunchPeriodStart,
  maximumDistanceEnabled,
  maximumDistanceValue,
  maximumTimeEnabled,
  maximumTimeValue,
  minimumTimeBetweenActivitiesEnabled,
  minimumTimeBetweenActivitiesValue,
  minimumTimeEnabled,
  minimumTimeValue,
  minDaysRequired,
  useIntelligence
}) {
  const requestBody = {
    id: getCompany()?.id,
    min_tour_time: null,
    max_tour_time: null
  }
  if (hasLunch) {
    requestBody.lunch = {
      start_time: lunchPeriodStart,
      end_time: lunchPeriodEnd,
      duration: lunchDuration | 0
    }
  }
  if (maximumDistanceEnabled) {
    requestBody.max_distance_allowed = maximumDistanceValue | 0
  }
  if (maximumTimeEnabled) {
    requestBody.max_tour_time = maximumTimeValue
  }
  if (minimumTimeBetweenActivitiesEnabled) {
    requestBody.minutes_between_tours = minimumTimeBetweenActivitiesValue | 0
  }

  if (minimumTimeEnabled) {
    requestBody.min_tour_time = minimumTimeValue
  }

  requestBody.min_days_required = parseInt(minDaysRequired) || 1

  const optionalServices = {
    use_intelligent_algorithm: useIntelligence
  }

  requestBody.optional_services = optionalServices

  return requestBody
}
