import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const CARD_HEIGHT = 250
export const CARD_WIDTH = 230

export const cardStyles = css`
  width: ${CARD_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  padding: 22px 16px;
  box-sizing: border-box;
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3rem 1rem;
  gap: 0px;

  cursor: grab;

  h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin: 2px 0;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.gray3};

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      margin: auto 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 2px 0;
      word-wrap: break-word;
    }
  }

  span {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: ${colors.gray5};
    font-weight: 500;
  }

  a {
    width: 30px;
    height: 30px;
    position: absolute;
    font-size: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background: transparent;
    cursor: pointer;
    right: 4px;
    top: 4px;

    transition: opacity 200ms;
    opacity: 0;

    text-decoration: none;
    color: inherit;
  }

  &:hover a {
    opacity: 1;
  }
`

export const marginless = css`
  margin: 0;
`

export const itinialsCircleStyle = css`
  border-radius: var(--round-element-radius, 50%);

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: ${colors.white};

  margin: 0 auto;
`

export const InlineToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 0.625rem;

  .toggle__container {
    margin: unset;

    .toggle {
      max-width: 3.125rem;
    }

    .label {
      min-width: 8.438rem;
    }
  }
`
