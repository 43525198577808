/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Base } from './Base'
import { Router } from './Router'
import { routes } from 'Routes'

export const App = () => (
  <Base>
    <Router routes={routes} />
  </Base>
)
