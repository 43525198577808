import React from 'react'
import { ToggleInputGroup } from '@bonitour/components'
import { toggleContainerCss, toggleLabelCss } from './InlineToggle.style'

export const InlineToggle = ({
  id,
  label,
  isEnabled,
  onChange,
  disabled = false,
  ...props
}) => {
  return (
    <ToggleInputGroup
      className='toggle__container'
      id={`experience-agent-visibility-${id}`}
      disabled={disabled}
      onChange={onChange}
      checked={isEnabled}
      customCss={[toggleContainerCss]}
      customLabelCss={[toggleLabelCss]}
      {...props}
    >
      {label}
    </ToggleInputGroup>
  )
}
