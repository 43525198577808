import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BiSortAlt2, BiSortUp, BiSortDown } from 'react-icons/bi'

import { defaultSortingHandler, useTable } from './Table'

import { changeSortOrder } from './helpers'

import {
  MobileSortButton,
  MobileSortTooltip,
  MobileSortTooltipBackdrop,
  MobileSortTooltipOption
} from './styles'

export const MobileSortMenu = ({ onSortingChange = defaultSortingHandler }) => {
  const {
    labels,
    sortableColumns = [],
    sortedOrder,
    sortedColumn = -1
  } = useTable()

  const sortedLabel = useMemo(
    () => labels[sortedColumn],
    [labels, sortedColumn]
  )

  const isTableSortable = useMemo(
    () => sortableColumns.length,
    [sortableColumns]
  )

  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleMenu = useCallback(() => {
    if (isTableSortable) {
      setMenuOpen(v => !v)
    }
  }, [isTableSortable])

  useEffect(() => {
    if (isTableSortable) {
      setMenuOpen(false)
    }
  }, [isTableSortable])

  const handleChange = useCallback(
    idx => () => {
      setMenuOpen(false)
      onSortingChange({
        column: idx,
        order: changeSortOrder(sortedColumn === idx ? sortedOrder : '')
      })
    },
    [onSortingChange, sortedColumn, sortedOrder]
  )

  return isTableSortable || (sortedLabel && sortedOrder) ? (
    <>
      <MobileSortButton
        className={[
          isTableSortable ? 'sortable' : '',
          isMenuOpen ? 'open' : ''
        ].join(' ')}
        onClick={toggleMenu}
        disabled={!isTableSortable}
        type='button'
        layout
      >
        {isTableSortable && (!sortedLabel || !sortedOrder) ? (
          <>
            <BiSortAlt2 size='1.75rem' />
            <p>Ordenar</p>
          </>
        ) : null}
        {sortedLabel && sortedOrder ? (
          <>
            {sortedOrder === 'asc' ? (
              <BiSortDown size='1.75rem' />
            ) : (
              <BiSortUp size='1.75rem' />
            )}
            <p>{sortedLabel}</p>
          </>
        ) : null}
      </MobileSortButton>
      <AnimatePresence>
        {isMenuOpen ? (
          <>
            <MobileSortTooltipBackdrop onClick={toggleMenu} type='button' />
            <MobileSortTooltip
              initial={{
                transform: 'scale(0)',
                opacity: 0
              }}
              animate={{
                transform: 'scale(1)',
                opacity: 1
              }}
              exit={{
                transform: 'scale(0)',
                opacity: 0
              }}
              transition={{ type: 'tween' }}
            >
              {sortableColumns.map((label, idx) => (
                <MobileSortTooltipOption key={idx} onClick={handleChange(idx)}>
                  <p>{label}</p>
                  {idx === sortedColumn ? (
                    sortedOrder === 'desc' ? (
                      <BiSortDown size='1.25rem' />
                    ) : (
                      <BiSortUp size='1.25rem' />
                    )
                  ) : null}
                </MobileSortTooltipOption>
              ))}
            </MobileSortTooltip>
          </>
        ) : null}
      </AnimatePresence>
    </>
  ) : null
}
