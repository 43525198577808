import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { colors } from '@bonitour/components'

const pulse = keyframes`
  100% {
    opacity: 0.5;
  }
`

export const ButtonDefault = styled.button`
  --button-hover-brightness: 0.88;

  position: relative;
  padding: 0.75em 1.25em;
  border-radius: 30px;
  border: 0;

  font-size: 1rem;
  font-weight: 700;
  background-color: var(--primary-color);
  color: ${colors.white};
  outline: none;

  transition: all 250ms;

  cursor: pointer;

  .button__text_wrapper {
    animation: ${({ isLoading }) => (isLoading ? pulse : 'none')} 0.75s infinite
      alternate;
  }

  span svg {
    width: 3.25em;
    height: 3.25em;
  }

  &.button__outline {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 30px;
      transition: opacity 250ms;
      box-shadow: 0 0 10px 1px var(--primary-color);
      opacity: 0;
    }
  }

  &:hover {
    filter: brightness(var(--button-hover-brightness));

    &.button__outline::after {
      opacity: 0.25;
    }
  }

  &:disabled {
    cursor: default;
    --button-hover-brightness: 1;
  }

  &:before {
    content: '';
    display: block;

    width: ${({ isLoading, hasLoadingText }) =>
      isLoading && hasLoadingText ? '2em' : '0'};
  }

  .button__spinner_wrapper {
    margin: -0.5em 0;
    display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
    ${({ hasLoadingText }) =>
      hasLoadingText &&
      css`
        top: calc(50% + 0.5em);
        transform: translateY(-50%);
        position: absolute;
        left: 0.25rem;
      `}
  }
`
