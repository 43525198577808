/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const PortalContent = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${props => props.zIndex || 999};
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      left: 0;
    `}
`

const PortalsContainer = ({ children }) => {
  return (
    <>
      <PortalContent id='__ROOT_PORTAL__' />
      <PortalContent zIndex={9000} isFullWidth />
      <PortalContent id='__MODALS_PORTAL__' zIndex={9990} />
      <PortalContent id='__POPUP_PORTAL__' zIndex={9990} isFullWidth />
      {children}
    </>
  )
}

export default PortalsContainer
