/* eslint-disable */

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { TooltipOnHover } from '@bonitour/components'
import {
  loadItiniraries,
  progressBarStatus
} from './ItinerariesStatistics.style'

export const ProgressBarItineraries = ({ segments }) => {
  return (
    <div css={loadItiniraries}>
      <div css={progressBarStatus}>
        {segments.map(segment => (
          <TooltipOnHover
            text={`${segment.name} - ${segment.value}`}
            position='top'
            className='segmentTooltip'
            style={{ maxWidth: `${segment.width}%` }}
          >
            <div
              className='segment'
              style={{ backgroundColor: segment.color }}
            />
          </TooltipOnHover>
        ))}
      </div>
    </div>
  )
}
