import { css } from '@emotion/react'
import { BREAK_POINTS } from 'assets/styles/queries'

export const headingRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px 40px;

  h1 {
    margin-bottom: 30px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: end;

    .backButton {
      width: 150px;
      padding: 15px 30px;
      margin-bottom: 90px;
    }

    .filters {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  @media (max-width: ${BREAK_POINTS.beforeLaptop + 15}px) {
    margin: 20px 20px 40px;
  }
`

export const tableContainer = css`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: scroll;
  display: flex;

  .headerOrder {
    display: flex;
    gap: 5px;
  }
`

export const secondaryRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: ${BREAK_POINTS.beforeLaptop + 15}px) {
    margin: 0 20px;
  }
`
