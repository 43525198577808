/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { EDITOR_TOOLS } from '../consts/EditorConsts'

const HeadingWithVal = ({ val }) => (
  <>
    <text x='16' y='20' style={{ font: 'bold 14px sans-serif' }}>
      {val}
    </text>
    <path
      transform='translate(-3,0)'
      d='M18 20L18 4 15 4 15 10 9 10 9 4 6 4 6 20 9 20 9 13 15 13 15 20z'
    />
  </>
)

const icons = {
  [EDITOR_TOOLS.italic]: (
    <path d='M19 7L19 4 9 4 9 7 11.868 7 9.012 17 5 17 5 20 15 20 15 17 12.132 17 14.988 7z' />
  ),
  [EDITOR_TOOLS.bold]: (
    <path
      transform='scale(1.1) translate(-1,0)'
      d='M17.061,11.22C17.641,10.462,18,9.526,18,8.5C18,6.019,15.981,4,13.5,4H6v15h8c2.481,0,4.5-2.019,4.5-4.5 C18.5,13.203,17.94,12.042,17.061,11.22z M13.5,7C14.327,7,15,7.673,15,8.5S14.327,10,13.5,10H9V7H13.5z M14,16H9v-3h5 c0.827,0,1.5,0.673,1.5,1.5S14.827,16,14,16z'
    />
  ),
  [EDITOR_TOOLS.h1]: <HeadingWithVal val='1' />,
  [EDITOR_TOOLS.h2]: <HeadingWithVal val='2' />,
  [EDITOR_TOOLS.h3]: <HeadingWithVal val='3' />,
  [EDITOR_TOOLS.h4]: <HeadingWithVal val='4' />,
  [EDITOR_TOOLS.h5]: <HeadingWithVal val='5' />,
  [EDITOR_TOOLS.h6]: <HeadingWithVal val='6' />,
  [EDITOR_TOOLS.list]: (
    <path
      transform='scale(1.05)'
      d='M4 6H6V8H4zM4 11H6V13H4zM4 16H6V18H4zM20 8L20 6 18.8 6 9.2 6 8.023 6 8.023 8 9.2 8 18.8 8zM8 11H20V13H8zM8 16H20V18H8z'
    />
  ),
  [EDITOR_TOOLS.orderedList]: (
    <>
      <text x='3' y='10' style={{ font: 'bold 8px sans-serif' }}>
        1
      </text>
      <text x='3' y='21' style={{ font: 'bold 8px sans-serif' }}>
        2
      </text>
      <rect x='10' y='6' width='12' height='1.5' />
      <rect x='10' y='12' width='12' height='1.5' />
      <rect x='10' y='18' width='12' height='1.5' />
    </>
  ),
  [EDITOR_TOOLS.underline]: (
    <>
      <line strokeWidth={3} x1='5' y1='5' x2='19' y2='5'></line>
      <line strokeWidth={3} x1='12' y1='5' x2='12' y2='16'></line>
      <line strokeWidth={3} x1='9' y1='16' x2='15' y2='16'></line>
      <line strokeWidth={2} x1='5' y1='21' x2='19' y2='21'></line>
    </>
  ),
  [EDITOR_TOOLS.strikethrough]: (
    <>
      <line strokeWidth={3} x1='5' y1='5' x2='19' y2='5'></line>

      <line strokeWidth={3} x1='12' y1='5' x2='12' y2='9'></line>
      <line strokeWidth={3} x1='12' y1='14' x2='12' y2='19'></line>

      <line strokeWidth={3} x1='9' y1='19' x2='15' y2='19'></line>

      <line strokeWidth={2} x1='2' y1='11.5' x2='22' y2='11.5'></line>
    </>
  )
}

export const EditorIcon = ({ icon, size }) => (
  <svg
    stroke='currentColor'
    fill='currentColor'
    strokeWidth='0'
    height={size}
    width={size}
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    {icons[icon]}
  </svg>
)
