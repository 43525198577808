import axios from 'axios'
import { ThrowReporter } from 'io-ts/lib/ThrowReporter'
import { fold, left } from 'fp-ts/lib/Either'
import { UserStore } from '../core/Store/User'
import { identity } from '@bonitour/common-functions'
import { AppStore } from 'core/Store/App'

/**
 * @param { { data: T } } response
 * @return T
 * @template T
 */
export const extractData = ({ data }) => data

export const xpertAxiosInstance = () => {
  const instance = axios.create({
    headers: {
      ...(UserStore?.authKey && { Authorization: UserStore?.authKey })
    },
    baseURL: process.env?.REACT_APP_HOST_XPERT_URI
  })

  instance.interceptors.response.use(identity, error => {
    if (error.response && error.response.status === 401) {
      if (
        error.response.data?.error?.errors_msg?.includes(
          'auth::authorization_key::unauthorized'
        )
      ) {
        AppStore.clean()
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    }
    return Promise.reject(error)
  })
  return instance
}

/**
 * @param { () => import('axios').AxiosInstance } makeAxiosInstance
 */
function makePatcher(makeAxiosInstance) {
  /**
   * @param { string } url
   * @param { any } data
   * @param { import('io-ts').Decoder<I, A> } decoder
   * @template I, A
   */
  function patch(url, data, decoder) {
    const axiosInstance = makeAxiosInstance()
    return axiosInstance
      .patch(url, data)
      .then(x => decoder.decode(x.data))
      .then(
        fold(
          errors => Promise.reject(ThrowReporter.report(left(errors))),
          value => Promise.resolve(value)
        )
      )
  }
  return patch
}

export const patchXpert = makePatcher(xpertAxiosInstance)

/**
 * @param { () => import('axios').AxiosInstance } makeAxiosInstance
 */
function makePutter(makeAxiosInstance) {
  /**
   * @param { string } url
   * @param { any } data
   * @param { import('io-ts').Decoder<I, A> } decoder
   * @template I, A
   */
  function patch(url, data, decoder) {
    const axiosInstance = makeAxiosInstance()
    return axiosInstance
      .put(url, data)
      .then(x => decoder.decode(x.data))
      .then(
        fold(
          errors => Promise.reject(ThrowReporter.report(left(errors))),
          value => Promise.resolve(value)
        )
      )
  }
  return patch
}

export const putXpert = makePutter(xpertAxiosInstance)

/**
 * @param { () => import('axios').AxiosInstance } makeAxiosInstance
 */
function makePoster(makeAxiosInstance) {
  /**
   * @param { string } url
   * @param { any } data
   * @param { import('io-ts').Decoder<I, A> } decoder
   * @template I, A
   */
  function post(url, data, decoder) {
    const axiosInstance = makeAxiosInstance()
    return axiosInstance
      .post(url, data)
      .then(x => decoder.decode(x.data))
      .then(
        fold(
          errors => Promise.reject(ThrowReporter.report(left(errors))),
          value => Promise.resolve(value)
        )
      )
  }
  return post
}

export const postXpert = makePoster(xpertAxiosInstance)

function makeGetter(makeAxiosInstance) {
  /**
   * @param { string } url
   * @param { Record<string, any> } params,
   * @param { import('io-ts').Decoder<I, A> } decoder
   * @template I, A
   */
  function get(url, params, decoder) {
    const axiosInstance = makeAxiosInstance()
    return axiosInstance
      .get(url, {
        params
      })
      .then(x => decoder.decode(x.data))
      .then(
        fold(
          errors => Promise.reject(ThrowReporter.report(left(errors))),
          value => Promise.resolve(value)
        )
      )
  }

  return get
}

export const getXpert = makeGetter(xpertAxiosInstance)

const { REACT_APP_SERVERLESS_API_ENDPOINT } = process.env
export const getFeatureFlags = () =>
  REACT_APP_SERVERLESS_API_ENDPOINT &&
  axios
    .get(`${REACT_APP_SERVERLESS_API_ENDPOINT}/feature-flags`)
    .then(extractData)
