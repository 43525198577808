/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  colors,
  HeaderPage,
  LoadingAnimation,
  useToast
} from '@bonitour/components'
import { css, keyframes, jsx } from '@emotion/react'
import { RegionCard } from './Components/RegionCard'
import { SearchBar } from './Components/SearchBar'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd'
import { RegionsHint } from './Components/RegionsHint'
// TODO: implement infinite scroll when pagination is ready in the back-end
// import { InfiniteScroll } from './Components/InfiniteScroll'
import { useMediaQuery } from 'Shared/hooks/useMediaQuery'
import {
  usePermission,
  useRequirePermission
} from 'Shared/contexts/Permissions'
import { useCustomRegions } from 'Shared/contexts/CustomRegions'
import { HeaderContainer } from './CustomRegionsPage.style'
import { reorderCustomRegion } from './io/customRegions.io'
import { NewRegionButton } from './NewRegionButton'
import { useHistory } from 'react-router-dom'
import { CARD_HEIGHT } from './Components/RegionCard.style'

const GAP = 16

const regionsGridStyle = ({ childrenNumber = 0, columnsNumber = 5 }) => css`
  height: ${Math.ceil(childrenNumber / columnsNumber) * (CARD_HEIGHT + GAP) +
  2 * GAP}px;
  position: relative;
  display: grid;
  gap: 16px 0;
  grid-template-rows: repeat(
    ${Math.ceil(childrenNumber / columnsNumber)},
    ${CARD_HEIGHT}px
  );
  grid-template-columns: repeat(${columnsNumber}, 1fr);
  user-select: none;

  .card-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${CARD_HEIGHT + GAP}px;
    box-sizing: border-box;

    padding: ${GAP + 4}px;
  }

  .card-position-indicatior {
    box-sizing: border-box;
    width: 100%;
    max-width: 220px;
    height: 174px;
    border: 3px dashed ${colors.gray8};
    border-radius: 16px;
  }

  .regions-dropzone {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    .grid-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const loadingOverlayFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const loadingOverlayStyles = css`
  z-index: 5;
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(247, 247, 247, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);

  opacity: 1;

  animation: ${loadingOverlayFadeIn} 250ms ease;
`

export function CustomRegionsPage() {
  const {
    customRegions,
    isLoading,
    handleSearchRegions,
    setIsLoading,
    setCustomRegions,
    searchFilter
  } = useCustomRegions()

  const { push } = useHistory()

  const { add: addToast } = useToast()

  useRequirePermission({
    permission: 'xpert_backoffice'
  })

  const [isHintModalOpen, setIsHintModalOpen] = useState(() => {
    const localStorageShowHintData = localStorage.getItem(
      '@xpert-dashboard/notShowRegionTip'
    )
    return !localStorageShowHintData
  })

  const [notShowAgain, setNotShowAgain] = useState(false)

  const { allowed: canReorder } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const onOrderChange = useCallback(
    async (sourceId, sourceIndex, targetIndex, targetId = sourceId) => {
      if (!canReorder) {
        return addToast(
          'Usuário sem permissão de escrita/atualização do Backoffice'
        )
      }
      if (sourceId !== 'regions' || targetId !== 'regions') return
      if (sourceIndex === targetIndex || targetIndex > customRegions.length - 1)
        return

      const result = swap(customRegions, sourceIndex, targetIndex)

      const oldRegionsOrder = customRegions

      const positionData = {
        previous: targetIndex <= 0 ? '' : result[targetIndex - 1],
        next: targetIndex >= result.length - 1 ? '' : result[targetIndex + 1]
      }
      if (!result[targetIndex]?.id) return

      setIsLoading(true)

      reorderCustomRegion({
        regionId: result[targetIndex].id,
        positionBefore: positionData.previous.position ?? '',
        positionAfter: positionData.next.position ?? ''
      })
        .then(res => {
          const updatedList = result.map(region => {
            if (region.id === res.id) {
              return { ...region, position: res.position }
            }
            return region
          })

          setCustomRegions(updatedList)
          addToast('Ordenação salva com sucesso', 'success')
        })
        .catch(err => {
          console.error(err)
          setCustomRegions(oldRegionsOrder)
          addToast('Erro ao ordenar regiões')
          setTimeout(function () {
            window.location.reload()
          }, 2000)
        })
        .finally(() => {
          setIsLoading(false)
        })

      setCustomRegions(result)
    },
    [addToast, customRegions, setIsLoading, setCustomRegions, canReorder]
  )

  const handleCloseHintModal = useCallback(() => {
    setIsHintModalOpen(false)
    if (notShowAgain) {
      localStorage.setItem('@xpert-dashboard/notShowRegionTip', 'true')
    }
  }, [notShowAgain])

  const fits5Rows = useMediaQuery(`(min-width: 1200px)`)
  const fits4Rows = useMediaQuery(`(min-width: 980px)`)
  const fits3Rows = useMediaQuery(`(min-width: 740px)`)
  const fits2Rows = useMediaQuery(`(min-width: 510px)`)
  const isTouchDevice = useMediaQuery(`(hover: none)`)

  const { allowed: canUpdate } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const columnsNumber = useMemo(
    () => (fits5Rows ? 5 : fits4Rows ? 4 : fits3Rows ? 3 : fits2Rows ? 2 : 1),
    [fits2Rows, fits3Rows, fits4Rows, fits5Rows]
  )

  const onNewRegionClick = useCallback(() => {
    return push('regions/new')
  }, [push])

  const goToDashboard = useCallback(() => {
    push('/dashboard')
  }, [push])

  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderPage onBack={goToDashboard} title={`Regiões`} />

        <div className='actions__container'>
          <NewRegionButton onClick={onNewRegionClick} />
          <SearchBar
            onDebounce={handleSearchRegions}
            currentValue={searchFilter}
            placeholder='Buscar uma região'
          />
        </div>
      </HeaderContainer>

      <div
        css={regionsGridStyle({
          childrenNumber: customRegions?.length || 0,
          columnsNumber
        })}
      >
        {customRegions.map(region => (
          <div key={`mock${region.id} `} className='card-block'>
            <div className='card-position-indicatior' />
          </div>
        ))}

        <GridContextProvider onChange={onOrderChange}>
          <GridDropZone
            className='regions-dropzone'
            id='regions'
            boxesPerRow={columnsNumber}
            rowHeight={CARD_HEIGHT + GAP}
            style={{ position: 'absolute' }}
            disableDrag={!canUpdate || isTouchDevice}
          >
            {customRegions.map(region => (
              <GridItem key={region.id} className='grid-item'>
                {(Wrapper, props, extra) => (
                  <Wrapper {...props}>
                    <RegionCard
                      key={region.id}
                      regionId={region.id}
                      title={region.name}
                      numberOfActivities={region.serviceCount}
                      isAgentVisible={region.agentVisible}
                      isUserVisible={region.userVisible}
                      isDragging={extra.dragging}
                    />
                  </Wrapper>
                )}
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>

        {isLoading ? (
          <div css={loadingOverlayStyles}>
            <LoadingAnimation />
          </div>
        ) : null}
        {/* TODO: implement infinite scroll when pagination is ready in the back-end */}
        {/* <InfiniteScroll {...{ loading: isLoading, nextPage }} /> */}
      </div>

      {isHintModalOpen
        ? createPortal(
            <RegionsHint
              onClose={handleCloseHintModal}
              setNotShowAgain={setNotShowAgain}
            />,
            document.querySelector('#modal-root')
          )
        : null}
    </React.Fragment>
  )
}
