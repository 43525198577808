import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import { classContains } from 'Shared/utils/utils.styles'

export const modalConfirm = css`
  span {
    color: ${colors.gray3};
  }
  ${classContains('flexCenteredSpaceBetweenRow')} {
    width: 100%;
    justify-content: flex-start;
  }
`

export const buttonOnClose = css`
  &${classContains('closeIcon')} {
    position: relative;
    top: -10px;
    right: -10px;
  }
`
