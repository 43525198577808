import React from 'react'
import { Spinner } from 'assets/Animations/Spinner'
import { ButtonDefault } from './styles'

/**
 * @typedef {{
 *  isLoading?: boolean
 *  loadingText?: string
 *  outlined?: boolean
 * }} ButtonProps
 */

/** @type { React.FC<ButtonProps & import('react').ButtonHTMLAttributes<HTMLButtonElement>> } */
export const Button = ({
  disabled = false,
  isLoading = false,
  loadingText = '',
  outlined = false,
  className = '',
  children,
  ...rest
}) => {
  return (
    // @ts-ignore
    <ButtonDefault
      disabled={disabled || isLoading}
      isLoading={isLoading}
      hasLoadingText={!!loadingText}
      className={`${className} ${outlined ? 'button__outline' : ''}`}
      type='button'
      {...rest}
    >
      <div className='button__spinner_wrapper'>
        <Spinner size='1.5em' />
      </div>

      <span className='button__text_wrapper'>
        {isLoading ? loadingText || '' : children}
      </span>
    </ButtonDefault>
  )
}

export default Button
