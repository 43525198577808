/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { EditInfoIcon } from '@bonitour/components'
import { NewActivityButton } from './NewCustomRegionActivity.style'
import { useMemo } from 'react'

/** @jsxFrag React.Fragment */
export const NewCustomRegionActivity = ({
  onClick,
  serviceType,
  className = ''
}) => {
  const addNewExperienceLabel = useMemo(() => {
    const labelOptions = {
      'Experiences::Activity': 'Editar lista',
      'Experiences::Transport': 'Editar lista'
    }
    return labelOptions[serviceType]
  }, [serviceType])

  return (
    <NewActivityButton onClick={onClick} className={className}>
      <EditInfoIcon className='icon' /> {addNewExperienceLabel}
    </NewActivityButton>
  )
}
