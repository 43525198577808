import anyBase from 'any-base'

const FLICKR_BASE_58 =
  '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ'
const reversedAlphabet = FLICKR_BASE_58.split('').reverse().join('')

const FLICKR_BASE_58_REGEX = /^[1-9A-HJ-NP-Za-km-z]{16,17}$/
const MONGO_ID_REGEX = /^[0-9A-Fa-f]{24}$/

/** @type {(s: string) => string} */
// @ts-ignore
export const encodeToShortId = anyBase(anyBase.HEX, reversedAlphabet)
/** @type {(s: string) => string} */
// @ts-ignore
export const encodeToLongId = anyBase(reversedAlphabet, anyBase.HEX)

/**
 * @param {string} id
 * @returns boolean
 */
export const isLongIdValid = id => {
  if (typeof id !== 'string') return false
  return MONGO_ID_REGEX.test(id)
}

/**
 * @param {string} id
 * @returns boolean
 */
export const isShortIdValid = id => {
  return FLICKR_BASE_58_REGEX.test(id)
}
