export const PermissionsMapParser = {
  get: ({ permissions_map }) =>
    Object.fromEntries(
      Object.entries(permissions_map).map(([key, { C, R, U, D }]) => [
        key,
        { create: C, read: R, update: U, delete: D }
      ])
    )
}

export const PermissionsMacrosParser = {
  get: ({ macro_permissions }) => macro_permissions
}
