import { css } from '@emotion/react'
import { colors } from '@bonitour/components'

export const wrapper = css`
  border: 1px solid ${colors.gray10};
  width: 100%;
  min-height: 465px;
  background-color: ${colors.gray15};
`

export const browserBarStyle = css`
  top: 191px;
  left: 507px;
  height: 27px;
  border-bottom: 1px solid ${colors.gray10};
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const domainBrowserBar = css`
  color: ${colors.gray5};
  font: normal normal 600 10px/18px Mulish;
  vertical-align: center;
  padding: 5px;
`

export const faviconBrowserBar = css`
  padding-left: 5px;
  width: 16px;
  height: 16px;
  vertical-align: center;
`

export const containerCircles = css`
  margin-left: 23px;
  width: 50px;
  padding: 6px 11px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-right: 1px solid ${colors.gray13};
`

export const header = css`
  width: 100%;
  height: 60px;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
`

export const circleStyle = css`
  width: 11px;
  height: 11px;
  border: 1px solid;
  background-color: ${colors.white};
  border-color: ${colors.gray8};
  border-radius: 25px;
`

export const logoWrapper = css`
  width: auto;
  max-width: 100%;
  height: 100%;
  position: relative;
`

export const logoImg = css`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
  max-width: 100%;
`

export const loginWrapper = css`
  display: flex;
`

export const login = css`
  position: relative;
  height: 20px;
  width: 20px;
  border: 2px solid ${colors.white};
  border-radius: var(--round-element-radius, 20px);
  top: 50%;
  transform: translateY(-50%);
  margin-right: 5px;
`

export const loginText = css`
  &,
  &::after {
    display: block;
    position: relative;
    height: 5px;
    width: 45px;
    background-color: ${colors.white};
    border-radius: var(--round-element-radius, 20px);
    top: calc(50% - 5px);
    transform: translateY(-50%);
  }
  &::after {
    content: '';
    top: 11px;
    height: 6px;
    width: 35px;
  }
`

export const searchBar = css`
  position: relative;
  width: 100%;
  height: 35px;
  padding: 0 23px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 20px;
    right: 20px;
    top: 1px;
    bottom: 10px;
    background-color: ${colors.white};
    border-radius: 40px;
  }
`

export const searchBarText = css`
  display: block;
  position: relative;
  height: 7px;
  width: 100px;
  margin: 0 10px;
  background-color: ${colors.gray7};
  border-radius: var(--round-element-radius, 20px);
  top: 50%;
  transform: translateY(-50%);
`

export const searchBarCalendar = css`
  display: flex;
  > div {
    width: 70px;
  }
`

export const searchBarButton = css`
  display: block;
  position: relative;
  height: 18px;
  width: 90px;
  background-color: ${colors.gray7};
  border-radius: var(--round-element-radius, 20px);
  top: 50%;
  transform: translateY(-50%);
`

export const contentArea = css`
  padding: 20px;
  @media (min-width: 900px) {
    display: flex;
  }
`

export const leftArea = css`
  width: 100%;
  padding: 10px 0;
  position: relative;
  @media (min-width: 900px) {
    width: 66.66%;
  }
`

export const leftHeading = css`
  background-color: ${colors.gray12};
  width: calc(100% - 20px);
  padding: 16px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-sizing: border-box;
`

export const leftTitle = css`
  background-color: ${colors.gray8};
  border-radius: var(--round-element-radius, 20px);
  height: 18px;
  width: 340px;
  max-width: 100%;
`

export const textArea = css`
  display: flex;
  flex-wrap: wrap;
`

export const text = primaryColor => css`
  margin-bottom: 30px;
  margin-top: 28px;
  position: relative;
  margin-right: 20px;
  width: calc(33.33% - 20px);

  &,
  &::before {
    background-color: ${colors.gray8};
    border-radius: var(--round-element-radius, 20px);
    height: 18px;
    display: block;
  }

  &::before {
    border: 1px solid;
    border-color: ${primaryColor};
    background-color: ${colors.gray15};
    width: 50%;
    content: '\\00a0';
    position: absolute;
    margin-top: -28px;
  }
`

export const primaryButton = css`
  width: 126px;
  height: 35px;
  border-radius: var(--round-element-radius, 20px);
`

export const rightArea = css`
  width: 100%;
  margin: 10px 0;
  position: relative;
  border-radius: 10px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray11};
  overflow: hidden;
  @media (min-width: 900px) {
    width: 33.33%;
  }
`

export const rightHeading = css`
  background-color: ${colors.gray5};
  width: 100% - 20px;
  padding: 11px 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`

export const rightTitle = css`
  background-color: ${colors.white};
  border-radius: var(--round-element-radius, 20px);
  height: 18px;
  width: 153px;
  max-width: 100%;
`

export const scrollArea = css`
  margin: 0 20px;
  padding-right: 20px;
  position: relative;
  border-bottom: 1px solid ${colors.gray8};
  height: 183px;
  margin-bottom: 20px;

  &::before,
  &::after {
    content: '\\00a0';
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 100%;
    background-color: ${colors.gray14};
  }

  &::after {
    height: 58%;
    background-color: ${colors.gray9};
    border-radius: 5px;
  }
`

export const card = css`
  background-color: ${colors.gray8};
  border-radius: 10px;
  height: 74px;
  width: auto;
  margin-bottom: 20px;
`

export const rightButton = css`
  background-color: ${colors.gray3};
  border-radius: 10px;
  height: 18px;
  width: auto;
  margin: 0 20px 20px;
`
