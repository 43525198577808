import { HeaderPage, LoadingAnimation } from '@bonitour/components'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { TitleTabs } from './TitleTabs'

export const SiteConfigContainer = ({
  tabs,
  setCurrentTab,
  currentTab,
  children,
  isLoading = true
}) => {
  const { push } = useHistory()
  const goToDashboard = useCallback(() => {
    push('/app')
  }, [push])

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Configurações do Meu Site' />
      {isLoading ? (
        <LoadingAnimation style={{ width: '100%', height: '300px' }} />
      ) : (
        <>
          <TitleTabs
            tabs={tabs}
            onTabSelect={setCurrentTab}
            currentTab={currentTab}
          />
          {children}
        </>
      )}
    </>
  )
}
