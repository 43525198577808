/**
 * @param {string} json
 * @return {boolean}
 */
export const isValidJson = json => {
  try {
    JSON.parse(json)
    return true
  } catch (e) {
    return false
  }
}
