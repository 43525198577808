import styled from '@emotion/styled'

export const FiltersBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  &:only-of-type {
    margin: 0 0 0 auto;
  }
`
