import React, { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { Table } from './Table'
import { TableRow } from './Row'
import { CellContainer, TableContainer, submitButton } from './styles'
import {
  Button,
  CheckIcon,
  Checkbox,
  FloatingSelectionBar
} from '@bonitour/components'

const CheckboxCell = ({
  onChange,
  label,
  checked,
  className = '',
  disabled = false
}) => {
  return (
    <CellContainer className={className}>
      <Checkbox
        disabled={disabled}
        className='checkbox'
        onChange={onChange}
        checked={checked}
      />
      {label}
    </CellContainer>
  )
}
CheckboxCell.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string
}

export const SelectableTable = ({
  onSubmit,
  labels,
  data,
  emptyMessage = 'Nenhum item encontrado',
  columnsWidths = [],
  customCss = [],
  sortedColumn = '',
  isLoading = false,
  isSubmitting = false,
  alreadyLinkedIds = []
}) => {
  const [selectedIds, setSelectedIds] = useState(alreadyLinkedIds)

  useEffect(() => {
    setSelectedIds(currentSelectedIds => [
      ...currentSelectedIds,
      ...alreadyLinkedIds
    ])
  }, [alreadyLinkedIds])

  const allSelected = useMemo(
    () => !data.some(({ id }) => !selectedIds.includes(id)),
    [data, selectedIds]
  )

  const onSelectAll = useCallback(
    ev => {
      if (allSelected) {
        return setSelectedIds(selected =>
          selected.filter(id => !data.some(({ id: itemId }) => itemId === id))
        )
      } else {
        return setSelectedIds(selected =>
          Array.from(new Set([...selected, ...data.map(({ id }) => id)]))
        )
      }
    },
    [allSelected, data]
  )

  const labelsWithCheckbox = useMemo(
    () =>
      labels.map((label, idx) =>
        idx === 0 ? (
          <CheckboxCell
            className='checkbox_header__container'
            onChange={onSelectAll}
            label={label}
            disabled={!data.length || isLoading}
            checked={allSelected}
            key={idx}
          />
        ) : (
          label
        )
      ),
    [allSelected, data.length, isLoading, labels, onSelectAll]
  )

  const handleOnSelectRow = useCallback(
    itemId => {
      if (selectedIds.includes(itemId)) {
        return setSelectedIds(selectedIds.filter(id => id !== itemId))
      } else {
        return setSelectedIds([...selectedIds, itemId])
      }
    },
    [selectedIds]
  )

  const handleOnSubmit = useCallback(() => {
    onSubmit(selectedIds)
  }, [onSubmit, selectedIds])

  return (
    <>
      <Table
        isLoading={isLoading}
        labels={labelsWithCheckbox}
        customCss={[TableContainer, ...customCss]}
        sortedColumn={sortedColumn}
        columnsWidths={columnsWidths}
        emptyMessage={emptyMessage}
      >
        {data.map(({ id, ...rowItems }, idx) => {
          const rowValues = Object.values(rowItems)
          const firstCellLabel = rowValues.splice(0, 1)[0]

          return (
            <TableRow
              className={
                selectedIds.includes(id) || allSelected
                  ? 'table_row__selected'
                  : ''
              }
              onClick={() => handleOnSelectRow(id)}
              key={idx}
            >
              {[
                <CheckboxCell
                  key={idx}
                  onChange={() => handleOnSelectRow(id)}
                  checked={selectedIds.includes(id) || allSelected}
                  label={firstCellLabel}
                />,
                ...rowValues
              ]}
            </TableRow>
          )
        })}
      </Table>
      <FloatingSelectionBar selected={selectedIds}>
        <Button
          onClick={handleOnSubmit}
          css={submitButton}
          disabled={isLoading || isSubmitting}
        >
          Salvar alterações <CheckIcon />
        </Button>
      </FloatingSelectionBar>
    </>
  )
}

SelectableTable.propTypes = {
  labels: PropTypes.any,
  data: PropTypes.any.isRequired,
  columnsWidths: PropTypes.arrayOf(PropTypes.string),
  customCss: PropTypes.arrayOf(PropTypes.string),
  sortedColumn: PropTypes.string
}
