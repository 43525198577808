import { getXpert } from 'external/axios'
import * as t from 'io-ts'

export const UNRESERVED = 'unreserved'

const optional = type => t.union([type, t.null, t.undefined])

export const PriceDetailsType = optional(
  t.array(
    t.type({
      count: optional(t.number),
      unit_price: optional(t.string),
      price: optional(t.string),
      type: optional(t.string),
      description: optional(
        t.strict({
          min_age: optional(t.union([t.string, t.number])),
          max_age: optional(t.union([t.string, t.number]))
        })
      )
    })
  )
)

const regionType = t.strict({
  id: optional(t.string),
  name: optional(t.string)
})

const tourType = t.array(
  optional(
    t.type({
      id: optional(t.string),
      date: optional(t.string),
      price: optional(t.string),
      prices: optional(
        t.array(
          t.strict({
            name: t.string,
            price: t.string,
            min_age: t.number,
            max_age: t.number
          })
        )
      ),
      time: optional(t.string),
      adults: optional(t.number),
      children_ages: optional(t.array(t.number)),
      seniors: optional(t.number),
      activity: optional(
        t.strict({
          title: optional(
            t.strict({
              'pt-br': optional(t.string),
              'en-us': optional(t.string),
              'es-es': optional(t.string)
            })
          ),
          company: optional(
            t.strict({
              name: optional(t.string)
            })
          ),
          category: optional(t.string)
        })
      )
    })
  )
)

const quotationType = t.type({
  id: t.string,
  domain: optional(t.string),
  reservation: t.type({
    reservation_code: optional(t.string)
  }),
  status: optional(t.string),
  region: optional(regionType),
  custom_regions: optional(t.array(regionType)),
  tours: t.record(t.string, optional(tourType)),
  quotation: t.type({
    code: optional(t.string),
    agent_id: t.string,
    agent_name: t.string,
    client_name: t.string,
    client_email: optional(t.string),
    client_phone: optional(t.string),
    created_at: optional(t.string),
    send_date: optional(t.string),
    opened_at: optional(t.string)
  })
})

const quotationsType = t.strict({
  quotations: t.array(quotationType),
  meta: t.strict({
    total_entries: t.number,
    current_page: t.number,
    total_pages: t.number
  })
})

export async function getQuotations(queryParams) {
  return await getXpert(
    'itineraries/quotations',
    queryParams,
    quotationsType
  ).then(res => res)
}

const QuotationsTotalizerType = t.type({
  total: t.number,
  confirmed: t.number,
  reserved: t.number,
  unreserved: t.number,
  canceled: t.number,
  opened: t.number,
  expired: t.number
})

export async function getQuotationsTotalizer(queryParams) {
  return await getXpert(
    'v2/itineraries/quotations/status/count',
    queryParams,
    QuotationsTotalizerType
  ).then(res => res)
}
