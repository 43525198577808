import { AlgorithmSetup } from 'Domains/Dashboard/AlgorithmSetup/AlgorithmSetup'
import Appearance from 'Domains/Dashboard/Appearance/Appearance'
import { PurchasingPolicy } from 'Domains/Dashboard/Documents/PurchasingPolicy'
import { QuotationPolicy } from 'Domains/Dashboard/Documents/QuotationPolicity'
import { Experiences as CustomRegionsExperiences } from 'Domains/Dashboard/CustomRegionsExperiences/Experiences'
import Home from 'Domains/Dashboard/Home'
import { Layout } from 'Domains/Layout/Layout'
import { QuotationsPage } from 'Domains/Dashboard/Quotations/QuotationsPage'
import { CustomTextPage } from 'Domains/Dashboard/CustomText/CustomTextPage'
import { AbandonedsCartsPage } from 'Domains/Dashboard/AbandonedCarts/AbandonedCartsPage'
import { TableMock } from 'Domains/Dashboard/TestComponents/TableMock'
import { SelectExperiencesMock } from 'Domains/Dashboard/TestComponents/SelectExperiencesMock'
import { EditCustomRegionPage } from 'Domains/Dashboard/CustomRegionsExperiences/CustomRegions/CustomRegionPage/EditCustomRegionPage'
import { ExperiencesToBindPage } from 'Domains/Dashboard/CustomRegionsExperiences/CustomRegions/ExperiencesToBindPage/ExperiencesToBindPage'
import { NewCustomRegionPage } from 'Domains/Dashboard/CustomRegionsExperiences/CustomRegions/CustomRegionPage/NewCustomRegionPage'
import { CustomRegionsPage } from 'Domains/Dashboard/CustomRegionsExperiences/CustomRegionsPage'
import { ExperienceConfigContainer } from 'Domains/Dashboard/CustomRegionsExperiences/ExperienceConfigContainer'

/**
 * @param {string} path
 * @param { React.FunctionComponent } component
 */
function exactPath(path, component) {
  return {
    exact: true,
    path,
    component
  }
}

export const privateRoutes = [
  {
    path: '/app',
    component: Layout,
    routes: [
      exactPath('/', Home),
      exactPath('/appearance', Appearance),
      exactPath('/algorithm-setup', AlgorithmSetup),
      exactPath('/documents/purchasing-policy', PurchasingPolicy),
      exactPath('/documents/quotation-policy', QuotationPolicy),
      exactPath('/quotations', QuotationsPage),
      exactPath('/custom-text', CustomTextPage),
      exactPath('/carts-abandoneds', AbandonedsCartsPage),
      exactPath('/test-components/table', TableMock),
      exactPath('/test-components/select-experiences', SelectExperiencesMock),

      // IT WILL REPLACE CURRENT EXPERIENCES PAGE
      exactPath('/regions', CustomRegionsPage),
      exactPath('/regions/new', NewCustomRegionPage),
      exactPath('/regions/:regionId/edit', EditCustomRegionPage),

      exactPath(
        '/regions/:regionId/:regionName/experiences/to-bind',
        ExperiencesToBindPage
      ),
      exactPath(
        '/regions/:regionId/:regionName/experiences/:id',
        ExperienceConfigContainer
      ),
      exactPath('/regions/:regionId/:regionName', CustomRegionsExperiences)
    ]
  },
  {
    redirect: true,
    path: '/app'
  }
]
