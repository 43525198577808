/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import {
  Card
  /* TODO */
  /*   Button, */
} from '@bonitour/components'
import { CardCustomText } from './CardCustomText'
import { jsx } from '@emotion/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRequirePermission } from 'Shared/contexts/Permissions'

export const CustomText = ({
  currentTab,
  data,
  onUpdateData,
  setHasEdit,
  shouldSave = false
}) => {
  const [editedFields, setEditedFields] = useState([])
  useRequirePermission({
    permission: 'xpert_backoffice'
  })

  useEffect(() => {
    setHasEdit(Boolean(editedFields.length))
  }, [editedFields, setHasEdit])

  const setEditedField = useCallback(
    field => value => {
      if (
        (value && editedFields.includes(field)) ||
        (!value && !editedFields.includes(field))
      ) {
        return value
      }
      const editedFieldsAsSet = new Set(editedFields)
      if (value) {
        editedFieldsAsSet.add(field)
      } else {
        editedFieldsAsSet.delete(field)
      }
      setEditedFields(Array.from(editedFieldsAsSet))
      return value
    },
    [editedFields]
  )

  const defaultMessages = useMemo(() => {
    // IMPORTANT:
    // Messages must be the same as the default custom text on jaiminho (src/utils/i18n/dict/**_**.ts)
    return {
      trip_reminder: {
        'pt-br':
          'Olá <<NAME>>, ótimas notícias:\nEstá quase na hora da sua viagem para <<TOURIST_REGION>>!\nO código da sua reserva é: <<RESERVATION_CODE>>\n\nAqui estão os horários de todas as suas atividades:',
        'en-us':
          "Hello <<NAME>>, great news:\nIt's almost time for your trip to <<TOURIST_REGION>>!\nYour reservation code is: <<RESERVATION_CODE>>\n\nHere are the times of all your activities:",
        'es-es':
          'Hola <<NAME>>, buenas noticias:\n¡Ya casi es hora de tu viaje a <<TOURIST_REGION>>!\nTu código de reserva es: <<RESERVATION_CODE>>\n\nAquí están los horarios de todas tus actividades:'
      },
      abandoned_cart: {
        'pt-br':
          'Olá <<NAME>>, parece que você iniciou a compra de atividades do seu roteiro para <<TOURIST_REGION>> mas não a concluiu.\nPara fazer isto, é só clicar no botão abaixo:',
        'en-us':
          'Hello <<NAME>>, it looks like you started purchasing activities from your roadmap to <<TOURIST_REGION>>, but did not completed it.\nTo do that, just click on the button below:',
        'es-es':
          'Hola, <<NAME>>, parece que comenzaste a comprar actividades de tu hoja de ruta a <<TOURIST_REGION>>, pero no las completaste.\nPara hacerlo, simplemente haz clic en el botón a continuación:'
      },
      share_itinerary: {
        'pt-br':
          'Olá <<NAME>>, Este é o roteiro com as atividades selecionadas!\nPara dar continuidade na compra, é só clicar no botão abaixo.',
        'en-us':
          'Hello <<NAME>>, This is the itinerary with the selected activities!\nTo proceed with your purchase, just click the button below.',
        'es-es':
          'Hola <<NAME>>, ¡Este es el itinerario con las actividades seleccionadas!\nPara continuar con su compra, simplemente haga clic en el botón a continuación.'
      },
      share_quotation: {
        'pt-br':
          'Olá <<CLIENT_NAME>>.\nEstas são as suas atividades desta próxima aventura em <<TOURIST_REGION>>!\nEnviado por: <<AGENT_NAME>>.\n\nPara dar continuidade na compra, é só clicar no botão abaixo:',
        'en-us':
          'Hello <<CLIENT_NAME>>.\nThese are your activities for this next adventure in <<TOURIST_REGION>>!\nSent by: <<AGENT_NAME>>.\n\nTo proceed with the purchase, just click the button below:',
        'es-es':
          'Hola <<CLIENT_NAME>>.\n¡Estas son tus actividades para esta próxima aventura en <<TOURIST_REGION>>!\nEnviado por: <<AGENT_NAME>>.\n\nPara continuar con la compra, solo haz clic en el botón a continuación:'
      },
      payment_confirm: {
        'pt-br':
          'Olá <<NAME>>, sua reserva está confirmada e agora está tudo pronto para a sua viagem :)\nCódigo da reserva: <<RESERVATION_CODE>>\n\nConfira os seus horários agendados e as informações de cada uma de suas atividades:',
        'en-us':
          'Hello <<NAME>>, your reservation is confirmed and now everything is ready for your trip :)\nReservation Code: <<RESERVATION_CODE>>\n\nCheck your schedule and the information about each of your activities:',
        'es-es':
          'Hola <<NAME>>, tu reserva está confirmada y ahora todo está listo para tu viaje :)\nCódigo de reserva: <<RESERVATION_CODE>>\n\nConsulta tu horario y la información de cada una de tus actividades:'
      }
    }
  }, [])

  const initialCustomMessagesState = useMemo(
    () => ({
      trip_reminder: {
        type: 'trip_reminder',
        content: {},
        isPatch: false
      },
      abandoned_cart: {
        type: 'abandoned_cart',
        content: {},
        isPatch: false
      },
      share_itinerary: {
        type: 'share_itinerary',
        content: {},
        isPatch: false
      },
      share_quotation: {
        type: 'share_quotation',
        content: {},
        isPatch: false
      },
      payment_confirm: {
        type: 'payment_confirm',
        content: {},
        isPatch: false
      }
    }),
    []
  )

  const [customMessages, setCustomMessages] = useState(
    initialCustomMessagesState
  )

  const [tripReminder, setTripReminder] = useState({
    type: 'trip_reminder',
    content: {},
    isPatch: false
  })
  const [abandonedCart, setAbandonedCart] = useState({
    type: 'abandoned_cart',
    content: {},
    isPatch: false
  })
  const [shareItinerary, setShareItinerary] = useState({
    type: 'share_itinerary',
    content: {},
    isPatch: false
  })
  const [shareQuotation, setShareQuotation] = useState({
    type: 'share_quotation',
    content: {},
    isPatch: false
  })
  const [paymentConfirm, setPaymentConfirm] = useState({
    type: 'payment_confirm',
    content: {},
    isPatch: false
  })

  const setSavedMessages = useCallback(
    data => {
      const newCustomMessages = data.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            type: item.type,
            content: item.content,
            isPatch: true
          }
        }),
        initialCustomMessagesState
      )
      if (
        JSON.stringify(newCustomMessages) !== JSON.stringify(customMessages)
      ) {
        setCustomMessages(newCustomMessages)
      }
      customMessages.trip_reminder?.content
        ? setTripReminder(customMessages.trip_reminder)
        : setTripReminder({
            type: 'trip_reminder',
            content: {
              [currentTab]: defaultMessages.trip_reminder[currentTab]
            },
            isPatch: !!customMessages.trip_reminder?.content
          })
      customMessages.abandoned_cart?.content
        ? setAbandonedCart(customMessages.abandoned_cart)
        : setAbandonedCart({
            type: 'abandoned_cart',
            content: {
              [currentTab]: defaultMessages.abandoned_cart[currentTab]
            },
            isPatch: !!customMessages.abandoned_cart?.content
          })
      customMessages.share_itinerary?.content
        ? setShareItinerary(customMessages.share_itinerary)
        : setShareItinerary({
            type: 'share_itinerary',
            content: {
              [currentTab]: defaultMessages.share_itinerary[currentTab]
            },
            isPatch: !!customMessages.share_itinerary?.content
          })
      customMessages.share_quotation?.content
        ? setShareQuotation(customMessages.share_quotation)
        : setShareQuotation({
            type: 'share_quotation',
            content: {
              [currentTab]: defaultMessages.share_quotation[currentTab]
            },
            isPatch: !!customMessages.share_quotation?.content
          })
      customMessages.payment_confirm?.content
        ? setPaymentConfirm(customMessages.payment_confirm)
        : setPaymentConfirm({
            type: 'payment_confirm',
            content: {
              [currentTab]: defaultMessages.payment_confirm[currentTab]
            },
            isPatch: !!customMessages.payment_confirm?.content
          })
    },
    [
      currentTab,
      customMessages,
      defaultMessages.abandoned_cart,
      defaultMessages.payment_confirm,
      defaultMessages.share_itinerary,
      defaultMessages.share_quotation,
      defaultMessages.trip_reminder,
      initialCustomMessagesState
    ]
  )

  useEffect(() => {
    setSavedMessages(data)
  }, [
    customMessages,
    defaultMessages.abandoned_cart,
    defaultMessages.payment_confirm,
    defaultMessages.share_itinerary,
    defaultMessages.share_quotation,
    defaultMessages.trip_reminder,
    data,
    setSavedMessages
  ])

  const updateValue = useCallback(
    type => value => {
      onUpdateData()
      switch (type) {
        case 'trip_reminder':
          setTripReminder(value)
          break
        case 'abandoned_cart':
          setAbandonedCart(value)
          break
        case 'share_itinerary':
          setShareItinerary(value)
          break
        case 'share_quotation':
          setShareQuotation(value)
          break
        case 'payment_confirm':
          setPaymentConfirm(value)
          break
        default:
      }
    },
    [onUpdateData]
  )

  const cardTitles = useMemo(() => {
    return {
      abandoned_cart: {
        'pt-br': 'Carrinho abandonado',
        'es-es': 'Carrito abandonado',
        'en-us': 'Abandoned Cart'
      },
      trip_reminder: {
        'pt-br': 'Lembrete de viagem',
        'es-es': 'Recordatorio de viaje',
        'en-us': 'Trip reminder'
      },
      share_itinerary: {
        'pt-br': 'Compartilhar roteiro',
        'es-es': 'Compartir guión',
        'en-us': 'Share itinerary'
      },
      share_quotation: {
        'pt-br': 'Compartilhar cotação',
        'en-us': 'Share quotation',
        'es-es': 'Compartir cotización'
      },
      payment_confirm: {
        'pt-br': 'Confirmação de compra',
        'en-us': 'Purchase confirmation',
        'es-es': 'Confirmación de compra'
      }
    }
  }, [])

  const scheduleMessages = useMemo(() => {
    return {
      abandoned_cart: {
        'pt-br': {
          firstMessage:
            'Enviar e-mail de lembrete do carrinho abandonado depois de',
          secondMessage: 'do carrinho ter sido criado.'
        },
        'es-es': {
          firstMessage:
            'Enviar un correo electrónico de recordatorio de carrito abandonado después de',
          secondMessage: 'del carro ha sido creado.'
        },
        'en-us': {
          firstMessage: 'Send abandoned cart reminder email after',
          secondMessage: 'the cart has been create.'
        }
      },
      trip_reminder: {
        'pt-br': {
          firstMessage: 'Enviar e-mail de lembrete',
          secondMessage: 'antes da viagem.'
        },
        'es-es': {
          firstMessage: 'Enviar correo electrónico de recordatorio',
          secondMessage: 'antes del viaje.'
        },
        'en-us': {
          firstMessage: 'Send reminder email',
          secondMessage: 'before the trip.'
        }
      }
    }
  }, [])

  return (
    <Card>
      <CardCustomText
        title={cardTitles.abandoned_cart[currentTab]}
        value={abandonedCart}
        lang={currentTab}
        defaultMessage={defaultMessages.abandoned_cart[currentTab]}
        scheduleMessage={scheduleMessages.abandoned_cart[currentTab]}
        daysInterval={1}
        customTags={[
          { value: '<<NAME>>', label: 'Nome' },
          { value: '<<TOURIST_REGION>>', label: 'Região Turística' }
        ]}
        // @ts-ignore
        onValueChange={updateValue('abandoned_cart')}
        setHasEdit={setEditedField('abandoned_cart')}
        shouldSave={shouldSave}
      />
      <CardCustomText
        title={cardTitles.trip_reminder[currentTab]}
        value={tripReminder}
        lang={currentTab}
        defaultMessage={defaultMessages.trip_reminder[currentTab]}
        scheduleMessage={scheduleMessages.trip_reminder[currentTab]}
        daysInterval={7}
        customTags={[
          { value: '<<NAME>>', label: 'Nome' },
          { value: '<<TOURIST_REGION>>', label: 'Região Turística' },
          { value: '<<RESERVATION_CODE>>', label: 'Código da Reserva' }
        ]}
        // @ts-ignore
        onValueChange={updateValue('trip_reminder')}
        setHasEdit={setEditedField('trip_reminder')}
        shouldSave={shouldSave}
      />
      <CardCustomText
        title={cardTitles.share_itinerary[currentTab]}
        value={shareItinerary}
        lang={currentTab}
        defaultMessage={defaultMessages.share_itinerary[currentTab]}
        isScheduled={false}
        daysInterval={2}
        customTags={[{ value: '<<NAME>>', label: 'Nome' }]}
        // @ts-ignore
        onValueChange={updateValue('share_itinerary')}
        setHasEdit={setEditedField('share_itinerary')}
        shouldSave={shouldSave}
      />
      <CardCustomText
        title={cardTitles.share_quotation[currentTab]}
        value={shareQuotation}
        lang={currentTab}
        defaultMessage={defaultMessages.share_quotation[currentTab]}
        isScheduled={false}
        daysInterval={2}
        customTags={[
          { value: '<<CLIENT_NAME>>', label: 'Nome do Viajante' },
          { value: '<<AGENT_NAME>>', label: 'Nome do Agente' },
          { value: '<<TOURIST_REGION>>', label: 'Região Turística' }
        ]}
        // @ts-ignore
        onValueChange={updateValue('share_quotation')}
        setHasEdit={setEditedField('share_quotation')}
        shouldSave={shouldSave}
      />
      <CardCustomText
        title={cardTitles.payment_confirm[currentTab]}
        value={paymentConfirm}
        lang={currentTab}
        defaultMessage={defaultMessages.payment_confirm[currentTab]}
        isScheduled={false}
        daysInterval={2}
        customTags={[
          { value: '<<NAME>>', label: 'Nome' },
          { value: '<<RESERVATION_CODE>>', label: 'Código da Reserva' }
        ]}
        // @ts-ignore
        onValueChange={updateValue('payment_confirm')}
        setHasEdit={setEditedField('payment_confirm')}
        shouldSave={shouldSave}
      />
    </Card>
  )
}
