/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { useHistory } from 'react-router-dom'
import { ExperiencesTableSelector } from 'Domains/Dashboard/ExperiencesTableSelector/ExperiencesTableSelector'
import { useCallback, useEffect, useRef, useState } from 'react'
import { updateCustomRegionsLinkedServices } from '../../io/customRegions.io'
import { HeaderPage, PaginationContainer, useToast } from '@bonitour/components'
import { useParams } from 'react-router-dom'
import { listRegionCustomServices } from '../../io/activities.io'
import { useLinkedCustomServices } from 'utilityHooks/useLinkedCustomServices'
import { CustomRegionExperiencesFilter } from './CustomRegionExperiencesFilter'
import { headerRow } from './CustomRegionExperiencesFilter.style'

const paginationOptions = [25, 50, 100, 200].map(value => ({
  value,
  label: String(value)
}))

export const ExperiencesToBindPage = () => {
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [experiences, setExperiences] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 25,
    totalEntries: 0,
    totalPages: 0
  })
  const { add: addToast } = useToast()
  // @ts-ignore
  const { regionId, regionName } = useParams()

  const { servicesIds: linkedServicesIds } = useLinkedCustomServices({
    customRegionId: regionId
  })

  const lastFetchParams = useRef('')
  const fetchExperiences = useCallback(() => {
    const fetchParams = JSON.stringify({
      filters,
      pagination: {
        page: pagination.page,
        perPage: pagination.perPage
      }
    })
    if (lastFetchParams.current === fetchParams) return
    setIsLoading(true)
    lastFetchParams.current = fetchParams
    listRegionCustomServices({
      ...filters,
      ...pagination,
      customRegionId: regionId
    })
      .then(({ services, meta }) => {
        if (lastFetchParams.current !== fetchParams) return
        setExperiences(
          services.map(service => ({
            id: service.id,
            name: service.title['pt-br'],
            provider: service.company.name,
            regionsName: service.custom_regions
              ?.map(region => region.name)
              .join(', '),
            serviceType: service.type
          }))
        )

        setPagination(currentPagination => ({
          ...currentPagination,
          totalEntries: meta.total_entries,
          totalPages: meta.total_pages
        }))
      })
      .catch(err => {
        console.error(err)
        addToast('Houve um erro ao buscar as experiências', 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [addToast, filters, pagination, regionId])

  const onChangeFilters = useCallback(newFilters => {
    setPagination(currentPagination => ({
      ...currentPagination,
      page: 1
    }))
    setFilters(newFilters)
  }, [])

  const onPagination = useCallback((page, perPage) => {
    setPagination(currentPagination => ({
      ...currentPagination,
      page,
      perPage
    }))
  }, [])

  const { push } = useHistory()

  const goBackToRegion = useCallback(() => {
    push(`/app/regions/${regionId}/${regionName}`)
  }, [push, regionId, regionName])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const onLinkExperiences = useCallback(
    servicesIds => {
      setIsSubmitting(true)
      updateCustomRegionsLinkedServices({
        customRegionId: regionId,
        servicesIds
      })
        .then(() => {
          fetchExperiences()
          addToast('Alterações salvas com sucesso', 'success')
          goBackToRegion()
        })
        .catch(() => {
          addToast('Houve um erro ao vincular as experiências', 'error')
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    },
    [addToast, fetchExperiences, goBackToRegion, regionId]
  )

  useEffect(fetchExperiences, [fetchExperiences])

  return (
    <>
      <div css={headerRow}>
        <HeaderPage
          title={`Editar lista de experiências`}
          subtitle={decodeURIComponent(regionName)}
          onBack={goBackToRegion}
        />
        <CustomRegionExperiencesFilter setFilters={onChangeFilters} />
      </div>
      <PaginationContainer
        pageLimitDefault={pagination.perPage}
        total={pagination.totalEntries || 1}
        currentPage={pagination.page}
        pagesQuantity={pagination.totalPages}
        onPagination={onPagination}
        pageLimitOptions={paginationOptions}
        isOpenDrawer
      >
        <ExperiencesTableSelector
          onSubmit={onLinkExperiences}
          isSubmitting={isSubmitting}
          isLoading={isLoading}
          columnsWidths={['1fr', '0.6fr', '0.8fr', '0.3fr']}
          data={experiences}
          alreadyLinkedIds={linkedServicesIds}
        />
      </PaginationContainer>
    </>
  )
}
