/** @jsxRuntime classic */
/** @jsx jsx */
import {
  colors,
  MarkersIcon,
  UserIcon,
  CalendarIcon,
  AngleDownIcon
} from '@bonitour/components'
import { css, jsx } from '@emotion/react'

const wrapper = css`
  flex: 1;

  align-self: center;

  margin-top: 27px;

  height: 42px;
  border: 1px solid ${colors.gray11};
  color: rgb(106, 106, 106);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 21px;

  margin-left: 20px;
  padding-left: 6px;

  span {
    flex: 1;
    font-size: 11px;
    margin-left: 8px;
  }

  pointer-events: none;
`

const iconCss = css`
  margin-left: 14px;
  font-size: 14px;
`

const chevronCss = css`
  margin-right: 14px;
  font-size: 14px;
`

const mockButton = css`
  padding: 0 12px;
  height: 32px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 700;

  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.white};
`

const verticalBarCss = css`
  width: 1px;
  height: 50%;
  background-color: ${colors.gray8};
`

export function WidgetPreview({ color = '#aaa' }) {
  return (
    <div css={wrapper}>
      <MarkersIcon customCss={[iconCss]} />
      <span>Selecione a região</span>
      <AngleDownIcon customCss={[chevronCss]} />

      <div css={verticalBarCss} />

      <CalendarIcon customCss={[iconCss]} />
      <span>Selecione as datas</span>
      <AngleDownIcon customCss={[chevronCss]} />

      <div css={verticalBarCss} />

      <UserIcon customCss={[iconCss]} />
      <span>Selecione os viajantes</span>
      <AngleDownIcon customCss={[chevronCss]} />

      <div css={mockButton} style={{ backgroundColor: color }}>
        Montar Roteiro
      </div>
    </div>
  )
}
