/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { App } from 'App'

import { createRoot } from 'react-dom/client'

globalThis.Buffer = globalThis.Buffer || require('buffer').Buffer

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
