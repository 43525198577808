import styled from '@emotion/styled'
import { BREAK_POINTS } from 'assets/styles/queries'

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;

  .actions__row {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .searchBar__element {
    max-width: 255px;
    margin: auto 0;
  }

  @media (max-width: ${BREAK_POINTS.beforeTabletUp}px) {
    .actions__row {
      flex-direction: column;
      gap: 10px;
    }
  }
`
