/* eslint-disable camelcase */
import * as t from 'io-ts'
import { getXpert, postXpert, patchXpert } from 'external/axios'

const CustomPageType = t.type({
  id: t.string,
  slug: t.string,
  // Markdown Text
  content: t.record(t.string, t.string)
})

export function getCustomPage({ slug }) {
  return getXpert(`/v2/custom_pages/${slug}`, {}, CustomPageType)
}

export const postPurchasingPolicy = ({ content }) =>
  postXpert(
    '/v2/custom_pages',
    {
      slug: 'purchase-policy',
      content
    },
    CustomPageType
  )

export const patchPurchasingPolicy = ({ content }) =>
  patchXpert(
    '/v2/custom_pages/purchase-policy',
    {
      slug: 'purchase-policy',
      content
    },
    t.string
  )
