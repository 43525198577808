import React, { useMemo } from 'react'
import { CloseIcon } from '@bonitour/components'
import { Global } from '@emotion/react'
import { Portal } from '../Portal/Portal'

import {
  PopUpBackground,
  PopUpCard,
  PopUpContainer,
  CloseButton
} from './styles'

/**
 * @typedef {{
 * onClose: () => void
 * isVisible?: boolean
 * overlayColor?: 'white' | 'black'
 * overlayOpacity?: number
 * bluredOverlay?: boolean
 * hasOverlay?: boolean
 * requiresAction?: boolean
 * isPadless?: boolean
 * cardCustomStyles?: import('@emotion/react').SerializedStyles
 * blockScroll?: boolean
 * }} PopUpProps
 */

/** @type { React.FC<PopUpProps> } */
export const PopUp = ({
  isVisible = false,
  onClose,
  hasOverlay = true,
  overlayColor = 'black',
  overlayOpacity = 0.7,
  bluredOverlay = false,
  isPadless = false,
  requiresAction = false,
  cardCustomStyles = null,
  children = '',
  blockScroll = false
}) => {
  const overlayRGBA = useMemo(() => {
    if (overlayColor === 'black') {
      return `rgba(0, 0, 0, ${overlayOpacity})`
    }

    return `rgba(255, 255, 255, ${overlayOpacity})`
  }, [overlayColor, overlayOpacity])

  return (
    <Portal selector='__POPUP_PORTAL__'>
      {isVisible && (
        <>
          <PopUpContainer>
            {hasOverlay && (
              <PopUpBackground
                key='modal-bg'
                bluredOverlay={bluredOverlay}
                overlayRGBA={overlayRGBA}
                role={!requiresAction ? 'button' : undefined}
                aria-label={!requiresAction ? 'Close Modal' : undefined}
                onClick={!requiresAction ? onClose : undefined}
              />
            )}
            <PopUpCard
              key='modal-card'
              isPadless={isPadless}
              transition={{ duration: 0.4 }}
              cardCustomStyles={cardCustomStyles}
              hasClosingIcon={!requiresAction}
              layout
            >
              {!requiresAction ? (
                <CloseButton className='closeButton' onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
              ) : null}
              {children}
            </PopUpCard>
          </PopUpContainer>
          {blockScroll && (
            <Global
              styles={{
                body: {
                  overflow: 'hidden'
                }
              }}
            />
          )}
        </>
      )}
    </Portal>
  )
}
