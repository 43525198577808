/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {
  AngleDownIcon,
  AngleUpIcon,
  Card,
  ConfirmDialog,
  Dialog,
  H3,
  PlusIcon,
  useToast
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { InfoTooltip } from 'Domains/Dashboard/InfoTooltip'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd'
import { useHistory } from 'react-router-dom'
import { usePermission } from 'Shared/contexts/Permissions'
import { useMediaQuery } from 'Shared/hooks/useMediaQuery'
import {
  pageCard,
  sectionTitle,
  sectionTitleRow,
  experiencesGridStyle,
  GAP,
  CARD_HEIGHT,
  pointer
} from '../Experiences.style'
import { Experience } from './ExperienceCard'
import { SearchBar } from './SearchBar'
import { NewCustomRegionActivity } from './NewCustomRegionActivity/NewCustomRegionActivity'
import { HeadContainer } from './ExperiencesList.style'
import { updateCustomRegionToCustomService } from '../io/customRegionsToCustomServices.io'
import { useLinkedCustomServices } from 'utilityHooks/useLinkedCustomServices'
import { ACTIVITY_TYPE } from '../Experiences'

const MAX_NUM_OF_MAIN_ACTIVITIES = 20

export const ExperiencesList = ({
  experiences,
  type,
  region,
  setLoading,
  reloadExperiences,
  setExperiences
}) => {
  const fits5Rows = useMediaQuery(`(min-width: 1240px)`)
  const fits4Rows = useMediaQuery(`(min-width: 1020px)`)
  const fits3Rows = useMediaQuery(`(min-width: 790px)`)
  const fits2Rows = useMediaQuery(`(min-width: 560px)`)
  const isTouchDevice = useMediaQuery(`(hover: none)`)

  const columnsNumber = useMemo(
    () => (fits5Rows ? 5 : fits4Rows ? 4 : fits3Rows ? 3 : fits2Rows ? 2 : 1),
    [fits2Rows, fits3Rows, fits4Rows, fits5Rows]
  )

  const [mainExperiences, setMainExperiences] = useState([])

  const { id: regionId, name: regionName } = region

  const { servicesIds: linkedServicesIds, updateLinkedCustomServices } =
    useLinkedCustomServices({
      customRegionId: regionId
    })

  useEffect(() => {
    setMainExperiences(
      experiences
        ?.filter(
          experience =>
            experience.main_position &&
            !experience.service_id.includes('--cloned-')
        )
        .sort((a, b) =>
          a.position < b.position ? -1 : a.position > b.position ? 1 : 0
        )
    )
  }, [experiences])

  const { add: addToast } = useToast()

  const [searchFilter, setSearchFilter] = useState('')

  const searchResults = useMemo(() => {
    const removeSpecialChars = str =>
      String(str || '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, ' ')
        .trim()
        .toLowerCase()

    return experiences?.filter(experience =>
      removeSpecialChars(
        experience.title?.['pt-br'] ?? experience.title
      ).includes(removeSpecialChars(searchFilter))
    )
  }, [experiences, searchFilter])

  const [experienceToRemoveFromRegion, setExperienceToRemoveFromRegion] =
    useState(null)
  const [experienceToHideAndRemove, setHideAndRemove] = useState(null)
  const [experienceToShowAndAdd, setShowAndAdd] = useState(null)

  const getExperienceById = useCallback(
    customServiceId =>
      experiences?.find(({ service_id }) => service_id === customServiceId),
    [experiences]
  )

  const { allowed: canUpdate } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const updateLinkedCustomService = useCallback(
    ({ customServiceId, newParams }) =>
      updateCustomRegionToCustomService({
        customRegionId: regionId,
        customServiceId,
        updateParams: newParams
      }),
    [regionId]
  )

  const [displayLimitDialog, setLimitDialog] = useState(false)

  const handleMainToggle = useCallback(
    (serviceId, mainPosition, ignoreModal = false) => {
      if (!canUpdate) {
        return addToast(
          'Usuário sem permissão de escrita/atualização do Backoffice'
        )
      }
      if (
        mainPosition &&
        mainExperiences.length >= MAX_NUM_OF_MAIN_ACTIVITIES
      ) {
        return setLimitDialog(true)
      }
      const experience = getExperienceById(serviceId)

      if (
        mainPosition &&
        !(experience?.user_visible || experience?.agent_visible) &&
        !ignoreModal
      ) {
        return setShowAndAdd({ experienceId: experience.service_id })
      }
      setLoading(true)
      updateLinkedCustomService({
        customServiceId: experience.id,
        newParams: {
          main_position: mainPosition,
          ...(mainPosition && { user_visible: true, agent_visible: true }),
          position: mainPosition
            ? [mainExperiences[mainExperiences.length - 1]?.position || '', '']
            : null
        }
      })
        .then(() => {
          setLoading(false)
          reloadExperiences()
          addToast(
            `Experiência ${mainPosition ? '' : 'des'}marcada como destaque`,
            'success'
          )
        })
        .catch(e => {
          console.error(e)
          reloadExperiences()
          addToast('Erro ao salvar configurações')
          setLoading(false)
        })
    },
    [
      canUpdate,
      mainExperiences,
      getExperienceById,
      setLoading,
      updateLinkedCustomService,
      addToast,
      reloadExperiences
    ]
  )

  const history = useHistory()

  const handleNavigate = useCallback(
    url => {
      history.push(url)
    },
    [history]
  )

  const onOrderChange = useCallback(
    (
      sourceId,
      sourceIndex,
      targetIndex,
      targetId = sourceId,
      ignoreModal = false
    ) => {
      if (!canUpdate) {
        return addToast(
          'Usuário sem permissão de escrita/atualização do Backoffice'
        )
      }
      if (targetId !== 'main-experiences') return
      if (sourceId === 'search-result-experiences') {
        const sourceExperience = searchResults?.[sourceIndex]
        if (!sourceExperience) return
        if (
          mainExperiences.some(
            experience => experience.id === sourceExperience.id
          )
        ) {
          return addToast('Experiência já destacada', 'warning')
        }
        if (mainExperiences.length >= MAX_NUM_OF_MAIN_ACTIVITIES) {
          return setLimitDialog(true)
        }
        if (
          !(sourceExperience.user_visible || sourceExperience.agent_visible) &&
          !ignoreModal
        ) {
          return setShowAndAdd({
            sourceIndex,
            targetIndex,
            experienceId: sourceExperience.id
          })
        }

        const newMainExperiences = JSON.parse(JSON.stringify(mainExperiences))
        newMainExperiences.splice(targetIndex, 0, sourceExperience)
        window.requestAnimationFrame(() =>
          setMainExperiences(newMainExperiences)
        )

        const previousPosition =
          newMainExperiences[targetIndex - 1]?.position || ''
        const nextPosition = newMainExperiences[targetIndex + 1]?.position || ''

        setLoading(true)

        return updateLinkedCustomService({
          customServiceId: sourceExperience.id,
          newParams: {
            main_position: true,
            user_visible: true,
            agent_visible: true,
            position: [previousPosition, nextPosition]
          }
        })
          .then(() => {
            setLoading(false)
            reloadExperiences()
            addToast('Lista de destaques atualizada com sucesso', 'success')
          })
          .catch(e => {
            console.error(e)
            addToast('Erro ao salvar configurações')
            setLoading(false)
            reloadExperiences()
          })
      }

      if (sourceId !== 'main-experiences') return

      // reorder inside main:
      if (
        sourceIndex === targetIndex ||
        targetIndex > mainExperiences.length - 1
      )
        return
      const result = swap(mainExperiences, sourceIndex, targetIndex)

      const positionData = {
        previous: targetIndex <= 0 ? '' : result[targetIndex - 1],
        next: targetIndex >= result.length - 1 ? '' : result[targetIndex + 1]
      }
      if (!result[targetIndex]?.id) return

      setMainExperiences(result)

      setLoading(true)
      updateLinkedCustomService({
        customServiceId: result[targetIndex].id,
        newParams: {
          main_position: true,
          user_visible: true,
          agent_visible: true,
          position: [
            positionData.previous.position ?? '',
            positionData.next.position ?? ''
          ]
        }
      })
        .then(() => {
          setLoading(false)
          reloadExperiences()
          addToast('Ordem dos destaques atualizada com sucesso', 'success')
        })
        .catch(e => {
          console.error(e)
          addToast('Erro ao salvar configurações')
          setLoading(false)
          reloadExperiences()
        })
    },
    [
      canUpdate,
      mainExperiences,
      setLoading,
      addToast,
      searchResults,
      updateLinkedCustomService,
      reloadExperiences
    ]
  )

  const handleHideAndRemove = useCallback(() => {
    handleMainToggle(experienceToHideAndRemove, false, true)
    setHideAndRemove(null)
  }, [experienceToHideAndRemove, handleMainToggle])

  const handleShowAndAdd = useCallback(() => {
    if (!experienceToShowAndAdd) return

    const { experienceId, sourceIndex, targetIndex } = experienceToShowAndAdd
    if (!targetIndex || !sourceIndex) {
      handleMainToggle(experienceId, true, true)
    } else {
      onOrderChange(
        'search-result-experiences',
        sourceIndex,
        targetIndex,
        'main-experiences',
        true
      )
    }
    setShowAndAdd(null)
  }, [experienceToShowAndAdd, handleMainToggle, onOrderChange])

  const handleDragEnd = useCallback(
    id => {
      if (!id?.includes('--cloned')) {
        setExperiences(experiences =>
          experiences.filter(
            experience => !experience.service_id?.includes('--cloned-')
          )
        )
      }
    },
    [setExperiences]
  )

  const handleDragStart = useCallback(
    id => {
      setExperiences(experiences => {
        const ids = experiences?.map(({ service_id }) => service_id)
        if (ids?.some(id => id?.includes('--cloned-'))) return
        const indexToClone = ids?.indexOf(id)
        if (indexToClone < 0 || indexToClone >= experiences?.length) return
        const newAllExperiences = JSON.parse(JSON.stringify(experiences))
        const clonedExperience = {
          ...JSON.parse(JSON.stringify(experiences?.[indexToClone])),
          service_id: `--cloned-${id}`
        }
        if (newAllExperiences) {
          newAllExperiences.splice(indexToClone + 1, 0, clonedExperience)
        }
        return newAllExperiences
      })
    },
    [setExperiences]
  )

  useEffect(() => {
    setSearchFilter('')
  }, [regionId])

  const cardsQty = useMemo(
    () =>
      Math.min(
        MAX_NUM_OF_MAIN_ACTIVITIES,
        mainExperiences?.length + (isTouchDevice ? 0 : 1)
      ),
    [isTouchDevice, mainExperiences?.length]
  )

  const [highlightsVisible, setHighlightsVisible] = useState(true)
  const toggleHighlights = useCallback(() => {
    setHighlightsVisible(v => !v)
  }, [])

  const onUnbindService = useCallback(
    serviceId => {
      setLoading(true)
      setExperienceToRemoveFromRegion(null)

      const newServicesIds = linkedServicesIds.filter(id => id !== serviceId)

      updateLinkedCustomServices({
        customRegionId: regionId,
        servicesIds: newServicesIds
      })
        .then(() => {
          setExperiences(experiences =>
            experiences.filter(({ service_id }) => service_id !== serviceId)
          )
          addToast('Experiência removida', 'success')
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [
      addToast,
      linkedServicesIds,
      regionId,
      setExperiences,
      setLoading,
      updateLinkedCustomServices
    ]
  )

  const onAddNewActivity = useCallback(() => {
    history.push(
      `/app/regions/${regionId}/${encodeURIComponent(
        regionName
      )}/experiences/to-bind`
    )
  }, [history, regionId, regionName])

  return (
    <GridContextProvider onChange={onOrderChange}>
      <Card css={pageCard}>
        <section style={{ marginBottom: '40px' }}>
          <div css={sectionTitleRow}>
            <span onClick={toggleHighlights} css={pointer}>
              <H3 css={sectionTitle}>
                {type === ACTIVITY_TYPE ? 'Atividades' : 'Transportes'} em
                destaque
              </H3>
              {highlightsVisible ? <AngleUpIcon /> : <AngleDownIcon />}
            </span>
            <InfoTooltip>
              <p>
                As experiências em destaque são apresentadas na inicio da lista
                após uma busca pela região em seu Xpert. <br />
                As 5 primeiras aparecerão na tela inicial antes de uma busca ser
                realizada.
              </p>
            </InfoTooltip>
          </div>
          <div
            css={experiencesGridStyle(cardsQty, columnsNumber)}
            className={highlightsVisible ? '' : 'collapsed'}
          >
            {!isTouchDevice &&
              new Array(Math.min(cardsQty)).fill(0).map((...[, idx]) => (
                <div key={`mock${idx}`} className='card-block'>
                  <div className='card-position-indicatior'>
                    <PlusIcon className='plus-icon' />
                    <p className='hover-text'>
                      Arraste uma experiência para destacá-la
                    </p>
                  </div>
                </div>
              ))}

            <GridDropZone
              className='main-experiences-dropzone'
              id='main-experiences'
              boxesPerRow={columnsNumber}
              rowHeight={CARD_HEIGHT + GAP}
              style={{ position: 'absolute' }}
              disableDrag={!canUpdate || isTouchDevice}
            >
              {mainExperiences?.map(
                ({
                  id,
                  service_id,
                  agent_visible,
                  user_visible,
                  title,
                  image,
                  company,
                  main_position
                }) => (
                  <GridItem key={`${id}`} className={`grid-item ${id}`}>
                    {(Wrapper, props, extra) => (
                      <Wrapper {...props}>
                        <Experience
                          isAgentVisible={agent_visible}
                          isUserVisible={user_visible}
                          isMain={main_position}
                          isDragging={extra.dragging}
                          title={title?.['pt-br']}
                          companyName={company?.name}
                          key={id}
                          customServiceId={id}
                          serviceId={service_id}
                          imageURL={image ? image.file : null}
                          regionId={regionId}
                          regionName={regionName}
                          onChangeMain={handleMainToggle}
                          onUnbindService={() =>
                            setExperienceToRemoveFromRegion(service_id)
                          }
                          setExperiences={setExperiences}
                          navigate={handleNavigate}
                          onMainList
                        />
                      </Wrapper>
                    )}
                  </GridItem>
                )
              )}
            </GridDropZone>
          </div>
        </section>
        <section>
          <HeadContainer>
            <H3 css={sectionTitle}>
              {type === ACTIVITY_TYPE
                ? 'Todas as atividades'
                : 'Todos os transportes'}
            </H3>
            <div className='actions__row'>
              <NewCustomRegionActivity
                serviceType={type}
                onClick={onAddNewActivity}
                className='new_region__btn'
              />
              {experiences.length > 0 ? (
                <SearchBar
                  onDebounce={value => setSearchFilter(value)}
                  currentValue={searchFilter}
                  placeholder='Buscar uma experiência'
                  debounceTime={500}
                />
              ) : null}
            </div>
          </HeadContainer>
          <div
            css={experiencesGridStyle(
              searchResults?.length || 0,
              columnsNumber
            )}
          >
            <GridDropZone
              className='all-experiences-dropzone'
              id='search-result-experiences'
              boxesPerRow={columnsNumber}
              rowHeight={CARD_HEIGHT + GAP}
              style={{ position: 'absolute' }}
              disableDrop
              disableDrag={!canUpdate || isTouchDevice || !highlightsVisible}
            >
              {searchResults?.map(
                ({
                  id,
                  service_id,
                  title,
                  image,
                  company,
                  main_position,
                  agent_visible,
                  user_visible
                }) => (
                  <GridItem
                    key={`result-${service_id}`}
                    className={`grid-item result-${service_id}`}
                  >
                    {(Wrapper, props, extra) => (
                      <Wrapper {...props} key={`result-${service_id}-wrapper`}>
                        <Experience
                          key={`result-${service_id}-card`}
                          isUserVisible={user_visible}
                          isAgentVisible={agent_visible}
                          isMain={main_position}
                          isDragging={extra.dragging}
                          title={title?.['pt-br']}
                          companyName={company?.name}
                          customServiceId={id}
                          serviceId={service_id}
                          imageURL={image ? image.file : null}
                          regionId={regionId}
                          regionName={regionName}
                          onChangeMain={handleMainToggle}
                          navigate={handleNavigate}
                          onDragStart={handleDragStart}
                          setExperiences={setExperiences}
                          onUnbindService={() =>
                            setExperienceToRemoveFromRegion(service_id)
                          }
                          onDragEnd={handleDragEnd}
                        />
                      </Wrapper>
                    )}
                  </GridItem>
                )
              )}
            </GridDropZone>
          </div>
        </section>
      </Card>

      <Dialog
        isVisible={displayLimitDialog}
        title='Ops, limite atingido!'
        onClose={() => setLimitDialog(false)}
      >
        <p style={{ margin: '10px 0' }}>
          O limite de &quot;Experiências em Destaque&quot; é de{' '}
          {MAX_NUM_OF_MAIN_ACTIVITIES} experiências por categoria.
        </p>
        <p style={{ margin: '10px 0 20px' }}>
          Você pode alterá-las a qualquer momento desmarcando uma e adicionando
          outra.
        </p>
      </Dialog>
      <ConfirmDialog
        isVisible={Boolean(experienceToHideAndRemove)}
        title='Remover experiência dos destaques?'
        successCallback={handleHideAndRemove}
        cancelCallback={() => setHideAndRemove(null)}
      >
        <p style={{ margin: '10px 0  20px' }}>
          Ao desativar esta experiência, ela será retirada da lista de
          &quot;Experiências em Destaque&quot;.
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        isVisible={Boolean(experienceToRemoveFromRegion)}
        title={`Remover experiência da região?`}
        successCallback={() => onUnbindService(experienceToRemoveFromRegion)}
        cancelCallback={() => setExperienceToRemoveFromRegion(null)}
      >
        <p style={{ margin: '10px 0  20px' }}>
          Ao remover esta experiência, ela será retirada da lista de
          experiências de {region.name}
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        isVisible={Boolean(experienceToShowAndAdd)}
        title='Ops, esta experiência está desativada... '
        successCallback={handleShowAndAdd}
        cancelCallback={() => setShowAndAdd(null)}
      >
        <p style={{ margin: '10px 0  20px' }}>
          Ao marcar essa experiência como uma &quot;Experiência em Destaque
          &quot; você também estará ativando-a para a venda no seu Xpert.
        </p>
      </ConfirmDialog>
    </GridContextProvider>
  )
}
