import { string } from 'yup'
import { validatePhone } from '@bonitour/common-functions'

export const contactPhoneSchema = {
  number: string().test(
    'validate-phone',
    'O número de telefone deve ser válido',
    validatePhone
  )
}
