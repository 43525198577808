import { extractData, xpertAxiosInstance } from 'external/axios'
import { removeNullAndEmptyFields } from 'utils/utils'
import { CustomRegionParser } from './Parser/CustomRegion'

const xpertInstance = xpertAxiosInstance()

export function createRegion({ name, description, agentVisible, userVisible }) {
  return xpertInstance
    .post('/custom_regions/backoffice', {
      name,
      description,
      agent_visible: agentVisible,
      user_visible: userVisible
    })
    .then(extractData)
}

export function getCustomRegions({ countServices = false, ...params }) {
  return xpertInstance
    .get('/custom_regions/backoffice', {
      params: removeNullAndEmptyFields({
        count_services: countServices,
        ...params
      })
    })
    .then(extractData)
    .then(CustomRegionParser.list)
}

export function getCustomServicesByCustomRegion({
  customRegionId,
  perPage = 20,
  page = 1,
  type = '',
  title = ''
}) {
  return xpertInstance
    .get(`/custom_regions/${customRegionId}/custom_services/backoffice`, {
      params: removeNullAndEmptyFields({
        per_page: perPage,
        page,
        service_type: type,
        title
      })
    })
    .then(extractData)
}

export function getCustomRegionLinkedServicesIds({ regionId }) {
  return xpertInstance
    .get(`/custom_regions/${regionId}/linked_services/backoffice`)
    .then(extractData)
}

export function getCustomRegion(regionId) {
  return xpertInstance
    .get(`/custom_regions/${regionId}/backoffice`, {})
    .then(extractData)
}

export function updateCustomRegion(regionId, attrs) {
  return xpertInstance
    .patch(`/custom_regions/${regionId}/backoffice`, attrs)
    .then(extractData)
}

export function updateCustomRegionsLinkedServices({
  customRegionId,
  servicesIds
}) {
  return xpertInstance
    .put(`/custom_regions/${customRegionId}/link_custom_services/backoffice`, {
      services_ids: servicesIds
    })
    .then(extractData)
}

export function reorderCustomRegion({
  regionId,
  positionBefore,
  positionAfter
}) {
  return xpertInstance
    .patch(`/custom_regions/${regionId}/position/backoffice`, {
      position: [positionBefore, positionAfter]
    })
    .then(extractData)
}
