import { useCallback, useState } from 'react'

import { useToast } from '@bonitour/components'
import { CompanyService } from 'core/Service/Company'
import { normalizeString } from 'utils/utils'
import {
  ADULT_FEE,
  CHILD_FEE,
  PRIVATE_FEE,
  SENIOR_FEE,
  UNIQUE_FEE,
  FREE_FEE
} from 'Shared/constants/fees'
import {
  browserDownloadFromBuffer,
  cleanQuotationPolicyMarkdown,
  parseToCurrencyString
} from '@binamik/js-functions'
import { getQuotationExportData, postExporterQuotation } from './io/quotation'
import { minutesToText } from 'utils/parsers/minutesToText'
import { countTravelersOnAgeRange } from 'utils/countTravelersOnAgeRange'
import { SafeDate } from '@binamik/js-providers'

const MOUNTAIN_FALLBACK_IMAGE =
  'https://storage.googleapis.com/jaiminho-api/v1/activity-padded.png'

function parseDateStringFormat(inputDate) {
  return new SafeDate(inputDate).format('MM-DD-YYYY').toString()
}

const sortServicesByDate = services =>
  services.sort((a, b) => {
    const dateA = a.date.split('/').reverse().join('-')
    const dateB = b.date.split('/').reverse().join('-')

    return new Date(dateA).getTime() - new Date(dateB).getTime()
  })

const retrieveExtraServiceCheckinAndCheckout = extraService => {
  const { checkin_date, pickup_date } = extraService?.ticket_info
  const { checkout_date, dropoff_date } = extraService?.ticket_info

  return {
    checkinDate: parseDateStringFormat(checkin_date || pickup_date),
    checkoutDate: parseDateStringFormat(checkout_date || dropoff_date)
  }
}

const getPrivateAmount = ({
  adults,
  seniors,
  childrenAges,
  minAge,
  maxAge,
  capacity = 1
}) => {
  const travelersCount = countTravelersOnAgeRange({
    adults,
    seniors,
    childrenAges,
    minAge,
    maxAge
  })

  return Math.ceil(travelersCount / capacity)
}

const retrievePriceAmount = ({ fee, adults, seniors, children_ages }) => {
  if (!fee) {
    return null
  }

  const normalizedFeeName = normalizeString(fee.name)

  switch (normalizedFeeName) {
    case normalizeString(UNIQUE_FEE):
      return adults + seniors + children_ages.length
    case normalizeString(ADULT_FEE):
      return adults
    case normalizeString(SENIOR_FEE):
      return seniors
    case normalizeString(PRIVATE_FEE):
      return getPrivateAmount({
        adults,
        seniors,
        childrenAges: children_ages,
        minAge: fee.min_age,
        maxAge: fee.max_age,
        capacity: fee.capacity
      })
    case normalizeString(FREE_FEE):
      return countTravelersOnAgeRange({
        adults,
        seniors,
        childrenAges: children_ages,
        minAge: fee.min_age,
        maxAge: fee.max_age
      })
    case normalizeString(CHILD_FEE):
      return countTravelersOnAgeRange({
        adults,
        seniors,
        childrenAges: children_ages,
        minAge: fee.min_age,
        maxAge: fee.max_age
      })
    default:
      return 0
  }
}

const parseServicePriceDetails = ({
  prices = [],
  adults = 0,
  seniors = 0,
  children_ages = []
}) => {
  const childrenOlderThanChildFees = children_ages.filter(age =>
    prices.some(
      price =>
        age > price.max_age &&
        normalizeString(price.name) === normalizeString(CHILD_FEE)
    )
  )

  return prices
    .map(fee => ({
      label: fee?.name,
      price: parseToCurrencyString(fee?.price),
      amount: retrievePriceAmount({
        fee,
        adults: adults + childrenOlderThanChildFees.length,
        seniors,
        children_ages
      })
    }))
    .filter(({ amount }) => amount > 0)
}

const parseExperiencesCommonData = (experience = {}) => ({
  date: parseDateStringFormat(experience?.date),
  priceDetails: parseServicePriceDetails({
    prices: experience?.prices,
    adults: experience?.adults,
    seniors: experience?.seniors,
    children_ages: experience?.children_ages
  }),
  totalPrice: parseToCurrencyString(experience?.price)
})

const parseTransports = (transports = []) =>
  transports.map(transport => ({
    name: transport.transport.title['pt-br'],
    duration: minutesToText(transport.transport.duration),
    company: transport.transport.company.name,
    whatIsIncluded: transport.transport?.what_is_included || [],
    image: transport?.transport?.image?.file,
    ...parseExperiencesCommonData(transport)
  }))

const parseTours = (tours = []) => {
  return tours.map(tour => ({
    name: tour.activity.title['pt-br'],
    duration: minutesToText(tour.activity.duration),
    company: tour.activity.company.name,
    whatIsIncluded: tour.activity?.what_is_included || [],
    image: tour.activity?.image?.file,
    ...parseExperiencesCommonData(tour)
  }))
}

const parseExperiences = (experiences = {}, isTransports = false) => {
  const experiencesArray = Object.values(experiences).flat()

  if (isTransports) {
    return parseTransports(experiencesArray)
  } else {
    return parseTours(experiencesArray)
  }
}

const parseExtraServices = (extraServices = []) =>
  extraServices.map(extraService => ({
    name: extraService.title,
    company: extraService.ticket_info.company_name,
    image: extraService?.image || MOUNTAIN_FALLBACK_IMAGE,
    date: parseDateStringFormat(extraService.slot),
    duration: extraService?.duration || '--',
    totalPrice: parseToCurrencyString(extraService.ticket_info.price),
    priceDetails: [
      {
        label: UNIQUE_FEE,
        price: parseToCurrencyString(extraService.ticket_info.price),
        amount: extraService.count
      }
    ],
    ...retrieveExtraServiceCheckinAndCheckout(extraService)
  }))

const getMaxInstallments = arr => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return null
  }

  const maxInstallmentsObj = arr.reduce((acc, currentObj) => {
    return currentObj.max_installments > acc.max_installments ? currentObj : acc
  })

  return maxInstallmentsObj.max_installments
}

export const useQuotationExporter = () => {
  const { add: addToast } = useToast()
  const [isExporting, setIsExporting] = useState(false)

  const parseExporterData = useCallback(
    async ({ itinerary, quotation, whitelabel }) => {
      const maxInstallments = await CompanyService.getCardBrands().then(
        cardBrands => getMaxInstallments(cardBrands)
      )
      const {
        id: itineraryId,
        travel_date: { start_date: checkinDate, end_date: checkoutDate },
        price: totalPrice
      } = itinerary

      const {
        agent_name: agentName,
        client_name: clientName,
        policy: quotationPolicyMarkDown,
        code: quotationNumber
      } = quotation

      const {
        colors: { primary: companyColor },
        logo: companyLogo,
        url: companyUrl
      } = whitelabel

      const itineraryUrl = `${companyUrl}?itinerary=/${itineraryId}`

      const services = sortServicesByDate([
        ...parseExperiences(itinerary?.tours || {}),
        ...parseExperiences(itinerary?.transports || {}, true)
      ])

      const extraServices = sortServicesByDate(
        parseExtraServices(itinerary?.extra_services || [])
      )

      return {
        itineraryUrl,
        agentName,
        clientName,
        checkinDate: parseDateStringFormat(checkinDate),
        checkoutDate: parseDateStringFormat(checkoutDate),
        companyLogo,
        companyColor,
        quotationNumber,
        totalPrice: parseToCurrencyString(totalPrice),
        maxInstallments,
        quotationPolicyMarkDown: cleanQuotationPolicyMarkdown(
          quotationPolicyMarkDown
        ),
        services,
        extraServices
      }
    },
    []
  )

  const buildQuotationPdfFileName = useCallback(
    ({ clientName, quotationNumber }) =>
      `cotacao-${normalizeString(clientName).replace(
        /[^a-zA-Z0-9]/g,
        '_'
      )}-${quotationNumber}.pdf`,
    []
  )

  const onExport = useCallback(
    async quotationId => {
      if (isExporting) {
        return addToast('Aguarde finalizar a exportação anterior')
      }

      setIsExporting(true)
      addToast(
        'Sua cotação está sendo gerada, aguarde um momento',
        'info',
        12000 // 12 seconds
      )

      try {
        const quotationData = await getQuotationExportData(quotationId)

        const parsedDataToExport = await parseExporterData(quotationData)

        const buffer = await postExporterQuotation(parsedDataToExport)

        const fileName = buildQuotationPdfFileName({
          clientName: parsedDataToExport.clientName,
          quotationNumber: parsedDataToExport.quotationNumber
        })

        browserDownloadFromBuffer({
          buffer,
          fileName
        })
      } catch {
        addToast('Houve um erro ao tentar gerar o PDF da cotação')
      } finally {
        setIsExporting(false)
      }
    },
    [addToast, buildQuotationPdfFileName, isExporting, parseExporterData]
  )

  return {
    isExporting,
    onExport
  }
}
