import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const card = css`
  margin-top: 20px;
  padding: 10px;
`

export const container = css`
  padding: 10px;
`

export const content = css`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
`
export const iconArrow = css`
  color: ${colors.indigo1};
  font-size: 60px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
`

export const totalItineraries = css`
  font-size: 24px;
  color: ${colors.gray3};
  font-weight: 700;
  margin-left: 10px;
`

export const loadItiniraries = css`
  background-color: ${colors.gray12};
  border-radius: 5px;
  margin-bottom: 10px;
`

export const progressBarStatus = css`
  display: flex;
  flex-direction: row;
  animation-duration: 1s;
  animation-name: progressBar;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  width: 100%;
  transform-origin: center left;
  animation-delay: 300ms;
  border-radius: 5px;

  @keyframes progressBar {
    0% {
      transform: scalex(0);
    }
    100% {
      transform: scalex(100%);
    }
  }

  .segmentTooltip {
    flex: 1;

    .tooltip-on-hover {
      width: max-content;
      bottom: 15px;
    }

    &:first-child {
      .segment {
        border-radius: 5px 0 0 5px;
      }
    }

    &:last-child {
      .segment {
        border-radius: 0 5px 5px 0;
      }
      &:first-child {
        .segment {
          border-radius: 5px;
        }
      }
    }
  }

  .segment {
    flex: 1;
    height: 8px;
    border-radius: 0;
  }
`

export const loadingStyle = css`
  margin: 20px auto;
`
