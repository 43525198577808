import { getXpert, patchXpert, postXpert, putXpert } from 'external/axios'
import * as t from 'io-ts'
import { convertToFormData } from 'Shared/utils/forms'

const optional = type => t.union([type, t.null, t.undefined])

export const langType = t.strict({
  default_language: optional(t.string),
  active_languages: optional(t.array(t.string))
})

export const configType = t.strict({
  url: t.string,
  deployment_type: optional(t.string),
  title: optional(t.string),
  colors: t.strict({
    primary: t.string,
    secondary: t.string
  }),
  logo: optional(t.string),
  favicons: optional(t.dictionary(t.string, t.string)),
  google_ads: optional(t.string),
  google_analytics: optional(t.string),
  google_tag_manager: optional(t.string),
  pinterest_tag_id: optional(t.string),
  facebook_pixel: optional(t.union([t.string, t.number])),
  facebook: optional(t.string),
  instagram: optional(t.string),
  linkedin: optional(t.string),
  youtube: optional(t.string),
  whatsapp: optional(t.string),
  tiktok: optional(t.string),
  blog: optional(t.string),
  email: optional(t.string),
  homepage: optional(t.string),
  phones: optional(t.array(t.string)),
  languages: optional(langType)
})

export const getSiteConfig = companyId =>
  getXpert('/config/whitelabel/company/' + companyId, {}, configType)

export const postSiteConfig = payload =>
  postXpert('/config/whitelabel', convertToFormData(payload), configType)

export const patchSiteConfig = (payload, companyId) =>
  patchXpert(
    '/config/whitelabel/company/' + companyId,
    convertToFormData(payload),
    configType
  )

export const putLanguageConfig = payload =>
  putXpert('config/whitelabel/languages', convertToFormData(payload), langType)
