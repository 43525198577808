/** @jsxRuntime classic */
/** @jsx jsx */
import { colors, TooltipOnHover } from '@bonitour/components'
import { jsx, css } from '@emotion/react'
import { classContains } from 'Shared/utils/utils.styles'
import { ReactComponent as InfoSVG } from '../../Shared/svgs/info.svg'

const tooltip = css`
  display: inline-block;
  flex-grow: 1;

  .tooltip-on-hover {
    bottom: -5px;
    left: 30px;

    @media (max-width: 680px) {
      left: -150px;
      ${classContains('TooltipArrow')} {
        display: none;
      }
    }
  }

  svg {
    .a {
      fill: ${colors.gray4};
    }
    .b {
      fill: ${colors.white};
      stroke: none;
    }
  }
`

export function InfoTooltip({ children, aditionalCss = [] }) {
  return (
    <TooltipOnHover
      text={children}
      customCss={[...aditionalCss, tooltip]}
      position='right'
    >
      <InfoSVG />
    </TooltipOnHover>
  )
}
