import { css } from '@emotion/react'
import styled from '@emotion/styled'

const { TableRow, Table } = require('components/Table')
const React = require('react')

const ColorDot = ({ color }) => (
  <span
    style={{
      backgroundColor: color,
      height: '0.625rem',
      width: '0.625rem',
      minHeight: '0.625rem',
      minWidth: '0.625rem',
      marginRight: '1ch',
      borderRadius: '50%',
      display: 'inline-block'
    }}
  />
)

const Status = ({ color, status }) => (
  <>
    <ColorDot color={color} />
    {status}
  </>
)

const Actions = () => (
  <p>
    {/* eslint-disable-next-line */}
    <a href='#' style={{ textDecoration: 'underline' }}>
      Editar
    </a>{' '}
    {/* eslint-disable-next-line */}
    <a href='#' style={{ textDecoration: 'underline' }}>
      Excluir
    </a>
  </p>
)

const RowAnnexLayout = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const TableMockData = {
  labels: [
    'Tipo de veículo',
    'Identificador',
    'Placa',
    'Ano',
    'Assentos',
    'Vínculo com a empresa',
    'Status',
    'Situação do veículo',
    'Ações'
  ],
  columnsWidths: undefined,
  customCss: css`
    --column-template: /*  type:       */ minmax(19ch, 1fr)
      /*                   id:         */ minmax(16ch, 20ch)
      /*                   plate:      */ minmax(12.5ch, 16ch)
      /*                   year:       */ minmax(9ch, 13ch)
      /*                   seats:      */ minmax(13ch, 15ch)
      /*                   link:       */ minmax(26ch, 1.25fr)
      /*                   status:     */ minmax(15ch, 1fr)
      /*                   situation:  */ minmax(14.5ch, 23ch)
      /*                   actions:    */ minmax(15ch, 20ch);

    @media (max-width: 1400px) {
      --column-template: /*  type:       */ minmax(19ch, 22ch)
        /*                   id:         */ minmax(16ch, 18ch)
        /*                   plate:      */ minmax(12.5ch, 15ch)
        /*                   year:       */ minmax(9ch, 12ch)
        /*                   seats:      */ minmax(13ch, 14ch)
        /*                   link:       */ minmax(22ch, 26ch)
        /*                   status:     */ minmax(15ch, 20ch)
        /*                   situation:  */ minmax(14.5ch, 20ch)
        /*                   actions:    */ minmax(15ch, 18ch);
    }

    @media (max-width: 1200px) {
      --column-template: /*  type:       */ minmax(14ch, 19ch)
        /*                   id:         */ minmax(15.5ch, 16ch)
        /*                   plate:      */ minmax(12.5ch, 13.5ch)
        /*                   year:       */ minmax(8.5ch, 11ch)
        /*                   seats:      */ minmax(13ch, 14ch)
        /*                   link:       */ minmax(16.5ch, 22ch)
        /*                   status:     */ minmax(14ch, 16ch)
        /*                   situation:  */ minmax(14.5ch, 16ch)
        /*                   actions:    */ minmax(15ch, 16ch);
    }
    @media (max-width: 1080px) {
      --column-template: 1fr;
    }
  `,
  mobileBreakpoint: 1080,
  data: [
    {
      children: [
        'Ônibus',
        '359',
        'ABC1234',
        '2011',
        40,
        <Status key={0} color='#2AA747' status='Veículo próprio' />,
        <Status key={1} color='#2AA747' status='Disponível' />,
        'Ativo',
        <Actions key={2} />
      ]
    },
    {
      children: [
        'Van',
        '217',
        'EFG5678',
        '2009',
        12,
        <Status key={0} color='#2AA747' status='Veículo próprio' />,
        <Status key={1} color='#FDBB4E' status='Agendado' />,
        'Ativo',
        <Actions key={2} />
      ],
      annex: (
        <div style={{ color: 'var(--clr-gray2)' }}>
          <h3 style={{ marginBottom: '2rem' }}>Informações do veículo</h3>
          <RowAnnexLayout>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 2rem 1rem 0'
              }}
            >
              <h5>Renavan</h5>
              <p>1234567890123</p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 2rem 1rem 0'
              }}
            >
              <h5>Ano do documento</h5>
              <p>
                <Status key={0} color='#2AA747' status='2022' />
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 2rem 1rem 0'
              }}
            >
              <h5>Kilometragem</h5>
              <p>255.000Km (Atualizado 10/01/2022)</p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 2rem 1rem 0'
              }}
            >
              <h5>Ultima revisão</h5>
              <p>
                <Status
                  key={0}
                  color='#FDBB4E'
                  status='04/01/2021 - 230.000Km'
                />
              </p>
            </div>
          </RowAnnexLayout>
        </div>
      )
    },
    {
      children: [
        'Carro',
        '147',
        'HIJ9101',
        '2013',
        4,
        <Status key={0} color='#2AA747' status='Veículo próprio' />,
        <Status key={1} color='#4D4DFF' status='Em rota' />,
        'Ativo',
        <Actions key={2} />
      ]
    },
    {
      children: [
        'Microônibus',
        '973',
        'KLM2345',
        '2011',
        40,
        <Status key={0} color='#2AA747' status='Veículo próprio' />,
        <Status key={1} color='#FDBB4E' status='Agendado' />,
        'Ativo',
        <Actions key={2} />
      ]
    },
    {
      children: [
        'Van',
        '304',
        'NOP6789',
        '2015',
        12,
        <Status key={0} color='#FF6461' status='Veículo terceirizado' />,
        <Status key={1} color='#4D4DFF' status='Em rota' />,
        'Ativo',
        <Actions key={2} />
      ]
    },
    {
      children: [
        'Barco',
        '875',
        '-',
        '2007',
        4,
        <Status key={0} color='#FF6461' status='Veículo terceirizado' />,
        <Status key={1} color='#2AA747' status='Disponível' />,
        'Ativo',
        <Actions key={2} />
      ]
    }
  ]
}

export const MockedTableComponent = args => (
  <>
    <Table {...args} {...TableMockData}>
      {TableMockData.data.map((row, idx) => (
        <TableRow key={idx} annex={row.annex}>
          {row.children}
        </TableRow>
      ))}
    </Table>
  </>
)

export const TableMock = () => (
  <MockedTableComponent
    sortableColumns={[
      'Tipo de veículo',
      'Identificador',
      'Placa',
      'Ano',
      'Assentos',
      'Vínculo com a empresa',
      'Status',
      'Situação do veículo',
      'Ações'
    ]}
    sortedColumn={0}
    sortedOrder='asc'
    toggleAnnex={false}
  />
)
