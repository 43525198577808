/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { colors, H2 } from '@bonitour/components'
import { jsx, css } from '@emotion/react'

export const tabsList = css`
  display: flex;

  padding-left: 32px;
`

export const titleTab = css`
  width: 120px;
  height: 58px;
  background: ${colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px 10px 0 0;

  border: 1px solid ${colors.gray9};
  border-bottom: none;

  margin-bottom: -1px;

  z-index: 2;

  cursor: pointer;

  & + button {
    margin-left: 16px;
  }

  h2 {
    color: ${colors.gray4};
    font-size: 14px;
    text-align: center;
    margin: 0;
  }
`

export const insetShaddow = css`
  box-shadow: inset 0 -5px 10px -6px rgba(0, 0, 0, 0.15);
`

export function TitleTabs({ tabs = [], currentTab, onTabSelect }) {
  return (
    <div css={tabsList}>
      {tabs.map(tab => (
        <button
          key={tab.value}
          css={[titleTab, currentTab !== tab.value && insetShaddow]}
          onClick={() => onTabSelect(tab.value)}
        >
          <H2>{tab.label}</H2>
        </button>
      ))}
    </div>
  )
}
