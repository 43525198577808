/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { Card, Row, H1, H2 } from '@bonitour/components'

import { noConfigCard } from './NoConfig.styles'

import { headingRow } from '../AlgorithmSetup/AlgorithmSetup.style'
import { Link } from 'react-router-dom'

export const unlinkIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='258'
    height='258'
    viewBox='0 0 258 258'
  >
    <g transform='translate(-59 -130)'>
      <circle
        className='a'
        cx='129'
        cy='129'
        r='129'
        transform='translate(59 130)'
      />
      <g transform='translate(127 198.003)'>
        <g transform='translate(-0.997 -1)'>
          <g transform='translate(0 0)'>
            <path
              className='b'
              d='M50.414,228.4,35.8,243.007a15.5,15.5,0,0,1-21.92-21.914l29.226-29.226a15.494,15.494,0,0,1,21.914,0,5.166,5.166,0,1,0,7.306-7.306,25.827,25.827,0,0,0-36.527,0L6.577,213.786A25.831,25.831,0,0,0,43.11,250.313L57.72,235.7a5.166,5.166,0,0,0-7.307-7.306Z'
              transform='translate(0.991 -133.889)'
            />
            <path
              className='b'
              d='M260.126,43.1A25.831,25.831,0,0,0,223.595,6.573L206.064,24.1a5.166,5.166,0,0,0,7.306,7.306L230.9,13.881A15.5,15.5,0,0,1,252.82,35.8L220.675,67.94a15.494,15.494,0,0,1-21.914,0,5.166,5.166,0,1,0-7.306,7.306,25.827,25.827,0,0,0,36.527,0Z'
              transform='translate(-143.701 0.993)'
            />
            <path
              className='b'
              d='M370.5,363.191a5.166,5.166,0,1,0-7.306,7.306l15.494,15.494a5.166,5.166,0,1,0,7.306-7.306Z'
              transform='translate(-273.85 -273.847)'
            />
            <path
              className='b'
              d='M58.677,65.981a5.166,5.166,0,1,0,7.306-7.306L50.474,43.165a5.166,5.166,0,0,0-7.306,7.306Z'
              transform='translate(-31.326 -31.323)'
            />
            <path
              className='b'
              d='M425,319H409.5a5.166,5.166,0,1,0,0,10.333H425A5.166,5.166,0,1,0,425,319Z'
              transform='translate(-306.176 -241.505)'
            />
            <path
              className='b'
              d='M153.5,24.832a5.166,5.166,0,0,0,5.166-5.166V4.167a5.166,5.166,0,1,0-10.333,0v15.5A5.166,5.166,0,0,0,153.5,24.832Z'
              transform='translate(-112.172 1)'
            />
            <path
              className='b'
              d='M4.169,158.667h15.5a5.166,5.166,0,1,0,0-10.333H4.169a5.166,5.166,0,1,0,0,10.333Z'
              transform='translate(0.997 -112.169)'
            />
            <path
              className='b'
              d='M324.169,404.333A5.166,5.166,0,0,0,319,409.5V425a5.166,5.166,0,1,0,10.333,0V409.5A5.166,5.166,0,0,0,324.169,404.333Z'
              transform='translate(-241.508 -306.172)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export function NoConfig() {
  return (
    <>
      <Row customCss={[headingRow]}>
        <H1>Documentação</H1>
      </Row>
      {/* @ts-ignore */}
      <Card customCss={[noConfigCard]}>
        {unlinkIcon}
        <H2>Sem Configuração</H2>
        <p>
          Você ainda não configurou seu E-commerce Xpert
          <br />
          Para cadastrar sua política de compra
        </p>
        <Link to='/app/appearance'>Configurar meu E-commerce</Link>
      </Card>
    </>
  )
}
