import { useCallback, useEffect, useRef } from 'react'

const PERCENTAGE_TO_SCROLL = 15
const SCROLL_SPEED = 5

// Hook for draggables, to automatically scroll the page
// when dragging near the top or bottom of the page
export const useScrollOnDrag = ({ enabled = true } = {}) => {
  const screenYpercent = useRef(0)
  const interval = useRef(null)

  const onMouseMove = useCallback(
    event => {
      if (enabled && ['mousemove', 'touchmove'].includes(event.type)) {
        const screenHeight = window.innerHeight
        screenYpercent.current = (event.clientY / screenHeight) * 100
      }
    },
    [enabled]
  )

  useEffect(() => {
    if (enabled && !interval.current) {
      interval.current = setInterval(() => {
        if (screenYpercent.current < PERCENTAGE_TO_SCROLL) {
          window.scrollBy({
            top: (screenYpercent.current - PERCENTAGE_TO_SCROLL) * SCROLL_SPEED,
            behavior: 'smooth'
          })
        } else if (screenYpercent.current > 100 - PERCENTAGE_TO_SCROLL) {
          window.scrollBy({
            top:
              (screenYpercent.current - 100 + PERCENTAGE_TO_SCROLL) *
              SCROLL_SPEED,
            behavior: 'smooth'
          })
        }
      }, 50)
    }
    if (!enabled && interval.current) {
      clearInterval(interval.current)
      interval.current = null
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
        interval.current = null
      }
    }
  })

  return { onMouseMove }
}
