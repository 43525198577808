/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

export const EmptyResult = ({ message = 'Nenhum item foi encontrado' }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '22px',
      padding: '50px'
    }}
  >
    <span>{message}</span>
  </div>
)
