import React, { useEffect, useState } from 'react'
import {
  Card,
  LoadingAnimation,
  PurchaseIcon,
  useToast
} from '@bonitour/components'
import { CardHeader } from 'components/HeadersDashboard/CardHeader'
import { AbandonedContainer, iconPurchase } from './AbandonedCartCount.style'
import { getAbandonedCart } from './io/abandonedCount.io'

/**
 * @typedef DataProps
 * @prop {number} [count]
 */

/**
 * @typedef AbandonedCartsCountProps
 * @prop { boolean } [isPageAbandonedCarts]
 * @prop { boolean } [mocked]
 * @prop { boolean } [hideErrors]
 * @prop { number } [totalAbandonedCarts]
 */

/** @type { React.FC<AbandonedCartsCountProps> } */
export const AbandonedCartsCount = ({
  isPageAbandonedCarts,
  totalAbandonedCarts,
  mocked = false,
  hideErrors = false
}) => {
  /** @type {[DataProps, (t: DataProps) => void ]} */
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [hasError, setError] = useState(false)

  const { add: addToast } = useToast()

  useEffect(() => {
    if (!addToast || mocked) return
    setLoading(true)
    getAbandonedCart()
      .then(res => {
        setData(res)
      })
      .catch(e => {
        console.error(e)
        setError(true)
        if (!hideErrors) {
          addToast('Erro ao carregar carrinhos abandonados', 'danger')
        }
      })
      .finally(() => setLoading(false))
  }, [addToast, mocked, hideErrors])

  if (hasError) {
    return null
  }

  return (
    <AbandonedContainer>
      <Card
        className='card'
        style={{ maxWidth: isPageAbandonedCarts ? '265px' : '' }}
      >
        <div className='container'>
          {!isPageAbandonedCarts && (
            <CardHeader
              titleCard='Carrinhos abandonados'
              linkUrl={loading ? '#' : 'app/carts-abandoneds'}
            />
          )}
          {loading ? (
            <LoadingAnimation className='loadingStyle' />
          ) : (
            <div>
              <div
                className='content'
                style={{
                  marginTop: isPageAbandonedCarts ? '0px' : '',
                  marginBottom: isPageAbandonedCarts ? '0px' : ''
                }}
              >
                <PurchaseIcon customCss={[iconPurchase]} />
                <div className='totalCount'>
                  <p>
                    {isPageAbandonedCarts
                      ? 'Registros apresentados'
                      : 'Total de registros apresentados'}
                  </p>
                  {mocked ? (
                    <span>000</span>
                  ) : (
                    <span>
                      {isPageAbandonedCarts
                        ? totalAbandonedCarts
                        : data.count?.toLocaleString('pt-BR')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </AbandonedContainer>
  )
}
