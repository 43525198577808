import { useToast } from '@bonitour/components'
import React, { useCallback, useMemo, useState } from 'react'
import { PopUp } from '../../PopUp/PopUp'
import { ShareEmail } from './ShareEmail'
import { ShareWhatsapp } from './ShareWhatsapp'
import { SharingInfo } from './SharingInfo'
import { ShareModalContainer, shareModalPopUp } from './styles'

/**
 * @typedef {{
 * name: string
 * reservationRef: string
 * encodedItineraryId: string
 * quotedItineraryId: string
 * }} QuotationDataProps
 */

/**
 * @typedef {{
 * isOpen: boolean
 * itineraryId: string
 * regionName: string
 * handleClose: () => void
 * quotation: QuotationDataProps
 * host: string
 * }} AgentShareModalProps
 */

/** @typedef { 'shareError' | 'sharingInfo' | 'shareWhatsapp' | 'shareEmail' | 'shareEnd' } ShareStages */

/** @type { QuotationDataProps } */
const BASE_QUOTATION_DATA = {
  name: '',
  reservationRef: '',
  encodedItineraryId: '',
  quotedItineraryId: ''
}

/** @type { React.FC<AgentShareModalProps> } */
export const AgentShareModal = ({
  isOpen,
  itineraryId = '',
  regionName = '',
  handleClose,
  quotation: quotationData = BASE_QUOTATION_DATA,
  host = ''
}) => {
  const [stage, setStage] = useState('sharingInfo')
  const [error, setError] = useState('')

  const { add } = useToast()

  const addToast = useCallback(
    ({ text, type = undefined }) => {
      add(text, type)
    },
    [add]
  )

  const handleConcludeSharing = useCallback(() => {
    setStage('sharingInfo')
    addToast({ text: 'Cotação compartilhada com sucesso', type: 'success' })
  }, [addToast])

  const handleCloseModal = useCallback(() => {
    setStage('sharingInfo')
    handleClose()
  }, [handleClose])

  const handleErrors = useCallback(
    err => {
      setStage('shareError')
      if (typeof err === 'string') {
        setError(err)
      }
      addToast({ text: 'Erro ao compartilhar cotação', type: 'danger' })
    },
    [addToast]
  )

  const changeStage = useCallback(
    /** @type {( newStage: ShareStages) => void} */
    newStage => setStage(newStage),
    []
  )

  const shareLink = useMemo(() => {
    const uri = `/share/${quotationData.encodedItineraryId}`

    return {
      url: `${host}${uri}`,
      uri
    }
  }, [host, quotationData.encodedItineraryId])

  return (
    <PopUp
      onClose={handleCloseModal}
      isVisible={isOpen}
      overlayColor='black'
      overlayOpacity={0.35}
      cardCustomStyles={shareModalPopUp}
      blockScroll
    >
      <ShareModalContainer>
        <h2 className='titleSendItinerary'>Enviar roteiro</h2>
        {stage === 'shareError' || (!itineraryId && !quotationData) ? (
          <>
            <b>Erro ao compartilhar cotação</b>
            {error === 'minium_advance' ? (
              <p className='error__message'>Roteiro expirado</p>
            ) : null}
          </>
        ) : stage === 'sharingInfo' ? (
          <SharingInfo
            reservationRef={quotationData.reservationRef}
            travelerName={quotationData.name}
            shareLink={shareLink?.url}
            addToast={addToast}
            onNext={changeStage}
            i18n={{
              copyLinkAria: 'Copiar Link',
              itineraryDetails: 'Detalhes do roteiro',
              linkCopiedAlert: 'Link copiado com sucesso',
              refCode: 'Código de referência',
              shareByEmailAria: 'Compartilhar por Email',
              shareByWhatsappAria: 'Compartilhar por WhatsApp',
              shareItinerary: 'Compartilhar roteiro',
              travelersName: 'Nome do viajante',
              titleSendItineraryMobile: 'Enviar roteiro',
              whatsappLabel: 'WhatsApp',
              emailLabel: 'E-mail'
            }}
          />
        ) : stage === 'shareWhatsapp' ? (
          <ShareWhatsapp
            onNext={handleConcludeSharing}
            shareLink={shareLink?.url}
            onPrevious={() => setStage('sharingInfo')}
            regionName={regionName}
            i18n={{
              backButton: 'Voltar',
              shareButton: 'Enviar',
              whatsappInputAria: 'WhatsApp',
              whatsappPlaceholder: 'WhatsApp',
              titleSendItineraryMobile: 'Enviar roteiro',
              shareWhatsapp: 'Compartilhar roteiro por whatsApp'
            }}
          />
        ) : stage === 'shareEmail' ? (
          <ShareEmail
            onNext={handleConcludeSharing}
            uri={shareLink?.uri}
            onError={handleErrors}
            clientName={quotationData.name}
            onPrevious={() => setStage('sharingInfo')}
            itineraryId={quotationData.quotedItineraryId}
            i18n={{
              backButton: 'Voltar',
              shareButton: 'Enviar',
              emailPlaceholder: 'Email',
              emailInputAria: 'Email',
              titleSendItineraryMobile: 'Enviar roteiro',
              shareEmail: 'Compartilhar roteiro por e-mail'
            }}
          />
        ) : null}
      </ShareModalContainer>
    </PopUp>
  )
}
