import { colors } from '@bonitour/components'

export const quotationsStatusData = [
  { name: 'error', alias: '', color: 'transparent' },
  { name: 'unreserved', alias: 'Criado', color: `${colors.indigo1}` },
  { name: 'opened', alias: 'Aberto', color: `${colors.yellow2}` },
  { name: 'reserved', alias: 'Reservado', color: `${colors.green1}` },
  { name: 'confirmed', alias: 'Pago', color: `${colors.gray4}` },
  { name: 'checked_in', alias: 'Pago', color: `${colors.gray4}` },
  { name: 'canceled', alias: 'Cancelado', color: `${colors.red3}` },
  { name: 'expired', alias: 'Expirado', color: `${colors.gray9}` }
]

export const abandonedCartsStatusData = quotationsStatusData.map(item => {
  const saleConverted =
    item?.name === 'confirmed' || item?.name === 'checked_in'
  return {
    ...item,
    alias: saleConverted ? 'Convertido em venda' : 'Abandonado',
    color: saleConverted ? colors.green1 : colors.red3
  }
})
