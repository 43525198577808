/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import {
  getCustomPage,
  postPurchasingPolicy,
  patchPurchasingPolicy
} from './Documents.io'

import { GenericDocumentPage } from './GenericDocumentPage'
import { useCallback } from 'react'

export const PurchasingPolicy = () => {
  const handleGetDoc = useCallback(async () => {
    const res = await getCustomPage({ slug: 'purchase-policy' })
    return res?.content || null
  }, [])

  const handlePostDoc = useCallback(
    async data => await postPurchasingPolicy(data),
    []
  )

  const handlePatchDoc = useCallback(
    async data => await patchPurchasingPolicy(data),
    []
  )

  return (
    <GenericDocumentPage
      getDoc={handleGetDoc}
      postDoc={handlePostDoc}
      patchDoc={handlePatchDoc}
      successMessage='Política de compras salva com sucesso!'
      successRemovalMessage='Política de compras removida com sucesso!'
      impossibleToSaveEmptyMessage='Não é possível salvar uma política de compra vazia!'
      failedToSaveMessage='Não foi possível salvar as alterações, tente novamente mais tarde'
      documentTitle='Política de compras'
    />
  )
}
