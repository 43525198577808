const ALL_CUSTOM_LANGUAGES = [
  { label: 'Português', value: 'pt-br' },
  { label: 'Inglês', value: 'en-us' },
  { label: 'Espanhol', value: 'es-es' }
]

export const getUnselectedLanguages = selectedLanguages => {
  const selectedLanguagesSet = new Set(selectedLanguages)
  return ALL_CUSTOM_LANGUAGES.filter(
    ({ value }) => !selectedLanguagesSet.has(value)
  )
}

export const getLanguageData = language => {
  return ALL_CUSTOM_LANGUAGES.find(({ value }) => value === language)
}
