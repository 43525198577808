const SENIORS_AGE = 65
const ADULTS_AGE = 30

export const countTravelersOnAgeRange = ({
  adults = 0,
  childrenAges = [],
  seniors = 0,
  maxAge = Number.MAX_SAFE_INTEGER,
  minAge = -1
}) => {
  const ageArray = [
    ...childrenAges,
    ...Array(adults).fill(ADULTS_AGE),
    ...Array(seniors).fill(SENIORS_AGE)
  ]

  const count = ageArray.reduce((acc, age) => {
    if (age >= minAge && age <= maxAge) {
      return acc + 1
    }

    return acc
  }, 0)

  return count
}
