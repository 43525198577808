/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { identity } from '@bonitour/common-functions'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckboxInputGroup,
  CloseIcon,
  EditInfoIcon,
  useToast
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { Portal } from 'components/Portal/Portal'
import { motion } from 'framer-motion'
import { useMemo, useState, useCallback } from 'react'
import { usePermission } from 'Shared/contexts/Permissions'
import {
  ServiceTypeOrderContainer,
  ServiceTypeOrderBg,
  ServiceTypeOrderInfoModalCard,
  ServiceTypeOrderContent,
  ServiceTypeOrderModalButtonsBar,
  EditButton
} from './ServiceTypeOrder.style'

/**
 * @type {React.FC<{
 *   onClose: () => void
 *   onSave: (args: {
 *     updateAllRegions: boolean,
 *     priorityList: string[],
 *   }) => void
 *   dataTypes: string[]
 *   region: string
 * }>}
 */
const ServiceTypeOrderModal = ({ onClose, dataTypes, region, onSave }) => {
  const [types, setTypes] = useState(dataTypes)
  const [isAllRegions, setIsAllRegions] = useState(false)

  const labelsTypes = useMemo(() => {
    return {
      'Experiences::Activity': 'Atividades',
      'Experiences::Transport': 'Transportes'
    }
  }, [])

  const onServiceTypeOrder = useCallback(
    (index, order) => {
      if (
        (order === 'up' && index < 1) ||
        (order === 'down' && index === types.length - 1)
      ) {
        return types
      }

      const newArr = types.slice()
      const otherIndex = order === 'up' ? index - 1 : index + 1

      newArr[otherIndex] = types[index]
      newArr[index] = types[otherIndex]

      setTypes(newArr)
    },
    [types]
  )

  const lastIndex = types.length - 1

  const toggleAllRegions = useCallback(
    () => setIsAllRegions(value => !value),
    []
  )

  const opacity = useCallback(
    isDisabled => ({
      opacity: isDisabled ? 0.5 : 1
    }),
    []
  )

  const { allowed: canReorder } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const { add: addToast } = useToast()

  const handleSubmit = useCallback(() => {
    if (!canReorder) {
      return addToast(
        'Usuário sem permissão de escrita/atualização do Backoffice'
      )
    }
    onSave({
      updateAllRegions: isAllRegions,
      priorityList: types
    })
  }, [isAllRegions, onSave, types, addToast, canReorder])

  return (
    <Portal selector='__MODALS_PORTAL__'>
      <ServiceTypeOrderContainer>
        <ServiceTypeOrderBg type='button' onClick={onClose} />
        <ServiceTypeOrderInfoModalCard>
          <button
            type='button'
            onClick={onClose}
            className='close-modal-button'
          >
            <CloseIcon />
          </button>
          <div className='modal-title'>
            <b>Organizar experiências em {region}</b>
          </div>
          <p className='info'>
            Escolha o tipo de experiência que deseja apresentar na primeira
            posição da tela inicial do seu e-commerce
          </p>

          {types.map((type, index) => (
            <ServiceTypeOrderContent key={type} layout>
              <span>{labelsTypes[type] || type}</span>
              <div>
                <motion.button
                  disabled={index === 0}
                  initial={opacity(index === 0)}
                  animate={opacity(index === 0)}
                  transition={{ duration: 0.5 }}
                  onClick={() => onServiceTypeOrder(index, 'up')}
                >
                  <ArrowUpIcon />
                </motion.button>
                <motion.button
                  disabled={index === lastIndex}
                  initial={opacity(index === lastIndex)}
                  animate={opacity(index === lastIndex)}
                  transition={{ duration: 0.5 }}
                  onClick={() => onServiceTypeOrder(index, 'down')}
                >
                  <ArrowDownIcon />
                </motion.button>
              </div>
            </ServiceTypeOrderContent>
          ))}
          <div className='all-regions'>
            <CheckboxInputGroup
              onChange={toggleAllRegions}
              checked={isAllRegions}
              disabled={false}
              id='all-regions'
            >
              Aplicar esta configuração em todas as regiões
            </CheckboxInputGroup>
          </div>
          <ServiceTypeOrderModalButtonsBar>
            <button className='cancel_btn' onClick={onClose}>
              Cancelar
            </button>

            <button className='add_btn' onClick={handleSubmit}>
              Salvar
            </button>
          </ServiceTypeOrderModalButtonsBar>
        </ServiceTypeOrderInfoModalCard>
      </ServiceTypeOrderContainer>
    </Portal>
  )
}

/**
 * @type {React.FC<{
 *   onSave?: (args: {
 *     updateAllRegions: boolean,
 *     priorityList: string[],
 *   }) => void
 *   savedPriorities: string[]
 *   regionName: string
 * }>}
 */
export const ServiceTypeOrder = ({
  regionName,
  savedPriorities = [],
  onSave = identity
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = useCallback(() => setIsModalOpen(value => !value), [])
  const handleSave = useCallback(
    params => {
      onSave(params)
      setIsModalOpen(false)
    },
    [onSave]
  )

  return (
    <>
      <EditButton onClick={toggleModal} aria-pressed={isModalOpen}>
        <EditInfoIcon />
      </EditButton>
      {isModalOpen && (
        <ServiceTypeOrderModal
          dataTypes={savedPriorities}
          onClose={toggleModal}
          region={regionName}
          onSave={handleSave}
        />
      )}
    </>
  )
}
