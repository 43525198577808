import styled from '@emotion/styled'

export const InlineToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.625rem;

  .toggle__container {
    margin: unset;

    .toggle {
      max-width: 3.125rem;
    }

    .label {
      min-width: 8.438rem;
    }
  }
`
