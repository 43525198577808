import { Text } from 'slate'
import { EDITOR_TOOLS } from '../consts/EditorConsts'

export const serializeToMarkdown = (node, meta = {}) => {
  if (Text.isText(node)) {
    let string = node.text.trim()
    const hasSpaceBefore = node.text.startsWith(' ')
    const hasSpaceAfter = node.text.endsWith(' ')
    const isOnlySpace = /^( )*$/.test(node.text)

    // @ts-ignore
    if (node.bold) {
      string = `**${string}**`
    }
    // @ts-ignore
    if (node.italic) {
      string = `*${string}*`
    }

    // @ts-ignore
    if (node.underline) {
      string = `<u>${string}</u>`
    }

    // @ts-ignore
    if (node.strikethrough) {
      string = `~~${string}~~`
    }

    return isOnlySpace
      ? ' '
      : `${hasSpaceBefore ? ' ' : ''}${string}${hasSpaceAfter ? ' ' : ''}`
  }

  const children = node.children.map(n => serializeToMarkdown(n)).join('')

  const buildOrderedListChildren = listNode => {
    return listNode.children
      .map((cNode, i) =>
        serializeToMarkdown(cNode, { idx: i, inOrderedList: true })
      )
      .join('')
  }

  const buildListItem = itemNode => {
    if (meta.inOrderedList) {
      return `${meta.idx + 1}. ${children}\n`
    }

    return `- ${children}\n`
  }

  switch (node.type) {
    case EDITOR_TOOLS.orderedList:
      return `${buildOrderedListChildren(node)}\n\n`
    case EDITOR_TOOLS.h1:
      return `# ${children}\n`
    case EDITOR_TOOLS.h2:
      return `## ${children}\n`
    case EDITOR_TOOLS.h3:
      return `### ${children}\n`
    case EDITOR_TOOLS.h4:
      return `#### ${children}\n`
    case EDITOR_TOOLS.h5:
      return `##### ${children}\n`
    case EDITOR_TOOLS.h6:
      return `###### ${children}\n`
    case EDITOR_TOOLS.listItem:
      return buildListItem(node)
    case EDITOR_TOOLS.paragraph:
      return children ? `${children}\n\n` : ' \n\n'
    default:
      return `${children}\n\n`
  }
}
