import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'
import { FiFilter } from 'react-icons/fi'

import {
  FilterPopUp,
  FiltersContainer as Container,
  OpenFiltersButton
} from './Filters.styles'
import { useOnClickOutside } from 'utils/utilityHooks/useOnClickOutside'
import {
  Button,
  CheckIcon,
  FilterIcon,
  GhostButton,
  Row
} from '@bonitour/components'

const FiltersContext = createContext({
  toggleIsOpen: () => console.error('toggleIsOpen not defined'),
  handleCloseFilters: () => console.error('handleCloseFilters not defined'),
  isOpen: null
})

export const Filter = ({
  onResetFilters,
  onSubmit,
  hasFilterApplied,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const filterContainerRef = useRef(null)

  const filterPopUpRef = useRef(null)

  const toggleIsOpen = useCallback(
    () =>
      setIsOpen(curr => {
        if (!curr) {
          setTimeout(() => {
            return filterPopUpRef?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          })
        }
        return !curr
      }),
    []
  )
  const handleCloseFilters = useCallback(() => setIsOpen(false), [])

  useOnClickOutside(filterContainerRef, handleCloseFilters)

  return (
    <FiltersContext.Provider
      value={{ toggleIsOpen, handleCloseFilters, isOpen }}
    >
      <Container ref={filterContainerRef}>
        <OpenFiltersButton
          type='button'
          onClick={toggleIsOpen}
          className={`filters__btn ${hasFilterApplied ? 'has_filter' : ''} ${
            isOpen ? 'is_open' : ''
          }`}
          aria-pressed={isOpen}
        >
          <FiFilter />
        </OpenFiltersButton>
        {isOpen && (
          <FilterPopUp ref={filterPopUpRef}>
            <div className='container__title'>
              <FilterIcon className='filter__icon' />
              <b style={{ margin: 'auto 0' }} className='popup__title'>
                Filtros
              </b>
            </div>
            {children}
            <Row className='buttons_container'>
              <GhostButton className='reset_button' onClick={onResetFilters}>
                Limpar
              </GhostButton>
              <Button
                className='submit_button'
                type='button'
                onClick={onSubmit}
              >
                Aplicar <CheckIcon />
              </Button>
            </Row>
          </FilterPopUp>
        )}
      </Container>
    </FiltersContext.Provider>
  )
}

export const useFilters = () => {
  const context = useContext(FiltersContext)

  if (context.isOpen === null) {
    throw new Error('useFilters must be used within a Filter')
  }

  return context
}
