import unified from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'

/** @type {(markdown: string) => Promise<string>} */
export const convertMarkdownToHypertext = async markdown => {
  const hyperText = await new Promise((resolve, reject) => {
    unified()
      .use(remarkParse) // Parse markdown content to a syntax tree
      .use(remarkRehype) // Turn markdown syntax tree to HTML syntax tree, ignoring embedded HTML
      .use(rehypeStringify) // Serialize HTML syntax tree
      .process(markdown)
      .then(file => resolve(String(file)))
      .catch(_error => reject(new Error('Parsing Error - Md to Hyper')))
  })

  return hyperText
}
