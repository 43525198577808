import { colors } from '@bonitour/components'
import styled from '@emotion/styled'

export const UploadContainer = styled.div`
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;

  display: flex;

  margin-top: 40px;
  max-width: 400px;

  h1 {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.gray2};
  }

  .uploadContent {
    border: 1px dashed ${colors.gray8};
    border-radius: 10px;
    padding: 10px;
  }

  .dropzoneFileDragging {
    background-color: ${colors.gray9};
    .uploadDropZone {
      cursor: default;
    }
  }

  .uploadDropZone {
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    gap: 5px;

    display: flex;

    padding: 10px;

    cursor: pointer;

    i {
      font-size: 35px;
      color: ${colors.gray3};
    }

    p {
      font-size: 12px;
      font-weight: 600;

      &:nth-child(2) {
        color: ${colors.gray4};
      }

      &:nth-child(3) {
        color: ${colors.gray5};
        text-decoration: underline;
      }
    }

    input {
      display: none;
    }
  }

  .idealWeightStyle {
    color: ${colors.gray4};
    font-size: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
