/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// eslint-disable-next-line no-unused-vars
import { head, identity } from '@bonitour/common-functions'
import { jsx } from '@emotion/react'
import { useCallback, useRef, useState } from 'react'
import { UploadContainer } from './UploadImage.style'
import { isFileSizeValid, isFileTypeValid } from './UploadImage.utils'

/**
 * @typedef {{
 * labels: {
 *  titlePage: string,
 *  titleContent: string,
 *  subTitleContent: string
 * }
 * onAdd: (e) => identity
 * onError: (e) => identity
 * limitMbSize: number
 * accept: string
 * disabled: boolean
 * }} UploadImageProps
 */

/** @type { React.FC<UploadImageProps> } */
export const UploadImage = ({
  labels,
  onAdd,
  onError,
  limitMbSize,
  accept,
  disabled
}) => {
  const [fileDragged, setFileDragged] = useState(false)

  const dropzoneReference = useRef(null)

  const validateAndEmitFiles = useCallback(
    files => {
      const fileArray = Array.isArray(files) ? files : Array.from(files)
      if (!isFileTypeValid(accept, fileArray)) {
        onError('Formato inválido')
        return false
      } else if (!isFileSizeValid(limitMbSize, fileArray)) {
        onError('Arquivo está acima do limite de tamanho')
        return false
      } else {
        return true
      }
    },
    [accept, limitMbSize, onError]
  )

  const onFileAdd = useCallback(
    sentFiles => {
      if (validateAndEmitFiles(sentFiles)) {
        onAdd(sentFiles)
      }
    },
    [onAdd, validateAndEmitFiles]
  )

  const handleInputFilesUpload = useCallback(
    () => onFileAdd(dropzoneReference?.current?.files || []),
    [onFileAdd]
  )

  const activateFileInput = useCallback(
    event => {
      if (disabled) {
        return
      }
      event.stopPropagation()
      dropzoneReference.current.click()
    },
    [disabled]
  )

  const activateFileDrag = useCallback(
    event => {
      if (disabled) {
        return
      }
      event.stopPropagation()
      event.preventDefault()
      setFileDragged(true)
    },
    [disabled]
  )

  const deactivateFileDrag = useCallback(() => {
    if (disabled) {
      return
    }
    setFileDragged(false)
  }, [disabled])

  const onFileDrop = useCallback(
    event => {
      if (disabled) {
        return
      }
      event.stopPropagation()
      event.preventDefault()
      const files = event.dataTransfer.files
      const filteredFiles = [head(files)].filter(file => file instanceof File)
      if (validateAndEmitFiles(filteredFiles)) {
        onFileAdd(filteredFiles)
      }
      deactivateFileDrag()
    },
    [deactivateFileDrag, disabled, onFileAdd, validateAndEmitFiles]
  )

  return (
    <UploadContainer>
      <h1>{labels.titlePage}</h1>
      <div
        className={[
          'uploadContent',
          fileDragged || disabled ? 'dropzoneFileDragging' : ''
        ].join(' ')}
      >
        <div
          className='uploadDropZone'
          onClick={activateFileInput}
          onKeyPress={activateFileInput}
          onDragOver={activateFileDrag}
          onDragLeave={deactivateFileDrag}
          onDrop={onFileDrop}
        >
          {disabled ? (
            <strong>Limite de imagens atingido</strong>
          ) : (
            <>
              <i className='b-font-upload' />
              <p>{labels.titleContent}</p>
              <p>{labels.subTitleContent}</p>
            </>
          )}

          <input
            ref={dropzoneReference}
            type='file'
            accept={accept}
            onChange={handleInputFilesUpload}
            disabled={disabled}
          />
        </div>
      </div>
      <span className='idealWeightStyle'>Peso: até 2.5MB</span>
    </UploadContainer>
  )
}
