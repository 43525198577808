import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import { BREAK_POINTS } from 'assets/styles/queries'

export const tableContainer = css`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: scroll;

  display: flex;

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
  }
`

export const headingRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px 40px;
  @media (max-width: ${BREAK_POINTS.beforeLaptop + 15}px) {
    margin: 20px 20px 40px;
  }

  .backButton {
    padding: 0 40px;
  }
`

export const filterDrawerContainer = css`
  padding: 5px;
`

export const secondaryRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
`

export const quotationsPaginationContainer = css`
  margin-top: 0;
`

export const filterContainer = css`
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 25px;
  background-color: ${colors.primary};

  &::after {
    width: 8px;
    height: 8px;
    border-radius: 25px;
    transform: translate(-15px, 9px);
    content: '';
    display: inline-block;
    position: absolute;
    background-color: ${colors.yellow2};
  }
`

export const iconStyle = ({ hasFilters, isOpen }) => css`
  font-size: 25px;
  color: ${colors.gray6};
  padding: 10px;
  border-radius: var(--round-element-radius, 100vw);
  width: 40px;
  height: 40px;
  transition: background-color 0.25s, color 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin: 0;
  box-sizing: border-box;
  .b-font-filter {
    transform: translateY(2px);
  }

  ${isOpen &&
  `
  background-color: ${colors.primary};
  color: ${colors.white};
  `}

  .b-font-filter {
    ${hasFilters && 'transform: translateY(2px);'}
    ${hasFilters &&
    `
      &::after {
        width: 8px;
        height: 8px;
        border-radius: 25px;
        transform: translate(-5px, -2px);
        content: '';
        display: inline-block;
        position: absolute;
        background-color: ${colors.yellow2};
      }
    `}
  }

  &:hover {
    ${!isOpen && `background-color: ${colors.white};`}
    cursor: pointer;
  }
`

export const searchBarContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  .searchBar__element {
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .component-popover-arrow {
    right: 20px;
  }
  .component-popover {
    margin-right: -10px;
  }
`
