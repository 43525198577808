import { CompanyParser } from 'core/Parser/Company'
import { orbCore } from './Orb/Service'

const { companiesDomain, checkoutDomain } = orbCore

export const CompanyService = {
  listUserCompanies(active) {
    return companiesDomain
      .listUserCompanies(active)
      .then(CompanyParser.listUserCompanies)
  },
  getCardBrands() {
    return checkoutDomain.getCardBrands().then(CompanyParser.getCardBrands)
  }
}
