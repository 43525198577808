import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const CardHeaderContainer = styled.div`
  justify-content: space-between;
  align-items: center;

  display: flex;

  margin-top: 10px;

  a {
    text-decoration: none;
  }

  .dropdown {
    justify-items: center;
    align-items: center;

    display: flex;

    color: ${colors.primary};

    span {
      margin-right: 10px;
      font-size: 14px;
      font-weight: 700;
    }

    .icon {
      position: relative;
      top: 1px;
      font-size: 23px;
    }
  }
`
export const title = css`
  margin-bottom: 0;

  font-size: 22px;
  font-weight: 700;
  color: ${colors.gray1};
`
