import { css } from '@emotion/react'

export const saveButton = css`
  width: 102px;
`

export const rowButtons = css`
  margin-top: 50px;
  width: 300px;
`

export const customCardStyles = css`
  position: relative;
  overflow: unset;
`

export const customTooltip = css`
  margin-left: 10px;
  width: 250px;

  .tooltip-on-hover {
    border-radius: 5px 5px 5px 0;
  }
`

export const documentsHeader = css`
  p {
    font-size: 1.25rem;
  }
`
