import { extractData, xpertAxiosInstance } from 'external/axios'

const xpertInstance = xpertAxiosInstance()
/**
 * @todo: route to get experiences
 * that the current company sells
 */
export function getExperiences(page = 1, type = 'Experiences::Activity') {
  return xpertInstance
    .get('/v2/activities/backoffice', {
      params: {
        per_page: 50,
        page,
        service_type: type
      }
    })
    .then(extractData)
}

export function getExperience(id) {
  return xpertInstance.get(`/activities/${id}/backoffice`).then(extractData)
}

export function patchExperience(experience) {
  return xpertInstance
    .patch(
      `/activities/${experience.id}/backoffice`,
      preprocessPatchData(experience)
    )
    .then(extractData)
}

export function patchPosition(experience) {
  return xpertInstance
    .patch(
      `/activities/${experience.id}/backoffice/position`,
      preprocessPatchData(experience)
    )
    .then(extractData)
}

export function postImageExperience(experience) {
  return xpertInstance
    .post(
      `/galleries/${experience.id}/image/backoffice`,
      preprocessPatchData(experience)
    )
    .then(extractData)
}

export function deleteImageExperience(experience) {
  return xpertInstance
    .delete(
      `/galleries/${experience.id}/image/${experience.image_id}/backoffice`
    )
    .then(extractData)
}

export function patchImageExperience(experience) {
  return xpertInstance
    .patch(
      `/galleries/${experience.id}/image/backoffice`,
      preprocessPatchData(experience)
    )
    .then(extractData)
}

/**
 * @returns {Promise<{[region: string]: string[]}>}
 */
export function getServiceTypePriorities() {
  return xpertInstance
    .get('v2/config/priority_listing_by_type')
    .then(extractData)
}

/**
 * @param {{
 *   updateAllRegions: boolean,
 *   regionId: string,
 *   priorityList: string[]
 * }} args
 * @returns {Promise<undefined>}
 */
export function putServiceTypePriorities({
  updateAllRegions,
  regionId,
  priorityList
}) {
  return xpertInstance
    .patch('v2/config/priority_listing_by_type', {
      all_regions: updateAllRegions,
      custom_region_id: regionId,
      priority_listing_by_type: priorityList
    })
    .then(extractData)
}

function preprocessPatchData(data) {
  const copyData = { ...data }
  delete copyData.id

  if ('position' in copyData) {
    copyData.position = JSON.stringify(copyData.position)
  }

  const formData = new window.FormData()
  Object.entries(copyData).forEach(([key, data]) =>
    appendData(key, data, formData)
  )
  return formData
}

function appendData(key, data, formData) {
  if (data === undefined || data === null) {
    return
  }
  if (data instanceof Array) {
    data.forEach(item => appendData(`${key}[]`, item, formData))
    return
  } else if (data instanceof window.File) {
    formData.append(key, data)
    return
  } else if (data instanceof Object) {
    appendData(key, JSON.stringify(data), formData)
    return
  }
  formData.append(key, data)
}

export function browseItinerarySuggestions(regionId, page = 1, filters = {}) {
  return xpertInstance
    .get('/itinerary_suggestions', {
      params: {
        ...filters,
        tourist_region_id: regionId,
        per_page: 50,
        page
      }
    })
    .then(extractData)
}
