const SOCIAL_MEDIA_REGEX = {
  facebook:
    /^(?:https?:\/\/)?([a-zA-Z]{2}-[a-zA-Z]{2})?(?:www\.|m\.|mobile\.|touch\.|mbasic\.)?((face|facebook|fb)(\.[a-zA-Z]{2,3}){1,2})\//gi,
  instagram:
    /^((?:https?:)?\/\/)?(?:www\.)?(?:instagram\.com(\.br)?|instagr\.am)\//gi,
  linkedin:
    /^((?:https?:)(\/\/)?)?(www\.||([a-z]{2,3}\.))?((linkedin\.com)||linked\.in)\//gi,
  youtube:
    /^((?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com(\.br)?|youtu\.be)\//gi,
  blog: /^(https?:)?\/\//gi
}

/** @type {(value: string) => string} */
export const formatGoogleAnalytics = value => value?.toUpperCase()

/** @type {(value: string) => string} */
export const formatTiktok = value => {
  value = value
    ?.replace(
      /^((?:https?:)?\/\/)?(?:www\.)?(?:tiktok\.com(\.br)?|tiktok\.)\//gi,
      ''
    )
    .replace(/[^a-z0-9-_.]/gi, '')
  if (value) {
    return '@'.concat(value)
  }
  return value
}

/** @type {(value: string) => string} */
export const formatGoogleAds = value =>
  value
    ?.replace(/\W/, '')
    .toUpperCase()
    ?.replace(/([\w]{2})([\w]{4,10})/g, '$1-$2')
    ?.replace(/([^-\w])/g, '')

/** @type {(value: string) => string} */
export const formatGoogleTagManager = value =>
  value
    ?.replace(/\W/, '')
    .toUpperCase()
    ?.replace(/([\w]{3})([\w]{5})/g, '$1-$2')
    ?.replace(/([^-\w])/g, '')

/** @type {(value: string) => (value: string) => string} */
export const formatSocial = socialMedia => {
  return value => value?.replace(SOCIAL_MEDIA_REGEX[socialMedia], '')
}
