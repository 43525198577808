import styled from '@emotion/styled'

const { colors } = require('@bonitour/components')
const { css } = require('@emotion/react')

export const resetPosition = css`
  align-items: unset;
  justify-content: unset;
`

export const nameStyle = css`
  cursor: pointer;
`

export const arrowIconStyle = isOpen => css`
  cursor: pointer;
  font-size: 10px;
  padding: 8px;
  transition: transform 200ms ease;
  transform: rotate(${isOpen ? '90' : '0'}deg);
`

export const statusStyle = statusColor => css`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    margin: 5px;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: var(--round-element-radius, 100vw);
    background-color: ${statusColor};
  }
`

export const smallTitleCss = css`
  font-weight: bold;
  color: ${colors.gray4};
  padding-bottom: 1em;
  font-size: 16px;
`

export const quotationDetailsStyle = css`
  max-width: 100%;
  border: 1px solid ${colors.gray7};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .detailed-price {
    position: relative;
  }

  h3 {
    align-self: flex-start;
  }

  .info__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1em;
    width: 100%;

    > div > section {
      > span {
        display: block;
      }
      &:nth-child(1) {
        margin-right: 12%;
      }
    }
  }

  table {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    width: 100%;
    background-color: ${colors.white};

    th:nth-child(1),
    td:nth-child(2) {
      width: 30%;
    }

    th:nth-child(3) {
      width: 10%;
    }

    th:nth-child(4) {
      width: 7%;
    }

    th:nth-child(5) {
      width: 9%;
    }

    th:nth-child(6) {
      width: 14%;
    }

    tr > th {
      ${smallTitleCss}
    }

    td {
      padding-bottom: 10px;
    }

    tr:nth-of-type(2n) td {
      background-color: ${colors.white};
    }
  }
`

export const peopleRowStyle = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 15px;
`

export const checkBoxContainer = css`
  display: flex;
  align-items: center;
`

export const cardStyle = css`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 100px;
  width: 100%;
  left: 0;
  bottom: 1rem;
  border-radius: 0;
  font-size: 24px;
  z-index: 999;
  font-weight: bold;
  color: ${colors.gray3};
  align-items: center;

  b {
    color: ${colors.primary};
  }

  Button {
    margin-right: 60px;
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    background-color: ${colors.white};
  }
`

export const smallFont = css`
  td {
    font-size: 12px;
  }
`

export const limitedTruncateStyle = css`
  max-width: 10.5rem;
  overflow: hidden;
  line-height: 2rem;
  line-clamp: 1;
  text-overflow: ellipsis;
`

export const PackageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-weight: bold;

  svg {
    font-size: 1.8em;
  }

  span.title__ctn {
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
