import React, { useCallback } from 'react'
import {
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'
import { OrderByColumn } from './components/OrderByColumn'

const columns = [
  {
    label: 'Criação do roteiro',
    key: 'created_at'
  },
  {
    label: 'Região do roteiro',
    key: 'region__name'
  },
  {
    label: 'Data de check-in',
    key: 'travel_date__start_date'
  },
  {
    label: 'Data de check-out',
    key: 'travel_date__end_date'
  },
  {
    label: 'Viajante',
    key: 'reservation__name'
  },
  {
    label: 'E-mail',
    key: 'reservation__email'
  },
  {
    label: 'Etapa do carrinho',
    key: 'status'
  }
]

/**
 * @typedef AbandonedCartsHeaderProps
 * @prop { (e) => void } [onChange]
 * @prop { string } [order]
 */

/** @type { React.FC<AbandonedCartsHeaderProps> } */
export const AbandonedCartsHeader = ({ onChange, order }) => {
  const orderBy = useCallback(
    columnKey => {
      if (order?.endsWith(columnKey)) {
        return order?.startsWith('-') ? '-' : '+'
      } else {
        return ''
      }
    },
    [order]
  )

  return (
    <TableHeader>
      <TableHeaderRow>
        {columns.map(column => (
          <TableHeaderCell key={column?.key} colSpan={1}>
            <OrderByColumn
              order={orderBy(column?.key)}
              onChange={e =>
                onChange(e === '-' ? `-${column?.key}` : column?.key)
              }
            >
              {column?.label}
            </OrderByColumn>
          </TableHeaderCell>
        ))}
      </TableHeaderRow>
    </TableHeader>
  )
}
