import { colors } from '@bonitour/components'
import styled from '@emotion/styled'
import { BREAK_POINTS } from 'assets/styles/queries'
import { motion } from 'framer-motion'

export const ServiceTypeOrderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
`

export const ServiceTypeOrderBg = styled.button`
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 3;
`

export const ServiceTypeOrderInfoModalCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  width: 100%;
  max-width: 470px;
  max-height: calc(100% - 2rem);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  background: ${colors.white};

  z-index: 4;
  opacity: 1;
  transform: translateY(0);
  overflow-y: auto;

  .close-modal-button {
    --close-modal-button-size: 30px;
    height: var(--close-modal-button-size);
    width: var(--close-modal-button-size);
    min-width: var(--close-modal-button-size);
    min-height: var(--close-modal-button-size);
    border-radius: var(--round-element-radius, 100vw);
    top: calc(var(--close-modal-button-size) / 2);
    right: calc(var(--close-modal-button-size) / 2);

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    background: ${colors.primary};
    color: ${colors.white};
    margin-left: 10px;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 700;
    color: ${colors.gray1};
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .info {
    color: ${colors.gray3};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .all-regions {
    margin-top: 10px;

    input {
      border-color: #d4d6de;
    }

    label {
      color: ${colors.gray3};
    }
  }

  @media (max-width: ${BREAK_POINTS.afterTinyPhone}px) {
    padding: 15px;
  }
`

export const ServiceTypeOrderContent = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9f0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  min-height: 50px;
  margin-bottom: 10px;

  span {
    color: ${colors.gray3};
    font-size: 16px;
    font-weight: 700;
  }

  i {
    margin-left: 5px;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 18px;
    color: ${colors.gray3};

    &:disabled {
      cursor: default;
    }
  }

  button + button {
    margin-left: 10px;
  }
`

export const ServiceTypeOrderModalButtonsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 40px;

  button {
    width: 120px;
    min-height: 40px;
    border-radius: var(--round-element-radius, 100vw);
    border: 0;

    font-weight: 700;
    font-size: 14px;
    background: transparent;

    cursor: pointer;
  }

  button + button {
    margin-left: 20px;
  }

  .cancel_btn {
    background: ${colors.white};
    border: 1px solid ${colors.gray5};
    color: ${colors.gray4};
  }

  .add_btn {
    background: ${colors.primary};
    border: 0;
    color: ${colors.white};
  }

  @media (max-width: ${BREAK_POINTS.afterTinyPhone}px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin-top: 20px;

    button + button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`

export const EditButton = styled.button`
  border: none;
  background: none;
  color: ${colors.gray3};
  font-size: 22px;
  cursor: pointer;
`
