/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { ExternalLinkIcon } from '@bonitour/components'
import { helpInfo, helpInfoExternalLink } from '../Appearance.style'

export const ExternalLink = ({
  href,
  title = 'Mais informações em ',
  linkText,
  customCss = []
}) => {
  return (
    <p css={[helpInfo, helpInfoExternalLink, ...customCss]}>
      {title}
      <a rel='noopener noreferrer' target='_blank' href={href}>
        <span>{linkText}</span> <ExternalLinkIcon />
      </a>
    </p>
  )
}
