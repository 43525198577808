import React, { useCallback, useState } from 'react'
import { Input, InputFormGroup, MailIcon } from '@bonitour/components'
import { emailSchema } from './schemas/emailSchema'
import { shareByEmail } from './io/share'
import { Button } from 'components/Button'
import { inputContainerWithIcon, ShareButtonsContainer } from './styles'
import { validateYupSchema } from 'Shared/utils/validateYupSchema'

/**
 * @typedef {{
 * uri: string
 * clientName: string
 * itineraryId: string
 * onNext: () => void
 * onPrevious: () => void
 * onError: (err?: any) => void
 * i18n: {
 *   backButton: string
 *   shareButton: string
 *   emailPlaceholder: string
 *   emailInputAria: string
 *   titleSendItineraryMobile: string
 *   shareEmail: string
 * }
 * }} ShareEmailProps
 */

/** @type { React.FC<ShareEmailProps> } */
export const ShareEmail = ({
  uri,
  clientName,
  itineraryId,
  onNext,
  onPrevious,
  onError,
  i18n
}) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const handleShare = useCallback(async () => {
    setIsSendingEmail(true)

    const [isValid, err] = await validateYupSchema(emailSchema, { email })

    if (!isValid) {
      if (err?.email) setEmailError(err?.email)
      setIsSendingEmail(false)
      return
    }

    const [shared, error] = await shareByEmail({
      email,
      uri,
      itineraryId,
      name: clientName
    })

    setIsSendingEmail(false)

    if (shared && !error) {
      onNext()
    } else {
      onError(error)
    }
  }, [itineraryId, clientName, email, uri, onNext, onError])

  return (
    <>
      <b className='titleShareAgent'>{i18n.shareEmail}</b>

      <InputFormGroup
        errorMessage={emailError}
        customCss={[inputContainerWithIcon]}
      >
        <MailIcon className='input__icon' />
        <Input
          // @ts-ignore
          name='email'
          value={email}
          // @ts-ignore
          onChange={setEmail}
          // @ts-ignore
          ariaLabel={i18n.emailInputAria}
          placeholder={i18n.emailPlaceholder}
          required
        />
      </InputFormGroup>
      <ShareButtonsContainer>
        <Button onClick={onPrevious} outlined>
          {i18n.backButton}
        </Button>
        <Button onClick={handleShare} isLoading={isSendingEmail}>
          {i18n.shareButton}
        </Button>
      </ShareButtonsContainer>
    </>
  )
}
