import { colors } from '@bonitour/components'
import styled from '@emotion/styled'

export const VisibleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;

  h2 {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.gray2};
  }

  .visibleContent {
    flex-direction: column;
    gap: 10px;

    display: flex;

    max-width: 200px;
    height: auto;
  }

  .imageContainer {
    position: relative;

    button {
      position: absolute;
      z-index: 1;

      top: -10px;
      right: -10px;

      border: none;
      border-radius: var(--round-element-radius, 50%);
      height: 30px;
      width: 30px;

      background-color: var(--primary-color);
      cursor: pointer;

      font-weight: 700;
      color: white;
    }

    img {
      object-fit: cover;
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }
  }

  .inputTextAlt {
    width: 100%;
  }
`
