import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { classContains } from 'Shared/utils/utils.styles'

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  padding: 2px;

  ${classContains('-TooltipOnHover')} {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 0;
    background-color: transparent;
    font-size: 1rem;
    color: ${colors.gray3};
    cursor: pointer;
    padding: 6px 10px;

    i {
      height: 16px;
      width: 16px;
    }

    :hover {
      border-radius: 5px;
      background-color: ${colors.gray11};
    }
  }
`

export const ActionsButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border: 0;
  background-color: transparent;
  font-size: 1.2rem;
  color: ${colors.gray3};
  cursor: pointer;
`

export const popoverCss = css`
  .leftArrow-Popover {
    right: -14px;
  }
`
