import { useToast } from '@bonitour/components'
import {
  getCustomRegionLinkedServicesIds,
  updateCustomRegionsLinkedServices
} from 'Domains/Dashboard/CustomRegionsExperiences/io/customRegions.io'
import { useCallback, useEffect, useState } from 'react'

export const useLinkedCustomServices = ({ customRegionId }) => {
  const [servicesIds, setServicesIds] = useState([])

  const { add: addToast } = useToast()

  const fetchCustomRegionServicesIds = useCallback(() => {
    if (!customRegionId) return

    getCustomRegionLinkedServicesIds({ regionId: customRegionId })
      .then(({ services_ids }) => {
        setServicesIds(services_ids)
      })
      .catch(() => {
        setServicesIds([])
        addToast('Houve um erro ao buscar os serviços vinculados', 'error')
      })
  }, [addToast, customRegionId])

  const updateLinkedCustomServices = useCallback(
    ({ customRegionId, servicesIds }) =>
      updateCustomRegionsLinkedServices({
        customRegionId,
        servicesIds
      })
        .then(() => {
          setServicesIds(servicesIds)
        })
        .catch(() => {
          addToast('Houve um erro ao atualizar os serviços vinculados', 'error')
        }),
    [addToast]
  )

  useEffect(() => {
    if (!customRegionId) return

    fetchCustomRegionServicesIds()
  }, [customRegionId, fetchCustomRegionServicesIds])

  return {
    servicesIds,
    fetchCustomRegionServicesIds,
    updateLinkedCustomServices
  }
}
