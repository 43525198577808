/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react'
import { colors, SearchIcon } from '@bonitour/components'
import { css, jsx } from '@emotion/react'
import { identity } from 'fp-ts/lib/function'
import { useDebounce } from '@bonitour/app-functions'

const searchBarContainerStyles = css`
  width: 100%;
  max-width: 370px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 10px;

  border: 1px solid ${colors.gray7};
  background: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0 16px;

  color: ${colors.gray4};
  font-size: 24px;

  margin-bottom: 20px;

  &:focus-within {
    border: 1px solid ${colors.gray4};
  }

  input {
    color: ${colors.gray3};
    font-size: 14px;
    flex: 1;
    margin-left: 8px;
    height: 36px;
    border: none;
    outline: none;
    background: transparent;
    &::placeholder {
      opacity: 1;
    }
  }
  &.small-searchbar {
    width: 225px;
    box-sizing: border-box;
    flex-direction: row-reverse;
    input {
      width: 170px;
      box-sizing: border-box;
      margin-left: 0;
      margin-right: 8px;
    }
  }

  .search-icon {
    &:hover {
      cursor: pointer;
      color: ${colors.gray1};
    }
  }
`

/**
 * @typedef {{
 *  onDebounce: () => void,
 *  currentValue: string,
 *  small?: boolean
 *  customCss?: import('@emotion/react').SerializedStyles[]
 *  debounceTime?: number
 * }} InputProps
 * @type {(data: import('react').InputHTMLAttributes<HTMLInputElement> & InputProps ) => React.ReactElement}
 */
export const SearchBar = ({
  onDebounce = identity,
  currentValue,
  small,
  customCss = [],
  debounceTime = 1500,
  ...rest
}) => {
  const [searchTitle, setSearchTitle] = useState('')

  const debouncedTitle = useDebounce(searchTitle || undefined, debounceTime)

  useEffect(() => {
    /** @type {(str: string) => string} */
    const cleanStr = str => str?.trim().replace(/\s+/g, ' ') || ''

    const cleanedCurrentTitle = cleanStr(currentValue)
    const cleanedDebouncedTitle = cleanStr(debouncedTitle)

    if (cleanedDebouncedTitle !== cleanedCurrentTitle) {
      onDebounce(debouncedTitle)
    }
  }, [currentValue, debouncedTitle, onDebounce])

  return (
    <div
      css={[searchBarContainerStyles, ...customCss]}
      className={small ? 'small-searchbar' : 'searchBar__element'}
    >
      <input
        type='text'
        onChange={event => setSearchTitle(event.target.value)}
        {...rest}
      />
      <SearchIcon
        className='search-icon'
        onClick={() => onDebounce(debouncedTitle)}
      />
    </div>
  )
}
