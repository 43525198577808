import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'

import { TableHeader } from './Header'
import { MobileSortMenu } from './MobileSortMenu'

import { buildColumnTemplate, validateProps } from './helpers'

import { TableBody, TableHeaderRow, TableWrapper } from './styles'
import { Loading } from './Loading'
import { EmptyResult } from './EmptyResult'

export const defaultSortingHandler = _ => undefined

const TableContext = createContext({
  labels: [],
  sortableColumns: [],
  sortedOrder: '',
  sortedColumn: 0
})

const Table = ({
  labels,
  children,
  columnsWidths,
  customCss,
  sortedColumn,
  sortedOrder = '',
  onSortingChange = defaultSortingHandler,
  mobileBreakpoint = 900,
  sortableColumns = [],
  isLoading = false,
  emptyMessage = 'Nenhum item encontrado'
}) => {
  const columnTemplate = useMemo(
    () => buildColumnTemplate(labels.length, columnsWidths),
    [columnsWidths, labels]
  )

  const handleSortChange = useCallback(
    column => order => {
      const columnName = labels[column]

      if (sortableColumns.includes(columnName)) {
        onSortingChange({ column, order })
      }
    },
    [onSortingChange, labels, sortableColumns]
  )

  useEffect(() => {
    validateProps({
      labels,
      children,
      columnsWidths,
      mobileBreakpoint,
      sortableColumns
    })
  }, [children, columnsWidths, labels, mobileBreakpoint, sortableColumns])

  const isEmpty = useMemo(() => !children.length, [children])

  return (
    <TableContext.Provider
      value={{ labels, sortableColumns, sortedOrder, sortedColumn }}
    >
      <TableWrapper
        columnTemplate={columnTemplate}
        customCss={customCss}
        mobileBreakpoint={mobileBreakpoint}
        layout
      >
        <MobileSortMenu onSortingChange={onSortingChange} />
        <TableHeaderRow layout>
          {labels.map((label, index) => (
            <TableHeader
              key={index}
              label={label}
              columnIdx={index}
              onChange={handleSortChange(index)}
            />
          ))}
        </TableHeaderRow>
        <TableBody>
          {!isLoading ? (
            isEmpty ? (
              <EmptyResult message={emptyMessage} />
            ) : (
              children.map((child, idx) => (
                <Fragment key={idx}>{child}</Fragment>
              ))
            )
          ) : (
            <Loading />
          )}
        </TableBody>
      </TableWrapper>
    </TableContext.Provider>
  )
}

function useTable() {
  const context = useContext(TableContext)

  if (!context) {
    throw new Error('useTable and TableRow must be used within a Table')
  }

  return context
}

export { Table, useTable }
