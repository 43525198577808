/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useMemo, useState } from 'react'
import { formatMoney } from '@bonitour/common-functions'
import {
  BabyIcon,
  Card,
  H3,
  SortRightIcon,
  TableBodyCell,
  TableBodyRow,
  TooltipOnHover,
  AdultIcon,
  SeniorIcon
} from '@bonitour/components'
import { parseDate, parseTitleProp } from 'utils/utils'
import { abandonedCartsStatusData } from '../utils/status'
import { jsx } from '@emotion/react'

import {
  resetPosition,
  arrowIconStyle,
  checkBoxContainer,
  smallFont,
  detailsStyle,
  statusStyle,
  Icon
} from './AbandonedCartsList.style'

/**
 * @typedef DataProps
 * @prop { { name: string } } [region]
 * @prop { { name: string }[] } [custom_regions]
 * @prop {{ start_date: number; end_date: number}} [travel_date]
 * @prop {{name: string; email: string }} [reservation]
 * @prop { string } [created_at]
 * @prop { string[] } [tours]
 * @prop { string[] } [transports]
 * @prop { string } [status]
 */

/**
 * @typedef AbandonedCartsListProps
 * @prop { DataProps } [abandonedCart]
 */

/** @type { React.FC<AbandonedCartsListProps> } */
export const AbandonedCartsList = ({ abandonedCart }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { start_date: startDate = '', end_date: endDate = '' } =
    abandonedCart.travel_date
  const { name: reservationName = '', email: reservationEmail } =
    abandonedCart.reservation
  const {
    created_at: createdAt = '',
    tours: activities,
    transports = {},
    status = ''
  } = abandonedCart

  const parsedStatus = useMemo(
    () =>
      abandonedCartsStatusData.find(({ name }) => name === status) ||
      abandonedCartsStatusData[0],
    [status]
  )

  const regionName = useMemo(
    () =>
      abandonedCart?.custom_regions?.map(({ name }) => name).join(', ') ||
      abandonedCart?.region?.name ||
      '--',
    [abandonedCart]
  )

  return (
    <>
      <TableBodyRow css={smallFont}>
        <TruncateCell text={parseDate(createdAt?.split('T')[0])}>
          <CheckboxAbandonedCart
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            name={parseDate(createdAt?.split('T')[0])}
          />
        </TruncateCell>
        <TruncateCell text={regionName} />
        <TruncateCell text={parseDate(startDate)} />
        <TruncateCell text={parseDate(endDate)} />
        <TruncateCell text={reservationName} />
        <TruncateCell text={reservationEmail} />
        <TruncateCell key={parsedStatus?.name} text={parsedStatus?.alias}>
          <span key={parsedStatus?.name} css={statusStyle(parsedStatus?.color)}>
            {parsedStatus?.alias}
          </span>
        </TruncateCell>
      </TableBodyRow>
      {isOpen && (
        <TableBodyCell colSpan={8}>
          <AbandonedCartDetails
            activities={activities}
            transports={transports}
          />
        </TableBodyCell>
      )}
    </>
  )
}

const AbandonedCartDetails = ({ activities, transports }) => {
  const tours = useMemo(
    () =>
      [
        ...Object.values(activities || []).flat(),
        ...Object.values(transports || []).flat()
      ].filter(Boolean),
    [activities, transports]
  )

  return (
    <Card css={detailsStyle}>
      <H3>Informação do roteiro</H3>
      <table>
        <tr>
          <th>Fornecedor</th>
          <th>Experiência</th>
          <th>Data</th>
          <th>Hora</th>
          <th>Viajantes</th>
          <th>Valor</th>
        </tr>
        {tours.map(tour => (
          <tr key={tour.id}>
            <td>
              {tour.activity?.company?.name ||
                tour.transport?.company?.name ||
                '--'}
            </td>
            <td>
              {parseTitleProp(
                tour.activity?.title || tour.transport?.title || '--'
              )}
            </td>
            <td>{parseDate(tour.date)}</td>
            <td>{tour.time}</td>
            <td>
              <div className='people-row'>
                {tour.adults +
                  tour.seniors +
                  (tour.children_ages?.length || 0) || 0}
                <div className='info'>
                  <span className='info-icon'>i</span>
                  <div className='info-tooltip'>
                    {tour.adults > 0 ? (
                      <>
                        <div className='info-content'>
                          <AdultIcon customCss={[Icon]} />
                          <p>{tour.adults}</p>
                          <p>{tour.adults > 1 ? 'Adultos' : 'Adulto'}</p>
                        </div>
                      </>
                    ) : null}
                    {(tour.children_ages?.length || 0) > 0 ? (
                      <>
                        <div className='info-content'>
                          <BabyIcon customCss={[Icon]} />
                          <p>{tour.children_ages.length}</p>
                          <p>
                            {tour.children_ages.length > 1
                              ? 'Crianças'
                              : 'Criança'}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {tour.seniors > 0 ? (
                      <>
                        <div className='info-content'>
                          <SeniorIcon customCss={[Icon]} />
                          <p>{tour.seniors}</p>
                          <p>{tour.seniors > 1 ? 'Seniores' : 'Sênior'}</p>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </td>
            <td>{formatMoney(tour.price)}</td>
          </tr>
        ))}
        <br />
      </table>
    </Card>
  )
}

const TruncateCell = ({
  text = '',
  truncateStyle = undefined,
  children = null
}) => (
  <TableBodyCell colSpan={1}>
    {text ? (
      <TooltipOnHover customCss={resetPosition} text={text}>
        {' '}
        <div css={truncateStyle}>{children || text}</div>{' '}
      </TooltipOnHover>
    ) : (
      <div css={truncateStyle}>{children || text}</div>
    )}
  </TableBodyCell>
)

const CheckboxAbandonedCart = ({ setIsOpen, isOpen, name }) => {
  return (
    <div css={checkBoxContainer}>
      <SortRightIcon
        onClick={() => setIsOpen(v => !v)}
        css={arrowIconStyle(isOpen)}
      />
      <span className='nameStyle' onClick={() => setIsOpen(v => !v)}>
        {name}
      </span>
    </div>
  )
}
