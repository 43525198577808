import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const VisibilityContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 30px 0 30px 0;

  h4 {
    font-weight: bold;
    font-size: 18px;
    color: ${colors.gray3};
  }

  .title__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;

    .title__label {
      margin: auto 0;
    }
  }
`

export const formContainer = css`
  max-width: 906px;

  .input__container {
    gap: 5px;
    color: ${colors.gray3};
  }
`

export const submitButton = css`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-weight: normal;
`

export const infoTooltipStyle = css`
  transform: translate(0, -5px);
`
