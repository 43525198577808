import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const SpinnerContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: ${({ size }) => size || '1.5rem'};

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

export const SpinnerComponent = styled.svg`
  animation: ${rotate} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

export const Circle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${dashAnimation} 1.5s ease-in-out infinite;
  stroke-linecap: round;
`
