import { TermParser } from '../Parser/Term'
import { orbCore } from './Orb/Service'

const { termDomain } = orbCore

export const Termservice = {
  getByType(type) {
    return termDomain.getByType(type).then(TermParser.get)
  },
  acceptTerm(termId) {
    return termDomain.acceptTerm(termId)
  }
}
