/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useCallback, useState } from 'react'
import { iconStyle, filterDrawerContainer } from './QuotationsPage.style'
import {
  Button,
  DatePicker,
  FilterIcon,
  GhostButton,
  H2,
  Input,
  InputFormGroup,
  Label,
  Manager,
  Popover,
  Reference,
  Row,
  useToast
} from '@bonitour/components'

export const QuotationsFilter = ({ onFilter, hasFilters = false }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { add: addToast } = useToast()

  const [filters, setFilters] = useState({
    initial_date: '',
    final_date: '',
    agent_name: null,
    client_name: null,
    client_email: null
  })

  const onResetFilters = useCallback(() => {
    setFilters({
      initial_date: '',
      final_date: '',
      agent_name: '',
      client_name: '',
      client_email: ''
    })
    onFilter({
      initial_date: '',
      final_date: '',
      agent_name: null,
      client_name: null,
      client_email: null
    })
  }, [onFilter])

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            onClick={() => setIsOpen(v => !v)}
            css={iconStyle({ hasFilters, isOpen })}
          >
            <FilterIcon />
          </div>
        )}
      </Reference>

      {isOpen && (
        <>
          <Popover position='bottom-end'>
            <>
              <div css={filterDrawerContainer}>
                <H2>Filtrar roteiro por:</H2>
                <Row>
                  <InputFormGroup label='E-mail do Turista'>
                    <Input
                      onChange={v =>
                        setFilters(data => ({ ...data, client_email: v }))
                      }
                      placeholder='Digite o e-mail aqui'
                      value={filters.client_email}
                    />
                  </InputFormGroup>
                </Row>
                <Row>
                  <span
                    className='datesContainer'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '5px'
                    }}
                  >
                    <Label>Data inicial</Label>
                    <DatePicker
                      placeholder='__/__/____'
                      allowsEmpty
                      onChange={initialDate => {
                        if (
                          initialDate > filters.final_date &&
                          filters.final_date
                        ) {
                          return addToast(
                            'A data inicial não pode ser maior que a data final.',
                            'danger'
                          )
                        } else if (initialDate > new Date()) {
                          return addToast(
                            'A data inicial não pode ser maior que a data de hoje.',
                            'danger'
                          )
                        }
                        setFilters(v => ({
                          ...v,
                          initial_date: initialDate
                        }))
                      }}
                      value={filters.initial_date}
                    />
                  </span>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '5px'
                    }}
                  >
                    <Label>Data final</Label>
                    <DatePicker
                      placeholder='__/__/____'
                      allowsEmpty
                      onChange={finalDate => {
                        if (
                          finalDate < filters.initial_date &&
                          filters.initial_date
                        ) {
                          return addToast(
                            'A data final não pode ser menor que a data inicial.',
                            'danger'
                          )
                        } else if (finalDate > new Date()) {
                          return addToast(
                            'A data final não pode ser maior que a data de hoje.',
                            'danger'
                          )
                        }
                        setFilters(v => ({
                          ...v,
                          final_date: finalDate
                        }))
                      }}
                      value={filters.final_date}
                    />
                  </span>
                </Row>
                <Row>
                  <InputFormGroup label='Nome do Agente'>
                    <Input
                      onChange={v =>
                        setFilters(data => ({ ...data, agent_name: v }))
                      }
                      placeholder='Digite o nome aqui'
                      value={filters.agent_name}
                    />
                  </InputFormGroup>
                </Row>
                <Row>
                  <InputFormGroup label='Nome do Turista'>
                    <Input
                      onChange={v =>
                        setFilters(data => ({ ...data, client_name: v }))
                      }
                      value={filters.client_name}
                      placeholder='Digite o nome aqui'
                    />
                  </InputFormGroup>
                </Row>
                <Row
                  style={{ justifyContent: 'space-around' }}
                  className='buttonsContainer'
                >
                  <GhostButton
                    onClick={onResetFilters}
                    style={{ width: '150px' }}
                  >
                    Limpar
                  </GhostButton>
                  <Button
                    type='submit'
                    style={{ width: '150px' }}
                    onClick={() => {
                      setIsOpen(false)
                      onFilter(filters)
                    }}
                  >
                    Filtrar
                  </Button>
                </Row>
              </div>
            </>
          </Popover>
          <button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              width: '100vw',
              height: '100vh',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1
            }}
            onClick={() => setIsOpen(v => !v)}
          />
        </>
      )}
    </Manager>
  )
}
