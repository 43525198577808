/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Card,
  Input,
  InputFormGroup,
  H4,
  Button,
  CheckIcon,
  EditInfoIcon
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import {
  VisibilityContainer,
  formContainer,
  infoTooltipStyle,
  submitButton
} from './CustomRegionForm.style'
import { InfoTooltip } from 'Domains/Dashboard/InfoTooltip'
import { useCallback, useEffect } from 'react'
import { TextareaWithLength } from 'components/TextareaWithLength/TextareaWithLength'
import { InlineToggle } from 'components/InlineToggle/InlineToggle'
import { NewActivityButton } from '../../Components/NewCustomRegionActivity/NewCustomRegionActivity.style'
import { identity } from 'io-ts'

export const CustomRegionForm = ({
  form,
  errors,
  isEditing = false,
  onSubmit,
  onInputChange,
  onInputBlur,
  isLoading = false,
  hasChanges = true,
  onEditExperiences = identity
}) => {
  const onSubmitForm = useCallback(() => {
    onSubmit()
  }, [onSubmit])

  useEffect(() => {
    if (form?.userVisible && !form?.agentVisible) {
      onInputChange('userVisible')(false)
    }
  }, [form, onInputChange])

  return (
    <Card customCss={[formContainer]}>
      <InputFormGroup
        label='Nome da região'
        errorMessage={errors.name}
        className='input__container'
      >
        <Input
          disabled={isLoading}
          maxLength={99}
          value={form.name}
          onChange={onInputChange('name')}
          onBlur={onInputBlur('name')}
          placeholder='Nome'
        />
      </InputFormGroup>

      <VisibilityContainer>
        <div className='title__container'>
          <H4 className='title__label'>Visualização</H4>
          <InfoTooltip aditionalCss={[infoTooltipStyle]}>
            Isso definirá para qual tipo de usuário a região irá aparecer no
            e-commerce
          </InfoTooltip>
        </div>

        <InlineToggle
          id={`new-region-agent-visibility-${form.id}`}
          disabled={isLoading}
          onChange={ev => onInputChange('agentVisible')(ev.target.checked)}
          isEnabled={form.agentVisible}
          label='Visível para agente'
        />
        <InlineToggle
          id={`new-region-user-visibility-${form.id}`}
          disabled={isLoading || !form.agentVisible}
          onChange={ev => onInputChange('userVisible')(ev.target.checked)}
          isEnabled={form.userVisible}
          label='Visível para turista'
        />
      </VisibilityContainer>

      <InputFormGroup
        label='Descrição'
        errorMessage={errors.description}
        className='input__container'
      >
        <TextareaWithLength
          disabled={isLoading}
          value={form.description}
          onChange={onInputChange('description')}
          error={errors.description}
          maxLength={1000}
        />
      </InputFormGroup>

      {isEditing && (
        <NewActivityButton onClick={onEditExperiences}>
          <EditInfoIcon className='icon' /> Editar lista de experiências
        </NewActivityButton>
      )}

      <Button
        disabled={isLoading || !hasChanges}
        onClick={onSubmitForm}
        customCss={[submitButton]}
      >
        {isEditing ? 'Editar região' : 'Criar nova região'}
        <CheckIcon />
      </Button>
    </Card>
  )
}
