import axios from 'axios'
import { UserStore } from 'core/Store/User'

/**
 * @param { { data: T } } response
 * @return T
 * @template T
 */
export const extractData = ({ data }) => data

export const exporterAxiosInstance = () => {
  const instance = axios.create({
    headers: {
      ...(UserStore?.authKey && { Authorization: UserStore?.authKey })
    },
    baseURL: process.env?.REACT_APP_PDF_API_URL,
    responseType: 'arraybuffer'
  })

  return instance
}

/**
 * @param { () => import('axios').AxiosInstance } makeAxiosInstance
 */
function makePoster(makeAxiosInstance) {
  /**
   * @param { string } url
   * @param { any } data
   * @template I, A
   */
  function post(url, data) {
    const axiosInstance = makeAxiosInstance()
    return axiosInstance.post(url, data).then(x => x.data)
  }
  return post
}

export const postExporter = makePoster(exporterAxiosInstance)
