/* eslint-disable react/display-name */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { colors } from '@bonitour/components'
import { css, jsx } from '@emotion/react'

import { Editor, Transforms, Element as SlateElement } from 'slate'
import { useSlate } from 'slate-react'
import { EditorIcon } from './EditorIcon'
import { buttonStyles } from '../Components.styles'
import { EDITOR_TOOLS } from '../consts/EditorConsts'

const toolbarStyles = css`
  width: calc(100% + 40px);
  border-bottom: 1px solid ${colors.gray8};
  margin: -20px -20px 0 -20px;
  padding: 10px 20px;
  box-sizing: border-box;

  display: flex;

  margin-bottom: 20px;
`

const LIST_TYPES = [EDITOR_TOOLS.list, EDITOR_TOOLS.orderedList]

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      LIST_TYPES.includes(
        // @ts-ignore
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true
  })
  const newProperties = {
    type: isActive
      ? EDITOR_TOOLS.paragraph
      : isList
      ? EDITOR_TOOLS.listItem
      : format
  }
  // @ts-ignore
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  // @ts-ignore
  const [match] = Editor.nodes(editor, {
    match: n => {
      if (Editor.isEditor(n)) return false
      if (!SlateElement.isElement(n)) return false

      return n.type === format
    }
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const EditorButton = ({ active, children, ...rest }) => (
  <button css={buttonStyles({ active })} type='button' {...rest}>
    {children}
  </button>
)

const BlockButton = ({ format }) => {
  const editor = useSlate()
  return (
    <EditorButton
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <EditorIcon size='18px' icon={format} />
    </EditorButton>
  )
}

const MarkButton = ({ format }) => {
  const editor = useSlate()
  return (
    <EditorButton
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <EditorIcon size='18px' icon={format} />
    </EditorButton>
  )
}

const toolSet = {
  [EDITOR_TOOLS.bold]: () => <MarkButton format={EDITOR_TOOLS.bold} />,
  [EDITOR_TOOLS.italic]: () => <MarkButton format={EDITOR_TOOLS.italic} />,
  [EDITOR_TOOLS.underline]: () => (
    <MarkButton format={EDITOR_TOOLS.underline} />
  ),
  [EDITOR_TOOLS.strikethrough]: () => (
    <MarkButton format={EDITOR_TOOLS.strikethrough} />
  ),
  [EDITOR_TOOLS.h2]: () => <BlockButton format={EDITOR_TOOLS.h2} />,
  [EDITOR_TOOLS.h3]: () => <BlockButton format={EDITOR_TOOLS.h3} />,
  [EDITOR_TOOLS.h4]: () => <BlockButton format={EDITOR_TOOLS.h4} />,
  [EDITOR_TOOLS.h5]: () => <BlockButton format={EDITOR_TOOLS.h5} />,
  [EDITOR_TOOLS.h6]: () => <BlockButton format={EDITOR_TOOLS.h6} />,
  [EDITOR_TOOLS.list]: () => <BlockButton format={EDITOR_TOOLS.list} />,
  [EDITOR_TOOLS.orderedList]: () => (
    <BlockButton format={EDITOR_TOOLS.orderedList} />
  )
}

export const EditorToolbar = ({ tools = [] }) => {
  return (
    <div css={toolbarStyles}>
      {tools.map(tool => toolSet[tool]?.() || null)}
    </div>
  )
}
