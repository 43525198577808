import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect
} from 'react'
import { useUser } from './User'
import { useHistory } from 'react-router-dom'
import { UserStore } from 'core/Store/User'

const { REACT_APP_ACCOUNTS_URL } = process.env

export const AuthorizationContext = createContext({})

export const useAuthorization = () => useContext(AuthorizationContext)

export const AuthorizationProvider = props => {
  const history = useHistory()
  const { authKey, updateAuthKey } = useUser()

  useEffect(() => {
    if (!authKey) {
      window.location = REACT_APP_ACCOUNTS_URL
    }
  }, [authKey, history])

  const logout = useCallback(() => {
    updateAuthKey()
    localStorage.clear()
    UserStore.clear()
  }, [updateAuthKey])

  const providerData = useMemo(() => ({ logout }), [logout])

  return <AuthorizationContext.Provider value={providerData} {...props} />
}
