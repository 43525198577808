import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const SelectServicesContainer = styled.div`
  width: 100%;
  height: 100%;

  .plus-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--primary-color);
    font-size: 14px;

    span {
      margin-left: 10px;
      font-weight: 700;
    }

    .plus-icon {
      font-size: 16px;
      background-color: var(--primary-color);
      color: ${colors.white};
      height: 30px;
      width: 30px;
      border-radius: var(--round-element-radius, 50%);
    }
  }
`

export const ServicesContent = styled.div`
  display: flex;
  flex-direction: column;

  overflow: auto;
  width: 100%;
`

export const ServiceButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  min-height: 40px;
  color: ${({
    // @ts-ignore
    isActive
  }) => (isActive ? 'var(--primary-color)' : colors.gray3)};
  padding: 0;
  padding-left: 34px;
  position: relative;
  transition: color 300ms;

  i {
    opacity: ${({
      // @ts-ignore
      isActive
    }) => (isActive ? 1 : 0)};
    position: absolute;
    left: 13px;
    transition: opacity 300ms;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  &:hover {
    background: ${colors.gray12};
  }
`

export const dropdownLoading = css`
  margin: 10px auto;
`

export const servicesDropDown = css`
  opacity: 1;
  width: 450px;
  max-width: 100vw;
  max-height: 340px;
  flex-direction: column;
  padding: 10px 10px 10px 0;
  margin-top: -50px;

  .component-popover-arrow {
    top: 55px;
  }

  .complete_button {
    margin-top: 15px;
    max-width: 174px;
    width: 100%;
    align-self: flex-start;
    margin-left: 34px;
    margin-bottom: 5px;
  }
`

export const customSearchBar = css`
  max-width: 426px;
  max-height: 35px;

  &.small-searchbar {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const SelectServicesBg = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 3;
`
