import { css } from '@emotion/react'
import { colors } from '@bonitour/components'

export const noConfigCard = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;

  svg {
    .a {
      fill: #f7f7f7;
    }
    .b {
      fill: #ededed;
    }
  }

  h2 {
    color: ${colors.gray3};
    font-size: 22px;
    margin-top: 30px;
  }

  p {
    color: ${colors.gray3};
    font-size: 14px;
  }

  a {
    background: ${colors.primary};
    height: 40px;
    border-radius: var(--round-element-radius, 20px);
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${colors.white};
    font-weight: 700;

    font-size: 14px;
    text-decoration: none;

    margin-top: 30px;
  }
`
