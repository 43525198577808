/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {
  Button,
  colors,
  GhostButton,
  Row,
  ToggleInputGroup,
  useToast
} from '@bonitour/components'
import { css, jsx } from '@emotion/react'
import { useCallback, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { rowButtons, saveButton } from '../Appearance.style'
import { NoUrlAlert } from './AnalyticsConfigForm'
import { ContentCard } from './ContentCard'
import { putLanguageConfig } from '../Appearance.io'
import { usePermission } from 'Shared/contexts/Permissions'

const description = css`
  color: ${colors.gray5};
  font-size: 14px;
  line-height: 1.285;
  a {
    color: ${colors.indigo2};
  }
`

const toggle = css`
  margin: 10px 0;
`

const togglesWrapper = css`
  margin: 30px 0;
`

export function TranslationsEnabler({ config, setConfig }) {
  const { push } = useHistory()
  const goBack = useCallback(() => push('/'), [push])

  const toggleLanguage = useCallback(
    langToToggle => () => {
      setConfig({
        ...config,
        languages: {
          default_language: config?.languages?.default_language || 'pt-br',
          active_languages: [
            ...(config?.languages?.active_languages?.filter(
              lang => lang !== langToToggle
            ) || []),
            ...(config?.languages?.active_languages?.includes(langToToggle)
              ? []
              : [langToToggle])
          ]
        }
      })
    },
    [config, setConfig]
  )

  const { add: addToast } = useToast()

  const { allowed: canSave } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const save = () => {
    if (!canSave) {
      return addToast(
        'Usuário sem permissão de escrita/atualização do Backoffice'
      )
    }
    const payload = {
      default_language: config?.languages?.default_language || 'pt_br',
      active_languages: JSON.stringify(
        // ensure unique entries and active pt-br:
        Array.from(new Set(config?.languages?.active_languages).add('pt-br'))
      )
    }

    putLanguageConfig(payload)
      .then(languages => {
        addToast('Configurações salvas com sucesso', 'success')
        setConfig({
          ...config,
          languages
        })
      })
      .catch(e => {
        console.error(e)
        addToast('Erro ao salvar configurações')
      })
  }

  const toggleableLangs = useMemo(
    () => [
      { value: 'en-us', label: 'Inglês' },
      { value: 'es-es', label: 'Espanhol' }
    ],
    []
  )

  return (
    <>
      <ContentCard title='Configuração de tradução' marginBottom='10px'>
        {config?.url ? (
          <>
            <p css={description}>
              O Xpert possui tradução padrão em inglês e espanhol para a página
              inicial do Meu Site.
            </p>

            <div css={togglesWrapper}>
              <ToggleInputGroup
                customCss={[toggle]}
                id='toggle-enable-pt-br'
                checked
                disabled
              >
                <span>
                  Idioma padrão do Meu Site em Português (Sempre habilitado)
                </span>
              </ToggleInputGroup>
              {toggleableLangs.map(lang => (
                <ToggleInputGroup
                  customCss={[toggle]}
                  key={lang.value}
                  id={`toggle-enable-${[lang.value]}`}
                  checked={config?.languages?.active_languages?.includes(
                    lang.value
                  )}
                  onChange={toggleLanguage(lang.value)}
                >
                  <span>Habilitar tradução do Meu Site para {lang.label}</span>
                </ToggleInputGroup>
              ))}
            </div>

            <p css={description}>
              {'Para adicionar uma tradução para as experiências cadastradas, '}
              <Link to='/app/regions'>clique aqui</Link>
              {' e acesse a área de Experiências.'}
            </p>
          </>
        ) : (
          <NoUrlAlert tabLabel='traduções' />
        )}
      </ContentCard>
      <Row customCss={[rowButtons]}>
        <GhostButton customCss={[saveButton]} onClick={goBack}>
          Voltar
        </GhostButton>
        {config?.url && (
          <Button customCss={[saveButton]} onClick={save}>
            Salvar
          </Button>
        )}
      </Row>
    </>
  )
}
