import escapeHtml from 'escape-html'
import { Text } from 'slate'

const lineBreakElement = {
  type: 'paragraph',
  children: [{ text: '' }]
}

export const unwrapLineBreaks = node => {
  const childrenList = node.children?.reduce((acc, current) => {
    if (!current.children) return [...acc, current]

    if (
      current.children?.every(child => {
        if (Text.isText(child)) {
          const childText = escapeHtml(child.text)
          if (childText === '') {
            return true
          }
        }
        return false
      })
    ) {
      return [
        ...acc,
        ...Array.from(
          { length: current.children.length },
          () => lineBreakElement
        )
      ]
    }
    return [...acc, current]
  }, [])

  return {
    ...node,
    children: childrenList
  }
}
