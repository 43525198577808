/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { colors, H2, PlusIcon } from '@bonitour/components'
import { jsx, css } from '@emotion/react'
import {
  insetShaddow,
  tabsList,
  titleTab
} from 'Domains/Dashboard/Appearance/components/TitleTabs'

const newTranslationButton = css`
  width: auto;
  padding: 4px 20px;
  opacity: 0.75;

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${colors.primary};

    span.plus__circle {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: var(--round-element-radius, 100vw);

      margin-right: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${colors.primary};
      color: ${colors.white};
      font-size: 1.125em;

      i {
        transform: translateY(1px);
      }
    }
  }
`

export function LanguageTabs({
  tabs = [],
  currentTab,
  onTabSelect,
  onNewLanguage,
  hideButton
}) {
  return (
    <div css={tabsList}>
      {tabs.map(tab => (
        <button
          key={tab.value}
          css={[titleTab, currentTab !== tab.value && insetShaddow]}
          onClick={() => onTabSelect(tab.value)}
        >
          <H2>{tab.label}</H2>
        </button>
      ))}
      {!hideButton && (
        <button
          css={[titleTab, insetShaddow, newTranslationButton]}
          onClick={onNewLanguage}
        >
          <H2>
            <span className='plus__circle'>
              <PlusIcon />
            </span>
            Nova tradução
          </H2>
        </button>
      )}
    </div>
  )
}
