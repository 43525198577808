/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import {
  CopyIcon,
  EditInfoIcon,
  EllipsisIcon,
  ExportIcon,
  Manager,
  Popover,
  Reference,
  SendIcon
} from '@bonitour/components'
import { jsx } from '@emotion/react'

import { useCallback, useState } from 'react'
import { HiLink } from 'react-icons/hi2'
import {
  ActionsButtonContainer,
  ActionsContainer,
  popoverCss
} from './QuotationsActions.style'

export const QuotationsActions = ({
  isUnreserved,
  isPackage,
  isExpired,
  onResend,
  onEdit,
  onClone,
  onCopyPackageLink,
  onExport
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen])

  const onAction = useCallback(
    action => {
      toggle()
      action()
    },
    [toggle]
  )

  return (
    <>
      <Manager>
        <Reference>
          {({ ref }) => (
            <ActionsButtonContainer
              ref={ref}
              onClick={toggle}
              className='options'
            >
              <EllipsisIcon />
            </ActionsButtonContainer>
          )}
        </Reference>
        {isOpen && (
          <Popover customCss={[popoverCss]} position='left'>
            <ActionsContainer>
              {!isPackage && (
                <button onClick={() => onAction(onExport)}>
                  <ExportIcon /> Exportar em PDF
                </button>
              )}
              {isUnreserved && (
                <>
                  {isExpired && !isPackage && (
                    <button onClick={() => onAction(onResend)}>
                      <SendIcon /> Reenviar roteiro
                    </button>
                  )}
                  <button onClick={() => onAction(onEdit)}>
                    <EditInfoIcon /> Editar {isPackage ? 'pacote' : 'roteiro'}
                  </button>
                </>
              )}
              {isPackage && (
                <button onClick={() => onAction(onCopyPackageLink)}>
                  <HiLink /> Copiar link pacote
                </button>
              )}
              {!isPackage && (
                <button onClick={() => onAction(onClone)}>
                  <CopyIcon /> Duplicar roteiro
                </button>
              )}
            </ActionsContainer>
          </Popover>
        )}
      </Manager>
    </>
  )
}
