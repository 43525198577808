import React from 'react'
import { SpinnerContainer, SpinnerComponent, Circle } from './SpinnerStyles'

/**
 * @typedef {{
 *  size: string
 *  color?: string
 *  thickness?: number
 * }} SpinnerProps
 */

/** @type { React.FC<SpinnerProps> } */
export const Spinner = ({ size, color, thickness }) => {
  return (
    // @ts-ignore
    <SpinnerContainer size={size}>
      <SpinnerComponent viewBox='25 25 50 50'>
        <Circle
          className='path'
          cx='50'
          cy='50'
          r='20'
          fill='none'
          stroke={color || 'rgba(255,255,255,0.5)'}
          strokeWidth={thickness || 5}
          strokeMiterlimit='10'
        />
      </SpinnerComponent>
    </SpinnerContainer>
  )
}
