/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import {
  Card,
  ArrowUpIcon,
  useToast,
  LoadingAnimation
} from '@bonitour/components'
import {
  card,
  totalItineraries,
  content,
  iconArrow,
  container,
  loadingStyle
} from './ItinerariesStatistics.style'

import { ProgressBarItineraries } from './ProgressBarItiniraries'

import { getAgentItineraries } from './io/agentItineraries.io'

import { quotationsStatusData } from '../utils/status'
import { CardHeader } from 'components/HeadersDashboard/CardHeader'

/**
 * @typedef DataProps
 * @prop {number} [total]
 * @prop {number} [confirmed]
 * @prop {number} [reserved]
 * @prop {number} [unreservd]
 * @prop {number} [canceled]
 */

const mockedSegments = [
  {
    name: 'Reservado',
    value: 0,
    width: 20,
    color: '#188955'
  },
  {
    name: 'Pago',
    value: 0,
    width: 40,
    color: '#959595'
  },
  {
    name: 'Cancelado',
    value: 0,
    width: 10,
    color: '#FF6461'
  },
  {
    name: 'Expirado',
    value: 0,
    width: 30,
    color: '#EAEAEA'
  }
]

export const ItinerariesStatistics = ({
  mocked = false,
  hideErrors = false
}) => {
  /** @type {[DataProps, (t: DataProps) => void ]} */
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [hasError, setError] = useState(false)

  const { add: addToast } = useToast()

  useEffect(() => {
    if (!addToast || mocked) return
    setLoading(true)
    getAgentItineraries()
      .then(res => {
        setData(res)
      })
      .catch(e => {
        console.error(e)
        setError(true)
        if (!hideErrors) {
          addToast('Erro ao carregar roteiros', 'danger')
        }
      })
      .finally(() => setLoading(false))
  }, [addToast, mocked, hideErrors])

  const segments = useMemo(() => {
    if (!data.total) return []

    const newData = quotationsStatusData
      .map(({ name, alias, color }) => {
        return {
          name: alias || name,
          value: data[name],
          width: (data[name] / data.total) * 100,
          color
        }
      })
      .filter(segment => segment.width)

    return newData
  }, [data])

  if (hasError) {
    return null
  }

  return (
    <Card customCss={[card]}>
      <div css={container}>
        <CardHeader
          titleCard='Todos roteiros'
          linkUrl={loading ? '#' : 'app/quotations'}
        />
        {loading ? (
          <LoadingAnimation css={loadingStyle} />
        ) : (
          <div>
            <div css={content}>
              <ArrowUpIcon customCss={[iconArrow]} />
              <span css={totalItineraries}>
                {mocked ? '0000' : data.total?.toLocaleString('pt-BR')}
              </span>
            </div>
            <ProgressBarItineraries
              segments={mocked ? mockedSegments : segments}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

/*
)} */
