import * as t from 'io-ts'
import { getXpert } from 'external/axios'
import { postExporter } from 'external/exporter'

const optional = type => t.union([type, t.null, t.undefined])

const Company = t.type({
  id: t.string,
  name: t.string
})

const Service = t.type({
  title: t.type({
    'pt-br': t.string
  }),
  image: t.strict({
    file: t.string
  }),
  duration: t.number,
  company: Company
})

const TicketInfo = t.type({
  company_name: t.string,
  price: t.number
})

const ExtraService = t.type({
  title: t.string,
  image: t.string,
  slot: t.string,
  count: t.number,
  ticket_info: TicketInfo
})

const Experience = t.type({
  date: t.string,
  time: t.string,
  price: t.union([t.string, t.number]),
  prices: t.array(
    t.strict({
      name: t.string,
      price: t.string,
      min_age: t.number,
      max_age: t.number,
      capacity: t.number
    })
  ),
  adults: t.number,
  children_ages: t.array(t.number),
  seniors: t.number,
  activity: optional(Service),
  transport: optional(Service)
})

const Experiences = t.record(t.string, t.array(Experience))

const TravelDate = t.type({
  start_date: t.string,
  end_date: t.string
})

const QuotationExportDataType = t.type({
  itinerary: t.type({
    travel_date: optional(TravelDate),
    price: t.string,
    tours: optional(Experiences),
    transports: optional(Experiences),
    extra_services: optional(t.array(ExtraService))
  }),
  whitelabel: t.type({
    logo: optional(t.string),
    colors: t.type({
      primary: t.string,
      secondary: t.string
    })
  }),
  quotation: t.type({
    code: t.string,
    client_name: t.string
  })
})

export async function getQuotationExportData(quotationId) {
  return await getXpert(
    `itineraries/${quotationId}/quotation/export`,
    {},
    QuotationExportDataType
  )
}

export async function postExporterQuotation(data) {
  return await postExporter(`/v1/quotation-pdf`, data)
}
