import { colors } from '@bonitour/components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const FilterDrawerContainer = styled.div`
  background-color: ${colors.white};
  z-index: 2;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  transform: translate(-6px, 5px);

  .contentRegion {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
  }

  .inputRegion {
    background: ${colors.white};
    color: ${colors.gray3};
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: var(--round-element-radius, 100vw);
    border: 1px solid ${colors.gray8};
    padding: 10px 20px;
    font-size: 15px;
    position: relative;
  }

  .regionsOptions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: ${colors.white};
    color: ${colors.gray3};
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    border-radius: var(--round-element-radius, 20px);
    border: 1px solid ${colors.gray8};
    padding: 20px;
    font-size: 15px;
    overflow-y: auto;
  }

  .datas {
    display: flex;
    flex-direction: column;

    &:first-of-type {
      padding-right: 5px;
    }

    &:last-of-type {
      padding-left: 5px;
    }
  }

  .buttonsContainer {
    justify-content: space-around;

    button {
      width: 150px;
    }
  }

  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    right: 5px;
    top: calc(0% - 9px);

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${colors.white};
  }
`

export const selectArrowIcon = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  cursor: pointer;
`

export const iconStyle = ({ hasFilters, isOpen }) => css`
  font-size: 25px;
  color: ${colors.gray6};
  border-radius: var(--round-element-radius, 100px);
  width: 40px;
  height: 40px;
  transition: background-color 0.25s, color 0.25s;
  .b-font-filter {
    transform: translateY(2px);
  }

  ${isOpen &&
  `
  background-color: ${colors.primary};
  color: ${colors.white};
  `}

  .b-font-filter {
    ${hasFilters && 'transform: translateY(2px);'}
    ${hasFilters &&
    `
      &::after {
        width: 8px;
        height: 8px;
        border-radius: 25px;
        transform: translate(-5px, -2px);
        content: '';
        display: inline-block;
        position: absolute;
        background-color: ${colors.yellow2};
      }
    `}
  }

  &:hover {
    ${!isOpen && `background-color: ${colors.white};`}
    cursor: pointer;
  }
`

export const dropdownLoading = css`
  margin: auto;
`
