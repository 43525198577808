/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { H3, ToggleInputGroup } from '@bonitour/components'

const marginless = css`
  margin: 0;
`

const intelligenceSectionStyle = css`
  margin-bottom: 20px;
  padding-bottom: 20px;
`

export const IntelligenceFormSection = ({
  form,
  onInputChange,
  onInputBlur
}) => {
  return (
    <section css={intelligenceSectionStyle}>
      <ToggleInputGroup
        id='use-inteligence-enabled'
        checked={!!form.useIntelligence}
        onChange={ev => onInputChange('useIntelligence')(ev.target.checked)}
        onBlur={onInputBlur('useIntelligence')}
      >
        <H3 customCss={[marginless]}>Usar roteirização inteligente</H3>
      </ToggleInputGroup>
    </section>
  )
}
